import { Avatar, Box, Button, ClickAwayListener, Divider, ListItem, Paper, Popper, Typography, withStyles } from '@material-ui/core'
import React, { useContext } from 'react'
import { LoggedInContext } from '../../contexts/LoggedInContext'
import LanguageSelector from './LanguageSelector'
import { FormattedMessage } from 'react-intl'

const styles = theme => ({
	root: {
		flex: 1,
		marginTop: 10,
		zIndex: 2000
	},
	buttonProfile: {
		width: '40px',
		height: '40px',
		boxShadow: 'none',
		padding: 0,
		backgroundColor: theme.palette.grey['900'],
		color: theme.palette.primary.contrastText
	},
	typoName: {
		marginLeft: theme.spacing(2.4)
	},
	darkBackground: {
		backgroundColor: theme.palette.background.default,
		color: theme.palette.text.secondary
	},
	thickerText: {
		fontWeight: 500
	},
	paper: {
		padding: '15px 0',
		border: '1px solid',
		borderColor: theme.palette.grey['200'],
		borderRadius: 4,
		boxShadow: '0 0 15px 0 rgba(0,25,80,.2)',
		minWidth: 425
	}
})

const LogoutMenu = ({ classes, anchorEl, onClose }) => {
	const { user, logout } = useContext(LoggedInContext)

	if (!user || Object.keys(user).length === 0) {
		return null
	}

	const { firstname, lastname, email } = user
	const initial = `${firstname[0]}${lastname[0]}`.toUpperCase()

	return (
		<Popper
			open={!!anchorEl}
			onClose={onClose}
			anchorEl={anchorEl}
			placement="bottom-end"
			className={classes.root}
		>
			<ClickAwayListener onClickAway={onClose} mouseEvent="onMouseUp">
				<Paper className={classes.paper} >
					<ListItem>
						<Avatar variant="circle" className={classes.darkBackground}>
							<Typography variant="body1">
								{initial}
							</Typography>
						</Avatar>
						<Typography className={classes.typoName}>
							{firstname} {lastname} <br />{email}
						</Typography>
					</ListItem>
					<Divider />
					<ListItem>
						<Box display="flex" flexGrow="1" justifyContent="space-between">
							<LanguageSelector />
							<Button
								style={{ marginLeft: 10 }}
								onClick={logout}
							>
								<Typography variant="body1" className={classes.thickerText}>
									<FormattedMessage id="layout.logout" />
								</Typography>
							</Button>
						</Box>
					</ListItem>
				</Paper>
			</ClickAwayListener>
		</Popper>
	)

}

export default withStyles(styles)(LogoutMenu)
