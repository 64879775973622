import * as React from 'react'
import { FunctionComponent, useState } from 'react'
import { createStyles, ListItem, ListItemIcon, ListItemText, Menu, Theme, withStyles } from '@material-ui/core'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { DrawerMenuItemProps } from './types'
import { drawerMenuItemStyles } from './styles'

const styles = (theme: Theme) => createStyles({
	...drawerMenuItemStyles(theme),
	drawerButtonInactive: {
		...drawerMenuItemStyles(theme).drawerButton,
		opacity: 1
	},
	drawerButtonLight: {
		opacity: 1,
		backgroundColor: theme.palette.background.paper,
		color: theme.palette.primary.dark
	},
	drawerIconDark: {
		opacity: 1,
		color: theme.palette.primary.dark
	},
	drawerSubButton: {
		backgroundColor: theme.palette.background.paper,
		width: '300px',
		'&:hover': {
			opacity: 1
		}
	},
	itemTextDark: {
		color: '#172F50'
	},
	subItemText: {
		fontSize: 17,
		fontWeight: 700,
		color: '#172F50',
		paddingLeft: '30px'
	},
	menu: {
		marginTop: '-35px'
	}
})

const timeOutDuration = 3000

const MultiLevel: FunctionComponent<DrawerMenuItemProps> = ({ item, isCurrent, classes }) => {
	const children = item.children
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
	const [mouseOverListItem, setMouseOverListItem] = useState<boolean>(false)
	const [mouseOverMenu, setMouseOverMenu] = useState<boolean>(false)

	const handleClose = () => {
		setMouseOverMenu(false)
		setMouseOverListItem(false)
		setAnchorEl(null)
	}

	const enterListItem = (event: React.MouseEvent<HTMLElement>) => {
		setMouseOverListItem(true)
		setAnchorEl(event.currentTarget)
	}

	const leaveListItem = () => setTimeout(() => setMouseOverListItem(false), timeOutDuration)

	const enterMenu = () => setMouseOverMenu(true)

	const leaveMenu = () => setMouseOverMenu(false)

	const open = React.useMemo(() => mouseOverListItem || mouseOverMenu, [mouseOverListItem, mouseOverMenu])

	return <>
		<ListItem button onMouseEnter={enterListItem} onMouseLeave={leaveListItem} className={classNames(classes.drawerButtonInactive, {
			[classes.drawerButtonActive]: isCurrent,
			[classes.drawerButtonLight]: open
		})}>
			<ListItemIcon className={classNames(classes.drawerIcon, { [classes.drawerIconDark]: open })}>{item.icon}</ListItemIcon>
			<ListItemText primary={item.label} className={classNames(classes.itemText, { [classes.itemTextDark]: open })} disableTypography />
		</ListItem>
		<Menu
			open={open}
			onClose={handleClose}
			anchorEl={anchorEl}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			transformOrigin={{ vertical: 'top', horizontal: 'left' }}
			className={classes.menu}
			MenuListProps={{ onMouseEnter: enterMenu, onMouseLeave: leaveMenu }}
		>
			{
				children.map((child, index) => (
					<Link to={child.path} key={index}>
						<ListItem button className={classes.drawerSubButton}>
							<ListItemText primary={child.label} className={classes.subItemText} disableTypography />
						</ListItem>
					</Link>
				))
			}
		</Menu>
	</>

}

export default withStyles(styles)(MultiLevel)