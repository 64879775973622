import React, { useCallback, useContext, useEffect, useState } from 'react'
import { ContractsContext } from '../../contexts/ContractsContext'
import { setFilters, setTableUrl } from '../contracts/services/contractsActions'
import { defaultFiltersPriority } from '../contracts/services/contractsReducer'
import ManuallyClosedContractsTable, { getManuallyClosedContracts } from '../kpi/ManuallyClosedContractsTable'

const ClosedContractsTableBox = ({ salesRepId }) => {
	const { dispatch } = useContext(ContractsContext)
	const [stats, setStats] = useState(null)

	useEffect(() => {
		getManuallyClosedContracts(salesRepId)
			.then(data => setStats(data))
	}, [salesRepId])

	const filterCallback = useCallback((row, col) => {
		let filters = { ...defaultFiltersPriority }
		dispatch(setTableUrl('all'))
		filters.status = row
		switch (col) {
			case 'highValue':
				filters.classification = ['HIGH_RISK_HIGH_VALUE']
				break
			case 'lowValue':
				filters.classification = ['LOW_VALUE']
				break
			default:
				break
		}
		dispatch(setFilters(filters))
	}, [dispatch])

	if (!stats) {
		return null
	}

	return <ManuallyClosedContractsTable
		onCellClick={filterCallback}
		stats={stats}
	/>
}

export default ClosedContractsTableBox