import * as React from 'react'
import { FunctionComponent } from 'react'
import { Theme, useMediaQuery } from '@material-ui/core'
import { DrawerItemDescription, MenuItemProps } from './types'
import MultiLevel from './MultiLevel'
import MultiLevelMobile from './MultiLevelMobile'
import SingleLevel from './SingleLevel'

export const hasChildren = (item : DrawerItemDescription) => {
    return item.children !== undefined && item.children.constructor === Array && item.children.length !== 0
}

const DrawerMenuItem: FunctionComponent<MenuItemProps> = ({ item, isCurrent }) => {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
    const MultiLevelMenuItem = isMobile ? MultiLevelMobile : MultiLevel
    const MenuItem = hasChildren(item) ?  MultiLevelMenuItem : SingleLevel
    return <MenuItem item={item} isCurrent={isCurrent}/>
}

export default DrawerMenuItem
