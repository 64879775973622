import { Avatar, Box, Button, Divider, IconButton, ListItem, Popover, Typography, withStyles } from '@material-ui/core'
import React, { useContext, useState } from 'react'
import { LoggedInContext } from '../../contexts/LoggedInContext'
import { UsersContext } from '../../contexts/UsersContext'
import { setUserForUpdateDialog } from './users/services/usersActions'
import { FormattedMessage } from 'react-intl'

const styles = theme => ({
	buttonProfile: {
		width: '40px',
		height: '40px',
		boxShadow: 'none',
		padding: 0,
		backgroundColor: theme.palette.grey['900'],
		color: theme.palette.primary.contrastText
	},
	typoName: {
		marginLeft: theme.spacing(2.4)
	},
	darkBackground: {
		backgroundColor: theme.palette.background.default,
		color: theme.palette.text.secondary
	},
	thickerText: {
		fontWeight: 500
	}
})

const ProfileMenu = ({ classes }) => {
	const { user } = useContext(LoggedInContext)
	const { dispatch } = useContext(UsersContext)
	const [open, setOpen] = useState(false)
	const [anchorEl, setAnchorEl] = useState(null)

	const handleClick = event => {
		setAnchorEl(event.currentTarget)
		setOpen(true)
	}

	if (!user || Object.keys(user).length === 0) {
		return null
	}

	const { firstname, lastname, email } = user
	const initial = `${firstname[0]}${lastname[0]}`.toUpperCase()

	return (
		<>
			<IconButton variant="circle" className={classes.buttonProfile} onClick={handleClick}>
				<Typography variant="body1" color="inherit">{initial}</Typography>
			</IconButton>
			<Popover
				open={open}
				onClose={() => setOpen(false)}
				anchorEl={anchorEl}
				anchorOrigin={{ vertical: 45, horizontal: 'right' }}
			>
				<ListItem>
					<Avatar variant="circle" className={classes.darkBackground}>
						<Typography variant="body1">
							{initial}
						</Typography>
					</Avatar>
					<Typography className={classes.typoName}>
						{firstname} {lastname} <br />{email}
					</Typography>
				</ListItem>
				<Divider />
				<ListItem>
					<Box display="flex" flexGrow="1" justifyContent="space-between">
						<Button variant="contained" className={classes.darkBackground} onClick={() => {
							setOpen(false)
							dispatch(setUserForUpdateDialog(user))
						}}>
							<Typography variant="body1">
								<FormattedMessage id="user.menu.myAccount" />
							</Typography>
						</Button>
						<Button
							style={{ marginLeft: 10 }}
							onClick={() => window.close()}
						>
							<Typography variant="body1" className={classes.thickerText}>
								<FormattedMessage id="user.menu.quit" />
							</Typography>
						</Button>
					</Box>
				</ListItem>
			</Popover>
		</>
	)

}

export default withStyles(styles)(ProfileMenu)
