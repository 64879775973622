import * as React from 'react'
import { FunctionComponent, useEffect, useState } from 'react'
import { loadDashboardContractsCount } from '../contracts/services/contractsApi'
import { LoggedInContext } from '../../contexts/LoggedInContext'
import ContractsIndicatorsCard from '../../components/ContractsIndicatorsCard'
import { injectIntl, IntlShape } from 'react-intl'

type Props = {
	intl: IntlShape
}

const PriorityOpenContracts: FunctionComponent<Props> = ({ intl }) => {

	const [needHelpCount, setNeedHelpCount] = useState(0)
	const [withActionOverdueCount, setWithActionOverdueCount] = useState(0)
	const [contractOverdueCount, setContractOverdueCount] = useState(0)
	const { user } = React.useContext(LoggedInContext)

	useEffect(() => {
		loadDashboardContractsCount().then(data => {
			setNeedHelpCount(data.needHelpCount)
			setWithActionOverdueCount(data.withActionOverdueCount)
			setContractOverdueCount(data.contractOverdueCount)
		})
	}, [user])

	return <ContractsIndicatorsCard
		title={intl.formatMessage({ id: 'pages.dashboard.priorityOpenContracts.title' })}
		indicators={[{
			label: intl.formatMessage({ id: 'pages.dashboard.priorityOpenContracts.indicators.srNeedHelp' }),
			count: needHelpCount,
			link: '/contracts-overview/need-help'
		}, {
			label: intl.formatMessage({ id: 'pages.dashboard.priorityOpenContracts.indicators.contractionWithActionsOverdue' }),
			count: withActionOverdueCount,
			link: '/contracts-overview/actions-overdue'
		}, {
			label: intl.formatMessage({ id: 'pages.dashboard.priorityOpenContracts.indicators.contractsOverdue' }),
			count: contractOverdueCount,
			link: '/contracts-overview/overdue'
		}]}
		button={{
			label: intl.formatMessage({ id: 'pages.dashboard.priorityOpenContracts.button' }),
			link: '/contracts-overview'
		}}
	/>
}

export default injectIntl(PriorityOpenContracts)