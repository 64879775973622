import { useMediaQuery, withStyles } from '@material-ui/core'
import { PaperLoaderDataTable } from 'isotope-client/components/layout/PaperLoader'
import React from 'react'
import PageTitle from '../../components/PageTitle'
import { PROFILE } from '../../enums'
import { AccessChecker } from '../../utils/accessChecker'
import DesktopSRTable from './DesktopSRTable'
import MobileSRTable from './MobileSRTable'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'

const styles = theme => ({
	radius: {
		borderRadius: '5px'
	}
})

const SalesRepOverview = ({ classes, intl }) => {
	const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

	const breadcrumbs = [
		{
			path: '/dashboard',
			label: intl.formatMessage({ id: 'pages.srOverview.breadcrumbs.dashboard' })
		},
		{
			label: intl.formatMessage({ id: 'pages.srOverview.breadcrumbs.srOverview' })
		}
	]

	const title = isMobile ? intl.formatMessage({ id: 'pages.srOverview.title.mobile' }) : intl.formatMessage({ id: 'pages.srOverview.title.desktop' })
	return (
		<AccessChecker profiles={[PROFILE.ZONE_MANAGER, PROFILE.NATIONAL, PROFILE.REGIONAL_MANAGER, PROFILE.BRANCH_MANAGER, PROFILE.BRANCH_MANAGER_ASSISTANT]}>
			<PageTitle title={title} breadcrumbs={breadcrumbs} />
			<PaperLoaderDataTable datatableName="SALES_REP_TABLE" className={classes.radius}>
				{isMobile ? <MobileSRTable /> : <DesktopSRTable />}
			</PaperLoaderDataTable>
		</AccessChecker>
	)
}

export default compose(
	withStyles(styles),
	injectIntl
)(SalesRepOverview)
