import { fetchUser as fetchUserApi } from 'isotope-client'
import React, { useState } from 'react'
import { LoggedInContextProvider } from '../contexts/LoggedInContext'
import LoginPage from '../modules/login/LoginPage'
import App from './App'
import { User } from '../utils/accessChecker'
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react'
import { useAzureToken } from '../config/azureUtils'
import { Language } from '../config/const'
import { changeLocale } from '../config/actions'
import { connect } from 'react-redux'
import Maintenance from './Maintenance'
import fetchIntercept from 'fetch-intercept'
import { withRouter } from 'react-router-dom'

type ConnectedRootReduxProps = {
	changeLocale: (locale: Language) => void
}

const ConnectedRootInternal: React.FC<ConnectedRootReduxProps> = ({ changeLocale }) => {
	const azureToken = useAzureToken()
	const [authenticated, setAuthenticated] = useState<User | null>(null)
	const [fetchingUser, setFetchingUser] = React.useState(true)

	React.useEffect(() => {
		if (azureToken && !authenticated) {
			fetchUserApi(azureToken.idToken)
				.then((response) => {
					if (response) {
						changeLocale(response.locale)
						setAuthenticated({ ...response })
						document.body.classList.toggle('login-content', false)
						document.body.parentElement?.classList.toggle('login-content', false)
					}
					setFetchingUser(false)
				})
				.catch(e => {
					setFetchingUser(false)
					throw e
				})
		}
	}, [azureToken, authenticated, changeLocale])

	if (!azureToken) {
		console.debug('ROOT : No account info')
		return null
	}

	if (fetchingUser) {
		console.debug('ROOT : Fetching user')
		return null
	}
	if (!!authenticated) {
		console.debug('ROOT : Authenticated')

		return <LoggedInContextProvider user={authenticated}>
			<App />
		</LoggedInContextProvider>
	}
	if (!fetchingUser && !authenticated) {
		console.debug('ROOT : Unauthorized')
		return <LoginPage unauthorized />
	} else {
		console.debug('ROOT : Login page')
		return <LoginPage />
	}
}

const actions = {
	changeLocale
}

const ConnectedRoot = connect(undefined, actions)(ConnectedRootInternal)

const Root = () => {
	const [maintenanceStatus, setMaintenanceStatus] = useState(false)

	React.useEffect(() => {
		fetchIntercept.register({
			response: function (response) {
				if (response.headers && response.headers.get('Maintenance') === 'true') {
					setMaintenanceStatus(true)
					return { ...response, status: 200 }
				} else return response
			},
		})
	}, [maintenanceStatus])

	return (
		<>
			<UnauthenticatedTemplate>
				{maintenanceStatus ?
					<Maintenance /> :
					<LoginPage />}
			</UnauthenticatedTemplate>
			<AuthenticatedTemplate>
				{maintenanceStatus ?
					<Maintenance /> :
					<ConnectedRoot />}
			</AuthenticatedTemplate>
		</>
	)
}

export default withRouter(Root)
