import { CardContent, Grid, Typography, withStyles } from '@material-ui/core'
import React from 'react'
import { FormattedMessage } from 'react-intl'

const styles = () => ({
	cardContent: {
		marginBottom: 20,
		padding: 20,
		'& [class*="MuiGrid-container"]': {
			gap: '20px normal',
		},
		backgroundColor: '#F4F6FB',
	},
})

function convertIfNull(value: any) {
	return value !== null && value !== undefined ? value : '-'
}

const ContractFranceDetails = ({ classes, contract }) => {
	return (
		<CardContent className={classes.cardContent}>
			<Grid container>
				<Grid item xs={12} md={3}>
					<Typography variant="subtitle2">
						<FormattedMessage id="pages.contract.information.tabs.detailsTab.moduleFrance.advancedNoticePeriodDate" />
					</Typography>
					<Typography variant="subtitle1">
						{contract.advancedNoticePeriodDate
							? new Date(contract.advancedNoticePeriodDate).toLocaleDateString()
							: '-'}
					</Typography>
				</Grid>
				<Grid item xs={12} md={3}>
					<Typography variant="subtitle2">
						<FormattedMessage id="pages.contract.information.tabs.detailsTab.moduleFrance.requestedCancellationDate" />
					</Typography>
					<Typography variant="subtitle1">
						{contract.requestedCancellationDate
							? new Date(contract.requestedCancellationDate).toLocaleDateString()
							: '-'}
					</Typography>
				</Grid>
				<Grid item xs={12} md={3}>
					<Typography variant="subtitle2">
						<FormattedMessage id="pages.contract.information.tabs.detailsTab.moduleFrance.claimLettersNb" />
					</Typography>
					<Typography variant="subtitle1">{convertIfNull(contract.claimLettersNb)}</Typography>
				</Grid>
				<Grid item xs={12} md={3}>
					<Typography variant="subtitle2">
						<FormattedMessage id="pages.contract.information.tabs.detailsTab.moduleFrance.supervisorName" />
					</Typography>
					<Typography variant="subtitle1">
						{convertIfNull(contract.supervisorName)}
					</Typography>
				</Grid>
				<Grid item xs={12} md={3}>
					<Typography variant="subtitle2">
						<FormattedMessage id="pages.contract.information.tabs.detailsTab.moduleFrance.contractEndTypology" />
					</Typography>
					<Typography variant="subtitle1">
						{convertIfNull(contract.contractEndTypology)}
					</Typography>
				</Grid>
				<Grid item xs={12} md={3}>
					<Typography variant="subtitle2">
						<FormattedMessage id="pages.contract.information.tabs.detailsTab.moduleFrance.contractCategory" />
					</Typography>
					<Typography variant="subtitle1">
						{convertIfNull(contract.contractCategory)}
					</Typography>
				</Grid>
				<Grid item xs={12} md={3}>
					<Typography variant="subtitle2">
						<FormattedMessage id="pages.contract.information.tabs.detailsTab.moduleFrance.customerSatisfactionLvl.title" />
					</Typography>
					<Typography variant="subtitle1">
						{contract.customerSatisfactionLvl ? (
							<FormattedMessage
								id={`pages.contract.information.tabs.detailsTab.moduleFrance.customerSatisfactionLvl.${contract.customerSatisfactionLvl}`}
							/>
						) : (
							'-'
						)}
					</Typography>
				</Grid>
				<Grid item xs={12} md={3}>
					<Typography variant="subtitle2">
						<FormattedMessage id="pages.contract.information.tabs.detailsTab.moduleFrance.mechanicName" />
					</Typography>
					<Typography variant="subtitle1">{convertIfNull(contract.mechanicName)}</Typography>
				</Grid>
			</Grid>
		</CardContent>
	)
}

export default withStyles(styles)(ContractFranceDetails)
