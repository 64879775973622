import React from 'react'
import { isWidthDown, Paper, withStyles, WithStyles, withWidth } from '@material-ui/core'
import { tabBarStyles } from '../../style'
import { injectIntl, IntlShape } from 'react-intl'
import TableAdoption from './TableAdoption'
import { REPORTING_PAGES } from '../../../../constants'
import ReportingFiltersForm from '../../ReportingFiltersForm'
import { onChange, validate } from '../utils'
import { ReportingContextProps } from '../../types'
import { ReportingContext } from '../../../../contexts/ReportingContext'
import { compose } from 'recompose'
import { WithWidth } from '@material-ui/core/withWidth/withWidth'
import MobileTableAdoption from './MobileAdoptionTable'
import classNames from 'classnames'

interface Props extends WithStyles<typeof tabBarStyles>, WithWidth {
	intl: IntlShape
}

const DetailedAdoption: React.FunctionComponent<Props> = ({ classes, intl, width }) => {
	const { setFilters } = React.useContext<ReportingContextProps>(ReportingContext)

	return (
		<Paper className={classNames(classes.radiusWithTabBar, isWidthDown('sm', width) ? classes.tabContentMobile : classes.tabContentDesktop)}>
			<div className={classes.pad}>
				<ReportingFiltersForm
					validate={validate(intl)}
					onChange={onChange(setFilters)}
					tab="adoption"
					showView
					alignRight
					showLabels={false}
				/>
			</div>
			<div className={classes.table}>
				{
					isWidthDown('sm', width) ? <MobileTableAdoption target={REPORTING_PAGES.DETAILED} /> : (
							<TableAdoption target={REPORTING_PAGES.DETAILED}/>
					)
				}
			</div>
		</Paper>
	)
}

const enhance = compose(
	withWidth(),
	withStyles(tabBarStyles),
	injectIntl
)

export default enhance(DetailedAdoption)