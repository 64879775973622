import React, { FunctionComponent } from 'react'
import { isWidthDown, Paper, WithStyles, withStyles, withWidth, WithWidth } from '@material-ui/core'
import { tabBarStyles } from '../../style'
import classNames from 'classnames'
import { compose } from 'recompose'
import { REPORTING_PAGES } from '../../../../constants'
import TableActivity from '../../details/activity/TableActivity'
import MobileTableActivity from '../../details/activity/MobileTableActivity'
import ComparativeActivityChart from './ComparativeActivityChart'

interface Props extends WithStyles<typeof tabBarStyles>, WithWidth {
}

const ComparativeActivity: FunctionComponent<Props> = ({ classes, width }) => {
	return (
		<Paper
			className={classNames(classes.radiusWithTabBar, isWidthDown('sm', width) ? classes.tabContentMobile : classes.tabContentDesktop)}>
			<ComparativeActivityChart />
			<div className={classes.table}>
				{
					isWidthDown('sm', width) ? <MobileTableActivity target={REPORTING_PAGES.TEAMS} /> : (
						<TableActivity target={REPORTING_PAGES.TEAMS} />
					)
				}
			</div>
		</Paper>
	)
}

const enhance = compose(
	withWidth(),
	withStyles(tabBarStyles)
)

export default enhance(ComparativeActivity)
