import {
	Box,
	Collapse,
	createStyles,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Typography,
	withStyles,
} from '@material-ui/core'
import { ExpandLessRounded, ExpandMoreRounded, FiberManualRecordRounded as CircleIcon } from '@material-ui/icons'
import { fetchFactory } from 'isotope-client'
import React, { useContext, useEffect, useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { compose } from 'recompose'
import NoResultFragment from '../../components/NoResult'
import StatusChip from '../../components/StatusChip'
import { ErrorHandlerContext } from '../../contexts/ErrorHandlerContext'
import { LoggedInContext } from '../../contexts/LoggedInContext'
import { ForecastLightBean } from '../../interfaces'
import { getColorByStatus } from '../../utils/getColorByStatus'
import { sanitizeLabel } from '../../utils/stringUtils'
import TablePaginationActions from '../datatable/TablePaginationActions'
import ContractComments from '../contracts/ContractComments'

interface ForecastPage {
	content: ForecastLightBean[]
	empty: boolean
	first: boolean
	last: boolean
	number: number
	numberOfElements: number
	pageable: Record<string, string>
	size: number
	sort: string[]
	totalElements: number
	totalPages: number
}

const styles = (theme: any) => {
	return createStyles({
		cardTable: {
			display: 'flex',
			flexDirection: 'column',
			flex: '0 1 300px',
			width: '100%',
		},
		titleCardTable: {
			fontSize: 15,
			fontWeight: 700,
			color: theme.palette.grey['800'],
		},
		subCardTable: {
			fontSize: 12,
			fontWeight: 400,
			textTransform: 'uppercase',
			color: theme.palette.grey['500'],
		},
		avatar: {
			marginRight: 15,
		},
		iconTypology: {
			height: 26,
			width: 26,
			padding: 3,
			margin: '0 3px',
			borderRadius: 5,
			borderColor: theme.palette.background.lightShadow,
			border: '2px solid',
		},
		error: {
			color: theme.palette.error.main,
			fontSize: 15,
			fontWeight: 600,
		},
		clockIcon: {
			color: theme.palette.warning.icon2,
		},
		rowHead: {
			'&:nth-child(4n+3)': {
				backgroundColor: `${theme.palette.background.light}!important`,
			},
			'&:nth-child(4n+1)': {
				backgroundColor: 'white!important',
			},
		},
		rowBody: {
			'&:nth-child(4n)': {
				backgroundColor: `${theme.palette.background.light}!important`,
			},
			'&:nth-child(4n+2)': {
				backgroundColor: 'white!important',
			},
		},
		comments: {
			padding: '15px 0px',
		},
	})
}

function capitalize(string: string) {
	return `${string.charAt(0).toUpperCase()}${string.slice(1)}`
}

const ForecastsListDesktop = ({ classes, match, intl, filters }) => {
	const { user } = useContext(LoggedInContext)
	const { setError } = useContext(ErrorHandlerContext)
	const [unitsNumber, setUnitsNumber] = useState(0)
	const [forecastPage, setForecastPage] = useState<ForecastPage>()
	const [open, setOpen] = useState<number>(-1)
	const [refresh, setRefresh] = useState(true)

	useEffect(() => {
		setRefresh(true)
		setOpen(-1)
	}, [filters])

	useEffect(() => {
		const searchParams = new URLSearchParams()
		for (const key of Object.keys(filters)) searchParams.set(key, filters[key])
		searchParams.set('page', forecastPage ? forecastPage.number.toString() : '0')
		searchParams.set('size', '10')
		if (refresh) {
			fetchFactory(
				`/contracts-negotiations/forecasts${match.params.id ? `/${match.params.id}` : ''}?${searchParams.toString()}`,
				{},
				'/api'
			)
				.then((result: ForecastPage) => {
					setRefresh(false)
					setForecastPage(result)
					fetchFactory(
						`/contracts-negotiations/units-number${
							match.params.id ? `/${match.params.id}` : ''
						}?${searchParams.toString()}`,
						{},
						'/api'
					).then((result: any) => setUnitsNumber(result))
				})
				.catch((error: any) => error && setError && setError(error))
		}
	}, [filters, forecastPage, match.params.id, setError, refresh])

	const handlePageChange = (_event: any, page: number) => {
		if (forecastPage) {
			setOpen(-1)
			forecastPage.number = page
			setRefresh(true)
		}
	}

	if (forecastPage && forecastPage.numberOfElements === 0) {
		return <NoResultFragment message="No forecasts found" />
	}

	return (
		<TableContainer component={Paper}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>
							<FormattedMessage
								id="forecasts.page.list.headers.contract"
								values={{ contractNumber: forecastPage?.totalElements }}
							/>
						</TableCell>
						<TableCell>
							<FormattedMessage id="forecasts.page.list.headers.status" />
						</TableCell>
						<TableCell align="right">
							<FormattedMessage id="forecasts.page.list.headers.units" values={{ unitsNumber: unitsNumber }} />
						</TableCell>
						<TableCell align="right">
							<FormattedMessage id="forecasts.page.list.headers.probability" />
						</TableCell>
						<TableCell align="right">
							<FormattedMessage id="forecasts.page.list.headers.cancellationMonth" />
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{forecastPage?.content.map((forecast: ForecastLightBean, index: number) => (
						<React.Fragment key={forecast.id}>
							<TableRow className={classes.rowHead}>
								<TableCell style={{ paddingLeft: 22 }}>
									<Box display="flex" alignItems="center">
										<IconButton
											style={{ marginRight: 8 }}
											size="small"
											onClick={() => setOpen((prevValue) => (prevValue === index ? -1 : index))}
										>
											{open === index ? <ExpandLessRounded /> : <ExpandMoreRounded />}
										</IconButton>
										<Box className={classes.cardTable}>
											<Link to={`/contracts/${forecast.idContract}`}>
												<Typography display="inline" className={classes.titleCardTable}>
													{forecast.customerName} ({forecast.idContract})
												</Typography>
												<Typography className={classes.subCardTable}>{forecast.customerAddress}</Typography>
											</Link>
										</Box>
									</Box>
								</TableCell>
								<TableCell>
									<StatusChip
										Icon={CircleIcon}
										color={getColorByStatus(forecast.manualStatus).color}
										gradient={getColorByStatus(forecast.manualStatus).gradient}
										label={sanitizeLabel(
											intl.formatMessage({ id: `forecasts.page.list.information.status.${forecast.manualStatus}` })
										)}
									/>
								</TableCell>
								<TableCell align="right">{forecast.unitsNumber}</TableCell>
								<TableCell align="right">
									<FormattedMessage id={`forecasts.probability.${forecast.forecastProbability}.label`} />
								</TableCell>
								<TableCell align="right">
									{capitalize(new Date(forecast.forecastCancellationMonth).toLocaleString(user?.locale, {
										month: 'long',
										year: 'numeric',
									}))}
								</TableCell>
							</TableRow>
							<TableRow className={classes.rowBody}>
								<TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 30, paddingRight: 30 }} colSpan={5}>
									<Collapse in={open === index} timeout="auto" unmountOnExit>
										<Box className={classes.comments}>
											<ContractComments
												managers={{
													date: forecast.managersCommentDate,
													firstname: forecast.managersCommentFirstName,
													lastname: forecast.managersCommentLastName,
													text: forecast.managersCommentText,
												}}
												salesRep={{
													date: forecast.srCommentDate,
													firstname: forecast.srCommentFirstName,
													lastname: forecast.srCommentLastName,
													text: forecast.srCommentText,
												}}
												update={false}
											/>
										</Box>
									</Collapse>
								</TableCell>
							</TableRow>
						</React.Fragment>
					))}
				</TableBody>
			</Table>
			{forecastPage && (
				<TablePagination
					ActionsComponent={(props: any) => <TablePaginationActions {...props} noPagination={false} />}
					count={forecastPage.totalElements}
					rowsPerPage={forecastPage.size}
					page={forecastPage.number}
					onChangePage={handlePageChange}
					rowsPerPageOptions={[]}
					labelRowsPerPage={null}
					labelDisplayedRows={() => null}
				/>
			)}
		</TableContainer>
	)
}

export default compose(withStyles(styles), withRouter, injectIntl)(ForecastsListDesktop)
