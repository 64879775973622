import React from 'react'
import { injectIntl } from 'react-intl'
import { Tooltip } from '@material-ui/core'

const SROpenActions = ({ percent, plannedCount, overdueCount, intl }) => {
	return (
		<svg viewBox="0 0 36 36">
			<Tooltip title={intl.formatMessage(
					{ id: "pages.srPortfolio.openActions.overdueActions.tooltip" },
					{ count: overdueCount }
				)}
				placement="left-start"
			>
				<path className="circle-bg"
					fill="none"
					stroke="#F65275"
					strokeWidth="3"
					d="M18 2.0845
			  a 15.9155 15.9155 0 0 1 0 31.831
			  a 15.9155 15.9155 0 0 1 0 -31.831"
				/>
			</Tooltip>
			<Tooltip
				title={intl.formatMessage(
					{ id: "pages.srPortfolio.openActions.plannedActions.tooltip" },
					{ count: plannedCount }
				)}
				placement="right-start"
			>
				<path
					d="M18 2.0845
			  a 15.9155 15.9155 0 0 1 0 31.831
			  a 15.9155 15.9155 0 0 1 0 -31.831"
					fill="none"
					stroke="#34CB81"
					strokeWidth="3"
					strokeDasharray={`${percent}, 100`}
				/>
			</Tooltip>
		</svg>
	)
}

export default injectIntl(SROpenActions)