import { Box, Chip, makeStyles, Typography } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(theme => {

	return ({
		root: props => {
			let color = props.color ? theme.palette[props.color] : null
			if (color && props.gradient) {
				color = color[props.gradient]
			} else if (color && !props.gradient) {
				color = color.main
			} else {
				color = theme.palette.grey['800']
			}

			return ({
				borderRadius: '5px',
				color: color,
				borderColor: color
			})
		},
		chip: {
			'& [class*="MuiSvgIcon-root"]': {
				height: 12,
				width: 12,
				marginRight: 5
			},
			'& [class*="MuiTypography-root"]': {
				fontSize: 12,
				fontWeight: 550
			}
		}
	})
})

const StatusChip = ({ label, Icon, color, gradient }) => {
	const classes = useStyles({ color, gradient })
	return (
		<Chip variant="outlined" className={classes.root} label={
			<Box display="flex" alignItems="center" className={classes.chip}>
				{Icon && React.createElement(Icon)}
				<Typography display="inline">{label}</Typography>
			</Box>
		} />
	)
}

export default StatusChip




