import * as React from 'react'
import { FunctionComponent } from 'react'
import Grid from '@material-ui/core/Grid'
import PageTitle from '../../components/PageTitle'
import SalesRepList from './SalesRepList'
import ManuallyClosedContracts from './ManuallyClosedContracts'
import { AccessChecker, hasAnyProfile } from '../../utils/accessChecker'
import { PROFILE } from '../../enums'
import { LoggedInContext } from '../../contexts/LoggedInContext'
import BranchesList from './BranchesList'
import TopPriorityContractsDistrib from './TopPriorityContractsDistrib'
import HighRiskContracts from './HighRiskContracts'
import PriorityOpenContracts from './PriorityOpenContracts'
import { injectIntl, IntlShape } from 'react-intl'
import RegionsList from "./RegionsList"
import {getCountryRegions} from "../regions/api"

type Props = {
	intl: IntlShape
}

const DashboardPage: FunctionComponent<Props> = ({ intl }) => {
	const { user } = React.useContext(LoggedInContext)

	const [hasRegions, setHasRegions] = React.useState(false)

	React.useEffect(() => {
		if (user && hasAnyProfile(user, [PROFILE.ZONE_MANAGER, PROFILE.NATIONAL])) {
			getCountryRegions(user.countryId)
				.then((regions: any []) => {
					setHasRegions(regions.length > 0)
				})
		}
	}, [user])

	return (
		<AccessChecker profiles={[PROFILE.ZONE_MANAGER, PROFILE.NATIONAL, PROFILE.REGIONAL_MANAGER, PROFILE.BRANCH_MANAGER, PROFILE.BRANCH_MANAGER_ASSISTANT]}>
			<PageTitle 
				title={intl.formatMessage(
					{ id: 'pages.dashboard.title' }, 
					{ level: user?.branchName ? user.branchName : user?.regionName ? user.regionName : user?.countryName }
				)} 
				topActionButtons={[]} 
				breadcrumbs={[]} 
			/>
			<Grid container spacing={1} wrap="wrap">
				<Grid item xs={12} md={8}>
					<Grid container spacing={1}>
						<Grid item xs={12}>
							{user && hasAnyProfile(user, [PROFILE.ZONE_MANAGER, PROFILE.NATIONAL, PROFILE.REGIONAL_MANAGER]) && !user.branchId ?
								hasRegions ? (user.regionId ? <BranchesList /> : <RegionsList />) : <BranchesList />
								: <SalesRepList />
							}
						</Grid>
						<Grid item xs={12}>
							<HighRiskContracts />
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} md={4}>
					<Grid container spacing={1}>
						<Grid item xs={12}>
							<PriorityOpenContracts />
						</Grid>
						<Grid item xs={12}>
							<TopPriorityContractsDistrib />
						</Grid>
						<Grid item xs={12}>
							<ManuallyClosedContracts />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</AccessChecker>
	)
}

export default injectIntl(DashboardPage)
