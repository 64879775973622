import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormLabel,
	makeStyles
} from '@material-ui/core'
import Grid from '@material-ui/core/Grid/Grid'
import IconButton from '@material-ui/core/IconButton/IconButton'
import { Close as CloseIcon } from '@material-ui/icons'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import StatusChip from '../../../components/StatusChip'
import { TABLE_URL } from '../../../components/TabBarDesktop'
import { allFilters, ContractsContext } from '../../../contexts/ContractsContext'
import { getColorByStatus } from '../../../utils/getColorByStatus'
import { sanitizeLabelLower } from '../../../utils/stringUtils'
import { resetFilters, setFilters } from '../services/contractsActions'
import { defaultFiltersAll, defaultFiltersMonthly, defaultFiltersPriority, defaultOverviewFiltersAll, defaultOverviewFiltersCancelled, defaultOverviewFiltersPriority } from '../services/contractsReducer'
import { injectIntl, FormattedMessage } from 'react-intl'
import { OVERVIEW_TABLE_URL } from '../../../constants'

const useStyles = makeStyles((theme) => ({
	root: {
		padding: 0,
		width: '100%'
	},
	groupFilter: {
		minWidth: 125
	},
	labelFilter: {
		fontSize: 12,
		fontWeight: 600,
		color: theme.palette.text.light,
		marginBottom: 15
	},
	link: {
		color: theme.palette.primary.light
	},
	text: {
		backgroundColor: theme.palette.background.light,
		padding: '10px'
	}
}))

const ContractFiltersMobile = ({ open, closeFilters, intl, modules, setFiltered }) => {
	const { tableUrl, filters, dispatch } = useContext(ContractsContext)
	const [tempFilters, setTempFilters] = useState(null)
	const classes = useStyles()

	useEffect(() => setTempFilters(filters), [filters])
	const handleChange = useCallback((filters, setFilters, targetFilter, groupKey) => {
		// If the targetFilter is in the filtersList (we are unchecking the box)
		if (filters[groupKey].includes(targetFilter.value)) {
			// then we remove it from the list
			setFilters({ ...filters, [groupKey]: filters[groupKey].filter(a => a !== targetFilter.value) })
		} else {
			// else we add it (the box was just checked)
			setFilters({ ...filters, [groupKey]: [...filters[groupKey], targetFilter.value] })
		}
	}, [])

	const validate = () => {
		const newFilters = { ...tempFilters, searchQuery: filters.searchQuery, sorting: filters.sorting }
		const searchParams = new URLSearchParams(window.location.search)
		for (const key of Object.keys(newFilters)) {
			searchParams.delete(key)
			if (newFilters[key].length > 0) {
				if (typeof newFilters[key] !== "string") {
					for (const value of newFilters[key]) {
						searchParams.append(key, value)
					}
				} else searchParams.append(key, newFilters[key])
			}
		}
		let tabDefaultFilters
		switch (tableUrl) {
			case TABLE_URL.PRIORITY:
				tabDefaultFilters = defaultFiltersPriority
				break;
			case TABLE_URL.MONTHLY:
				tabDefaultFilters = defaultFiltersMonthly
				break;
			case TABLE_URL.ALL:
				tabDefaultFilters = defaultFiltersAll
				break;
			case OVERVIEW_TABLE_URL.PRIORITY:
				tabDefaultFilters = defaultOverviewFiltersPriority
				break;
			case OVERVIEW_TABLE_URL.CANCELLED:
				tabDefaultFilters = defaultOverviewFiltersCancelled
				break;
			case OVERVIEW_TABLE_URL.ALL:
				tabDefaultFilters = defaultOverviewFiltersAll
				break;
			default:
				
		}
		if (tabDefaultFilters !== null && JSON.stringify(tempFilters).localeCompare(JSON.stringify(tabDefaultFilters)) === 0) {
			setFiltered(false);
			window.history.replaceState(null, "", `${window.location.pathname}`)
		} else window.history.replaceState(null, "", `${window.location.pathname}?${searchParams.toString()}`)
		dispatch(setFilters(newFilters))
		closeFilters()
	}

	const filterGroups = [
		{
			label: intl.formatMessage({ id: 'pages.contractsOverview.filters.headers.status' }),
			key: 'status'
		},
		{
			label: intl.formatMessage({ id: 'pages.contractsOverview.filters.headers.classification' }),
			key: 'classification'
		},
		{
			label: intl.formatMessage({ id: 'pages.contractsOverview.filters.headers.risk' }),
			key: 'risk'
		},
		{
			label: intl.formatMessage({ id: 'pages.contractsOverview.filters.headers.value' }),
			key: 'value'
		},
		{
			label: intl.formatMessage({ id: 'pages.contractsOverview.filters.headers.flags' }),
			key: 'flags'
		},
		{
			label: intl.formatMessage({ id: 'pages.contractsOverview.filters.headers.endDate' }),
			key: 'endDate'
		}
	]

	if (modules && modules.countryModule === 'FR' && (tableUrl === TABLE_URL.ALL || tableUrl === OVERVIEW_TABLE_URL.ALL)) {
		filterGroups.push({
			label: intl.formatMessage({ id: 'pages.contractsOverview.filters.headers.customerFeedback' }),
			key: 'customerFeedback',
		})
	}

	if (!filters || !tempFilters) {
		return null
	}
	return (
		<Dialog
			fullScreen
			className={classes.root}
			maxWidth="xs"
			open={open}
			onClose={closeFilters}
		>
			<DialogTitle>
				<Box display="flex" flexGrow="1" alignItems="center" justifyContent="space-between">
					<Box>
						<FormattedMessage id="pages.contractsOverview.filters.title" />
					</Box>
					<IconButton edge="start" color="inherit" onClick={closeFilters} aria-label="close">
						<CloseIcon />
					</IconButton>
				</Box>
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={12} className={classes.text}>
						<FormattedMessage id="pages.contractsOverview.filters.help" />
					</Grid>
					{filterGroups.map(group =>
						<Grid item xs={6} key={group.key}>
							<FormControl component="fieldset" className={classes.groupFilter}>
								<FormLabel component="legend" className={classes.labelFilter}>{group.label}</FormLabel>
								<FormGroup>
									{allFilters[group.key].map((targetFilter) => (
										<FormControlLabel
											key={`${group.key}${targetFilter.value}`}
											control={
												<Checkbox checked={tempFilters[group.key].includes(targetFilter.value)}
												          onChange={() => handleChange(tempFilters, setTempFilters, targetFilter, group.key)}
												/>
											}
											label={group.key === 'status' ?
												<StatusChip label={sanitizeLabelLower(intl.formatMessage({ id: `pages.contractsOverview.filters.${group.key}.${targetFilter.key}` }))}
												            {...getColorByStatus(targetFilter.value)} /> : intl.formatMessage({ id: `pages.contractsOverview.filters.${group.key}.${targetFilter.key}` })}
										/>
									))
									}
								</FormGroup>
							</FormControl>
						</Grid>
					)}
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button size="large" color="primary" disableElevation onClick={() => {
					dispatch(resetFilters())
					switch (tableUrl) {
						case TABLE_URL.PRIORITY:
							setTempFilters({ ...defaultFiltersPriority })
							break
						case TABLE_URL.MONTHLY:
							setTempFilters({ ...defaultFiltersMonthly })
							break
						case TABLE_URL.ALL:
							setTempFilters({ ...defaultFiltersAll })
							break
						case OVERVIEW_TABLE_URL.PRIORITY:
							setTempFilters({ ...defaultOverviewFiltersPriority })
							break
						case OVERVIEW_TABLE_URL.CANCELLED:
							setTempFilters({ ...defaultOverviewFiltersCancelled })
							break
						case OVERVIEW_TABLE_URL.ALL:
							setTempFilters({ ...defaultOverviewFiltersAll })
							break
						default:
					}
				}
				}>
					<FormattedMessage id="pages.contractsOverview.filters.reset" />
				</Button>
				<Button variant="contained" size="large" color="primary" disableElevation
				        onClick={validate}>
					<FormattedMessage id="pages.contractsOverview.filters.apply" />
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default injectIntl(ContractFiltersMobile)
