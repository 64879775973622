import { withStyles } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle'
import React, { useCallback, useContext } from 'react'
import { ActionsContext } from '../../contexts/ActionsContext'
import { ContractsContext } from '../../contexts/ContractsContext'
import { setContractStatusInProgress } from '../contracts/services/contractsActions'
import ActionForm from './ActionForm'
import { addAction } from './services/actionsActions'
import * as api from './services/actionsApi'
import { ErrorHandlerContext } from '../../contexts/ErrorHandlerContext'
import { FormattedMessage, injectIntl } from 'react-intl'
import { compose } from 'recompose'

const styles = (theme) => {
	return ({
			tab: {
				display: 'flex',
				width: 'auto'
			}
		}
	)
}

const NewActionDialog = ({ contract, open, onClose, setLastCreatedActionId, scrollToAction, intl }) => {
	const { dispatch: actionsDispatch } = useContext(ActionsContext)
	const { dispatch: contractsDispatch } = useContext(ContractsContext)
	const { setError } = React.useContext(ErrorHandlerContext)

	const initialValues = {
		idContractNegotiation: contract.contractNegotiation.id,
		type: '',
		deadline: '',
		description: ''
	}
	const createAction = useCallback((data) => {
		api.createAction(data).then(data => {
			actionsDispatch(addAction(data))
			contractsDispatch(setContractStatusInProgress())
			setLastCreatedActionId(data.id)
			onClose()
			scrollToAction()
		})
			.catch(error => setError(error))
	}, [onClose, actionsDispatch, contractsDispatch, setError, setLastCreatedActionId, scrollToAction])

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
			<DialogTitle><FormattedMessage id="action.contract.new.title" /></DialogTitle>
			<ActionForm initialValues={initialValues} onSubmit={createAction} onClose={onClose} validateButtonLabel={intl.formatMessage({ id: 'action.contract.new.buttonLabel' })} contract={contract} />
		</Dialog>
	)
}

export default compose(
	withStyles(styles),
	injectIntl
)(NewActionDialog)
