import { withStyles } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { ActionsContext } from '../../contexts/ActionsContext'
import ActionForm from './ActionForm'
import { updateAction } from './services/actionsActions'
import * as api from './services/actionsApi'
import { ErrorHandlerContext } from '../../contexts/ErrorHandlerContext'
import { FormattedMessage, injectIntl } from 'react-intl'
import { compose } from 'recompose'

const styles = (theme) => {
	return ({
			tab: {
				display: 'flex',
				width: 'auto'
			}
		}
	)
}


const UpdateActionDialog = ({ contract, action, open, onClose, intl }) => {
	const { dispatch } = useContext(ActionsContext)
	const { setError } = React.useContext(ErrorHandlerContext)

	const [initialValues, setInitialValues] = useState({
		idContractNegotiation: contract.contractNegotiation.id,
		type: 'none',
		deadline: null,
		description: ''
	})

	useEffect(() => {
		if (action && contract && contract.contractNegotiation) {
			setInitialValues({
				id: action.id,
				idContractNegotiation: contract.contractNegotiation.id,
				type: action.type,
				deadline: action.deadline,
				description: action.description
			})
		}
	}, [action, contract])

	const update = useCallback((data) => {
		api.updateAction(data).then(action => {
			dispatch(updateAction(action))
			onClose()
		})
			.catch(error => setError(error))
	}, [onClose, dispatch, setError])

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
			<DialogTitle><FormattedMessage id="action.contract.update.title" /></DialogTitle>
			<ActionForm initialValues={initialValues} onSubmit={update} onClose={onClose} validateButtonLabel={intl.formatMessage({ id: 'action.contract.update.buttonLabel' })} contract={contract}/>
		</Dialog>
	)
}

export default compose(
	withStyles(styles),
	injectIntl
)(UpdateActionDialog)
