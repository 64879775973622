import React, { FunctionComponent } from 'react'
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl'
import PageTitle from '../../../components/PageTitle'
import { LoggedInContext } from '../../../contexts/LoggedInContext'
import { ReportingContext } from '../../../contexts/ReportingContext'
import { LEVEL, REPORTING_URL } from '../../../constants'
import { Box, Button } from '@material-ui/core'
import { compose } from 'recompose'
import TabBar from '../../../components/TabBar'
import ComparativePerformance from './performance/ComparativePerformance'
import TabPanel from '../../../components/TabPanel'
import ComparativeAdoption from './adoption/ComparativeAdoption'
import ComparativeActivity from './activity/ComparativeActivity'
import { RouteComponentProps, withRouter } from 'react-router'
import { PROFILE } from '../../../enums'

const tabs = [{
	path: REPORTING_URL.ADOPTION,
	labelId: 'pages.reporting.tabBar.adoption',
	component: ComparativeAdoption
}, {
	path: REPORTING_URL.ACTIVITY,
	labelId: 'pages.reporting.tabBar.activity',
	component: ComparativeActivity
}, {
	path: REPORTING_URL.PERFORMANCE,
	labelId: 'pages.reporting.tabBar.performance',
	component: ComparativePerformance
}]

interface TeamsReportingPageProps {
	intl: IntlShape
}

interface Props extends TeamsReportingPageProps, RouteComponentProps {
}

const TeamsReportingPage: FunctionComponent<Props> = ({ intl, history }) => {
	const { tableUrl, setTableUrl, perimeter } = React.useContext(ReportingContext)
	const { user } = React.useContext(LoggedInContext)
	const areaName = React.useMemo(() => user?.regionName ? user.regionName : user?.countryName, [user])

	const backToHomePage = <Button
		variant="contained"
		size="large" color="secondary"
		disableElevation
		onClick={() => history.push('/select-country')}
	>
		<FormattedMessage id="pages.selectCountry.backToHomePage" />
	</Button>

	React.useEffect(() => {
		if (user) {
			if (user.authorities[0].authority === PROFILE.SALES_REPRESENTATIVE) history.push('/portfolio')
		}
	}, [user, history])

	const topActionButtons = React.useMemo(() => perimeter === LEVEL.COUNTRY ? [backToHomePage] : [], [perimeter, backToHomePage])

	return <Box>
		<PageTitle
			title={intl.formatMessage(
				{ id: 'pages.reporting.teams.title' },
				{ level: user?.branchName || areaName }
			)}
			topActionButtons={topActionButtons}
			breadcrumbs={[]}
		/>
		<TabBar data={tabs} tableUrl={tableUrl} setTableUrl={setTableUrl}/>
		{
			tabs.map((item, index) => (
				<TabPanel value={tableUrl} name={item.path} key={index}>
					{item.component}
				</TabPanel>
			))
		}
	</Box>

}

export default compose(injectIntl, withRouter)(TeamsReportingPage)