import { Box, Card, CardContent, CardHeader, SvgIcon, Typography, withStyles } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import { ContractsContext } from '../../contexts/ContractsContext'
import HighRiskHighValueIcon from '../../svg/HighRiskHighValueIcon'
import LowValueIcon from '../../svg/LowValueIcon'
import { setFilters, setTableUrl } from '../contracts/services/contractsActions'
import { defaultFiltersAll, defaultFiltersPriority } from '../contracts/services/contractsReducer'
import * as api from './services/salesPortfolioApi'
import ManuallyAddedIcon from '../../svg/ManuallyAddedIcon'
import { FormattedMessage, injectIntl } from 'react-intl'
import { compose } from 'recompose'

const styles = (theme) => ({
	root: {
		height: '100%'
	},
	cardContent: {
		height: '100%'
	},
	link: {
		color: theme.palette.primary.light,
		cursor: 'pointer'
	}
})

const PriorityContractsBox = ({ classes, salesRepId, scrollToContracts, intl }) => {
	const { filters, tableUrl, dispatch } = useContext(ContractsContext)
	const [noActionCount, setNoActionCount] = useState(0)
	const [highValueCount, setHighValueCount] = useState(0)
	const [lowValueCount, setLowValueCount] = useState(0)
	const [manuallyAddedCount, setManuallyAddedCount] = useState(0)

	useEffect(() => {
		api.loadSRContractsCounts(salesRepId).then(data => {
			setNoActionCount(data.noActionContractCount)
			setHighValueCount(data.highValueContractCount)
			setLowValueCount(data.lowValueContractCount)
			setManuallyAddedCount(data.manuallyPrioritizedContractCount)
		})
	}, [salesRepId])

	let baseFilters = tableUrl === 'all' ? defaultFiltersAll : defaultFiltersPriority
	return (
		<Card className={classes.root}>
			<CardHeader title={intl.formatMessage({ id: 'pages.srPortfolio.priorityContracts.title' })} />
			<CardContent className={classes.cardContent}>
				<Box pt={1} pb={1.5}>
					<Typography variant="h5"><FormattedMessage id='pages.srPortfolio.priorityContracts.noActionPlanned' /></Typography>
					<Typography variant="h5" className={classes.link}
					            onClick={() => {
						            dispatch(setTableUrl('priority'))
						            dispatch(setFilters({ ...baseFilters, status: ['TODO'], sorting: filters.sorting }))
									scrollToContracts()
					            }}><FormattedMessage id='pages.srPortfolio.priorityContracts.nbContracts' values={{ nb: noActionCount }} /> &gt;</Typography>
				</Box>
				<Box pt={1.5} pb={1.5}>
					<Box display="flex" alignItems="center">
						<Typography variant="h3">
							<SvgIcon><HighRiskHighValueIcon /></SvgIcon>
						</Typography>
						<Typography variant="h5"> <FormattedMessage id='pages.srPortfolio.priorityContracts.highValue' /></Typography>
					</Box>
					<Typography variant="h5" className={classes.link}
					            onClick={() => {
						            dispatch(setTableUrl('priority'))
						            dispatch(setFilters({ ...baseFilters, status: ['TODO', 'IN_PROGRESS'], classification: ['HIGH_RISK_HIGH_VALUE'], sorting: filters.sorting }))
									scrollToContracts()
					            }}><FormattedMessage id='pages.srPortfolio.priorityContracts.nbContracts' values={{ nb: highValueCount }} /> &gt;</Typography>
				</Box>
				<Box pt={1.5} pb={1.5}>
					<Box display="flex" alignItems="center">
						<Typography variant="h3">
							<SvgIcon><LowValueIcon /></SvgIcon>
						</Typography>
						<Typography variant="h5"> <FormattedMessage id='pages.srPortfolio.priorityContracts.lowValue' /></Typography></Box>
					<Typography variant="h5" className={classes.link}
					            onClick={() => {
						            dispatch(setTableUrl('priority'))
						            dispatch(setFilters({ ...baseFilters, status: ['TODO', 'IN_PROGRESS'], classification: ['LOW_VALUE'], sorting: filters.sorting }))
									scrollToContracts()
					            }}><FormattedMessage id='pages.srPortfolio.priorityContracts.nbContracts' values={{ nb: lowValueCount }} /> &gt;</Typography>
				</Box>
				<Box pt={1.5} pb={1.5}>
					<Box display="flex" alignItems="center">
						<Typography variant="h3">
							<SvgIcon><ManuallyAddedIcon /></SvgIcon>
						</Typography>
						<Typography variant="h5">&nbsp;<FormattedMessage id='pages.srPortfolio.priorityContracts.manually' /></Typography></Box>
					<Typography variant="h5" className={classes.link}
								onClick={() => {
									dispatch(setTableUrl('priority'))
									dispatch(setFilters({ ...baseFilters, status: ['TODO', 'IN_PROGRESS'], classification: ['MANUALLY_PRIORITIZED'], sorting: filters.sorting }))
									scrollToContracts()
								}}><FormattedMessage id='pages.srPortfolio.priorityContracts.nbContracts' values={{ nb: manuallyAddedCount }} /> &gt;</Typography>
				</Box>
			</CardContent>
		</Card>
	)
}

export default compose(
	withStyles(styles),
	injectIntl
)(PriorityContractsBox)
