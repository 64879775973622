import { Box, Button, Checkbox, ClickAwayListener, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, makeStyles, Paper, Popper } from '@material-ui/core'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import StatusChip from '../../../components/StatusChip'
import { TABLE_URL } from '../../../components/TabBarDesktop'
import { allFilters, ContractsContext } from '../../../contexts/ContractsContext'
import { getColorByStatus } from '../../../utils/getColorByStatus'
import { sanitizeLabelLower } from '../../../utils/stringUtils'
import { resetFilters, setFilters } from '../services/contractsActions'
import { defaultFiltersAll, defaultFiltersMonthly, defaultFiltersPriority, defaultOverviewFiltersAll, defaultOverviewFiltersCancelled, defaultOverviewFiltersPriority } from '../services/contractsReducer'
import { FormattedMessage, injectIntl } from 'react-intl'
import { OVERVIEW_TABLE_URL } from '../../../constants'

const useStyles = makeStyles((theme) => ({
	root: {
		flex: 1,
		marginTop: 10,
		zIndex: 2000
	},
	paper: {
		padding: '15px 0',
		border: '1px solid',
		borderColor: theme.palette.grey['200'],
		borderRadius: 4,
		boxShadow: '0 0 15px 0 rgba(0,25,80,.2)',
		minWidth: 425,
		maxWidth: 1200
	},
	groupFilter: {
		minWidth: 125
	},
	labelFilter: {
		fontSize: 12,
		fontWeight: 600,
		color: theme.palette.text.light,
		marginBottom: 15
	},
	link: {
		color: theme.palette.primary.light
	},
	text: {
		backgroundColor: theme.palette.background.light,
		padding: '10px'
	}
}))

const ContractFiltersDesktop = ({ anchorEl, open, closeFilters, intl, modules, setFiltered }) => {
	const { tableUrl, filters, dispatch } = useContext(ContractsContext)
	const [tempFilters, setTempFilters] = useState(null)
	const classes = useStyles()

	useEffect(() => setTempFilters(filters), [filters])
	const handleChange = useCallback((filters, setFilters, targetFilter, groupKey) => {
		// If the targetFilter is in the filtersList (we are unchecking the box)
		if (filters[groupKey].includes(targetFilter.value)) {
			// then we remove it from the list
			setFilters({ ...filters, [groupKey]: filters[groupKey].filter(a => a !== targetFilter.value) })
		} else {
			// else we add it (the box was just checked)
			setFilters({ ...filters, [groupKey]: [...filters[groupKey], targetFilter.value] })
		}
	}, [])

	const validate = () => {
		const newFilters = { ...tempFilters, searchQuery: filters.searchQuery, sorting: filters.sorting }
		const searchParams = new URLSearchParams(window.location.search)
		for (const key of Object.keys(newFilters)) {
			searchParams.delete(key)
			if (newFilters[key].length > 0) {
				if (typeof newFilters[key] !== "string") {
					for (const value of newFilters[key]) {
						searchParams.append(key, value)
					}
				} else searchParams.append(key, newFilters[key])
			}
		}
		let tabDefaultFilters
		switch (tableUrl) {
			case TABLE_URL.PRIORITY:
				tabDefaultFilters = defaultFiltersPriority
				break;
			case TABLE_URL.MONTHLY:
				tabDefaultFilters = defaultFiltersMonthly
				break;
			case TABLE_URL.ALL:
				tabDefaultFilters = defaultFiltersAll
				break;
			case OVERVIEW_TABLE_URL.PRIORITY:
				tabDefaultFilters = defaultOverviewFiltersPriority
				break;
			case OVERVIEW_TABLE_URL.CANCELLED:
				tabDefaultFilters = defaultOverviewFiltersCancelled
				break;
			case OVERVIEW_TABLE_URL.ALL:
				tabDefaultFilters = defaultOverviewFiltersAll
				break;
			default:
				
		}
		if (tabDefaultFilters !== null && JSON.stringify(tempFilters).localeCompare(JSON.stringify(tabDefaultFilters)) === 0) {
			setFiltered(false);
			window.history.replaceState(null, "", `${window.location.pathname}`)
		} else window.history.replaceState(null, "", `${window.location.pathname}?${searchParams.toString()}`)
		dispatch(setFilters(newFilters))
		closeFilters()
	}

	const filterGroups = [
		{
			label: intl.formatMessage({ id: 'pages.contractsOverview.filters.headers.status' }),
			key: 'status'
		},
		{
			label: intl.formatMessage({ id: 'pages.contractsOverview.filters.headers.classification' }),
			key: 'classification'
		},
		{
			label: intl.formatMessage({ id: 'pages.contractsOverview.filters.headers.risk' }),
			key: 'risk'
		},
		{
			label: intl.formatMessage({ id: 'pages.contractsOverview.filters.headers.value' }),
			key: 'value'
		},
		{
			label: intl.formatMessage({ id: 'pages.contractsOverview.filters.headers.flags' }),
			key: 'flags'
		},
		{
			label: intl.formatMessage({ id: 'pages.contractsOverview.filters.headers.endDate' }),
			key: 'endDate'
		},
		{
			label: intl.formatMessage({ id: 'pages.contractsOverview.filters.headers.riskTypology' }),
			key: 'riskTypology'
		}
	]

	if (modules && modules.countryModule === 'FR' && (tableUrl === TABLE_URL.ALL || tableUrl === OVERVIEW_TABLE_URL.ALL)) {
		filterGroups.push({
			label: intl.formatMessage({ id: 'pages.contractsOverview.filters.headers.customerFeedback' }),
			key: 'customerFeedback',
		})
	}

	if (!filters || !tempFilters) {
		return null
	}
	return (
		<Popper
			anchorEl={anchorEl}
			placement="bottom-end"
			open={open}
			onClose={closeFilters}
			className={classes.root}
			disablePortal={false}
			modifiers={{
				computeStyle: {
					enabled: true,
					fn(data) {
						data.styles = {
							...data.styles,
							position: 'fixed',
							left: `${(window.innerWidth - 17 - data.popper.width) / 2}px`,
							top: '50%',
							transform: 'translateY(-50%)',
						}

						return data
					},
				},
			}}
		>
			<ClickAwayListener onClickAway={closeFilters}>
				<Paper className={classes.paper}>
					<Box px={3} py={1}>
						<div className={classes.text}>
							<FormattedMessage id='pages.contractsOverview.filters.help' />
						</div>
						<Box mt={2} />
						<Grid container spacing={2} style={{justifyContent: 'space-around'}}>
							{filterGroups.map(group =>
								<Grid item key={group.key}>
									<FormControl component="fieldset" className={classes.groupFilter}>
										<FormLabel component="legend" className={classes.labelFilter}>{group.label}</FormLabel>
										<FormGroup>
											{allFilters[group.key].map((targetFilter) => (
												<FormControlLabel
													key={`${group.key}${targetFilter.value}`}
													control={
														<Checkbox
															checked={tempFilters[group.key].indexOf(targetFilter.value) >= 0}
															onChange={() => handleChange(tempFilters, setTempFilters, targetFilter, group.key)}
														/>
													}
													label={group.key === 'status' ?
														<StatusChip label={sanitizeLabelLower(intl.formatMessage({ id: `pages.contractsOverview.filters.${group.key}.${targetFilter.key}` }))} {...getColorByStatus(targetFilter.value)} />
														: intl.formatMessage({ id: `pages.contractsOverview.filters.${group.key}.${targetFilter.key}` })}
												/>
											))
											}
										</FormGroup>
									</FormControl>
								</Grid>
							)}
							<Grid item style={{flexGrow: 1}} />
						</Grid>
					</Box>
					<Box display="flex" justifyContent="flex-end" flexGrow={1} px={3} py={1}>
						<Grid
							container
							direction="row"
							justify="flex-end"
							alignItems="center" spacing={2}
						>
							<Grid item>
								<Button size="large" color="primary" disableElevation onClick={() => {
									dispatch(resetFilters())
									switch (tableUrl) {
										case TABLE_URL.PRIORITY:
											setTempFilters({ ...defaultFiltersPriority })
											break
										case TABLE_URL.MONTHLY:
											setTempFilters({ ...defaultFiltersMonthly })
											break
										case TABLE_URL.ALL:
											setTempFilters({ ...defaultFiltersAll })
											break
										case OVERVIEW_TABLE_URL.PRIORITY:
											setTempFilters({ ...defaultOverviewFiltersPriority })
											break
										case OVERVIEW_TABLE_URL.CANCELLED:
											setTempFilters({ ...defaultOverviewFiltersCancelled })
											break
										case OVERVIEW_TABLE_URL.ALL:
											setTempFilters({ ...defaultOverviewFiltersAll })
											break
										default:
									}
								}
								}>
									<FormattedMessage id="pages.contractsOverview.filters.reset" />
								</Button>
							</Grid>
							<Grid item>
								<Button variant="contained" size="large" color="primary" disableElevation onClick={validate}>
									<FormattedMessage id="pages.contractsOverview.filters.apply" />
								</Button>
							</Grid>
						</Grid>
					</Box>
				</Paper>
			</ClickAwayListener>
		</Popper>
	)
}

export default injectIntl(ContractFiltersDesktop)
