export const getColorByStatus = (status) => {
	switch (status) {
		case 'TODO':
			return { color: 'grey', gradient: '500' }
		case 'IN_PROGRESS':
			return { color: 'success', gradient: 'main' }
		case 'TO_BE_CANCELLED':
			return { color: 'secondary', gradient: 'main' }
		case 'OPEN':
			return { color: 'success', gradient: 'main' }
		case 'CLOSED':
			return { color: 'secondary', gradient: 'main' }
		default:
			return {}
	}
}