import { Box, Card, CardContent, CardHeader, withStyles, WithStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography/Typography'
import { ChevronRight } from '@material-ui/icons'
import * as React from 'react'
import { ContractsContext } from '../../contexts/ContractsContext'
import * as api from './services/contractsApi'
import { RouteComponentProps, withRouter } from 'react-router'
import OtherSalesRepDialog from './OtherSalesRepDialog'
import { truncate } from '../../utils/stringUtils'
import { injectIntl, IntlShape, FormattedMessage } from 'react-intl'
import { compose } from 'recompose'
import {useContext} from "react";
import {LoggedInContext} from "../../contexts/LoggedInContext";
import { PROFILE } from '../../enums'
import { setFilters } from './services/contractsActions'

const styles = (theme) => ({
	root: {
		height: '100%'
	},
	thinText: {
		fontWeight: 400,
		fontSize: '15px'
	},
	link: {
		fontWeight: 600,
		fontSize: '15px',
		color: theme.palette.primary.light,
		cursor: 'pointer'
	}
})

type CountData = {
	contractsCount?: number,
	salesRepCount?: number
}

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
	intl: IntlShape
}

const OtherContracts: React.FunctionComponent<Props> = ({ classes, history, intl }) => {
	const { filters, contract, dispatch } = React.useContext(ContractsContext)
	const { user } = useContext(LoggedInContext)

	const [data, setData] = React.useState<CountData>({})
	const [salesDialogOpen, setSalesDialogOpen] = React.useState<boolean>(false)

	const goToContracts = React.useCallback(() => {
		if (user && user.authorities[0].authority === PROFILE.SALES_REPRESENTATIVE) {
			dispatch(setFilters({ ...filters, searchQuery: contract.customer.name }))
			history.push('/portfolio?tableUrl=all')
		}
		else history.push(`/contracts-overview/from-other-contracts/${contract.customer.name}`)
	}, [history, contract, user, filters, dispatch])

	React.useEffect(() => {
		api.loadOtherContractsCounts(contract.id).then((response) => {
			if (response) {
				setData(response)
			}
		})
	}, [contract])

	return (
		<>
			<Card className={classes.root}>
				<CardHeader title={`${truncate(contract.customer.name, 25)} - ${!!user ? user.countryName : intl.formatMessage({ id: 'pages.contractsOverview.otherContract.title' })}`} />
				<CardContent className={classes.root}>
					<Box p={4}>
						<Typography className={classes.thinText}><FormattedMessage id="pages.contractsOverview.otherContract.portfolio" /></Typography>
						<Typography className={classes.link} onClick={goToContracts}>{data.contractsCount} {intl.formatMessage({ id: 'pages.contractsOverview.otherContract.contracts' })} <ChevronRight /></Typography>
						<Box pt={2} />
						<Typography className={classes.thinText}>{!!user && user.countryId === 'NSA' ? 'NSA' : 'OTIS'}</Typography>
						<Typography className={classes.link} onClick={() => setSalesDialogOpen(true)}>{data.salesRepCount} {intl.formatMessage({ id: 'pages.contractsOverview.otherContract.salesRep' })} <ChevronRight /></Typography>
					</Box>
				</CardContent>
			</Card>
			<OtherSalesRepDialog open={salesDialogOpen} onClose={() => setSalesDialogOpen(false)} />
		</>
	)
}

export default compose(
	withStyles(styles),
	withRouter,
	injectIntl
)(OtherContracts)
