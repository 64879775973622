import {
	Box,
	Button,
	CircularProgress,
	createStyles,
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	Grid,
	MenuItem,
	Typography,
	withStyles,
} from '@material-ui/core'
import { CloseRounded, ExpandMoreRounded, PersonAddRounded } from '@material-ui/icons'
import { FormSelect, postMultipart } from 'isotope-client'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { FormattedMessage, injectIntl } from 'react-intl'
import { compose } from 'recompose'
import { LoggedInContext } from '../../../contexts/LoggedInContext'
import { PROFILE } from '../../../enums'
import { AccessChecker } from '../../../utils/accessChecker'
import { getCountries } from '../../selectcountry/services/api'
import { serializeParts } from './serialize'

interface FormError {
	country?: string
	file?: string
}

interface Country {
	id: string
	name: string
}

const styles = (theme: any) =>
	createStyles({
		error: {
			fontSize: 10,
			color: theme.palette.error.main,
		},
		select: {
			width: 328,
			'&::before': {
				content: 'unset',
			},
			'&::after': {
				content: 'unset',
			},
		},
		inputFile: {
			marginBottom: 33.6,
		},
		label: {
			color: '#0033A0',
			fontSize: 13,
			fontWeight: 'bold',
		},
		buttonProgress: {
			position: 'absolute',
			top: '50%',
			left: '50%',
			marginTop: -12,
			marginLeft: -12,
		},
		container: {
			marginTop: 33.6,
		},
		panelSummary: {
			backgroundColor: 'rgba(224, 227, 232, 0.6)',
		},
		panelDetails: {
			backgroundColor: 'rgba(224, 227, 232, 0.4)',
			paddingTop: 8,
			paddingBottom: 8,
		},
		item: {
			display: 'flex',
			alignItems: 'center',
		},
		errorsHeader: {
			color: theme.palette.error.main,
		},
	})

const Import = ({ classes, intl }) => {
	const [countries, setCountries] = useState<Country[]>([])
	const [loading, setLoading] = useState(false)
	const [result, setResult] = useState<Record<string, string>[] | null>(null)
	const [errorsNb, setErrorsNb] = useState(0)
	const inputRef = useRef<HTMLInputElement>(null)

	const { user } = useContext(LoggedInContext)

	useEffect(() => {
		getCountries().then(setCountries)
	}, [])

	function onSubmit(values: any) {
		setLoading(true)
		setResult(null)
		return postMultipart(`/rtv-users/import/${values.country}`, {
			method: 'POST',
			body: serializeParts([{ key: 'file', value: values.file }]),
		})
			.then((result: Record<string, string>[]) => {
				setErrorsNb(parseInt(result[0].errorsNb))
				setResult(result.slice(1))
			})
			.catch((error: any) => console.error(error))
			.finally(() => setLoading(false))
	}

	function validate(values: any) {
		const errors: FormError = {}
		if (!values.country) {
			errors.country = intl.formatMessage({ id: 'import.form.country.errors.required' })
		}
		if (!values.file) {
			errors.file = intl.formatMessage({ id: 'import.form.file.errors.required' })
		} else if (values.file[0].name.split('.').pop() !== 'xlsx') {
			errors.file = intl.formatMessage({ id: 'import.form.file.errors.wrongFormat' })
		}
		return errors
	}

	return (
		<AccessChecker profiles={[PROFILE.ZONE_MANAGER, PROFILE.NATIONAL]}>
			<Box pt={5}>
				<Form
					initialValues={{country: user?.countryId}}
					onSubmit={onSubmit}
					validate={validate}
					render={({ handleSubmit, form }) => (
						<form
							onSubmit={(event: any) => {
								handleSubmit(event)?.then(() => {
									form.reset()
									form.resetFieldState('country')
									form.resetFieldState('file')
									if (inputRef.current) inputRef.current.value = ''
								})
							}}
						>
							<Field<string>
								name="country"
								fullWidth
								component={FormSelect}
								inputStyle={classes.select}
								label={intl.formatMessage({ id: 'import.form.country.label' })}
							>
								{countries.map((country, key) => (
									<MenuItem key={key} value={country.id}>
										{country.name}
									</MenuItem>
								))}
							</Field>
							<Field<FileList> name="file">
								{({ input: { value, onChange, ...rest }, meta }) => (
									<Box className={classes.inputFile}>
										<Typography variant="subtitle1" className={classes.label}>
											<FormattedMessage id="import.form.file.label" />
										</Typography>
										<Box display="flex" flexDirection="row" alignItems="center">
											<Button
												variant="contained"
												size="small"
												disableElevation
												onClick={() => inputRef.current?.click()}
											>
												<FormattedMessage id="import.form.file.button" />
											</Button>
											<Typography variant="subtitle2" style={{ marginLeft: 8 }}>
												{value ? value[0].name : <FormattedMessage id="import.form.file.empty" />}
											</Typography>
										</Box>
										<input
											{...rest}
											type="file"
											accept=".xlsx"
											ref={inputRef}
											onChange={({ target }) => onChange(target.files)}
											hidden
										/>
										{meta.error && meta.touched && <Typography className={classes.error}>{meta.error}</Typography>}
									</Box>
								)}
							</Field>
							<Box position="relative" width="min-content">
								<Button type="submit" variant="contained" color="primary" disableElevation disabled={loading}>
									<FormattedMessage id="import.form.submit" />
								</Button>
								{loading && <CircularProgress size={24} className={classes.buttonProgress} />}
							</Box>
						</form>
					)}
				/>
				{result !== null && (
					<Box className={classes.container}>
						<ExpansionPanel>
							<ExpansionPanelSummary expandIcon={<ExpandMoreRounded />} className={classes.panelSummary}>
								<Typography variant="subtitle1">
									<FormattedMessage id="import.results.header.added" values={{ count: result.length - errorsNb }} />
								</Typography>
							</ExpansionPanelSummary>
							<ExpansionPanelDetails className={classes.panelDetails}>
								<Grid container spacing={1}>
									{result.map((value: Record<string, string>) =>
										value.result === 'success' ? (
											<Grid item xs={12}>
												<Typography variant="subtitle2" className={classes.item}>
													<PersonAddRounded style={{ marginRight: 5 }} />
													<FormattedMessage id="import.results.success" values={{ email: value.email }} />
												</Typography>
											</Grid>
										) : null
									)}
								</Grid>
							</ExpansionPanelDetails>
						</ExpansionPanel>
						<ExpansionPanel>
							<ExpansionPanelSummary expandIcon={<ExpandMoreRounded />} className={classes.panelSummary}>
								<Typography variant="subtitle1">
									<FormattedMessage id="import.results.header.errors" values={{ count: errorsNb }} />
								</Typography>
							</ExpansionPanelSummary>
							<ExpansionPanelDetails className={classes.panelDetails}>
								<Grid container spacing={1}>
									{result.map((value: Record<string, string>) =>
										value.result === 'error' ? (
											<Grid item xs={12}>
												<Typography variant="subtitle2" className={classes.item}>
													<CloseRounded style={{ marginRight: 5 }} />
													<FormattedMessage
														id={`import.results.errors.${value.error}`}
														values={{
															line: value.line,
															email: value.email,
															name: value.name,
															profileDB: value.profileDB,
															profileFile: value.profileFile,
														}}
													/>
												</Typography>
											</Grid>
										) : null
									)}
								</Grid>
							</ExpansionPanelDetails>
						</ExpansionPanel>
					</Box>
				)}
			</Box>
		</AccessChecker>
	)
}

export default compose(injectIntl, withStyles(styles))(Import)
