import { createIsotopeTheme } from 'isotope-client'

export const palette = ({
	background: {
		default: '#1B375D',
		dark: '#041E42',
		light: '#F4F6FB',
		lightShadow: '#E5EAF5'
	},
	primary: {
		main: '#0033A0',
		light: '#2D6BFF'
	},
	secondary: {
		main: '#F65275',
		light: '#FFC8D4'
	},
	error: {
		main: '#EB1616'
	},
	warning: {
		main: '#FFEE17',
		icon: '#FF7622',
		icon2: '#DC004E'
	},
	success: {
		main: '#34CB81'
	},
	grey: {
		50: '#F6F6F8',
		100: '#EDEDF0',
		200: '#E0E3E8',
		300: '#CCD2D9',
		400: '#9FA9B7',
		500: '#818EA0',
		600: '#586981',
		700: '#3B4F6B',
		800: '#172F50',
		900: '#041E42'
	},
	text: {
		primary: '#021227',
		secondary: '#FFFFFF',
		light: '#687BAE'
	}
})

export const backTheme = createIsotopeTheme({
	login: {
		background: 'url(\'img/connexion.png\')'
	},
	typography: {},
	palette: palette,
	overrides: {
		MuiAppBar: {
			root: {
				'& [class*="MuiToolbar"]': {
					background: palette.background.default
				}
			}
		},
		MuiTableHead: {
			root: {
				height: 60
			}
		},
		MuiTableBody: {
			root: {
				'& [class*="MuiTableCell-root"]': {
					fontSize: 15
				}
			}
		},
		MuiTableCell: {
			root: {},
			head: {
				fontSize: '13px!important',
				fontWeight: '600!important',
				color: palette.text.light,
				height: 60,
				padding: '0 30px',
				lineHeight: '1.2'
			},
			body: {
				padding: '15px 30px'
			}

		},
		MuiTableSortLabel: {
			root: {
				color: 'inherit',
				'&:hover': {
					color: palette.grey['800']
				},
				'&$active': {
					color: palette.grey['800'],
					fontWeight: 500
				}
			},
			icon: {
				color: 'inherit !important'
			}
		},
		MuiSelect: {
			root: {
				display: 'flex',
				alignItems: 'center',
				fontWeight: 700,
				fontSize: 13,
				color: palette.text.primary
			},
			selectMenu: {
				height: 30,
				padding: '0 15px'
			},
			select: {
				backgroundColor: 'white'
			}
		},
		MuiInput: {
			root: {
				backgroundColor: 'white',
				display: 'flex',
				alignItems: 'center',
				height: 30,
				padding: 0,
				border: '1px solid',
				borderColor: palette.background.lightShadow

			},
			input: {
				'&::placeholder': {
					textOverflow: 'ellipsis !important',
					color: palette.grey['500'],
					opacity: 1,
					fontSize: 15
				}
			},
			multiline: {
				height: 150,
				padding: 10, boxShadow: 'none'
			}
		},
		MuiInputBase: {
			input: {
				height: 16,
				padding: 10
			},
			multiline: {
				height: 150
			}
		},
		MuiFormControlLabel: {
			root: {
				'& [class*="MuiCheckbox-colorSecondary"]': {
					color: palette.background.lightShadow
				},
				'& [class*="Mui-checked"]': {
					color: palette.primary.light
				}
			},
			label: {
				fontWeight: 425
			}
		},
		MuiDialogContent: {
			root: {
				padding: 20,
				'& [class*="MuiInputLabel-root"]': {
					color: palette.text.light,
					fontSize: '13px',
					fontWeight: 700,
					transform: 'inherit',
					marginBottom: 10
				},
				'& [class*="MuiFormControl-marginNormal"]': {
					marginTop: 0
				}
			}
		}
	}
})

export const frontTheme = createIsotopeTheme({
	login: {
		background: 'url(\'img/connexion.png\')'
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 960,
			lg: 1280,
			xl: 1920
		}
	},
	typography: {
		fontFamily: 'Nunito Sans, Roboto, sans-serif',
		h1: {
			fontFamily: 'Grifo, Roboto, sans-serif',
			fontSize: 32,
			fontWeight: 800
		},
		h2: {},
		h3: {
			fontSize: 19,
			fontWeight: 400
		},
		h4: { fontSize: 17 },
		h5: {
			fontSize: 15,
			fontWeight: 500
		},
		h6: {
			fontSize: 13,
			fontWeight: 400
		},
		body2: {
			fontSize: 14,
			fontWeight: 600
		}
	},
	palette: palette,
	overrides: {
		MuiTooltip: {
			tooltip: {
				borderRadius: '5px',
				fontSize: "15px",
				fontWeight: 400,
				padding: "15px",
				color: palette.grey['400'],
				backgroundColor: palette.grey['900'],
			}
		},
		MuiButton: {
			root: {
				fontWeight: 600,
				textTransform: 'none',
				borderRadius: 4,
				boxShadow: 'none',
				padding: '0 20px'
			},

			contained: {
				boxShadow: 'none',
				fontSize: 13,
				padding: '0 20px'
			},
			outlined: {
				borderWidth: 1,
				height: 40,
				fontSize: 13,
				padding: '0 20px'
			},

			containedSizeLarge: {
				height: 50,
				fontSize: 16,
				padding: '0 20px'
			},
			containedSizeSmall: {
				fontSize: 13,
				padding: '0 15px',
				height: 40
			},
			containedPrimary: {
				color: '#fff',
				backgroundColor: palette.primary.light
			},

			outlinedSizeLarge: {
				height: 50,
				fontSize: 16,
				border: '1px solid',
				padding: '0 20px'
			},
			outlinedSizeSmall: {
				height: 40,
				fontSize: 13,
				border: '1px solid',
				padding: '0 15px'
			},
			outlinedPrimary: {
				color: '#9FA9B7',
				borderColor: '#9FA9B7',
				'&:hover': {
					borderColor: '#9FA9B7'
				}
			}
		},
		MuiToolbar: {
			regular: {
				display: 'flex',
				flexDirection: 'row',
				flex: 1,
				background: '#FFFFFF',
				minHeight: '75px !important',
				'@media (min-width: 960px)': {
					minHeight: '75px'
				}
			}
		},
		Wrapper: {
			root: {
				flexGrow: 1,
				marginTop: 75
			}
		},
		MuiTypography: {
			root: {
				display: 'flex',
				'& [class*="MuiBadge-root"]': {},
				'& [class*="MuiBadge-badge"]': {
					position: 'relative',
					transform: 'inherit',
					marginLeft: 10
				}
			}
		},
		MuiSelect: {
			root: {
				display: 'flex',
				alignItems: 'center',
				fontWeight: 700,
				fontSize: 13,
				color: palette.text.primary
			},
			selectMenu: {
				height: 50,
				padding: '0 15px'
			},
			select: {
				'&:focus': {
					backgroundColor: palette.grey['50'],
					borderTop: '1px solid',
					borderColor: palette.grey['100'],
					borderRadius: '4px'
				}
			}

		},
		MuiInput: {
			root: {
				display: 'flex',
				alignItems: 'center',
				height: 50,
				padding: 0,
				border: '1px solid',
				borderColor: palette.background.lightShadow,
				borderRadius: '4px',
				boxShadow: '0 2px 0 0 #E5EAF5',
				'&:focus': {
					borderRadius: '4px'
				},
				'& [class*="MuiInputBase-inputMultiline"]': {
					height: '100%!important'
				}
			},
			input: {
				'&::placeholder': {
					textOverflow: 'ellipsis !important',
					color: palette.grey['800'],
					opacity: 1,
					fontSize: 15
				}
			},
			multiline: {
				height: 150,
				padding: 10, boxShadow: 'none'
			}
		},
		MuiInputBase: {
			input: {
				height: 50,
				padding: 10
			},
			multiline: {
				height: 150
			}
		},
		MuiAvatar: {
			colorDefault: {
				color: palette.text.secondary,
				backgroundColor: palette.primary.main
			}
		},
		MuiTableHead: {
			root: {
				backgroundColor: palette.background.light,
				height: 40
			}
		},
		MuiTableBody: {
			root: {
				'& [class*="MuiTableCell-root"]': {
					fontSize: 15,
					border: 'none'
				},
				'& [class*="MuiTableRow-root"]': {
					'&:nth-child(even)': {
						backgroundColor: palette.background.light
					}
				}
			}
		},
		MuiTableCell: {
			root: {},
			head: {
				fontSize: '12px!important',
				fontWeight: '600!important',
				color: palette.text.light,
				height: 40,
				padding: '0 30px',
				border: 'none',
				lineHeight: '1.2'
			},
			body: {
				padding: '15px 30px'
			}

		},
		MuiTablePagination: {
			root: {
				display: 'flex',
				minHeight: 75,
				color: palette.grey['900'],
				'& [class*="MuiToolbar-gutters"]': {
					padding: '0 30px'
				},
				'& [class*="MuiTablePagination-toolbar"]': {
					height: 90
				},
				'& [class*="MuiIconButton-root"]': {
					color: palette.grey['900'],
					opacity: 1,
					padding: 0
				},
				'& [class*="Mui-disabled"]': {
					color: palette.grey['900'],
					opacity: .2
				}
			},
			spacer: {
				flex: '0'
			}
		},
		MuiTableSortLabel: {
			root: {
				color: 'inherit',
				'&:hover': {
					color: palette.grey['800']
				},
				'&$active': {
					color: palette.grey['800'],
					fontWeight: 500
				}
			},
			icon: {
				color: 'inherit !important'
			}
		},
		MuiBreadcrumbs: {
			root: {
				color: palette.grey['500'],
				'& [class*="MuiLink-root"]': {
					color: palette.grey['500'],
					'&:hover': {
						color: palette.grey['400']
					}
				}
			}
		},
		MuiFormControlLabel: {
			root: {
				'& [class*="MuiCheckbox-colorSecondary"]': {
					color: palette.background.lightShadow
				},
				'& [class*="Mui-checked"]': {
					color: palette.primary.light
				}
			},
			label: {
				fontWeight: 425
			}
		},
		MuiCard: {
			root: {
				borderRadius: 5
			}
		},
		MuiCardHeader: {
			root: {
				display: 'flex',
				alignItems: 'center',
				minHeight: 75,
				'& [class*="MuiTypography-h5"]': {
					fontSize: 18,
					fontWeight: '700'
				}
			},
			content: {
				flex: '0 1 auto'
			},
			action: {
				flex: '0 0 auto',
				alignSelf: 'initial',
				marginTop: '0',
				marginRight: '0',
				marginBottom: '-5px',
				marginLeft: '15px',
				lineHeight: '0'
			}

		},
		MuiCardContent: {
			root: {
				backgroundColor: palette.background.light,
				'& [class*="MuiTypography-subtitle2"]': {
					color: palette.text.light,
					fontWeight: '700',
					fontSize: 13
				},
				'& [class*="MuiTypography-subtitle1"]': {
					color: palette.grey['800'],
					fontWeight: '600',
					fontSize: 16
				}
			}
		},
		MuiDialog: {
			paper: {
				borderRadius: 4,
				boxShadow: 'none'
			}
		},
		MuiDialogTitle: {
			root: {
				padding: 20,
				'& [class*="MuiTypography-h6"]': {
					color: palette.grey['800'],
					fontSize: 18,
					fontWeight: 700
				}
			}
		},
		MuiDialogContent: {
			root: {
				padding: 20,
				'& [class*="MuiInputLabel-root"]': {
					color: palette.text.light,
					fontSize: '13px',
					fontWeight: 700,
					transform: 'inherit',
					marginBottom: 10
				},
				'& [class*="MuiFormControl-marginNormal"]': {
					marginTop: 0
				}
			}
		},
		MuiDialogActions: {
			root: {
				padding: 20
			}
		},
		MuiBackdrop: {
			root: {
				backgroundColor: 'rgba(26,55,93,.7)'
			}
		},
		MuiDivider: {
			root: {
				backgroundColor: palette.background.lightShadow
			}
		}
	}
})

