// authProvider.js
import React from 'react'
import { useMsal } from '@azure/msal-react'
import { AccountInfo } from '@azure/msal-browser'
import { OIDC_DEFAULT_SCOPES } from '@azure/msal-common'


export const getConfig = ({ tenantId, clientId, redirectUri }) => ({
	auth: {
		authority: `https://login.microsoftonline.com/${tenantId}`,
		clientId,
		redirectUri
	},
	cache: {
		cacheLocation: 'localStorage'
	},
	system: {
		tokenRenewalOffsetSeconds: 1800
	}
})

export type AzureToken = {
	accessToken: string,
	idToken: string
}

export function getAzureRequest(account?: AccountInfo) {
	return {
		scopes: OIDC_DEFAULT_SCOPES,
		account
	}
}

// Access token hook
export const useAzureToken = () => {
	const { instance, accounts } = useMsal()
	const [azureToken, setAzureToken] = React.useState<AzureToken | null>(null)
	React.useEffect(() => {
		if (accounts.length > 0) {
			instance.acquireTokenSilent(getAzureRequest(accounts[0]))
				.then(response => setAzureToken({
					accessToken: response.accessToken,
					idToken: response.idToken
				}))
				.catch((error) => {
					console.error('Error while fetching Azure ID Token', error)
					return instance.logout({ account: accounts[0] })
				})
		}
	}, [instance, accounts])
	return azureToken
}
