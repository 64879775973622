import React, { FunctionComponent } from 'react'
import { SpringDataTable } from 'isotope-client'
import NoResultFragment from '../../components/NoResult'
import { ActivityData, AdoptionData, PerformanceData, ReportingContextProps } from './types'
import { injectIntl, IntlShape } from 'react-intl'
import { compose } from 'recompose'
import { ReportingContext } from '../../contexts/ReportingContext'
import { PaperLoaderDataTable } from 'isotope-client/components/layout/PaperLoader'

interface Props {
	intl: IntlShape
	target: string
	headers: object[]
	tab: string
}

const ReportingTable: FunctionComponent<Props> = ({
	                                                  intl,
	                                                  target,
	                                                  headers,
	                                                  tab
                                                  }) => {
	const { filters, perimeter } = React.useContext<ReportingContextProps>(ReportingContext)

	if (!filters || !perimeter) {
		return <></>
	}

	const applyRowStyle = (row: ActivityData | PerformanceData | AdoptionData) => {
		if (row.entityId === '-1') {
			return {
				backgroundColor: '#dcdee3'
			}
		}
	}

	return (
		<PaperLoaderDataTable
			elevation={0}
			datatableName={`reportingDataTable${tab}`}>
			<SpringDataTable
				nom={`reportingDataTable${tab}`}
				headers={headers}
				filters={{ ...filters, perimeter }}
				apiUrlPrefix="/api"
				apiUrl={`/reporting/${target}/${tab}`}
				noResultFragment={
					<NoResultFragment
						message={intl.formatMessage({ id: 'pages.reporting.noResult' })} />
				}
				noPagination
				applyRowStyle={applyRowStyle}
			/>
		</PaperLoaderDataTable>
	)
}

export default compose(
	injectIntl
)(ReportingTable)
