import { fetchFactory } from 'isotope-client'
import { ExtendedReportingFiltersProps } from './types'

export const getSalesRepList = () => fetchFactory('/sales-representatives', {}, '/api')

const getChartData = (filters: ExtendedReportingFiltersProps, target: string) => fetchFactory(target, {
	method: 'POST',
	body: JSON.stringify(filters)
}, '/api')

export const getComparativeAdoptionChartData = (filters: ExtendedReportingFiltersProps) => getChartData(filters, '/reporting/teams/adoption/chart')

export const getDetailedPerformanceChartData = (filters: ExtendedReportingFiltersProps) => getChartData(filters, '/reporting/detailed/performance/chart')

export const getComparativePerformanceChartData = (filters: ExtendedReportingFiltersProps) => getChartData(filters, '/reporting/teams/performance/chart')
