import withStyles from '@material-ui/core/styles/withStyles'
import withTheme from '@material-ui/core/styles/withTheme'
import React from 'react'
import { compose } from 'recompose'

const styles = (theme) => ({
	wrapper: {
		backgroundColor: 'white',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		color: theme.palette.disabledColor,
		padding: 40
	},
	icon: {
		display: 'block',
		padding: '1em 0',
		width: '5em',
		height: '5em',
		color: theme.palette.disabledColor
	},
	message: {
		fontSize: '1em'
	}
})

const MessageWithIconFragment = compose(
	withTheme,
	withStyles(styles)
)(({ message, classes, Icon }) => {
	return (
		<div className={classes.wrapper}>
			{Icon && <Icon className={classes.icon} />}
			<span className={classes.message}>{message}</span>
		</div>
	)
})

const NoResultFragment = ({ message }) => (
	<MessageWithIconFragment
		message={message}
	/>
)

export default NoResultFragment
