import { Box, WithStyles, withStyles } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import Select from '@material-ui/core/Select/Select'
import React, { FunctionComponent } from 'react'
import BadgeBasic from './BadgeBasic'
import { FormattedMessage } from 'react-intl'
import { TabBarProps } from './types'

const styles = (theme) => {
	return ({
			tabsContracts: {
				backgroundColor: theme.palette.text.secondary,
				color: theme.palette.text.primary,
				opacity: 1
			}
		}
	)
}

interface TabBarMobileProps extends WithStyles<typeof styles>, TabBarProps {
}


const TabBarMobile: FunctionComponent<TabBarMobileProps> = ({ tableUrl, dispatch, data, setUrl, setTableUrl, classes}) => {
	const handleChange = (e) => {
		const searchParams = new URLSearchParams()
		searchParams.append("tableUrl", e.target.value)
		window.history.replaceState(null, "", `${window.location.pathname}?${searchParams.toString()}`)
		dispatch && setUrl ? dispatch(setUrl( e.target.value)) : (setTableUrl && setTableUrl( e.target.value))
	}

	return (
		<Box pb={2}>
			<Select
				disableUnderline
				value={tableUrl}
				onChange={handleChange}
				className={classes.tabsContracts}
				MenuProps={{
					getContentAnchorEl: null,
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'left'
					}
				}}
			>
				{
					data.map((item, index) => (
						<MenuItem key={index} value={item.path}>
							<FormattedMessage id={item.labelId} />
							{ (item.badge || item.badge === 0) && <BadgeBasic badgeContent={item.badge} badgeState="primary" />}
						</MenuItem>
					))
				}
			</Select>
		</Box>
	)
}

export default withStyles(styles)(TabBarMobile)