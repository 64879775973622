import { Avatar, Box, Drawer as MuiDrawer, Grid, IconButton, List, withStyles } from '@material-ui/core'
import { SettingsOutlined as SettingsIcon } from '@material-ui/icons'
import React, { useContext, useState } from 'react'
import { withRouter } from 'react-router'
import { compose } from 'recompose'
import { LoggedInContext } from '../../../contexts/LoggedInContext'
import BranchSelector from '../../branches/BranchSelector'
import DrawerMenuItem from './DrawerMenuItem'
import LogoutMenu from '../LogoutMenu'
import { hasAnyProfile, hasProfile } from '../../../utils/accessChecker'
import { PROFILE } from '../../../enums'
import MobileRegionSelector from '../../regions/MobileRegionSelector'

const styles = (theme) => ({
	drawerOpen: {
		top: 75,
		left: 0,
		backgroundColor: theme.palette.background.dark,
		color: theme.palette.text.secondary,
		transition: 'all .2s ease',
		width: '100%',
		boxShadow: '0px 10px 10px 10px rgba(60,68,91,.15)',
		border: 'none',
	},
	drawerClosed: {
		top: 75,
		left: '-100%',
		width: '100%',
		backgroundColor: theme.palette.background.dark,
		color: theme.palette.text.secondary,
		transition: 'all .2s ease',
	},
	drawerHeader: {
		padding: '15px 15px',
		backgroundColor: theme.palette.background.light,
	},
	btnPrimary: {
		color: theme.palette.grey['600'],
		backgroundColor: theme.palette.text.secondary,
		border: '1px solid',
		borderColor: theme.palette.background.lightShadow,
		borderRadius: '4px',
		boxShadow: '0 2px 0 0 #E5EAF5',
		transition: 'all .2s ease',
		'&:hover': {
			backgroundColor: theme.palette.text.secondary,
			borderColor: theme.palette.grey['700'],
		},
	},
	avatar: {
		height: 52,
		width: 52,
	},
	avatarPointer: {
		cursor: 'pointer',
	},
	drawerMenu: {
		padding: '0 15px 0 15px',
	},
})

const MobileDrawer = ({ items, isDrawerOpen, classes, location }) => {
	const [logoutMenuOpen, setLogoutMenuOpen] = useState(null)
	const { user } = useContext(LoggedInContext)
	const isCurrent = (path) => path === location.pathname

	return (
		<MuiDrawer variant="permanent" PaperProps={{ className: isDrawerOpen ? classes.drawerOpen : classes.drawerClosed }}>
			<Box className={classes.drawerHeader}>
				<Grid container spacing={1} direction="row" justify="space-between" alignItems="center">
					<Grid item xs={7}>
						{hasAnyProfile(user, [PROFILE.ZONE_MANAGER, PROFILE.NATIONAL])
						|| (hasProfile(user, PROFILE.REGIONAL_MANAGER) && user.regions.length > 1)? <MobileRegionSelector /> : <BranchSelector />}
					</Grid>
					{hasAnyProfile(user, [PROFILE.ZONE_MANAGER, PROFILE.NATIONAL]) && (
						<Grid item>
							<IconButton onClick={() => window.open('/bo/users')} className={classes.btnPrimary}>
								<SettingsIcon />
							</IconButton>
						</Grid>
					)}
					<Grid item>
						<Avatar onClick={(e) => setLogoutMenuOpen(e.currentTarget)} className={classes.avatarPointer}>
							{(user.firstname[0] + user.lastname[0]).toUpperCase()}
						</Avatar>
					</Grid>
				</Grid>
			</Box>
			<Box pt={3} className={classes.drawerMenu}>
				<List>
					{items.map((item) => (
						<DrawerMenuItem item={item} key={item.path} isCurrent={isCurrent(item.path)} />
					))}
				</List>
			</Box>
			<LogoutMenu anchorEl={logoutMenuOpen} onClose={() => setLogoutMenuOpen(null)} />
		</MuiDrawer>
	)
}

const enhance = compose(withRouter, withStyles(styles))

export default enhance(MobileDrawer)
