import {
	Box,
	createStyles,
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	Grid,
	Typography,
	withStyles,
} from '@material-ui/core'
import { ExpandMoreRounded, FiberManualRecordRounded as CircleIcon } from '@material-ui/icons'
import { SpringDataTable } from 'isotope-client'
import React, { useContext } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { compose } from 'recompose'
import NoResultFragment from '../../components/NoResult'
import StatusChip from '../../components/StatusChip'
import { ErrorHandlerContext } from '../../contexts/ErrorHandlerContext'
import { LoggedInContext } from '../../contexts/LoggedInContext'
import { ForecastLightBean } from '../../interfaces'
import { getColorByStatus } from '../../utils/getColorByStatus'
import { sanitizeLabel } from '../../utils/stringUtils'
import TablePaginationActions from '../datatable/TablePaginationActions'
import ContractComments from '../contracts/ContractComments'

const styles = (theme: any) => {
	return createStyles({
		cardTable: {
			display: 'flex',
			flexDirection: 'column',
			flex: '0 1 300px',
			width: '100%',
		},
		titleCardTable: {
			fontSize: 15,
			fontWeight: 700,
			color: theme.palette.grey['800'],
		},
		subCardTable: {
			fontSize: 12,
			fontWeight: 400,
			textTransform: 'uppercase',
			color: theme.palette.grey['500'],
		},
		tableHeaderCol: {
			padding: 0,
			height: 0,
		},
		tableBodyCol: {
			padding: 0,
			fontSize: '16px!important',
			fontWeight: '400!important' as any,
		},
	})
}

const ForecastsListMobile = ({ classes, match, filters, intl }) => {
	const { user } = useContext(LoggedInContext)
	const { setError } = useContext(ErrorHandlerContext)

	const colProps = {
		classes: {
			head: classes.tableHeaderCol,
			body: classes.tableBodyCol,
		},
	}

	const headers = [
		{
			key: 'id',
			name: '',
			tableHeaderColumnProps: colProps,
			render: (forecast: ForecastLightBean) => {
				return (
					<ExpansionPanel>
						<ExpansionPanelSummary expandIcon={<ExpandMoreRounded />}>
							<Box className={classes.cardTable}>
								<Link to={`/contracts/${forecast.idContract}`}>
									<Typography display="inline" className={classes.titleCardTable}>
										{forecast.customerName} ({forecast.idContract})
									</Typography>
									<Typography className={classes.subCardTable}>{forecast.customerAddress}</Typography>
								</Link>
							</Box>
						</ExpansionPanelSummary>
						<ExpansionPanelDetails>
							<Grid container spacing={2}>
								<Grid item xs={6}>
									<Typography>
										<FormattedMessage id="forecasts.page.list.headers.status" />
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<StatusChip
										Icon={CircleIcon}
										color={getColorByStatus(forecast.manualStatus).color}
										gradient={getColorByStatus(forecast.manualStatus).gradient}
										label={sanitizeLabel(
											intl.formatMessage({ id: `forecasts.page.list.information.status.${forecast.manualStatus}` })
										)}
									/>
								</Grid>
								<Grid item xs={6}>
									<Typography>
										<FormattedMessage id="forecasts.page.list.headers.unitsEmpty" />
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography>{forecast.unitsNumber}</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography>
										<FormattedMessage id="forecasts.page.list.headers.probability" />
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography>
										<FormattedMessage id={`forecasts.probability.${forecast.forecastProbability}.label`} />
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography>
										<FormattedMessage id="forecasts.page.list.headers.cancellationMonth" />
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography>
										{new Date(forecast.forecastCancellationMonth)
											.toLocaleString(user?.locale, { month: 'long', year: 'numeric' })
											.toUpperCase()}
									</Typography>
								</Grid>
								<Grid item xs={12} className={classes.item}>
									<ContractComments
										managers={{
											date: forecast.managersCommentDate,
											firstname: forecast.managersCommentFirstName,
											lastname: forecast.managersCommentLastName,
											text: forecast.managersCommentText,
										}}
										salesRep={{
											date: forecast.srCommentDate,
											firstname: forecast.srCommentFirstName,
											lastname: forecast.srCommentLastName,
											text: forecast.srCommentText,
										}}
										update={false}
									/>
								</Grid>
							</Grid>
						</ExpansionPanelDetails>
					</ExpansionPanel>
				)
			},
		},
	]

	return (
		<SpringDataTable
			headers={headers}
			filters={filters}
			apiUrlPrefix="/api"
			apiUrl={`/contracts-negotiations/forecasts${match.params.id ? `/${match.params.id}` : ''}`}
			noResultFragment={<NoResultFragment message="No forecasts found" />}
			ActionsComponent={(props: any) => <TablePaginationActions {...props} noPagination={false} />}
			postFetch={(_data: any, error: any) => error && setError && setError(error)}
		/>
	)
}

export default compose(withStyles(styles), withRouter, injectIntl)(ForecastsListMobile)
