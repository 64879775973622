import React, { FunctionComponent, useContext } from 'react'
import { Grid, withStyles, WithStyles } from '@material-ui/core'
import { mobileTableStyle } from '../../style'
import {
	REPORTING_PAGES,
	REPORTING_URL,
	reportingPerformanceLowThreshold,
	reportingPerformanceUpThreshold
} from '../../../../constants'
import ReportingTable from '../../ReportingTable'
import MobileTableContent from '../../MobileTableContent'
import Typography from '@material-ui/core/Typography/Typography'
import { ThumbDownAltOutlined, ThumbUpAltOutlined } from '@material-ui/icons'
import { FormattedMessage } from 'react-intl'
import { shortenBigNumbers } from '../../../../utils/utils'
import { LoggedInContext } from '../../../../contexts/LoggedInContext'
import { ReportingContextProps } from '../../types'
import { ReportingContext } from '../../../../contexts/ReportingContext'
import { getEntityLibelle } from '../utils'

interface Props extends WithStyles<typeof mobileTableStyle> {
	target: string
}

const MobileTablePerformance : FunctionComponent<Props> = ({ classes, target}) => {
	const { user } = useContext(LoggedInContext)
	const { perimeter } = React.useContext<ReportingContextProps>(ReportingContext)

	const colProps = {
		classes: {
			head: classes.tableHeaderCol,
			body: classes.tableBodyCol
		}
	}

	const headers = React.useMemo(() => ([
		{
			key: 'performances',
			tableHeaderColumnProps: colProps,
			render: row => <MobileTableContent
				title={
					<Typography display="inline">
						{
							target === REPORTING_PAGES.DETAILED ?
								<FormattedMessage id={`pages.reporting.month.${row.month}`} /> :
								getEntityLibelle(perimeter, row.entity)
						}
					</Typography>
				}
			>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<Typography><FormattedMessage id="pages.reporting.performance.table.savedContracts" values={{ br: <br /> }}/></Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography>
							{`${shortenBigNumbers(row.savedContractsUnits, user?.locale)} / ${shortenBigNumbers(row.savedContractsValue, user?.locale)}${row.currency}`}
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography><FormattedMessage id="pages.reporting.performance.table.lostContracts" values={{ br: <br /> }}/></Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography>
							{`${shortenBigNumbers(row.lostContractsUnits, user?.locale)} / ${shortenBigNumbers(row.lostContractsValue, user?.locale)}${row.currency}`}
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography><FormattedMessage id="pages.reporting.performance.table.savedContractsRate" values={{ br: <br /> }}/></Typography>
					</Grid>
					<Grid item xs={6}>
						<div className={classes.aligned}>
							<Typography display="inline">
								{`${row.savedContractsRateUnits}% / ${row.savedContractsRateValue}%`}
							</Typography>
							{
								((row.savedContractsRateUnits < reportingPerformanceLowThreshold) || (row.savedContractsRateUnits > reportingPerformanceUpThreshold)) && (
									<div className={classes.icons}>
										{(row.savedContractsRateUnits < reportingPerformanceLowThreshold) &&
                                            <div className={classes.bad}><ThumbDownAltOutlined /></div>}
										{(row.savedContractsRateUnits > reportingPerformanceUpThreshold) &&
                                            <div className={classes.good}><ThumbUpAltOutlined /></div>}
									</div>
								)
							}
						</div>

					</Grid>
					<Grid item xs={6}>
						<Typography><FormattedMessage id="pages.reporting.performance.table.renegociatedContracts" values={{ br: <br /> }}/></Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography>
							{`${shortenBigNumbers(row.renegociatedContractsUnits, user?.locale)} / ${shortenBigNumbers(row.renegociatedContractsValue, user?.locale)}${row.currency}`}
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography><FormattedMessage id="pages.reporting.performance.table.renegociatedContractsRate" values={{ br: <br /> }}/></Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography>
							{`${row.renegociatedContractsRateUnits}% / ${row.renegociatedContractsRateValue}%`}
						</Typography>
					</Grid>
				</Grid>
			</MobileTableContent>
		}
	]), [classes.aligned, classes.icons, classes.bad, classes.good, colProps, target, user, perimeter])

	return (
		<ReportingTable
			target={target}
			headers={headers}
			tab={REPORTING_URL.PERFORMANCE}
		/>
	)

}

export default withStyles(mobileTableStyle)(MobileTablePerformance)