import LinearProgress from '@material-ui/core/LinearProgress'
import withStyles from '@material-ui/core/styles/withStyles'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { compose } from 'redux'

const styles = theme => getStyles({
	progressBar: {
		borderRadius: 5,
		backgroundColor: theme.palette.grey[200],
		width: '100%'
	},
	colorPrimary: {
		backgroundColor: theme.palette.grey[200]
	},
	bar: {
		backgroundColor: theme.palette.error.main
	}
})

const ProgressBar = ({ color, value, total, classes }) => {
	const percentage = 100 * value / total

	return (
		<LinearProgress variant="determinate" value={percentage} classes={{
			root: classes.progressBar,
			colorPrimary: classes.colorPrimary,
			bar: color === 'error' ? classes.bar : undefined
		}} />
	)
}

ProgressBar.propTypes = {
	value: PropTypes.number.isRequired,
	total: PropTypes.number.isRequired,
	classes: PropTypes.object
}

export default compose(
	withStyles(styles)
)(ProgressBar)
