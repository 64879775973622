import React, { FunctionComponent, useContext } from 'react'
import { Grid, InputLabel, MenuItem, Select, withStyles, WithStyles, withWidth } from '@material-ui/core'
import { filtersStyles } from './style'
import { WithWidth } from '@material-ui/core/withWidth/withWidth'
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl'
import { ExpandMoreRounded } from '@material-ui/icons'
import { compose } from 'recompose'
import Typography from '@material-ui/core/Typography/Typography'
import { Field, Form } from 'react-final-form'
import { ValidationErrors } from 'final-form'
import { PROFILE, VIEW } from '../../enums'
import { Level } from './types'
import { hasProfile } from '../../utils/accessChecker'
import { LoggedInContext } from '../../contexts/LoggedInContext'

type InitialValues = {
	level?: string,
	year: number,
	view?: VIEW
}

interface PerimeterFiltersProps {
	intl: IntlShape
	validate: (values: any) => ValidationErrors
	initialValues: InitialValues
	onChange: (event: React.ChangeEvent<{ name?: string; value: any }>) => void
	tab?: string,
	levelOptions?: Level[],
	yearOptions?: number[],
	showLevel: boolean,
	showYear: boolean,
	showView: boolean,
	perimeter?: string,
	alignRight: boolean,
	showLabels: boolean
}

interface Props extends WithStyles<typeof filtersStyles>, WithWidth, PerimeterFiltersProps {
}

const ReportingFiltersForm: FunctionComponent<Props> = ({
	                                                        validate,
	                                                        initialValues,
	                                                        onChange,
	                                                        tab,
	                                                        levelOptions,
	                                                        yearOptions,
	                                                        showLevel = false,
	                                                        showYear = false,
	                                                        showView = false,
	                                                        perimeter,
	                                                        alignRight = false,
	                                                        showLabels = true,
	                                                        classes
                                                        }) => {
	const { user } = useContext<LoggedInContext>(LoggedInContext)

	return (
		<Form
			initialValues={{ ...initialValues, view: VIEW.MONTHLY }}
			onSubmit={() => {}}
			validate={validate}
			render={({ handleSubmit }) => (
				<form onSubmit={handleSubmit} className={classes.itemContainer}>
					<Grid container className={alignRight ? classes.alignRight : ''}>
						{
							showLevel && levelOptions && <Grid item xs={12} md={3}>
                                <Field
                                    name="level"
                                    render={({ input, meta }) => (
										<>
											{
												showLabels &&
                                                <InputLabel className={classes.inputLabel} id="label-select-level">
                                                    <FormattedMessage
                                                        id={`pages.reporting.filters.level.${perimeter || 'default'}`} />
                                                </InputLabel>
											}
											<Select
												{...input}
												labelId="label-select-level"
												IconComponent={ExpandMoreRounded}
												className={classes.select}
												disableUnderline
												disabled={user && hasProfile(user, PROFILE.SALES_REPRESENTATIVE)}
												onChange={(e) => {
													onChange(e)
													input.onChange(e)
												}}
											>
												{levelOptions.map((item) => (
													<MenuItem key={item.value} value={item.value}>
														{item.label}
													</MenuItem>
												))}
											</Select>
											{meta.touched && meta.error &&
                                                <Typography className={classes.error}>{meta.error}</Typography>}
										</>
									)}
                                />
                            </Grid>
						}
						{
							showYear && yearOptions && <Grid item xs={12} md={3}>
                                <Field
                                    name="year"
                                    render={({ input, meta }) => (
										<>
											{
												showLabels &&
                                                <InputLabel className={classes.inputLabel} id="label-select-year">
                                                    <FormattedMessage id="pages.reporting.filters.year" />
                                                </InputLabel>
											}
											<Select
												{...input}
												labelId="label-select-year"
												IconComponent={ExpandMoreRounded}
												className={classes.select}
												disableUnderline
												onChange={(e) => {
													onChange(e)
													input.onChange(e)
												}}
											>
												{yearOptions.map((year) => (
													<MenuItem key={year} value={year}>{year}</MenuItem>))}
											</Select>
											{meta.touched && meta.error &&
                                                <Typography className={classes.error}>{meta.error}</Typography>}
										</>
									)}
                                />
                            </Grid>
						}
						{
							showView && <Grid item xs={12} md={3}>
                                <Field
                                    name={'view'}
                                    render={({ input, meta }) => (
										<>
											{
												showLabels &&
                                                <InputLabel className={classes.inputLabel} id="label-select-view">
                                                    <FormattedMessage id="pages.reporting.filters.view.label" />
                                                </InputLabel>
											}
											<Select
												{...input}
												labelId="label-select-view"
												IconComponent={ExpandMoreRounded}
												className={classes.select}
												disableUnderline
												displayEmpty
												onChange={(e) => {
													onChange(e)
													input.onChange(e)
												}}
											>
												{Object.values(VIEW).map((item) => (
													<MenuItem key={item} value={item}>
														<FormattedMessage
															id={`pages.reporting.filters.view.${item}.${tab}`} />
													</MenuItem>
												))}
											</Select>
											{meta.touched && meta.error &&
                                                <Typography className={classes.error}>{meta.error}</Typography>}
										</>
									)}
                                />

                            </Grid>
						}
					</Grid>
				</form>
			)}
		/>
	)
}

const enhance = compose(
	withStyles(filtersStyles),
	withWidth(),
	injectIntl
)

export default enhance(ReportingFiltersForm)