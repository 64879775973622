import { createStyles, Theme } from '@material-ui/core'

export const drawerMenuItemStyles = (theme: Theme) => createStyles({
	drawerButton: {
		padding: 0,
		opacity: .35,
		transition: 'all .2s ease',
		'&:hover': {
			opacity: 1
		}
	},
	drawerButtonActive: {
		position: 'relative',
		padding: 0,
		opacity: 1,
		transition: 'all .2s ease',
		'&::after': {
			content: '" "',
			position: 'absolute',
			display: 'block',
			borderRadius: 10,
			backgroundColor: theme.palette.secondary.main
		},
		'&:hover': {
			opacity: 1
		},
		[theme.breakpoints.down('sm')]: {
			'&::after': {
				bottom: 0,
				left: 25,
				height: 8,
				width: 8,
			}
		},
		[theme.breakpoints.up('md')]: {
			color: theme.palette.text.secondary,
			'&::after': {
				bottom: 15,
				left: 40,
				height: 8,
				width: 8,
			}
		}
	},
	drawerIcon: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		color: theme.palette.text.secondary,
		[theme.breakpoints.down('sm')]: {
			width: 60,
			height: 60,
		},
		[theme.breakpoints.up('md')]: {
			width: 85,
			height: 85,
		},
	},
	itemText: {
		fontSize: 17,
		fontWeight: 700,
		whiteSpace: 'nowrap',
		color: theme.palette.grey['50']
	}
})