import { Input, InputAdornment, withStyles } from '@material-ui/core'
import { Search as SearchIcon } from '@material-ui/icons'
import React from 'react'

const styles = (theme) => ({
	searchBar: {
		padding: '0 15px',
		maxWidth: 400,
		width: '100%',
		opacity: 1,
		color: theme.palette.grey['600'],
		backgroundColor: theme.palette.background.light,
		textOverflow: 'ellipsis',
		'& [class*="MuiInput-input"]': {
			textOverflow: 'ellipsis'
		},
		'& [class*="MuiSelect-icon"]': {
			right: 10
		},
		[theme.breakpoints.down('sm')]: {
			maxWidth: '100%'
		}
	}
})

const SearchBar = ({ classes, onClick, onChange, placeholder, endAdornment, ...otherProps }) => {
	return (
		<Input
			className={classes.searchBar}
			disableUnderline
			startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
			endAdornment={endAdornment}
			onClick={onClick}
			onChange={onChange}
			placeholder={placeholder}
			{...otherProps}
		>
		</Input>
	)
}

export default withStyles(styles)(SearchBar)