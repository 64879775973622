import * as React from 'react'
import { initialState, loginReducer } from '../modules/login/services/loginReducer'
import { Branch, User, Region } from '../utils/accessChecker'
import { setBranchAction, setUserAction, updateUserAction, setRegionAction } from '../modules/login/services/loginActions'
import { useMsal } from '@azure/msal-react'

type LoggedInContext = {
	user?: User,
	setUser: (user: User) => void,
	updateUser: (user: Partial<User>) => void,
	setBranch: (branch: Branch | null) => void,
	setRegion: (region: Region | null) => void,
	logout: VoidFunction
}

const LoggedInContext = React.createContext<LoggedInContext>(null as any)

const useLoggedInContext = () => React.useContext(LoggedInContext)

type Props = {
	user: User
}

const LoggedInContextProvider: React.FC<Props> = ({ user, children }) => {
	const [state, dispatch] = React.useReducer(loginReducer, initialState)
	const setUser = React.useCallback((user: User) => dispatch(setUserAction(user)), [])
	const updateUser = React.useCallback((user: Partial<User>) => dispatch(updateUserAction(user)), [])
	const setBranch = React.useCallback((branch: Branch | null) => dispatch(setBranchAction(branch)), [])
	const setRegion = React.useCallback((region: Region | null) => dispatch(setRegionAction(region)), [])
	const { instance } = useMsal()

	React.useEffect(() => setUser(user), [user, setUser])

	return (
		<LoggedInContext.Provider value={{ user: state, setUser, updateUser, setBranch, setRegion, logout: () => instance.logoutRedirect() }}>
			{children}
		</LoggedInContext.Provider>
	)
}

export { useLoggedInContext, LoggedInContext, LoggedInContextProvider }
