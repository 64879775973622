import React, { FunctionComponent } from 'react'
import { Box, CircularProgress, createStyles, withStyles, WithStyles } from '@material-ui/core'
import { injectIntl, IntlShape } from 'react-intl'
import { compose } from 'recompose'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import { getDetailedPerformanceChartData } from '../../api'
import { DetailedPerformanceChartData, ReportingContextProps } from '../../types'
import { ReportingContext } from '../../../../contexts/ReportingContext'
import { ErrorHandlerContext } from '../../../../contexts/ErrorHandlerContext'
import { centerLoader } from '../../style'

const styles = () => createStyles({
	box: {
		padding: '0 30px'
	},
	hidden: {
		display: 'none'
	},
	centerLoader
})

interface Props extends WithStyles<typeof styles> {
	intl: IntlShape
}

interface ExtendedYAxis extends Highcharts.YAxisOptions {
	value: number;
}

const DetailedPerformanceChart: FunctionComponent<Props> = ({ classes, intl }) => {
	const { filters, perimeter } = React.useContext<ReportingContextProps>(ReportingContext)
	const { setError } = React.useContext<ErrorHandlerContext>(ErrorHandlerContext)
	const [loading, setLoading] = React.useState<boolean>(true)
	const [currency, setCurrency] = React.useState<string>('€')

	const [values, setValues] = React.useState<number[]>([])
	const [units, setUnits] = React.useState<number[]>([])

	React.useEffect(() => {
		getDetailedPerformanceChartData({ ...filters, perimeter })
			.then((data: DetailedPerformanceChartData[]) => {
				setValues(data.map(item => item.savedContractsValue))
				setUnits(data.map(item => item.savedContractsUnits))
				if (data.length !== 0 ) setCurrency(data[0].currency)
			})
			.catch(e => setError && setError(e))
			.finally(() => setLoading(false))
	}, [filters, perimeter, setError])

	const getOptions = React.useCallback((intl: IntlShape) => ({
		chart: {
			zoomType: 'xy'
		},
		title: {
			text: intl.formatMessage({ id: 'pages.reporting.detailed.graph.title' }),
			align: 'left',
			style: {
				fontWeight: '600!important'
			}
		},
		xAxis: [{
			categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
				'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
			crosshair: true
		}],
		yAxis: [{ // Primary yAxis
			labels: {
				formatter: function(this: ExtendedYAxis) {
					if (this.value > 1000) {
						return Highcharts.numberFormat(this.value / 1000, 0) + `k ${currency}`;
					}
					return `${this.value} ${currency}`
				},
				style: {
					color: '#041E42',
					fontSize: '12px!important',
					fontWeight: '600!important'
				}
			},
			title: {
				text: intl.formatMessage({ id: 'pages.reporting.detailed.graph.value' }),
				style: {
					color: '#041E42',
					fontSize: '12px!important',
					fontWeight: '600!important'
				}
			}
		}, { // Secondary yAxis
			title: {
				text: intl.formatMessage({ id: 'pages.reporting.detailed.graph.units' }),
				style: {
					color: '#F65275',
					fontSize: '12px!important',
					fontWeight: '600!important'
				}
			},
			labels: {
				format: '{value}',
				style: {
					color: '#F65275',
					fontSize: '12px!important',
					fontWeight: '600!important'
				}
			},
			opposite: true
		}],
		tooltip: {
			pointFormat: '{series.name}: {point.y}'
		},
		legend: {
			align: 'left',
			x: 80,
			verticalAlign: 'top',
			y: 80,
			floating: true,
			backgroundColor: '#FFFFFF'
		},
		series: [{
			name: intl.formatMessage({ id: 'pages.reporting.detailed.graph.value' }),
			type: 'column',
			data: values,
			tooltip: {
				valueSuffix: ` ${currency}`
			},
			color: '#2F66BB'
		}, {
			name: intl.formatMessage({ id: 'pages.reporting.detailed.graph.units' }),
			type: 'scatter',
			data: units,
			yAxis: 1,
			color: '#F65275'
		}],
		credits: {
			enabled: false
		}
	}), [values, units, currency])

	return (
		<Box className={classes.box}>
			<div className={!loading ? classes.hidden : classes.centerLoader}>
				<CircularProgress />
			</div>
			<div className={loading ? classes.hidden : ''}>
				<HighchartsReact
					highcharts={Highcharts}
					options={getOptions(intl)}
				/>
			</div>
		</Box>
	)
}

export default compose(
	withStyles(styles),
	injectIntl
)(DetailedPerformanceChart)