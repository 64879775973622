export const sanitizeLabel = (label: string) => {
	let value
	if (label === 'TODO') {
		value = 'NO ACTION'
	} else if (label === 'TO_BE_AUTO_RENEWED') {
		value = 'TO BE EXTENDED'
	} else {
		value = label.replace(/_/g, ' ')
	}
	return value
}


export const sanitizeLabelLower = (label: string) => {
	let value
	if (label === 'TODO') {
		value = 'No action'
	} else if (label === 'TO_BE_AUTO_RENEWED') {
		value = 'To be extended'
	} else {
		value = label.replace('_', ' ')
	}
	return value.charAt(0) + value.slice(1).toLowerCase()
}

export const truncate = (text: string | null | undefined, length: number, withHellip: boolean = true) => {
	if (!text) {
		return text
	}
	const truncated = text.substring(0, length)
	if (withHellip && text.length > length) {
		return `${truncated}…`
	}
	return truncated
}