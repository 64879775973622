import { Card, CardContent, CardHeader, Grid } from '@material-ui/core'
import React, { useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import PopupResultat from '../../../components/PopupResultat'
import { AlertOptions } from '../../../interfaces'
import { setContractNegotiation } from '../services/contractsActions'
import { updateForecast } from '../services/contractsApi'
import ForecastForm from './ForecastForm'

interface FormError {
	probability?: string
	cancellationMonth?: string
}

const ForecastModule = ({ intl, contract, dispatch }) => {
	const [open, setOpen] = useState<boolean>(false)
	const [alertOptions, setAlertOptions] = useState<AlertOptions>({ severity: 'info', message: '' })

	const cancellationMonth = (locale: string | undefined) => {
		const months: Record<string, string>[] = []
		if (
			contract.contractNegotiation.forecastCancellationMonth &&
			new Date(contract.contractNegotiation.forecastCancellationMonth) < new Date()
		) {
			months.push({
				name: new Date(contract.contractNegotiation.forecastCancellationMonth)
					.toLocaleString(locale, { month: 'long', year: 'numeric' })
					.toUpperCase(),
				value: '',
			})
		}
		const currentDate = new Date()
		for (let i = 1; i <= 12; i++) {
			currentDate.setMonth(currentDate.getMonth() + 1)
			currentDate.setDate(1)
			months.push({
				name: currentDate.toLocaleString(locale, { month: 'long', year: 'numeric' }).toUpperCase(),
				value: currentDate.toLocaleDateString('en-CA'),
			})
		}
		return months
	}

	const initialValues = {
		probability: contract.contractNegotiation.forecastProbability,
		cancellationMonth: contract.contractNegotiation.forecastCancellationMonth
			? new Date(contract.contractNegotiation.forecastCancellationMonth) > new Date()
				? new Date(contract.contractNegotiation.forecastCancellationMonth).toLocaleDateString('en-CA')
				: ''
			: null,
	}

	const onSubmit = (values: any) => {
		updateForecast(contract.contractNegotiation.id, {
			probability: values.probability,
			cancellationMonth: new Date(values.cancellationMonth),
		})
			.then((result: any) => {
				dispatch(setContractNegotiation(result))
				setOpen(true)
				setAlertOptions({
					severity: 'success',
					message: intl.formatMessage({ id: 'pages.contract.forecast.snackbar.success' }),
				})
			})
			.catch((_error: any) => {
				setOpen(true)
				setAlertOptions({
					severity: 'error',
					message: intl.formatMessage({ id: 'pages.contract.forecast.snackbar.error' }),
				})
			})
	}

	const validate = (values: any) => {
		const errors: FormError = {}
		if (!values.probability) {
			errors.probability = intl.formatMessage({ id: 'pages.contract.forecast.errors.required' })
		}
		if (values.cancellationMonth === '') {
			errors.cancellationMonth = intl.formatMessage({ id: 'pages.contract.forecast.errors.outdated' })
		} else if (!values.cancellationMonth) {
			errors.cancellationMonth = intl.formatMessage({ id: 'pages.contract.forecast.errors.required' })
		}
		return errors
	}

	return (
		<>
			<Card>
				<CardHeader title={<FormattedMessage id="pages.contract.forecast.title" />} />
				<CardContent>
					<Grid container>
						<ForecastForm
							contract={contract}
							validate={validate}
							onSubmit={onSubmit}
							initialValues={initialValues}
							cancellationMonth={cancellationMonth}
						/>
					</Grid>
				</CardContent>
			</Card>
			<PopupResultat open={open} setOpen={setOpen} options={alertOptions} />
		</>
	)
}

export default injectIntl(ForecastModule)
