import { Box, isWidthDown, withWidth } from '@material-ui/core'
import PageTitle from '../../components/PageTitle'
import * as React from 'react'
import { useState } from 'react'
import ContractsTable from './ContractsTable'
import { setFilters, setTableUrl, setUserId } from './services/contractsActions'
import { ContractsContext } from '../../contexts/ContractsContext'
import { defaultOverviewFiltersAll, defaultOverviewFiltersPriority } from './services/contractsReducer'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import { LoggedInContext } from '../../contexts/LoggedInContext'
import { getCountryModules } from '../salesPortfolio/services/salesPortfolioApi'
import * as api from './services/contractsApi'
import TabBarMobile from '../../components/TabBarMobile'
import TabBarDesktop, { TABLE_URL } from '../../components/TabBarDesktop'
import { OVERVIEW_TABLE_URL } from '../../constants'

const breadcrumbs = intl => [
	{
		path: '/dashboard',
		label: intl.formatMessage({ id: 'pages.contractsOverview.breadcrumbs.dashboard' })
	},
	{
		label: intl.formatMessage({ id: 'pages.contractsOverview.breadcrumbs.contractsOverview' })
	}
]

const ContractsOverview = ({ width, match, intl }) => {
	const { tableUrl, dispatch } = React.useContext(ContractsContext)
	const { user } = React.useContext(LoggedInContext)
	const [countryModules, setCountryModules] = React.useState({})
	const [counts, setCounts] = useState({
		priorityContractsCount: 0,
		lastCancelledContractsCount: 0,
		allContractsCount: 0
	})

	React.useEffect(() => {
		api.loadOverviewContractsCount().then(setCounts)
	}, [user])

	const data = React.useMemo(() => ([{
		path: OVERVIEW_TABLE_URL.PRIORITY,
		labelId: 'pages.contractsOverview.tabBar.priority',
		badge: counts.priorityContractsCount
	}, {
		path: OVERVIEW_TABLE_URL.CANCELLED,
		labelId: 'pages.contractsOverview.tabBar.cancelled',
		badge: counts.lastCancelledContractsCount
	}, {
		path: OVERVIEW_TABLE_URL.ALL,
		labelId: 'pages.contractsOverview.tabBar.all',
		badge: counts.allContractsCount
	}]), [counts])

	React.useEffect(() => {
		getCountryModules(user.countryId).then((result) => {
			setCountryModules(result)
		})
	}, [user])

	React.useEffect(() => {
		dispatch(setUserId(null))
		let filters
		switch (match.params.filter) {
			case 'todo':
				dispatch(setTableUrl(OVERVIEW_TABLE_URL.PRIORITY))
				filters = { ...defaultOverviewFiltersPriority, status: ['TODO'] }
				break
			case 'in-progress':
				dispatch(setTableUrl(OVERVIEW_TABLE_URL.PRIORITY))
				filters = { ...defaultOverviewFiltersPriority, status: ['IN_PROGRESS'] }
				break
			case 'manually-closed':
				dispatch(setTableUrl(OVERVIEW_TABLE_URL.PRIORITY))
				filters = {
					...defaultOverviewFiltersPriority,
					status: ['TO_BE_CANCELLED', 'TO_BE_AUTO_RENEWED', 'RENEGOTIATED', 'UPGRADED', 'DOWNGRADED']
				}
				break
			case 'need-help':
				dispatch(setTableUrl(OVERVIEW_TABLE_URL.PRIORITY))
				filters = { ...defaultOverviewFiltersPriority, status: ['TODO', 'IN_PROGRESS'], flags: ['needHelp'] }
				break
			case 'actions-overdue':
				dispatch(setTableUrl(OVERVIEW_TABLE_URL.PRIORITY))
				filters = { ...defaultOverviewFiltersPriority, status: ['TODO', 'IN_PROGRESS'], flags: ['actionsOverdue'] }
				break
			case 'overdue':
				dispatch(setTableUrl(OVERVIEW_TABLE_URL.PRIORITY))
				filters = { ...defaultOverviewFiltersPriority, status: ['TODO', 'IN_PROGRESS'], flags: ['overdue'] }
				break
			case 'from-other-contracts':
				dispatch(setTableUrl(OVERVIEW_TABLE_URL.ALL))
				filters = { ...defaultOverviewFiltersAll, searchQuery: match.params.otherFilter}
				break
			case 'to-be-cancelled':
				dispatch(setTableUrl(OVERVIEW_TABLE_URL.ALL))
				filters = { ...defaultOverviewFiltersAll, status: ['TO_BE_CANCELLED']}
				break
			case 'to-be-auto-renewed':
				dispatch(setTableUrl(OVERVIEW_TABLE_URL.ALL))
				filters = { ...defaultOverviewFiltersAll, status: ['TO_BE_AUTO_RENEWED']}
				break
			case 'renegotiated':
				dispatch(setTableUrl(OVERVIEW_TABLE_URL.ALL))
				filters = { ...defaultOverviewFiltersAll, status: ['RENEGOTIATED']}
				break
			case 'upgraded':
				dispatch(setTableUrl(OVERVIEW_TABLE_URL.ALL))
				filters = { ...defaultOverviewFiltersAll, status: ['UPGRADED']}
				break
			case 'downgraded':
				dispatch(setTableUrl(OVERVIEW_TABLE_URL.ALL))
				filters = { ...defaultOverviewFiltersAll, status: ['DOWNGRADED']}
				break
			default:
				dispatch(setTableUrl(OVERVIEW_TABLE_URL.PRIORITY))
				filters = { ...defaultOverviewFiltersPriority }
		}
		switch (match.params.otherFilter) {
			case 'lowValue':
				filters = { ...filters, classification: ['LOW_VALUE'] }
				break
			case 'highValue':
				filters = { ...filters, classification: ['HIGH_RISK_HIGH_VALUE'] }
				break
			default:
		}
		dispatch(setFilters(filters))
	}, [dispatch, match])

	return (
		<Box>
			<PageTitle title={intl.formatMessage({ id: 'pages.contractsOverview.title' })} breadcrumbs={breadcrumbs(intl)} />
			{isWidthDown('sm', width) ? <TabBarMobile data={data} dispatch={dispatch} tableUrl={tableUrl} setUrl={setTableUrl}/> :
				<TabBarDesktop data={data} dispatch={dispatch} tableUrl={tableUrl === TABLE_URL.PRIORITY ? OVERVIEW_TABLE_URL.PRIORITY : tableUrl} setUrl={setTableUrl}/>}
			<ContractsTable isOverview modules={countryModules} />
		</Box>
	)
}

export default compose(
	withWidth(),
	injectIntl
)(ContractsOverview)