import { ClickAwayListener, makeStyles, Paper, Popper } from '@material-ui/core'
import * as React from 'react'
import { hasAnyProfile } from '../../utils/accessChecker'
import { PROFILE } from '../../enums'
import SalesRepresentativesSearchBlock from './SalesRepresentativesSearchBlock'
import { LoggedInContext } from '../../contexts/LoggedInContext'
import ContractsSearchBlock from './ContractsSearchBlock'

const useStyles = makeStyles((theme) => ({
	root: {
		flex: 1,
		marginTop: 10,
		zIndex: 2000
	},
	paper: {
		padding: '15px 0',
		border: '1px solid',
		borderColor: theme.palette.grey['200'],
		borderRadius: 4,
		boxShadow: '0 0 15px 0 rgba(0,25,80,.2)',
		minWidth: 425
	},
	title: {
		display: 'flex',
		alignItems: 'center',
		fontSize: 12,
		fontWeight: 600,
		color: theme.palette.text.primary,
		padding: '0 20px',
		marginBottom: 15,
		height: 30
	},
	groupList: {
		padding: '0 20px'
	},
	itemList: {
		fontSize: 15,
		fontWeight: 500,
		color: theme.palette.text.primary,
		padding: '0',
		marginBottom: 15
	}
}))

const SearchTray = ({ anchorEl, isMenuOpen, handleMenuClose, query }) => {
	const classes = useStyles()
	const { user } = React.useContext(LoggedInContext)

	return (
		<Popper
			anchorEl={anchorEl}
			placement="bottom-end"
			open={isMenuOpen}
			className={classes.root}
		>
			<ClickAwayListener onClickAway={handleMenuClose}>
				<Paper className={classes.paper}>
					{user && hasAnyProfile(user, [PROFILE.ZONE_MANAGER, PROFILE.NATIONAL, PROFILE.REGIONAL_MANAGER, PROFILE.BRANCH_MANAGER, PROFILE.BRANCH_MANAGER_ASSISTANT]) &&
					<SalesRepresentativesSearchBlock query={query} handleMenuClose={handleMenuClose} />
					}
					<ContractsSearchBlock query={query} handleMenuClose={handleMenuClose} />
				</Paper>
			</ClickAwayListener>
		</Popper>
	)
}

export default SearchTray