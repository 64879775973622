import React, { Fragment, ReactElement, useState } from 'react'
import {
	Box,
	createStyles, Dialog,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	WithStyles,
	withStyles
} from '@material-ui/core'
import Typography from '@material-ui/core/Typography/Typography'
import { Matrix } from '../modules/backOffice/renegotiation/type'
import classnames from 'classnames'

const styles = () =>
	createStyles({
		tableRoot: {
			'& [class*="MuiTable-root"]' : {
				borderCollapse: 'separate'
			}
		},
		header: {
			width: '100%'
		},
		headerCell: {
			backgroundColor: '#F4F6FB'
		},
		cell: {
			border: '1px solid',
			borderColor: '#00000047',
			borderRadius: '5px'
		},
		tableCellRoot: {
			'& [class*="MuiTableCell-root"]': {
				textAlign: 'center'
			}
		},
		hideElement: {
			visibility: 'hidden',
			borderLeft: 'hidden',
			borderTop: 'hidden'
		},
		rotate: {
			writingMode: 'vertical-lr',
			transform: 'rotate(180deg)',
			width: '85px',
			color: '#687BAE'
		},
		xHeaders: {
			width: '75px',
			color: '#687BAE',
			fontWeight: 500
		},
		cellWidth: {
			width: '160px'
		},
		bigHeaders: {
			'& [class*="MuiTypography-subtitle1"]': {
				color: '#687BAE',
				fontWeight: 500
			}
		},
		headers: {
			'& [class*="MuiTypography-subtitle1"]': {
				color: '#687BAE'
			}
		},
		text: {
			maxHeight: '90px',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			display: '-webkit-box',
			"-webkit-line-clamp": 4,
			"-webkit-box-orient": 'vertical'
		},
		body: {
			height: '600px'
		},
		pointer: {
			cursor: 'pointer'
		}
	})

interface MatrixProps extends WithStyles<typeof styles> {
	xHeaderLabel: React.ReactNode
	xSubHeaders: number[],
	yHeaderLabel: React.ReactNode
	ySubHeaders: number[]
	data: Matrix[],
	getDialogContent?: (positionX: number, positionY: number, onClose: () => void) => ReactElement,
	cellColors: string[][]
}

const MatrixTable = ({ xHeaderLabel, xSubHeaders, yHeaderLabel, ySubHeaders, classes, data, getDialogContent, cellColors } : MatrixProps) => {
	const [open, setOpen] = useState<boolean>(false)
	const [selectedPosition, setSelectedPosition] = useState<number[]>([0, 0])

	const onCellClick = (positionX, positionY) => {
		setSelectedPosition([positionX, positionY])
		setOpen(true)
	}

	const closeEditDialog = React.useCallback(() => {
		setOpen(false)
	}, [])

	const getCellColor = React.useCallback((positionX: number, positionY: number) => {
		return cellColors[positionY][positionX]
	}, [cellColors])

	const renderTableBody = () => {
		return (
			<TableBody className={classes.body}>
				<TableRow className={classnames(classes.tableCellRoot)}>
					<TableCell
						rowSpan={xSubHeaders.length + 1}
						className={classnames(classes.headerCell, classes.cell, classes.rotate, classes.bigHeaders)}
					>
						<Typography variant="subtitle1">
							{xHeaderLabel}
						</Typography>
					</TableCell>
				</TableRow>
				{xSubHeaders.map((item, index) => (
					<Fragment key={`row-${item}`}>
						<TableRow key={`body-${item}`} className={classnames(classes.tableCellRoot)}>
							<TableCell className={classnames(classes.headerCell, classes.cell, classes.xHeaders, classes.headers)}>
								<Typography variant="subtitle1">{item}</Typography>
							</TableCell>
							{
								data.filter(element => element.positionX === index + 1)
									.sort((a: Matrix, b: Matrix) => {
										if (a.positionY < b.positionY) return -1
										else if (a.positionY > b.positionY) return 1
										else return 0
									})
									.map(element => (
										<TableCell
											key={`cell-${element.positionX}-${element.positionY}`}
											className={classnames(classes.cell, classes.cellWidth, classes.pointer)}
											style={{backgroundColor : getCellColor(element.positionY - 1, element.positionX - 1)}}
											onClick={(_: React.MouseEvent<HTMLElement>) => onCellClick(element.positionX, element.positionY)}
										>
											<Typography variant="body1" className={classes.text}>{element.value}</Typography>
										</TableCell>
									))
							}
						</TableRow>
					</Fragment>
				))
				}
			</TableBody>
		)
	}

	return (
		<Box className={classes.tableRoot}>
			<Table>
				<TableHead className={classes.header}>
					<TableRow className={classnames(classes.tableCellRoot)}>
						<TableCell className={classes.hideElement}/>
						<TableCell className={classes.hideElement}/>
						<TableCell colSpan={xSubHeaders.length} className={classnames(classes.headerCell, classes.cell, classes.bigHeaders)}>
							<Typography variant="subtitle1">{yHeaderLabel}</Typography>
						</TableCell>
					</TableRow>
					<TableRow className={classes.tableCellRoot}>
						<TableCell className={classes.hideElement}/>
						<TableCell className={classes.hideElement}/>
						{
							ySubHeaders.map(item => (
								<TableCell key={`headers-${item}`} className={classnames(classes.headerCell, classes.cell, classes.cellWidth, classes.headers)}>
									<Typography variant="subtitle1">{item}</Typography>
								</TableCell>
							))
						}
					</TableRow>
				</TableHead>
					{
						data.length > 0 && renderTableBody()
					}
			</Table>
			{
				getDialogContent && (
					<Dialog open={open} onClose={closeEditDialog} fullWidth maxWidth="sm">
						{getDialogContent(selectedPosition[0], selectedPosition[1], closeEditDialog)}
					</Dialog>
				)
			}
		</Box>
	)
}

export default withStyles(styles)(MatrixTable)