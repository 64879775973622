import React, { FunctionComponent } from 'react'
import { isWidthDown, Paper, WithStyles, withStyles, withWidth, WithWidth } from '@material-ui/core'
import { tabBarStyles } from '../../style'
import { ReportingContext } from '../../../../contexts/ReportingContext'
import { ReportingContextProps } from '../../types'
import classNames from 'classnames'
import ReportingFiltersForm from '../../ReportingFiltersForm'
import { onChange, validate } from '../../details/utils'
import { injectIntl, IntlShape } from 'react-intl'
import { compose } from 'recompose'
import MobileTablePerformance from '../../details/performance/MobileTablePerformance'
import { REPORTING_PAGES } from '../../../../constants'
import TablePerformance from '../../details/performance/TablePerformance'
import BarChart from '../BarChart'
import { getComparativePerformanceChartData } from '../../api'

interface Props extends WithStyles<typeof tabBarStyles>, WithWidth {
	intl: IntlShape
}

const ComparativePerformance: FunctionComponent<Props> = ({ classes, intl, width }) => {
	const { yearOptions, setFilters } = React.useContext<ReportingContextProps>(ReportingContext)

	const initialValues = React.useMemo(() => {
		if (yearOptions.length > 0) {
			return { year: yearOptions[0] }
		}
		return {}
	}, [yearOptions])

	return (
		<Paper
			className={classNames(classes.radiusWithTabBar, isWidthDown('sm', width) ? classes.tabContentMobile : classes.tabContentDesktop)}>
			<div className={classes.pad}>
				<ReportingFiltersForm
					validate={validate(intl)}
					onChange={onChange(setFilters)}
					initialValues={initialValues}
					yearOptions={yearOptions}
					showYear
					alignRight
					showLabels={false}
				/>
			</div>
			<BarChart
				title={intl.formatMessage({ id: 'pages.reporting.teams.graph.performance.title' })}
				legend={intl.formatMessage({ id: 'pages.reporting.teams.graph.performance.legend' })}
				getData={getComparativePerformanceChartData}
			/>
			<div className={classes.table}>
				{
					isWidthDown('sm', width) ? <MobileTablePerformance target={REPORTING_PAGES.TEAMS} /> : (
						<TablePerformance target={REPORTING_PAGES.TEAMS}/>
					)
				}
			</div>
		</Paper>
	)
}

const enhance = compose(
	withWidth(),
	withStyles(tabBarStyles),
	injectIntl
)

export default enhance(ComparativePerformance)