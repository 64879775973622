import { CssBaseline, MuiThemeProvider } from '@material-ui/core'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ContractsContextProvider } from '../contexts/ContractsContext'
import Actions from '../modules/actions/Actions'
import Contract from '../modules/contracts/Contract'
import DashboardPage from '../modules/dashboard/DashboardPage'
import Layout from '../modules/layout/Layout'
import SalesPortfolio from '../modules/salesPortfolio/SalesPortfolio'
import SalesRepOverview from '../modules/SROverview/SalesRepOverview'
import { frontTheme } from '../theme'
import SalesPortfolioManagerView from '../modules/salesPortfolio/SalesPortfolioManagerView'
import ContractsOverview from '../modules/contracts/ContractsOverview'
import { ErrorHandlerContext } from '../contexts/ErrorHandlerContext'
import ErrorPopup from '../components/ErrorPopup'
import SelectCountryLayout from '../modules/selectcountry/SelectCountryPage'
import { injectIntl } from 'react-intl'
import Forecasts from '../modules/forecasts/Forecasts'
import TeamsReportingPage from '../modules/reporting/teams/TeamsReportingPage'
import DetailedReportingPage from '../modules/reporting/details/DetailedReportingPage'
import { ReportingContextProvider } from '../contexts/ReportingContext'
import EmeaReporting from '../modules/reporting/emea/EmeaReporting'

const FrontOfficeRouter = ({ intl }) => {
	const { error, setError } = React.useContext(ErrorHandlerContext)

	return (
		<MuiThemeProvider theme={frontTheme}>
			<CssBaseline />
			<Switch>
				<Route path="/select-country">
					<SelectCountryLayout />
				</Route>
				<Route exact path="/emea/reporting/teams/:filter?">
					<ReportingContextProvider>
						<EmeaReporting />
					</ReportingContextProvider>
				</Route>
				<Route path="*">
					<Layout>
						<Switch>
							<Route exact path="/dashboard" component={DashboardPage} />
							<ContractsContextProvider>
								<Route exact path="/sr-overview" component={SalesRepOverview} />
								<Route exact path="/portfolio" component={SalesPortfolio} />
								<Route exact path="/portfolio/:id" component={SalesPortfolioManagerView} />
								<Route exact path="/actions" component={Actions} />
								<Route exact path="/actions/:id" component={Actions} />
								<Route exact path="/contracts/:id" component={Contract} />
								<Route exact path="/contracts-overview/:filter?/:otherFilter?" component={ContractsOverview} />
								<Route exact path="/forecasts" component={Forecasts} />
								<Route exact path="/forecasts/:id" component={Forecasts} />
								<ReportingContextProvider>
									<Route exact path="/reporting/teams/:filter?" component={TeamsReportingPage} />
									<Route exact path="/reporting/detailed/:filter?" component={DetailedReportingPage} />
								</ReportingContextProvider>
								</ContractsContextProvider>
						</Switch>
					</Layout>
				</Route>
			</Switch>
			<ErrorPopup
				open={error && (error.status === 401 || error.status >= 500)}
				onClose={() => setError(null)}
				title={intl.formatMessage({ id: 'router.error.title' })}
				content={intl.formatMessage({ id: 'router.error.content' })}
			/>
		</MuiThemeProvider>
	)
}

export default injectIntl(FrontOfficeRouter)
