import { Box, IconButton, Typography, withStyles } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import React from 'react'
import Button from '@material-ui/core/Button/Button'
import { Close as CloseIcon } from '@material-ui/icons'
import { FormattedMessage, injectIntl } from 'react-intl'
import { compose } from 'recompose'

const styles = (theme) => {
	return ({
			tab: {
				display: 'flex',
				width: 'auto'
			}
		}
	)
}

const RiskTypologyPopup = ({ open, onClose, title, flagDetails, intl }) => {

	return (
		<Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="sm">
			<DialogTitle>
				<Box display="flex" alignItems="center" justifyContent="space-between" flexGrow="1">
					{title}
					<IconButton onClick={onClose}><CloseIcon /></IconButton>
				</Box>
			</DialogTitle>
			<DialogContent>{open && flagDetails.replace(/flag/gi,'').split(',').map(flag =>
				<Typography key={flag}>- {intl.formatMessage({ id: `pages.contract.flags.description.${flag}` })}</Typography>
			)}</DialogContent>
			<DialogActions>
				<Button onClick={onClose} variant="contained" size="small" color="primary" disableElevation={true}><FormattedMessage id="pages.contract.flags.close" /></Button>
			</DialogActions>
		</Dialog>
	)
}

export default compose(
	withStyles(styles),
	injectIntl
)(RiskTypologyPopup)
