import { Box, Tooltip, Typography, withStyles } from '@material-ui/core'
import { PriorityHigh as ClockIcon, FiberManualRecordRounded as CircleIcon } from '@material-ui/icons'
import { SpringDataTable } from 'isotope-client'
import React, { useContext } from 'react'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import NoResultFragment from '../../components/NoResult'
import StatusChip from '../../components/StatusChip'
import { ActionsFiltersContext } from '../../contexts/ActionsFiltersContext'
import { getColorByStatus } from '../../utils/getColorByStatus'
import TablePaginationActions from '../datatable/TablePaginationActions'
import { ACTION_TYPE_LABELS, ACTIONS_STATUS } from './actionConstants'
import { ErrorHandlerContext } from '../../contexts/ErrorHandlerContext'
import { compose } from 'recompose'
import { injectIntl } from 'react-intl'

const styles = (theme) => ({
	cardTable: {
		display: 'flex',
		flexDirection: 'column',
		flex: '0 1 300px',
		width: '100%'
	},
	titleCardTable: {
		fontSize: 15,
		fontWeight: '700',
		color: theme.palette.grey['800']
	},
	subCardTable: {
		fontSize: 12,
		fontWeight: '400',
		textTransform: 'uppercase',
		color: theme.palette.grey['500']
	},
	avatar: {
		marginRight: 15
	},
	iconTypology: {
		height: 26,
		width: 26,
		padding: 3,
		margin: '0 3px',
		borderRadius: 5,
		borderColor: theme.palette.background.lightShadow,
		border: '2px solid'
	},
	error: {
		color: theme.palette.error.main,
		fontSize: 15,
		fontWeight: 600
	},
	clockIcon: {
		color: theme.palette.warning.icon2
	}
})

const ActionsListDesktop = ({ classes, match, intl }) => {
	const { filters } = useContext(ActionsFiltersContext)
	const { setError } = React.useContext(ErrorHandlerContext)

	const headers = [
		{
			key: 'id',
			name: intl.formatMessage({ id: 'action.page.list.headers.action' }),
			render: action => (
				<Box display="flex" alignItems="center">
					<Box className={classes.cardTable}>
						<Link to={`/contracts/${action.idContract}`}>
							<Typography display="inline" className={classes.titleCardTable}>{ACTION_TYPE_LABELS(intl)[action.type].label}</Typography>
							<Typography className={classes.subCardTable}>{action.customerName} ({action.idContract})</Typography>
						</Link>
					</Box>
				</Box>
			)
		},
		{
			key: 'status',
			name: intl.formatMessage({ id: 'action.page.list.headers.status' }),
			render: action => <StatusChip Icon={CircleIcon} {...getColorByStatus(action.status)}
			                              label={ACTIONS_STATUS(intl)[action.status].chipLabel} />
		},
		{
			key: 'description',
			name: intl.formatMessage({ id: 'action.page.list.headers.description' }),
			render: action => action.description.length <= 150 ? action.description : action.description.substring(0, 150) + '...'
		},
		{
			key: 'deadline',
			name: intl.formatMessage({ id: 'action.page.list.headers.deadline' }),
			render: action => {
				const deadline = new Date(action.deadline)
				const date = new Date()
				date.setDate(date.getDate() - 1)
				return (
					<Box display="flex" alignItems="center">
						{date > deadline &&
						<Tooltip title={intl.formatMessage({ id: 'action.page.list.tooltip' })}><ClockIcon className={classes.clockIcon} /></Tooltip>}
						<Typography display="inline">&nbsp;{deadline.toLocaleDateString()}</Typography>
					</Box>
				)
			}
		}
	]

	return (
		<>
			<SpringDataTable
				nom="actionDataTable"
				headers={headers}
				filters={filters}
				apiUrlPrefix="/api"
				apiUrl={`/actions${match.params.id ? `/${match.params.id}` : ''}`}
				noResultFragment={<NoResultFragment message={intl.formatMessage({ id: 'action.page.list.noResult' })} />}
				ActionsComponent={(props) => <TablePaginationActions {...props} noPagination={false} />}
				postFetch={(data, error) => error && setError(error)}
			/>
		</>
	)
}

export default compose(
	withStyles(styles),
	withRouter,
	injectIntl
)(ActionsListDesktop)