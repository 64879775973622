import React from 'react'
import { Tooltip } from '@material-ui/core'

const ContractsDistrib = ({ firstPercent, secondPercent, todoCount, inProgressCount, manuallyClosedCount }) => {
	return (
		<svg viewBox="0 0 36 36">
			<Tooltip title={`${manuallyClosedCount} contracts`} placement='left-start'>
				<path
					d="M18 2.0845
      a 15.9155 15.9155 0 0 1 0 31.831
      a 15.9155 15.9155 0 0 1 0 -31.831"
					fill="none"
					stroke="#172F50"
					strokeWidth="3"
				/>
			</Tooltip>
			<Tooltip title={`${inProgressCount} contracts`} placement='bottom'>
				<path
					d="M18 2.0845
      a 15.9155 15.9155 0 0 1 0 31.831
      a 15.9155 15.9155 0 0 1 0 -31.831"
					fill="none"
					stroke="#34CB81"
					strokeWidth="3"
					strokeDasharray={`${firstPercent + secondPercent}, 100`}
				/>
			</Tooltip>
			<Tooltip title={`${todoCount} contracts`} placement='right-start'>
				<path
					d="M18 2.0845
      a 15.9155 15.9155 0 0 1 0 31.831
      a 15.9155 15.9155 0 0 1 0 -31.831"
					fill="none"
					stroke="#818EA0"
					strokeWidth="3"
					strokeDasharray={`${firstPercent}, 100`}
				/>
			</Tooltip>
		</svg>
	)
}

export default ContractsDistrib