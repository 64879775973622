import { ADD_ACTION, CLOSE_ACTION, SET_ACTIONS, UPDATE_ACTION } from './actionsActions'

const initialState = {
	actions: []
}

function reducer(state, action) {
	switch (action.type) {
		case ADD_ACTION:
			let tmpAddActions = [...state.actions, action.action]
			tmpAddActions.sort((a, b) => new Date(a.deadline) - new Date(b.deadline))
			return {
				...state,
				actions: [...tmpAddActions]
			}
		case UPDATE_ACTION:
			let index = state.actions.findIndex(
				a => a.id === action.action.id
			)
			let tmpUpdateActions = [...state.actions]
			tmpUpdateActions[index] = action.action
			tmpUpdateActions.sort((a, b) => new Date(a.deadline) - new Date(b.deadline))
			return {
				...state,
				actions: [...tmpUpdateActions]
			}
		case SET_ACTIONS:
			return {
				...state,
				actions: [...action.actions]
			}
		case CLOSE_ACTION:
			let tmpCloseAction = [...state.actions]
			if (action.status === 'OPEN') {
				tmpCloseAction = tmpCloseAction.filter(a => a.id !== action.action.id)
			} else {
				let i = state.actions.findIndex(
					a => a.id === action.action.id
				)
				let tmpCloseAction = [...state.actions]
				tmpCloseAction[i].status = action.action.status
				tmpCloseAction.sort((a, b) => new Date(a.deadline) - new Date(b.deadline))
			}
			return {
				...state,
				actions: [...tmpCloseAction]
			}
		default:
			throw new Error()
	}
}

export { initialState, reducer }