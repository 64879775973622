import { Theme, WithStyles, withStyles } from '@material-ui/core'
import Box from '@material-ui/core/Box/Box'
import Button from '@material-ui/core/Button/Button'
import DialogActions from '@material-ui/core/DialogActions/DialogActions'
import DialogContent from '@material-ui/core/DialogContent/DialogContent'
import Grid from '@material-ui/core/Grid/Grid'
import InputLabel from '@material-ui/core/InputLabel/InputLabel'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import Select from '@material-ui/core/Select/Select'
import TextField from '@material-ui/core/TextField/TextField'
import Typography from '@material-ui/core/Typography/Typography'
import * as React from 'react'
import { Field, Form, FormSpy } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'
import { ACTION_TYPE } from '../../enums'
import { ACTION_TYPE_LABELS } from './actionConstants'
import ContractsComparison from './ContractsComparison'
import { compose } from 'recompose'
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl'
import { OUTLIER } from '../../constants'

// TYPES
enum FIELDS {
	TYPE = 'type',
	DEADLINE = 'deadline',
	DESCRIPTION = 'description'
}

interface Values {
	cluster: Object,
	priceScore: string,
	[FIELDS.TYPE]?: ACTION_TYPE,
	[FIELDS.DEADLINE]?: Date,
	[FIELDS.DESCRIPTION]?: string
}

type Errors = {
	[k in FIELDS]?: string
}

const styles = (theme: Theme) => {
	return ({
			tab: {
				display: 'flex',
				width: 'auto'
			},
			datepicker: {
				'& [class*="MuiInputBase-input"]': {
					maxHeight: 25,
					backgroundColor: theme.palette.text.secondary
				}
			},
			errorLabel: {
				color: theme.palette.error.main
			}
		}
	)
}

const validate = (values: Values, intl) => {
	const errors: Errors = {}
	if (!values.type) {
		errors.type = intl.formatMessage({ id: 'errors.mandatory' })
	}
	if (!values.deadline) {
		errors.deadline = intl.formatMessage({ id: 'errors.mandatory' })
	} else if (values.deadline && values.deadline < new Date()) {
		errors.deadline = intl.formatMessage({ id: 'errors.onlyFutureDate' })
	}

	return errors
}

interface Props extends WithStyles<typeof styles> {
	initialValues: Values,
	onSubmit: (values: Values) => void,
	onClose: VoidFunction,
	validateButtonLabel: string,
	contract: Values,
	intl: IntlShape
}

const ActionForm: React.FunctionComponent<Props> = ({ initialValues, onClose, onSubmit, validateButtonLabel, classes, contract, intl }) => {
	const actionTypeLabels = ACTION_TYPE_LABELS(intl)

	return (
		<Form
			initialValues={initialValues}
			onSubmit={onSubmit}
			validate={values => validate(values, intl)}
			render={({ handleSubmit, values }) => (
				<form onSubmit={handleSubmit}>
					<DialogContent>
						<Grid container spacing={2}>
							<Grid item xs={8}>
								<Field
									name={FIELDS.TYPE}
									render={({ input, meta }) => (
										<>
											<InputLabel id="action-label">
												<FormattedMessage id="action.form.type" />
											</InputLabel>
											<Select
												labelId="action-label"
												{...input}
												disableUnderline
												displayEmpty
												MenuProps={{
													getContentAnchorEl: null,
													anchorOrigin: {
														vertical: 'bottom',
														horizontal: 'left'
													}
												}}
											>
												<MenuItem value="" disabled><FormattedMessage id="action.form.select" /></MenuItem>
												{Object.keys(ACTION_TYPE)
													.map(actionType => <MenuItem value={actionType}>{actionTypeLabels[actionType as keyof typeof ACTION_TYPE].label}</MenuItem>)
												}
											</Select>
											{meta.touched && meta.error && <Box pt={1}><Typography className={classes.errorLabel}>{meta.error}</Typography></Box>}
										</>
									)}
								/>
							</Grid>
							<Grid item xs={4}>
								<Field
									name={FIELDS.DEADLINE}
									render={({ input, meta }) => (
										<>
											<InputLabel shrink id="deadline-label">
												<FormattedMessage id="action.form.deadline" />
											</InputLabel>
											<TextField
												type="date"
												fullWidth
												{...input}
												InputProps={{ disableUnderline: true }}
												className={classes.datepicker}
											/>
											{meta.touched && meta.error && <Box pt={1}><Typography className={classes.errorLabel}>{meta.error}</Typography></Box>}
										</>
									)}
								/>
							</Grid>
							{(values[FIELDS.TYPE] === ACTION_TYPE.RENEGOTIATION
								|| values[FIELDS.TYPE] === ACTION_TYPE.PROACTIVE_PROPOSAL
								|| values[FIELDS.TYPE] === ACTION_TYPE.DOWNSELL
								|| values[FIELDS.TYPE] === ACTION_TYPE.UPSELL) &&
							<Grid item xs={12}>
								{contract.cluster && contract.priceScore !== OUTLIER ?
									<ContractsComparison contract={contract} />
									: <Typography variant="subtitle2"><FormattedMessage id="action.form.comparison" /></Typography>
								}
							</Grid>}
							<Grid item xs={12}>
								<Field
									name={FIELDS.DESCRIPTION}
									render={({ input, meta }) => (
										<>
											<FormSpy subscription={{}}>
												{() => (
													<OnChange name={FIELDS.TYPE}>
														{(value?: ACTION_TYPE) => {
															if (!!value) {
																input.onChange(actionTypeLabels[value].defaultDescription)
															}
														}}
													</OnChange>
												)}
											</FormSpy>
											<InputLabel shrink id="description-label">
												<FormattedMessage id="action.form.description" />
											</InputLabel>
											<TextField
												{...input}
												multiline
												fullWidth
												InputProps={{ disableUnderline: true }}
											/>
											{meta.touched && meta.error &&
											<Box pt={1}><Typography>{meta.error}</Typography></Box>}
										</>
									)}
								/>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button onClick={onClose}><FormattedMessage id="action.contract.new.cancel" /></Button>
						<Button type="submit" variant="contained" size="small" color="primary" disableElevation>
							{validateButtonLabel}
						</Button>
					</DialogActions>
				</form>
			)}
		/>

	)
}

export default compose(
	withStyles(styles),
	injectIntl
)(ActionForm)