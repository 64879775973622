import React, { FunctionComponent, useContext } from 'react'
import {
	REPORTING_PAGES,
	REPORTING_URL,
	reportingActivityLowThreshold,
	reportingActivityUpThreshold
} from '../../../../constants'
import ReportingTable from '../../ReportingTable'
import { Grid, withStyles, WithStyles } from '@material-ui/core'
import { mobileTableStyle } from '../../style'
import { RouteComponentProps, withRouter } from 'react-router'
import { FormattedMessage } from 'react-intl'
import MobileTableContent from '../../MobileTableContent'
import { compose } from 'recompose'
import Typography from '@material-ui/core/Typography/Typography'
import { shortenBigNumbers } from '../../../../utils/utils'
import { ThumbDownAltOutlined, ThumbUpAltOutlined } from '@material-ui/icons'
import { ActivityData, ReportingContextProps } from '../../types'
import { LoggedInContext } from '../../../../contexts/LoggedInContext'
import { getEntityLibelle } from '../utils'
import { ReportingContext } from '../../../../contexts/ReportingContext'

interface Props extends WithStyles<typeof mobileTableStyle>, RouteComponentProps {
	target: string
}

const MobileTableActivity: FunctionComponent<Props> = ({ classes, history, target }) => {
	const { user } = useContext(LoggedInContext)
	const { perimeter } = React.useContext<ReportingContextProps>(ReportingContext)

	const colProps = {
		classes: {
			head: classes.tableHeaderCol,
			body: classes.tableBodyCol
		}
	}

	const redirectToDashboard = React.useCallback(() => {
		history.push('/dashboard')
	}, [history])

	const headers = React.useMemo(() => ([
		{
			key: 'activities',
			tableHeaderColumnProps: colProps,
			render: (row : ActivityData) => <MobileTableContent
				title={
					<Typography display="inline">
						{
							target === REPORTING_PAGES.DETAILED ?
								new Date(row.day).toLocaleDateString() :
								getEntityLibelle(perimeter, row.entity)
						}
					</Typography>
				}
			>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<Typography><FormattedMessage id="pages.reporting.activity.table.noActionsContracts" values={{ br: <br /> }} /></Typography>
					</Grid>
					<Grid item xs={6} onClick={redirectToDashboard}>
						<Typography>
							{`${shortenBigNumbers(row.noActionsContractsUnits, user?.locale)} / ${shortenBigNumbers(row.noActionsContractsValue, user?.locale)}${row.currency}`}
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography>
							<FormattedMessage id="pages.reporting.activity.table.contractsWithActions" values={{ br: <br /> }}/></Typography>
					</Grid>
					<Grid item xs={6}>
						{`${shortenBigNumbers(row.contractsWithActionsUnits, user?.locale)} / ${shortenBigNumbers(row.contractsWithActionsValue, user?.locale)}${row.currency}`}
					</Grid>
					<Grid item xs={6}>
						<Typography><FormattedMessage id="pages.reporting.activity.table.contractsWithActionsRate" /></Typography>
					</Grid>
					<Grid item xs={6}>
						<div className={classes.aligned}>
							<Typography>
								{`${row.contractsWithActionsRate}%`}
							</Typography>
							{
								((row.contractsWithActionsRate < reportingActivityLowThreshold) || (row.contractsWithActionsRate > reportingActivityUpThreshold)) && (
									<div className={classes.icons}>
										{(row.contractsWithActionsRate < reportingActivityLowThreshold) &&
                                            <div className={classes.bad}><ThumbDownAltOutlined /></div>}
										{(row.contractsWithActionsRate > reportingActivityLowThreshold) &&
                                            <div className={classes.good}><ThumbUpAltOutlined /></div>}
									</div>
								)
							}
						</div>
					</Grid>
					<Grid item xs={6}>
						<Typography><FormattedMessage id="pages.reporting.activity.table.contractsWithOverdueActionsRate" /></Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography>{`${row.contractsWithOverdueActionsRate}%`}</Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography><FormattedMessage id="pages.reporting.activity.table.contractsWithHelpRequested" /></Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography>{row.contractsWithHelpRequested}</Typography>
					</Grid>
				</Grid>
			</MobileTableContent>
		}

	]), [classes.aligned, classes.icons, classes.bad, classes.good, colProps, redirectToDashboard, target, user, perimeter])

	return (
		<ReportingTable
			target={target}
			headers={headers}
			tab={REPORTING_URL.ACTIVITY}
		/>
	)
}

export default compose(
	withStyles(mobileTableStyle),
	withRouter
)(MobileTableActivity)