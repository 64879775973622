import * as React from 'react'
import { FunctionComponent, useCallback, useContext } from 'react'
import { SpringDataTable } from 'isotope-client'
import NoResultFragment from '../../components/NoResult'
import {
	Card,
	CardContent,
	CardHeader,
	createStyles,
	Grid,
	isWidthUp,
	Theme,
	Typography,
	WithStyles,
	withStyles,
	withWidth,
	WithWidth,
} from '@material-ui/core'
import ProgressBar from '../../components/ProgressBar'
import TablePaginationActions from '../datatable/TablePaginationActions'
import { RegionListRow } from './types'
import TablePaginationActionsMobile from '../datatable/TablePaginationActionsMobile'
import { LoggedInContext } from '../../contexts/LoggedInContext'
import * as api from '../regions/api'
import classNames from 'classnames'
import { PaperLoaderDataTable } from 'isotope-client/components/layout/PaperLoader'
import { injectIntl, IntlShape } from 'react-intl'
import { compose } from 'recompose'

const getTotalProgress = (row: RegionListRow) => {
	const nbOpenContrats = row.todoCount + row.inProgressCount
	return nbOpenContrats === 0 ? row.closedCount : nbOpenContrats
}

const styles = (theme: Theme) =>
	createStyles({
		card: {
			'& [class*="MuiSvgIcon-root"]': {
				width: 22,
				height: 22,
				margin: 0,
			},
			'& [aria-label="next page"]': {
				marginRight: -5,
			},
			[theme.breakpoints.down('sm')]: {
				width: '100%',
				overflow: 'scroll',
			},
		},
		cardContent: {
			padding: 0,
			position: 'relative',
			'&:last-child': {
				paddingBottom: 0,
			},
		},
		tableHeader: {
			width: '25%',
		},
		button: {
			fontSize: 13,
			fontWeight: 'normal',
			'& [class*="MuiButton-endIcon"]': {
				marginLeft: 2,
				marginRight: -9,
			},
		},
		tableCell: {
			fontWeight: 500,
			fontSize: 15,
		},
		buttonMobile: {
			color: theme.palette.primary.main,
			paddingLeft: 0,
			paddingRight: 0,
		},
		link: {
			cursor: 'pointer',
		},
	})

interface Props extends WithStyles<typeof styles>, WithWidth {
	intl: IntlShape
}

const RegionsList: FunctionComponent<Props> = ({ width, classes, intl }) => {
	const { setRegion } = useContext(LoggedInContext)

	const handleSelectRegion = useCallback(
		(region) => {
			api.setRegion(region)
				.then((response) => {
					if (response) {
						setRegion(response)
					}
				})
				.catch(() => {
					setRegion(null)
				})
		},
		[setRegion]
	)

	const headers = [
		{
			name: intl.formatMessage({ id: 'pages.dashboard.region.table.headers.regionName' }),
			key: 'regionName',
			sortable: false,
			tableHeaderColumnProps: {
				classes: {
					root: classes.tableHeader,
				},
			},
			render: (row: RegionListRow) => (
				<Typography
					component="span"
					className={classNames(classes.tableCell, classes.link)}
					onClick={() => handleSelectRegion(row.id)}
				>
					{row.regionName}
				</Typography>
			),
		},
		{
			name: intl.formatMessage({ id: 'pages.dashboard.region.table.headers.todo' }),
			key: 'todo',
			sortable: true,
			tableHeaderColumnProps: {
				classes: {
					root: classes.tableHeader,
				},
			},
			render: (row: RegionListRow) => (
				<Grid container alignItems="center" spacing={1}>
					<Grid item container xs={2} justify="flex-end">
						<Typography component="span" display="inline" className={classes.tableCell}>
							{row.todoCount}
						</Typography>
					</Grid>
					<Grid item xs={10}>
						<ProgressBar color="error" value={row.todoCount} total={getTotalProgress(row)} />
					</Grid>
				</Grid>
			),
		},
		{
			name: intl.formatMessage({ id: 'pages.dashboard.region.table.headers.inProgressCount' }),
			key: 'inProgress',
			sortable: true,
			tableHeaderColumnProps: {
				classes: {
					root: classes.tableHeader,
				},
			},
			render: (row: RegionListRow) => (
				<Grid container alignItems="center" spacing={1}>
					<Grid item container xs={2} justify="flex-end">
						<Typography component="span" display="inline" className={classes.tableCell}>
							{row.inProgressCount}
						</Typography>
					</Grid>
					<Grid item xs={10}>
						<ProgressBar color="primary" value={row.inProgressCount}
									 total={getTotalProgress(row)} />
					</Grid>
				</Grid>
			),
		},
		{
			name: intl.formatMessage({ id: 'pages.dashboard.region.table.headers.closedCount' }),
			key: 'closed',
			sortable: true,
			tableHeaderColumnProps: {
				classes: {
					root: classes.tableHeader,
				},
			},
			render: (row: RegionListRow) => (
				<Grid container justify="center">
					<Typography component="span" display="inline" className={classes.tableCell}>
						{row.closedCount}
					</Typography>
				</Grid>
			),
		},
	]

	return (
		<Card className={classes.card}>
			<CardHeader title={intl.formatMessage({ id: 'pages.dashboard.region.table.title' })} />
			<CardContent className={classes.cardContent}>
				<PaperLoaderDataTable datatableName="branches">
					<SpringDataTable
						nom="branches"
						headers={headers}
						apiUrlPrefix="/api"
						apiUrl="/dashboard/regions"
						ActionsComponent={({ classes: notused, ...props }: Props) =>
							isWidthUp('md', width) ? (
								<TablePaginationActions {...props} noPagination={false} />
							) : (
								<TablePaginationActionsMobile {...props} noPagination={false} />
							)
						}
						noResultFragment={
							<NoResultFragment
								message={intl.formatMessage({ id: 'pages.dashboard.region.table.noResult' })} />
						}
					/>
				</PaperLoaderDataTable>
			</CardContent>
		</Card>
	)
}

export default compose(withStyles(styles), withWidth({ withTheme: true }), injectIntl)(RegionsList)
