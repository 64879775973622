const ROOT = 'CONTRACTS'

export const SET_TABLE_URL = `${ROOT}/SET_TABLE_URL`
export const SET_CONTRACT = `${ROOT}/SET_CONTRACT`
export const SET_CONTRACT_ID = `${ROOT}/SET_CONTRACT_ID`
export const SET_CONTRACT_STATUS_IN_PROGRESS = `${ROOT}/SET_CONTRACT_STATUS_IN_PROGRESS`
export const REMOVE_CONTRACT = `${ROOT}/REMOVE_CONTRACT`
export const SET_FILTERS = `${ROOT}/SET_FILTERS`
export const RESET_FILTERS = `${ROOT}/RESET_FILTERS`
export const SET_CONTRACT_NEGOTIATION = `${ROOT}/SET_CONTRACT_NEGOTIATION`
export const SET_USER_ID = `${ROOT}/SET_USER_ID`

export const setTableUrl = (tableUrl) => ({
	type: SET_TABLE_URL,
	tableUrl
})

export const setContract = (contract) => ({
	type: SET_CONTRACT,
	contract
})

export const setContractId = (contractId) => ({
	type: SET_CONTRACT_ID,
	contractId
})

export const setContractStatusInProgress = () => ({
	type: SET_CONTRACT_STATUS_IN_PROGRESS
})

export const setFilters = (filters) => ({
	type: SET_FILTERS,
	filters
})

export const resetFilters = () => ({
	type: RESET_FILTERS
})

export const removeContract = () => ({
	type: REMOVE_CONTRACT
})

export const setContractNegotiation = (contractNegotiation) => ({
	type: SET_CONTRACT_NEGOTIATION,
	contractNegotiation
})

export const setUserId = (userId) => ({
	type: SET_USER_ID,
	userId
})

