import { Box, Grid } from '@material-ui/core'
import React from 'react'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router'
import { compose } from 'recompose'
import PageTitle from '../../components/PageTitle'
import { LoggedInContext } from '../../contexts/LoggedInContext'
import { PROFILE } from '../../enums'
import { AccessChecker } from '../../utils/accessChecker'
import { scrollToRef } from '../../utils/scrollToRef'
import Contracts from '../contracts/Contracts'
import ClosedContractsTableBox from './ClosedContractsTableBox'
import ContractsFollowUpBox from './ContractsFollowUpBox'
import OpenActionsBox from './OpenActionsBox'
import PriorityContractsBox from './PriorityContractsBox'
import { getCountryModules } from './services/salesPortfolioApi'

const SalesPortfolio = ({ match, intl }) => {
	const contractsRef = React.useRef(null)
	const scrollToContracts = () => scrollToRef(contractsRef)
	const { user } = React.useContext(LoggedInContext)
	const [countryModules, setCountryModules] = React.useState({})

	React.useEffect(() => {
		getCountryModules(user.countryId).then((result) => {
			setCountryModules(result)
		})
	}, [user])

	return (
		<AccessChecker profiles={[PROFILE.SALES_REPRESENTATIVE]}>
			<PageTitle
				title={intl.formatMessage({ id: 'pages.portfolio.title' })}
				breadcrumbs={[{ label: intl.formatMessage({ id: 'pages.portfolio.breadcrumbs' }) }]}
			/>
			<Box>
				<Grid container spacing={2}>
					<Grid item xs={12} md={4}>
						<PriorityContractsBox scrollToContracts={() => scrollToContracts()} />
					</Grid>
					<Grid item xs={12} md={4}>
						{countryModules.forecastModule ? (
							<ContractsFollowUpBox salesRepId={match.params.id} />
						) : (
							<OpenActionsBox salesRepId={match.params.id} />
						)}
					</Grid>
					<Grid item xs={12} md={4}>
						<ClosedContractsTableBox />
					</Grid>
					<Grid item xs={12}>
						<div ref={contractsRef}>
							<Contracts modules={countryModules} />
						</div>
					</Grid>
				</Grid>
			</Box>
		</AccessChecker>
	)
}

export default compose(withRouter, injectIntl)(SalesPortfolio)
