import React, { createContext } from 'react'
import { LEVEL, REPORTING_URL } from '../constants'
import { PROFILE, VIEW } from '../enums'
import { Level, ReportingContextProps, ReportingFiltersProps } from '../modules/reporting/types'
import { LoggedInContext } from './LoggedInContext'
import { hasAnyProfile, hasProfile } from '../utils/accessChecker'
import { getCountryRegions } from '../modules/regions/api'
import { getCountryBranches, getRegionBranches } from '../modules/branches/branchesApi'
import { FormattedMessage } from 'react-intl'
import { getContractsYears } from '../modules/contracts/services/contractsApi'
import { getSalesRepList } from '../modules/reporting/api'
import { CircularProgress } from '@material-ui/core'
import { Branch, Region } from '../modules/regions/types'
import { SalesRep } from '../modules/forecasts/types'

export const ReportingContext = createContext<ReportingContextProps>({
	tableUrl: REPORTING_URL.ADOPTION,
	filters: {
		year: '',
		view: VIEW.MONTHLY,
		level: undefined
	},
	levelOptions: [],
	yearOptions: [],
	perimeter: '',
	setTableUrl: () => undefined,
	setFilters: () => undefined
})

const getAllLevel = (level: string) => ({
	label: <FormattedMessage id={`pages.reporting.filters.level.all.${level}`} />,
	value: '0'
})

export const ReportingContextProvider = ({ children }) => {
	const { user } = React.useContext<LoggedInContext>(LoggedInContext)
	const [levelOptions, setLevelOptions] = React.useState<Level[]>([])
	const [perimeter, setPerimeter] = React.useState<string>('')
	const [yearOptions, setYearOptions] = React.useState<number[]>([])
	const [filters, setFilters] = React.useState<ReportingFiltersProps>({
		year: '',
		view: VIEW.MONTHLY,
		level: undefined
	})
	const [tableUrl, setTableUrl] = React.useState<string>(REPORTING_URL.ADOPTION)
	const [loading, setLoading] = React.useState<boolean>(true)

	React.useEffect(() => {
		setLoading(true)
		getContractsYears().then(setYearOptions)
		if (user) {
			const handleUpdateReportingContext = async () => {
				let regions = []
				if (hasAnyProfile(user, [PROFILE.ZONE_MANAGER, PROFILE.NATIONAL]) && user.countryId) {
					regions = await getCountryRegions(user.countryId)
				}
				if (hasAnyProfile(user, [PROFILE.ZONE_MANAGER, PROFILE.NATIONAL, PROFILE.REGIONAL_MANAGER]) && !user.branchId) {
					const hasRegions = regions.length > 0
					if (!hasRegions || user.regionId) {
						const apiCall = user.regionId ? getRegionBranches() : getCountryBranches(user.countryId)
						apiCall
							.then((branches: Branch[]) => {
								setPerimeter(LEVEL.BRANCH)
								setLevelOptions([getAllLevel(LEVEL.BRANCH), ...branches.map(branch => ({
									label: branch.name,
									value: branch.id
								}))])
							})
							.finally(() => setLoading(false))
					} else {
						getCountryRegions(user.countryId)
							.then((regions: Region[]) => {
								setPerimeter(LEVEL.REGION)
								setLevelOptions([getAllLevel(LEVEL.REGION), ...regions.map(region => ({
									label: region.name,
									value: region.id ? region.id : '0'
								}))])
							})
							.finally(() => setLoading(false))
					}
				} else if (!hasAnyProfile(user, [PROFILE.SALES_REPRESENTATIVE])) {
					getSalesRepList()
						.then((salesRep: SalesRep[]) => {
							setPerimeter(LEVEL.SALES_REP)
							setLevelOptions([getAllLevel(LEVEL.SALES_REP), ...salesRep.map(rep => ({
								label: `${rep.firstName} ${rep.lastName}`,
								value: rep.id.toString()
							}))])
						})
						.finally(() => setLoading(false))
				} else {
					getSalesRepList()
						.then((salesRep: SalesRep[]) => {
							setPerimeter(LEVEL.USER)
							setLevelOptions([...salesRep
								.filter(rep => rep.email === user.email)
								.map(rep => ({
									label: `${rep.firstName} ${rep.lastName}`,
									value: rep.id.toString()
								}))])
						})
						.finally(() => setLoading(false))
				}
			}
			if ((hasProfile(user, PROFILE.ZONE_MANAGER)
					|| (hasProfile(user, PROFILE.NATIONAL) && user.countries.length > 1))
				&& !user.countryId) {
				setPerimeter(LEVEL.COUNTRY)
				setLoading(false)
			} else {
				handleUpdateReportingContext().catch()
			}
		}
	}, [user])

	React.useEffect(() => {
		tableUrl && setFilters({
			level: levelOptions.length > 0 ? levelOptions[0].value : '',
			year: yearOptions.length > 0 ? yearOptions[0] : '',
			view: VIEW.MONTHLY
		})
	}, [levelOptions, yearOptions, tableUrl])

	return <ReportingContext.Provider
		value={{
			tableUrl,
			filters,
			levelOptions,
			yearOptions,
			perimeter,
			setTableUrl,
			setFilters
		}}
	>
		{loading ? <CircularProgress /> : children}
	</ReportingContext.Provider>
}