import { Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import HeaderTitle from '../../components/HeaderTitle'
import { RTVContextProvider } from '../../contexts/RTVContext'

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		marginTop: 75,
		marginBottom: 75
	}
}))

export default ({ children }) => {
	const classes = useStyles()
	return (
		<RTVContextProvider>
			<Container className={classes.root}>
				<HeaderTitle />
				{children}
			</Container>
		</RTVContextProvider>
	)
}