import { Box, Button, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, Tooltip, Typography, withStyles } from '@material-ui/core'
import { PriorityHigh as ClockIcon, ExpandMore as ExpandMoreIcon, FiberManualRecordRounded as CircleIcon } from '@material-ui/icons'
import { SpringDataTable } from 'isotope-client'
import React, { useContext } from 'react'
import { withRouter } from 'react-router'
import NoResultFragment from '../../components/NoResult'
import StatusChip from '../../components/StatusChip'
import { ActionsFiltersContext } from '../../contexts/ActionsFiltersContext'
import { getColorByStatus } from '../../utils/getColorByStatus'
import TablePaginationActions from '../datatable/TablePaginationActions'
import { ACTIONS_STATUS, ACTION_TYPE_LABELS } from './actionConstants'
import { Link } from 'react-router-dom'
import { ErrorHandlerContext } from '../../contexts/ErrorHandlerContext'
import { injectIntl, FormattedMessage } from 'react-intl'
import { compose } from 'recompose'

const styles = (theme) => ({
	cardTable: {
		display: 'flex',
		flexDirection: 'column',
		flex: '0 1 300px',
		width: '100%'
	},
	titleCardTable: {
		fontSize: 15,
		fontWeight: '700',
		color: theme.palette.grey['800']
	},
	subCardTable: {
		fontSize: 12,
		fontWeight: '400',
		textTransform: 'uppercase',
		color: theme.palette.grey['500']
	},
	tableHeader: {
		height: 0
	},
	tableHeaderCol: {
		padding: 0,
		height: 0
	},
	tableBodyCol: {
		padding: 0,
		fontSize: '16px!important',
		fontWeight: '400!important'
	},
	avatar: {
		marginRight: 15
	},
	iconTypology: {
		height: 26,
		width: 26,
		padding: 3,
		margin: '0 3px',
		borderRadius: 5,
		borderColor: theme.palette.background.lightShadow,
		border: '2px solid'
	},
	error: {
		color: theme.palette.error.main,
		fontSize: 15,
		fontWeight: 600
	},
	tableHead: {
		height: '0px'
	},
	clockIcon: {
		color: theme.palette.warning.icon2
	}
})

const ActionsListMobile = ({ classes, match, intl }) => {
	const { filters } = useContext(ActionsFiltersContext)
	const { setError } = React.useContext(ErrorHandlerContext)

	const colProps = {
		classes: {
			head: classes.tableHeaderCol,
			body: classes.tableBodyCol
		}
	}
	
	const date = new Date()
	date.setDate(date.getDate() - 1)

	const headers = [
		{
			key: 'id',
			name: '',
			tableHeaderColumnProps: colProps,
			render: action => (
				<ExpansionPanel>
					<ExpansionPanelSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Box className={classes.cardTable}>
							<Typography display="inline" className={classes.titleCardTable}>{ACTION_TYPE_LABELS(intl)[action.type].label}</Typography>
							<Typography className={classes.subCardTable}>{action.customerName} ({action.idContract})</Typography>
						</Box>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<Typography><FormattedMessage id="action.page.list.headers.status" /></Typography>
							</Grid>
							<Grid item xs={6}>
								<StatusChip
									Icon={CircleIcon}
									{...getColorByStatus(action.status)}
									label={ACTIONS_STATUS(intl)[action.status].chipLabel}
								/>
							</Grid>
							<Grid item xs={6}>
								<Typography><FormattedMessage id="action.page.list.headers.description" /></Typography>
							</Grid>
							<Grid item xs={6}>
								{action.description.length <= 150 ? action.description : action.description.substring(0, 150) + '...'}
							</Grid>
							<Grid item xs={6}>
								<Typography><FormattedMessage id="action.page.list.headers.deadline" /></Typography>
							</Grid>
							<Grid item xs={6}>
								<Box display="flex" alignItems="center">
									{date > new Date(action.deadline) &&
									<Tooltip title={intl.formatMessage({ id: 'action.page.list.tooltip' })}><ClockIcon className={classes.clockIcon} /></Tooltip>}
									<Typography display="inline">&nbsp;{new Date(action.deadline).toLocaleDateString()}</Typography>
								</Box>
							</Grid>
							<Grid item xs={12}>
								<Link to={`/contracts/${action.idContract}`}>
									<Button
										variant="contained"
										size="large"
										color="primary"
										disableElevation
									>
										<FormattedMessage id="action.page.list.button" />
									</Button>
								</Link>
							</Grid>
						</Grid>
					</ExpansionPanelDetails>
				</ExpansionPanel>
			)
		}
	]

	return (
		<>
			<SpringDataTable
				nom="actionDataTable"
				headers={headers}
				filters={filters}
				apiUrlPrefix="/api"
				apiUrl={`/actions${match.params.id ? `/${match.params.id}` : ''}`}
				noResultFragment={<NoResultFragment message="No actions found" />}
				ActionsComponent={(props) => <TablePaginationActions {...props} noPagination={false} />}
				postFetch={(data, error) => error && setError(error)}
			/>
		</>
	)
}

export default compose(
	withStyles(styles),
	withRouter,
	injectIntl
)(ActionsListMobile)