import * as React from 'react'
import { Box, Button, CircularProgress, Grid, withStyles, withWidth } from '@material-ui/core'
import Wrapper from '../layout/Wrapper'
import SelectCountryNavbar from '../layout/SelectCountryNavbar'
import PageTitle from '../../components/PageTitle'
import { compose } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { getCountries, setCountry } from './services/api'
import ContractsIndicatorsCard from '../../components/ContractsIndicatorsCard'
import { LoggedInContext } from '../../contexts/LoggedInContext'
import { withRouter } from 'react-router'
import { fetchUser } from 'isotope-client'
import { animated, useSpring } from 'react-spring'
import * as api from '../regions/api'

const styles = {
	loader: {
		color: 'white'
	}
}

const SelectCountryPage = ({ intl, history, classes }) => {
	const [countries, setCountries] = React.useState([])
	const { setUser, updateUser, setRegion } = React.useContext(LoggedInContext)
	// Loader
	const [props, set] = useSpring(() => ({ opacity: 0, transform: 'scale(0)' }))
	const [countriesLoaded, setCountriesLoaded] = React.useState(false)

	React.useEffect(() => {
		getCountries()
			.then(countries => {
				setCountries(countries)
				setCountriesLoaded(true)
				set({ opacity: 1, transform: 'scale(1)' })
			})
		// We clear selected country in both back and front end
		setCountry(null)
			.then(() => updateUser({ countryId: null }))
		api.setRegion(null).then(() => setRegion(null))
	}, [set, updateUser, setRegion])

	const handleClick = React.useCallback((countryId, path) => {
		setCountry(countryId)
			.then(() => fetchUser(localStorage.getItem('token'))
				.then(user => {
					setUser(user)
					history.push(path)
				})
			)
	}, [setUser, history])

	const handleCountryClick = React.useCallback((countryId) => handleClick(countryId, '/dashboard'), [handleClick])
	const handleWithoutActionsClick = React.useCallback((countryId) => handleClick(countryId, '/contracts-overview/todo'), [handleClick])
	const handleWithActionsClick = React.useCallback((countryId) => handleClick(countryId, '/contracts-overview/in-progress'), [handleClick])
	const handleClosedClick = React.useCallback((countryId) => handleClick(countryId, '/contracts-overview/manually-closed'), [handleClick])

	const seeReporting = <Button
		variant="contained"
		size="large" color="secondary"
		disableElevation={true}
		onClick={() => history.push('/emea/reporting/teams')}
	>
		<FormattedMessage id="pages.selectCountry.seeReporting" />
	</Button>

	return (
		<>
			<SelectCountryNavbar />
			<Wrapper>
				<PageTitle title={intl.formatMessage({ id: 'pages.selectCountry.title' })} breadcrumbs={[]} topActionButtons={[seeReporting]} />
				{!countriesLoaded && <Box display="flex" height="100%" alignItems="center" justifyContent="center">
					<CircularProgress classes={{ svg: classes.loader }} size={50} />
				</Box>}
				<animated.div style={props}>
					<Grid container spacing={1}>
						{countries.map((country, index) => <Grid key={`country-${index}`} item xs={12} md={3}>
							<ContractsIndicatorsCard
								title={country.name}
								indicators={[{
									label: intl.formatMessage({ id: 'pages.selectCountry.indicators.withoutActions' }),
									count: country.withoutActions,
									onClick: () => handleWithoutActionsClick(country.id)
								}, {
									label: intl.formatMessage({ id: 'pages.selectCountry.indicators.withActions' }),
									count: country.withActions,
									onClick: () => handleWithActionsClick(country.id)
								}, {
									label: intl.formatMessage({ id: 'pages.selectCountry.indicators.closed' }),
									count: country.closed,
									onClick: () => handleClosedClick(country.id)
								}]}
								button={{
									label: intl.formatMessage({ id: 'pages.selectCountry.button' }),
									onClick: () => handleCountryClick(country.id)
								}}
							/>
						</Grid>)}
					</Grid>
				</animated.div>
			</Wrapper>
		</>
	)
}

export default compose(
	withWidth(),
	withRouter,
	injectIntl,
	withStyles(styles)
)(SelectCountryPage)
