import { Box, ClickAwayListener, Grid, List, ListItem, ListItemText, Paper, Popper } from '@material-ui/core'
import React, { useContext } from 'react'
import { TABLE_URL } from '../../../components/TabBarDesktop'
import { ContractsContext } from '../../../contexts/ContractsContext'
import { setFilters } from '../services/contractsActions'
import { injectIntl } from 'react-intl'
import { OVERVIEW_TABLE_URL } from '../../../constants'

export const sorts = intl => ({
	priority: {
		label: intl.formatMessage({ id: 'pages.contractsOverview.sorts.priority.label' }),
		labelShort: intl.formatMessage({ id: 'pages.contractsOverview.sorts.priority.labelShort' }),
		key: 'priority'
	},
	risk: {
		label: intl.formatMessage({ id: 'pages.contractsOverview.sorts.risk.label' }),
		labelShort: intl.formatMessage({ id: 'pages.contractsOverview.sorts.risk.labelShort' }),
		key: 'risk'
	},
	value: {
		label: intl.formatMessage({ id: 'pages.contractsOverview.sorts.value.label' }),
		labelShort: intl.formatMessage({ id: 'pages.contractsOverview.sorts.value.labelShort' }),
		key: 'value'
	},
	endDate: {
		label: intl.formatMessage({ id: 'pages.contractsOverview.sorts.endDate.label' }),
		labelShort: intl.formatMessage({ id: 'pages.contractsOverview.sorts.endDate.labelShort' }),
		key: 'endDate'
	},
	unitsNumber: {
		label: intl.formatMessage({ id: 'pages.contractsOverview.sorts.unitsNumber.label' }),
		labelShort: intl.formatMessage({ id: 'pages.contractsOverview.sorts.unitsNumber.labelShort' }),
		key: 'unitsNumber'
	}
})

const ContractSortsDropdown = ({ anchorEl, open, closeSorts, intl, modules }) => {
	const { tableUrl, filters, dispatch } = useContext(ContractsContext)
	const sortsList = sorts(intl)
	const keys =
		tableUrl === TABLE_URL.ALL || tableUrl === OVERVIEW_TABLE_URL.ALL
			? modules.countryModule !== 'FR'
				? Object.keys(sortsList).filter((key) => key !== 'priority' && key !== 'unitsNumber')
				: Object.keys(sortsList).filter((key) => key !== 'priority')
			: Object.keys(sortsList).filter((key) => key !== 'unitsNumber')

	const handleClick = (sort) => {
		const searchParams = new URLSearchParams(window.location.search)
		searchParams.delete("sorting")
		searchParams.append("sorting", sort)
		window.history.replaceState(null, "", `${window.location.pathname}?${searchParams.toString()}`)
		dispatch(setFilters({ ...filters, sorting: sortsList[sort].key }))
		closeSorts()
	}

	return (
		<Popper
			anchorEl={anchorEl}
			placement="bottom-end"
			open={open}
			onClose={closeSorts}
		>
			<ClickAwayListener onClickAway={closeSorts}>
				<Paper>
					<Box p={3}>
						<Grid container spacing={3}>
							<List>
								{keys.map(sort =>
									<ListItem button key={sort} onClick={() => handleClick(sort)}>
										<ListItemText primary={sortsList[sort].label} />
									</ListItem>
								)}
							</List>
						</Grid>
					</Box>
				</Paper>
			</ClickAwayListener>
		</Popper>
	)
}

export default injectIntl(ContractSortsDropdown)
