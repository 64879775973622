import { Box, withStyles } from '@material-ui/core'
import React from 'react'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router'
import { compose } from 'recompose'
import PageTitle from '../../components/PageTitle'
import { LoggedInContext } from '../../contexts/LoggedInContext'
import { PROFILE } from '../../enums'
import { hasAnyProfile } from '../../utils/accessChecker'
import * as api from '../salesPortfolio/services/salesPortfolioApi'
import ForecastsTable from './ForecastsTable'
import { SalesRep } from './types'

const styles = () => {
	return {
		tab: {
			display: 'flex',
			width: 'auto',
		},
	}
}

const Forecasts = ({ classes, match, intl }) => {
	const { user } = React.useContext(LoggedInContext)
	const [salesData, setSalesData] = React.useState<SalesRep | null>(null)
	const salesRepId = match.params.id

	React.useEffect(() => {
		if (salesRepId) {
			api.getSalesData(salesRepId).then((data: SalesRep) => {
				if (data) setSalesData(data)
			})
		} else {
			setSalesData(null)
		}
	}, [salesRepId])

	const breadcrumbs =
		salesData &&
		user &&
		hasAnyProfile(user, [
			PROFILE.ZONE_MANAGER,
			PROFILE.NATIONAL,
			PROFILE.REGIONAL_MANAGER,
			PROFILE.BRANCH_MANAGER,
			PROFILE.BRANCH_MANAGER_ASSISTANT,
		])
			? [
					{
						path: '/dashboard',
						label: intl.formatMessage({ id: 'forecasts.page.breadcrumbs.dashboard' }),
					},
					{
						path: '/sr-overview',
						label: intl.formatMessage({ id: 'forecasts.page.breadcrumbs.srOverview' }),
					},
					{
						path: `/portfolio/${salesRepId}`,
						label: intl.formatMessage(
							{ id: 'forecasts.page.breadcrumbs.repPortfolio' },
							{ firstName: salesData.firstName, lastName: salesData.lastName }
						),
					},
					{
						label: intl.formatMessage({ id: 'forecasts.page.breadcrumbs.forecastsOverview' }),
					},
			  ]
			: [
					{
						path: '/portfolio',
						label: intl.formatMessage({ id: 'forecasts.page.breadcrumbs.myPortfolio' }),
					},
					{
						label: intl.formatMessage({ id: 'forecasts.page.breadcrumbs.forecastsOverview' }),
					},
			  ]

	const title = salesData
		? intl.formatMessage(
				{ id: 'forecasts.page.title' },
				{ firstName: salesData.firstName, lastName: salesData.lastName }
		  )
		: intl.formatMessage({ id: 'forecasts.page.defaultTitle' })

	return (
		<>
			<PageTitle title={title} breadcrumbs={breadcrumbs} topActionButtons={[]} />
			<Box className={classes.root}>
				<ForecastsTable />
			</Box>
		</>
	)
}

export default compose(withRouter, withStyles(styles), injectIntl)(Forecasts)
