import { Box } from '@material-ui/core'
import { SpringDataTable } from 'isotope-client'
import React, { useState } from 'react'
import { injectIntl } from 'react-intl'
import { PROFILE } from '../../../enums'
import { AccessChecker } from '../../../utils/accessChecker'
import UpdateCountryDialog from './forms/UpdateCountryDialog'
import { Country } from './types'

const Countries = ({intl}) => {
	const [country, setCountry] = useState<Country | null>(null)

	const makeActions = React.useCallback(
		(row: Country) => {
			return [{ label: intl.formatMessage({ id: 'countries.table.actions.modify' }), action: () => setCountry(row) }]
		},
		[intl]
	)

	const headers = React.useMemo(() => {
		return [
			{
				key: 'name',
				name: intl.formatMessage({ id: 'countries.table.headers.name' }),
			},
			{
				key: 'lowValueThreshold',
				name: intl.formatMessage({ id: 'countries.table.headers.lowValueThreshold' }),
			},
			{
				key: 'highRiskValueThreshold',
				name: intl.formatMessage({ id: 'countries.table.headers.highRiskValueThreshold' }),
			},
			{
				key: 'lowValueAmount',
				name: intl.formatMessage({ id: 'countries.table.headers.lowValueAmount' }),
			},
			{
				key: 'highRiskValueAmount',
				name: intl.formatMessage({ id: 'countries.table.headers.highRiskValueAmount' }),
			},
			{
				key: 'refresh',
				name: intl.formatMessage({ id: 'countries.table.headers.refresh' }),
				render: (row: Country) => intl.formatMessage({ id: `countries.table.values.${row.refresh}` }),
			},
			{
				key: 'forecastModule',
				name: intl.formatMessage({ id: 'countries.table.headers.forecastModule' }),
				render: (row: Country) => intl.formatMessage({ id: `countries.table.values.${row.forecastModule}` }),
			},
		]
	}, [intl])

	return (
		<AccessChecker profiles={[PROFILE.ZONE_MANAGER]}>
			<Box pt={5} pb={15}>
				<SpringDataTable
					makeActions={makeActions}
					showActions
					headers={headers}
					sort={[{ property: 'name', direction: 'ASC' }]}
					apiUrl="/countries/all"
					filters={{ country: country }}
				/>
				{country && <UpdateCountryDialog country={country} onClose={() => setCountry(null)} />}
			</Box>
		</AccessChecker>
	)
}

export default injectIntl(Countries)
