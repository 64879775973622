import React, { FunctionComponent } from 'react'
import { injectIntl, IntlShape } from 'react-intl'
import PageTitle from '../../../components/PageTitle'
import { LoggedInContext } from '../../../contexts/LoggedInContext'
import { Box } from '@material-ui/core'
import { REPORTING_URL } from '../../../constants'
import { compose } from 'recompose'
import { ReportingContext } from '../../../contexts/ReportingContext'
import DetailedPerformance from './performance/DetailedPerformance'
import TabPanel from '../../../components/TabPanel'
import { PROFILE } from '../../../enums'
import { AccessChecker } from '../../../utils/accessChecker'
import PerimeterFilters from './PerimeterFilters'
import DetailedActivity from './activity/DetailedActivity'
import DetailedAdoption from './adoption/DetailedAdoption'
import TabBar from '../../../components/TabBar'

interface DetailedReportingPageProps {
	intl: IntlShape
}

interface Props extends DetailedReportingPageProps {
}

const DetailedReportingPage: FunctionComponent<Props> = ({ intl }) => {
	const { tableUrl, setTableUrl } = React.useContext(ReportingContext)
	const { user } = React.useContext(LoggedInContext)
	const areaName = user?.regionName ? user.regionName : user?.countryName

	const data = React.useMemo(() => ([{
		path: REPORTING_URL.ADOPTION,
		labelId: 'pages.reporting.tabBar.adoption',
		component: DetailedAdoption
	}, {
		path: REPORTING_URL.ACTIVITY,
		labelId: 'pages.reporting.tabBar.activity',
		component:  DetailedActivity
	}, {
		path: REPORTING_URL.PERFORMANCE,
		labelId: 'pages.reporting.tabBar.performance',
		component: DetailedPerformance
	}]), [])

	return <AccessChecker profiles={[PROFILE.ZONE_MANAGER, PROFILE.NATIONAL, PROFILE.REGIONAL_MANAGER, PROFILE.BRANCH_MANAGER, PROFILE.BRANCH_MANAGER_ASSISTANT,  PROFILE.SALES_REPRESENTATIVE]}>
	<PageTitle
			title={intl.formatMessage(
				{ id: 'pages.reporting.detailed.title' },
				{ level: user?.branchName || areaName }
			)}
			topActionButtons={[]}
			breadcrumbs={[]}
		/>
		<Box mb="65px">
			<PerimeterFilters />
		</Box>
		<Box>
			<TabBar data={data} tableUrl={tableUrl} setTableUrl={setTableUrl} />
			{
				data.map((item, index) => (
					<TabPanel value={tableUrl} name={item.path} key={index}>
						{React.createElement(item.component, {})}
					</TabPanel>
				))
			}
		</Box>
	</AccessChecker>
}

export default compose(injectIntl)(DetailedReportingPage)