import {
	REMOVE_CONTRACT,
	RESET_FILTERS,
	SET_CONTRACT,
	SET_CONTRACT_ID,
	SET_CONTRACT_NEGOTIATION,
	SET_CONTRACT_STATUS_IN_PROGRESS,
	SET_FILTERS,
	SET_TABLE_URL,
	SET_USER_ID
} from './contractsActions'
import { TABLE_URL } from '../../../components/TabBarDesktop'
import { OVERVIEW_TABLE_URL } from '../../../constants'

const defaultFiltersPriority = {
	searchQuery: '',
	sorting: 'priority',
	status: ['TODO', 'IN_PROGRESS'],
	classification: [],
	risk: [],
	value: [],
	flags: [],
	endDate: [],
	riskTypology: [],
	customerFeedback: []
}

const defaultFiltersMonthly = {
	searchQuery: '',
	sorting: 'priority',
	status: [],
	classification: [],
	risk: [],
	value: [],
	flags: [],
	endDate: [],
	riskTypology: [],
	customerFeedback: []
}

const defaultFiltersAll = {
	searchQuery: '',
	sorting: 'endDate',
	status: [],
	classification: [],
	risk: [],
	value: [],
	flags: [],
	endDate: [],
	riskTypology: [],
	customerFeedback: []
}

const defaultOverviewFiltersPriority = {
	searchQuery: '',
	sorting: 'priority',
	status: ['TODO', 'IN_PROGRESS'],
	classification: [],
	risk: [],
	value: [],
	flags: [],
	endDate: [],
	riskTypology: [],
	customerFeedback: []
}

const defaultOverviewFiltersCancelled = {
	searchQuery: '',
	sorting: 'priority',
	status: [],
	classification: [],
	risk: [],
	value: [],
	flags: [],
	endDate: [],
	riskTypology: [],
	customerFeedback: []
}

const defaultOverviewFiltersAll = {
	searchQuery: '',
	sorting: 'endDate',
	status: [],
	classification: [],
	risk: [],
	value: [],
	flags: [],
	endDate: [],
	riskTypology: [],
	customerFeedback: []
}

const initialState = {
	contract: null,
	contractId: null,
	tableUrl: TABLE_URL.PRIORITY,
	filters: defaultFiltersPriority,
	userId: undefined
}

function reducer(state, action) {
	switch (action.type) {
		case SET_TABLE_URL:
			let newFilters = { ...state.filters }
			switch (action.tableUrl) {
				case TABLE_URL.PRIORITY:
					newFilters = { ...defaultFiltersPriority }
					break
				case TABLE_URL.MONTHLY:
					newFilters = { ...defaultFiltersMonthly }
					break
				case TABLE_URL.ALL:
					newFilters = { ...defaultFiltersAll }
					break
				case OVERVIEW_TABLE_URL.PRIORITY:
					newFilters = { ...defaultOverviewFiltersPriority }
					break
				case OVERVIEW_TABLE_URL.CANCELLED:
					newFilters = { ...defaultOverviewFiltersCancelled }
					break
				case OVERVIEW_TABLE_URL.ALL:
					newFilters = { ...defaultOverviewFiltersAll }
					break
				default:
			}
			return {
				...state,
				tableUrl: action.tableUrl,
				filters: {
					...newFilters,
					searchQuery: state.filters.searchQuery
				}
			}
		case SET_CONTRACT:
			return {
				...state,
				contract: { ...action.contract }
			}
		case SET_CONTRACT_ID:
			return {
				...state,
				contractId: action.contractId
			}
		case SET_CONTRACT_STATUS_IN_PROGRESS:
			return {
				...state,
				contract: {
					...state.contract,
					contractNegotiation: { ...state.contract.contractNegotiation, manualStatus: 'IN_PROGRESS' }
				}
			}
		case REMOVE_CONTRACT: {
			return {
				...state,
				contract: null,
				contractId: null
			}
		}
		case SET_FILTERS: {
			return {
				...state,
				filters: { ...action.filters }
			}
		}
		case RESET_FILTERS: {
			switch (action.tableUrl) {
				case TABLE_URL.ALL:
					return {
						...state,
						filters: {
							...defaultFiltersAll,
							searchQuery: state.filters.searchQuery,
							sorting: state.filters.sorting
						}
					}
				case TABLE_URL.MONTHLY:
					return {
						...state,
						filters: {
							...defaultFiltersMonthly,
							searchQuery: state.filters.searchQuery,
							sorting: state.filters.sorting
						}
					}
				case TABLE_URL.PRIORITY:
					return {
						...state,
						filters: {
							...defaultFiltersPriority,
							searchQuery: state.filters.searchQuery,
							sorting: state.filters.sorting
						}
					}
				case OVERVIEW_TABLE_URL.PRIORITY:
					return {
						...state,
						filters: {
							...defaultOverviewFiltersPriority,
							searchQuery: state.filters.searchQuery,
							sorting: state.filters.sorting
						}
					}
				case OVERVIEW_TABLE_URL.CANCELLED:
					return {
						...state,
						filters: {
							...defaultOverviewFiltersCancelled,
							searchQuery: state.filters.searchQuery,
							sorting: state.filters.sorting
						}
					}
				case OVERVIEW_TABLE_URL.ALL:
					return {
						...state,
						filters: {
							...defaultOverviewFiltersAll,
							searchQuery: state.filters.searchQuery,
							sorting: state.filters.sorting
						}
					}
				default:
					return state
			}
		}
		case SET_CONTRACT_NEGOTIATION:
			return {
				...state,
				contract: {
					...state.contract,
					contractNegotiation: {
						...state.contract.contractNegotiation,
						...action.contractNegotiation
					}
				}
			}
		case SET_USER_ID:
			return {
				...state,
				userId: action.userId
			}
		default:
			throw new Error()
	}
}

export {
	initialState,
	reducer,
	defaultFiltersAll,
	defaultFiltersPriority,
	defaultFiltersMonthly,
	defaultOverviewFiltersAll,
	defaultOverviewFiltersPriority,
	defaultOverviewFiltersCancelled
}
