import * as React from 'react'
import { FunctionComponent, useCallback, useContext, useState, useEffect } from 'react'
import { SpringDataTable } from 'isotope-client'
import NoResultFragment from '../../components/NoResult'
import { Card, CardContent, CardHeader, createStyles, Grid, isWidthUp, Theme, Typography, WithStyles, withStyles, withWidth, WithWidth } from '@material-ui/core'
import ProgressBar from '../../components/ProgressBar'
import TablePaginationActions from '../datatable/TablePaginationActions'
import { BranchListRow } from './types'
import TablePaginationActionsMobile from '../datatable/TablePaginationActionsMobile'
import { LoggedInContext } from '../../contexts/LoggedInContext'
import * as api from '../branches/branchesApi'
import classNames from 'classnames'
import { PaperLoaderDataTable } from 'isotope-client/components/layout/PaperLoader'
import { injectIntl, IntlShape } from 'react-intl'
import { compose } from 'recompose'

const getTotalContracts = (row : BranchListRow) => {
	const nbOpenContrats = row.todoCount + row.inProgressCount
	return nbOpenContrats === 0 ? row.closedCount : nbOpenContrats
}

const styles = (theme: Theme) => createStyles({
	card: {
		'& [class*="MuiSvgIcon-root"]': {
			width: 22,
			height: 22,
			margin: 0
		},
		'& [aria-label="next page"]': {
			marginRight: -5
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			overflow: 'scroll'
		}
	},
	cardContent: {
		padding: 0,
		position: 'relative',
		'&:last-child': {
			paddingBottom: 0
		}
	},
	tableHeader: {
		width: '25%'
	},
	button: {
		fontSize: 13,
		fontWeight: 'normal',
		'& [class*="MuiButton-endIcon"]': {
			marginLeft: 2,
			marginRight: -9
		}
	},
	tableCell: {
		fontWeight: 500,
		fontSize: 15
	},
	buttonMobile: {
		color: theme.palette.primary.main,
		paddingLeft: 0,
		paddingRight: 0
	},
	link: {
		cursor: 'pointer'
	}
})

interface Props extends WithStyles<typeof styles>, WithWidth {
	intl: IntlShape
}

const BranchesList: FunctionComponent<Props> = ({ width, classes, intl }) => {
	const { user, setBranch } = useContext(LoggedInContext)

	const [shouldRefresh, setShouldRefresh] = useState(Date.now())

	const handleSelectBranch = useCallback((branch) => {
		api.setBranch(branch).then(response => {
			if (response) {
				setBranch(response)
			}
		}).catch(() => {
			setBranch(null)
		})
	}, [setBranch])

	useEffect(() => {
		setShouldRefresh(Date.now())
	}, [user])

	const headers = [{
		name: intl.formatMessage({ id: 'pages.dashboard.branch.table.headers.branchName' }),
		key: 'branchName',
		sortable: false,
		tableHeaderColumnProps: {
			classes: {
				root: classes.tableHeader
			}
		},
		render: (row: BranchListRow) => <Typography component="span" className={classNames(classes.tableCell, classes.link)} onClick={() => handleSelectBranch(row.id)}>
			{row.branchName}
		</Typography>
	}, {
		name: intl.formatMessage({ id: 'pages.dashboard.branch.table.headers.todo' }),
		key: 'todo',
		sortable: true,
		tableHeaderColumnProps: {
			classes: {
				root: classes.tableHeader
			}
		},
		render: (row: BranchListRow) => <Grid container alignItems="center" spacing={1}>
			<Grid item container xs={2} justify="flex-end">
				<Typography component="span" display="inline" className={classes.tableCell}>{row.todoCount}</Typography>
			</Grid>
			<Grid item xs={10}>
				<ProgressBar color="error" value={row.todoCount} total={getTotalContracts(row)} />
			</Grid>
		</Grid>
	}, {
		name: intl.formatMessage({ id: 'pages.dashboard.branch.table.headers.inProgressCount' }),
		key: 'inProgress',
		sortable: true,
		tableHeaderColumnProps: {
			classes: {
				root: classes.tableHeader
			}
		},
		render: (row: BranchListRow) => <Grid container alignItems="center" spacing={1}>
			<Grid item container xs={2} justify="flex-end">
				<Typography component="span" display="inline" className={classes.tableCell}>{row.inProgressCount}</Typography>
			</Grid>
			<Grid item xs={10}>
				<ProgressBar color="primary" value={row.inProgressCount} total={getTotalContracts(row)} />
			</Grid>
		</Grid>
	}, {
		name: intl.formatMessage({ id: 'pages.dashboard.branch.table.headers.closedCount' }),
		key: 'closed',
		sortable: true,
		tableHeaderColumnProps: {
			classes: {
				root: classes.tableHeader
			}
		},
		render: (row: BranchListRow) => <Grid container justify="center">
			<Typography component="span" display="inline" className={classes.tableCell}>{row.closedCount}</Typography>
		</Grid>
	}]

	return (
		<Card className={classes.card}>
			<CardHeader title={intl.formatMessage({ id: `pages.dashboard.branch.table.title${user?.regionId ? 'Region' : 'National'}` })} />
			<CardContent className={classes.cardContent}>
				<PaperLoaderDataTable datatableName="branches">
					<SpringDataTable
						nom="branches"
						headers={headers}
						filters={{ timestamp: shouldRefresh }}
						apiUrlPrefix="/api"
						apiUrl={`/dashboard/${user?.regionId ? 'region/' : '' }branches`}
						ActionsComponent={({ classes: notused, ...props }: Props) => isWidthUp('md', width) ?
							<TablePaginationActions
								{...props}
								noPagination={false}
							/> : <TablePaginationActionsMobile
								{...props}
								noPagination={false}
							/>}
						noResultFragment={<NoResultFragment message={intl.formatMessage({ id: 'pages.dashboard.branch.table.noResult' })} />}
					/>
				</PaperLoaderDataTable>
			</CardContent>
		</Card>
	)
}

export default compose(
	withStyles(styles),
	withWidth({ withTheme: true }),
	injectIntl
)(BranchesList)
