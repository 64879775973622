export enum MANUALLY_CLOSED_STATUS {
    TO_BE_CANCELLED = 'TO_BE_CANCELLED',
    TO_BE_AUTO_RENEWED = 'TO_BE_AUTO_RENEWED',
    RENEGOTIATED = 'RENEGOTIATED',
    UPGRADED = 'UPGRADED',
    DOWNGRADED = 'DOWNGRADED'
}

export enum CLASSIFICATION {
    ALL = 'all',
    HIGH_RISK_VALUE = 'highValue',
    LOW_VALUE = 'lowValue',
    MANUALLY_PRIORITIZED = 'manuallyPrioritized'
}

export enum PROFILE {
    NATIONAL = 'NATIONAL',
    BRANCH_MANAGER = 'BRANCH_MANAGER',
    SALES_REPRESENTATIVE = 'SALES_REPRESENTATIVE',
    ZONE_MANAGER = 'ZONE_MANAGER',
    BRANCH_MANAGER_ASSISTANT = 'BRANCH_MANAGER_ASSISTANT',
    REGIONAL_MANAGER = 'REGIONAL_MANAGER'
}

export enum USER_PREFERENCES {
    COUNTRY = 'COUNTRY'
}

export enum CRM_STATUS {
    OPEN = 'OPEN',
    CANCELLED = 'CANCELLED'
}

export enum ACTION_TYPE {
    FIRST_CALL = 'FIRST_CALL',
    CUSTOMER_MEETING = 'CUSTOMER_MEETING',
    REPORT_TO_CUSTOMER = 'REPORT_TO_CUSTOMER',
    PROACTIVE_PROPOSAL = 'PROACTIVE_PROPOSAL',
    RENEGOTIATION = 'RENEGOTIATION',
    DOWNSELL = 'DOWNSELL',
    UPSELL = 'UPSELL',
    SEND_MECHANIC_TO_JOBSITE = 'SEND_MECHANIC_TO_JOBSITE',
    SEND_SUPERVISOR_TO_JOBSITE = 'SEND_SUPERVISOR_TO_JOBSITE',
    CONTACT_SUPERVISOR_MECHANIC = 'CONTACT_SUPERVISOR_MECHANIC',
    CUSTOMER_CLAIM = 'CUSTOMER_CLAIM',
    CANCELLATION_REQUEST = 'CANCELLATION_REQUEST',
    OTHER = 'OTHER'
}

export enum CONTRACT_TABS {
	DETAILS,
	ACTIONS,
}

export enum CUSTOMER_SATISFACTION {
	SATISFIED = "SATISFIED",
	UNSATISFIED = "UNSATISFIED",
}

export enum FORECAST_PROBABILITY {
	HIGH = 'HIGH',
	LOW = 'LOW',
}

export enum MANUAL_STATUS {
    TO_BE_CANCELLED = 'TO_BE_CANCELLED',
    TO_BE_AUTO_RENEWED = 'TO_BE_AUTO_RENEWED',
    RENEGOTIATED = 'RENEGOTIATED',
    UPGRADED = 'UPGRADED',
    DOWNGRADED = 'DOWNGRADED',
    TODO = 'TODO',
    IN_PROGRESS = 'IN_PROGRESS'
}

export enum CONTRACT_MODULE {
    FR = "FR"
}

export enum VIEW {
    MONTHLY = 'MONTHLY',
    YEAR_TO_DATE = "YEAR_TO_DATE"
}

export enum MONTH {
    JANUARY = 1,
    FEBRUARY,
    MARCH,
    APRIL,
    MAY,
    JUNE,
    JULY,
    AUGUST,
    SEPTEMBER,
    OCTOBER,
    NOVEMBER,
    DECEMBER
}