import { fetchFactory } from 'isotope-client'

export const getRegions = () => fetchFactory(`/regions`, {}, '/api')

export const getCountryRegions = (countryId: string) => fetchFactory(`/regions/${countryId}`, {}, '/api')

export const setRegion = (regionId: string) =>
	fetchFactory(`/rtv-users/set-region`, {
		method: 'post',
		body: JSON.stringify({ region: regionId }),
	})
