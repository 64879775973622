import { Box, Button, withStyles } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import Select from '@material-ui/core/Select/Select'
import Switch from '@material-ui/core/Switch/Switch'
import React, { useContext } from 'react'
import { compose } from 'recompose'
import SearchBar from '../../../components/SearchBar'
import { UsersContext } from '../../../contexts/UsersContext'
import { resetFilters, setFilters } from './services/usersActions'
import { injectIntl, FormattedMessage } from 'react-intl'

const styles = (theme) => ({
	toolsTable: {
		margin: 0,
		height: '53px',
		display: 'flex',
		flexGrow: '1',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	search: {
		width: '300px'
	},
	select: {
		width: '230px'
	},
	sideMargins: {
		margin: 'auto 10px'
	},
	thickButton: {
		fontWeight: 600
	}
})
const ContractsTable = ({ classes, width, intl }) => {
	const { filters, dispatch } = useContext(UsersContext)

	return (
		<Box className={classes.toolsTable}>
			<Box display="flex">
				<Box className={classes.sideMargins}>
					<SearchBar
						className={classes.search}
						disableUnderline
						placeholder={intl.formatMessage({ id: 'user.table.filters.searchBar' })}
						onChange={(e) => dispatch(setFilters({ ...filters, searchQuery: e.target.value }))}
						value={filters.searchQuery}
					/>
				</Box>
				<Box className={classes.sideMargins}>
					<Select
						disableUnderline
						className={classes.select}
						placeholder={intl.formatMessage({ id: 'user.table.filters.select.placeholder' })}
						value={filters.profile}
						onChange={e => dispatch(setFilters({ ...filters, profile: e.target.value }))}
						MenuProps={{
							getContentAnchorEl: null,
							anchorOrigin: {
								vertical: 'bottom',
								horizontal: 'left'
							}
						}}
					>
						<MenuItem value='all'><FormattedMessage id="user.table.filters.select.all" /></MenuItem>
						<MenuItem value='NATIONAL'><FormattedMessage id="user.table.filters.select.national" /></MenuItem>
						<MenuItem value='REGIONAL_MANAGER'><FormattedMessage id="user.table.filters.select.regionalManager" /></MenuItem>
						<MenuItem value='BRANCH_MANAGER'><FormattedMessage id="user.table.filters.select.branchManager" /></MenuItem>
						<MenuItem value='BRANCH_MANAGER_ASSISTANT'><FormattedMessage id="user.table.filters.select.branchManagerAssistant" /></MenuItem>
						<MenuItem value='SALES_REPRESENTATIVE'><FormattedMessage id="user.table.filters.select.salesRepresentative" /></MenuItem>
					</Select>
				</Box>
				<Box className={classes.sideMargins}>
					<FormControlLabel
						control={
							<Switch
								checked={filters.showDisabled}
								onChange={e => dispatch(setFilters({ ...filters, showDisabled: e.target.checked }))}
								color="primary"
							/>
						}
						label={intl.formatMessage({ id: 'user.table.filters.switch' })}
						labelPlacement="start"
					/>
				</Box>
			</Box>
			<Box className={classes.sideMargins}>
				<Button color="primary" onClick={() => dispatch(resetFilters())} className={classes.thickButton}><FormattedMessage id="user.table.filters.reset" /></Button>
			</Box>
		</Box>
	)
}

const enhance = compose(
	withStyles(styles),
	injectIntl
)

export default enhance(ContractsTable)
