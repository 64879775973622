import { Box, Button, Grid, isWidthDown, Paper, withStyles, withWidth } from '@material-ui/core'
import { ExpandMoreRounded } from '@material-ui/icons'
import React, { useContext, useRef, useState } from 'react'
import { compose } from 'recompose'
import ActionFilter from '../../components/ActionFilter'
import SearchBar from '../../components/SearchBar'
import { ActionsFiltersContext } from '../../contexts/ActionsFiltersContext'
import { ACTIONS_STATUS, ACTION_TYPE_LABELS } from './actionConstants'
import ActionsListDesktop from './ActionsListDesktop'
import ActionsListMobile from './ActionsListMobile'
import { injectIntl } from 'react-intl'

const styles = (theme) => ({
	radiusWithTabBar: {
		borderRadius: '0 5px 5px 5px',
		'& > div:last-child': {
			borderRadius: '0 0 5px 5px'
		}
	},
	btnPrimary: {
		fontSize: 13,
		fontWeight: 700,
		color: theme.palette.grey['600'],
		minHeight: 50,
		padding: '6px 10px',
		letterSpacing: 0,
		textTransform: 'inherit',
		backgroundColor: theme.palette.text.secondary,
		border: '1px solid',
		borderColor: theme.palette.background.lightShadow,
		borderRadius: '4px',
		boxShadow: '0 2px 0 0 #E5EAF5',
		transition: 'all .2s ease',
		'&:hover': {
			backgroundColor: theme.palette.text.secondary,
			borderColor: theme.palette.grey['700']
		}
	},
	cardTable: {
		display: 'flex',
		flexDirection: 'column',
		flex: '0 1 300px',
		width: '100%'
	},
	titleCardTable: {
		fontSize: 15,
		fontWeight: '700',
		textTransform: 'uppercase',
		color: theme.palette.grey['800']
	},
	subCardTable: {
		fontSize: 12,
		fontWeight: '400',
		textTransform: 'uppercase',
		color: theme.palette.grey['500']
	},
	avatar: {
		marginRight: 15
	},
	iconTypology: {
		height: 26,
		width: 26,
		padding: 3,
		margin: '0 3px',
		borderRadius: 5,
		borderColor: theme.palette.background.lightShadow,
		border: '2px solid'
	},
	toolsTable: {
		padding: 20
	},
	flexEnd: {
		justifyContent: "flex-end"
	}
})

const ActionsTable = ({ classes, width, intl }) => {
	const [statusAnchorEl, setStatusAnchorEl] = useState(null)
	const [typeAnchorEl, setTypeAnchorEl] = useState(null)
	const { filters, setSearchQuery } = useContext(ActionsFiltersContext)
	const statusRef = useRef()
	const typeRef = useRef()
	const actionStatus = ACTIONS_STATUS(intl)
	const actionTypeLabels = ACTION_TYPE_LABELS(intl)

	return (
		<>
			<Paper className={classes.radiusWithTabBar}>
				<Grid container display="flex" className={classes.toolsTable} >
					<Grid item xs={12} md={5} lg={7}>
						<Box display="flex" justifyContent="flex-start" flexGrow="1" mb={{ xs: '20px', md: 0 }}>
							<SearchBar
								placeholder={intl.formatMessage({ id: 'action.page.table.searchBar' })}
								onChange={(e) => setSearchQuery(e.target.value)}
								value={filters.searchQuery}
							/>
						</Box>
					</Grid>
					<Grid item xs={12} md={7} lg={5}>
						<Grid container display="flex" spacing={2} className={classes.flexEnd}>
							<Grid item xs={isWidthDown('sm', width) ? 12 : undefined}>
								<Box display="flex" flexGrow={{ xs: 1, md: 0 }}>
									<Button
										ref={typeRef}
										fullWidth={isWidthDown('sm', width)}
										className={classes.btnPrimary}
										onClick={() => setTypeAnchorEl(typeRef.current)}
									>
										<Box pl={1} pr={1}>
											{filters.type && actionTypeLabels[filters.type] ? actionTypeLabels[filters.type].label : intl.formatMessage({ id: 'action.page.table.filters.type' })}
										</Box>
										<ExpandMoreRounded />
									</Button>
								</Box>
							</Grid>
							<Grid item xs={isWidthDown('sm', width) ? 12 : undefined}>
								<Box display="flex" flexGrow={{ xs: 1, md: 0 }}>
									<Button
										ref={statusRef}
										fullWidth={isWidthDown('sm', width)}
										className={classes.btnPrimary}
										onClick={() => setStatusAnchorEl(statusRef.current)}
									>
										<Box pl={1} pr={1}>
											{filters.status ? actionStatus[filters.status].label : actionStatus['all'].label}
										</Box>
										<ExpandMoreRounded />
									</Button>
								</Box>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				{isWidthDown('sm', width) ?
					<ActionsListMobile /> : <ActionsListDesktop />}
				<ActionFilter
					open={Boolean(statusAnchorEl)}
					anchorEl={statusAnchorEl}
					closeFilters={() => setStatusAnchorEl(null)}
					filterItems={actionStatus}
					fieldName="status"
				/>
				<ActionFilter
					open={Boolean(typeAnchorEl)}
					anchorEl={typeAnchorEl}
					closeFilters={() => setTypeAnchorEl(null)}
					filterItems={actionTypeLabels}
					fieldName="type"
				/>
			</Paper>
		</>
	)
}

const enhance = compose(
	withStyles(styles),
	withWidth(),
	injectIntl
)

export default enhance(ActionsTable)