import { List, ListItem, makeStyles, Typography } from '@material-ui/core'
import * as React from 'react'
import * as api from './services/searchApi'
import { SalesRepresentativeSearchResult } from './types'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles((theme) => ({
	title: {
		display: 'flex',
		alignItems: 'center',
		fontSize: 12,
		fontWeight: 600,
		color: theme.palette.text.primary,
		padding: '0 20px',
		marginBottom: 15,
		height: 30
	},
	noResults: {
		fontSize: 15,
		fontWeight: 400,
		padding: '0',
		marginBottom: 15
	},
	groupList: {
		padding: '0 20px'
	},
	itemList: {
		fontSize: 15,
		fontWeight: 500,
		color: theme.palette.text.primary,
		padding: '0',
		marginBottom: 15
	}
}))

const SalesRepresentativesSearchBlock = ({ query, handleMenuClose }) => {
	const [salesRep, setSalesRep] = React.useState([])
	const classes = useStyles()

	React.useEffect(() => {
		if (query.length > 1) {
			api.getSalesRepresentatives(query).then((data) => {
				if (data) {
					setSalesRep(data)
				}
			})
		}
	}, [query])

	return (
		<>
			<Typography className={classes.title}><FormattedMessage id="search.salesRepresentative.title" /></Typography>
			<List className={classes.groupList}>
				{salesRep.length > 0 ? salesRep.map((sales: SalesRepresentativeSearchResult) => (
						<Link key={sales.id} to={`/portfolio/${sales.id}`}>
							<ListItem className={classes.itemList} onClick={handleMenuClose}>
								{sales.firstName} {sales.lastName}
							</ListItem>
						</Link>
					)) :
					<ListItem className={classes.noResults}><FormattedMessage id="search.salesRepresentative.noResult" /></ListItem>
				}
			</List>
		</>
	)
}

export default SalesRepresentativesSearchBlock