import { createStyles, Theme } from '@material-ui/core'

export const filtersStyles = (theme: Theme) => ({
	paper: {
		padding: 20,
		borderRadius: '5px'
	},
	cardContent: {
		padding: '20px'
	},
	select: {
		fontSize: 13,
		color: theme.palette.grey['800'],
		backgroundColor: theme.palette.background.paper,
		fontWeight: 700,
		lineHeight: 0,
		[theme.breakpoints.down('sm')]: {
			backgroundColor: theme.palette.text.secondary
		},
		'& [class*="MuiSelect-root"]': {
			paddingRight: 34
		},
		'& [class*="MuiSelect-icon"]': {
			right: 10
		}
	},
	inputLabel: {
		color: '#687BAE',
		width: '100%',
		marginBottom: 5
	},
	itemContainer: {
		width: '100%',
		'& [class*="MuiGrid-item"]': {
			padding: 5
		}
	},
	error: {
		color: 'red',
		marginTop: 5
	},
	alignRight: {
		display: 'flex',
		justifyContent: 'flex-end'
	}
})

export const tabBarStyles = () => ({
	radiusWithTabBar: {
		borderRadius: '0 5px 5px 5px',
		'& > div:last-child': {
			borderRadius: '0 0 5px 5px'
		},
	},
	tabContentDesktop : {
		padding: '20px 0'
	},
	tabContentMobile : {
		paddingTop: '20px'
	},
	pad: {
		padding: '0 30px'
	},
	table: {
		paddingTop: '40px'
	},
	tableAlone: {
		paddingTop: '10px'
	}
})

export const centerLoader = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	padding: '20px 0'
}

export const tableStyles = () => createStyles({
	hidden: {
		display: 'none'
	},
	icons: {
		paddingLeft: '10px'
	},
	inline: {
		display: 'flex',
		justifyContent: 'center'
	},
	good: {
		color: '#17AD89'
	},
	bad: {
		color: '#EB2929'
	},
	centerText: {
		textAlign: 'center'
	},
	pointer: {
		cursor: 'pointer'
	},
	centerLoader
})

export const mobileTableStyle = (theme: Theme) => createStyles({
	...tableStyles(),
	tableHeader: {
		height: 0
	},
	tableHeaderCol: {
		padding: 0,
		bottom: theme.spacing(1),
		height: 0
	},
	tableBodyCol: {
		padding: 0,
		fontSize: '16px!important',
		fontWeight: 400
	},
	cardTable: {
		display: 'flex',
		flexDirection: 'column',
		flex: '0 1 300px',
		width: '100%',
	},
	aligned: {
		display: 'flex'
	}
})