import React from 'react'
import { Tooltip } from '@material-ui/core'

interface Value {
	title: string
	value: number
}

interface Props {
	firstValue: Value
	secondValue: Value
}

const TwoValuesDonutChart: React.FC<Props> = ({ firstValue, secondValue }) => {
	return (
		<svg viewBox="0 0 36 36">
			<Tooltip title={secondValue.title} placement="left-start">
				<path
					className="circle-bg"
					fill="none"
					stroke="#F65275"
					strokeWidth="3"
					d="M18 2.0845
			  a 15.9155 15.9155 0 0 1 0 31.831
			  a 15.9155 15.9155 0 0 1 0 -31.831"
				/>
			</Tooltip>
			<Tooltip title={firstValue.title} placement="right-start">
				<path
					d="M18 2.0845
			  a 15.9155 15.9155 0 0 1 0 31.831
			  a 15.9155 15.9155 0 0 1 0 -31.831"
					fill="none"
					stroke="#34CB81"
					strokeWidth="3"
					strokeDasharray={`${(firstValue.value * 100) / (firstValue.value + secondValue.value)}, 100`}
				/>
			</Tooltip>
		</svg>
	)
}

export default TwoValuesDonutChart
