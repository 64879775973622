import Dialog from '@material-ui/core/Dialog/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle'
import React, { useCallback, useContext } from 'react'
import { UsersContext } from '../../../../contexts/UsersContext'
import { refreshUserList } from '../services/usersActions'
import * as api from '../services/usersApi'
import UserForm from './UserForm'
import { FormattedMessage, injectIntl } from 'react-intl'

const UpdateUserDialog = ({ open, onClose, intl }) => {
	const { userForUpdateDialog, tableDispatch } = useContext(UsersContext)

	const updateUser = useCallback((data) => {
		api.updateUser(userForUpdateDialog.id, data).then(() => {
			refreshUserList(tableDispatch)
			onClose()
		})
	}, [onClose, tableDispatch, userForUpdateDialog])

	if (!userForUpdateDialog) {
		return null
	}

	const initialValues =  {
		lastName: userForUpdateDialog.lastName,
		firstName: userForUpdateDialog.firstName,
		email: userForUpdateDialog.email,
		profile: userForUpdateDialog.profile,
		countries: userForUpdateDialog.countries,
		regions: userForUpdateDialog?.regions?.map(region => region.id),
		branches: userForUpdateDialog.branches.map((value) => value.id),
	}

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
			<DialogTitle><FormattedMessage id="user.update.title" /></DialogTitle>
			<UserForm initialValues={initialValues} onSubmit={updateUser} onClose={onClose} validateButtonLabel={intl.formatMessage({id: 'user.update.buttonLabel'})} editing />
		</Dialog>
	)
}

export default injectIntl(UpdateUserDialog)
