import { Box, Collapse, Grid, Tooltip, Typography, withStyles } from '@material-ui/core'
import React from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import HelpIcon from '@material-ui/icons/Help'
import ContractChart from '../../components/ContractChart'
import { getClusterData } from '../contracts/services/contractsApi'
import { injectIntl, FormattedMessage } from 'react-intl'
import { compose } from 'recompose'

const styles = (theme) => ({
		grid: {
			backgroundColor: theme.palette.background.light,
			borderRadius: '5px',
			border: '1px solid',
			borderColor: theme.palette.background.lightShadow,
			alignItems: 'center'
		},
		collapseText: {
			color: theme.palette.primary.light,
			cursor: 'pointer',
			margin: '10px',
			alignItems: 'center'
		},
		tooltip: {
			marginTop: '4px'
		},
		collapse: {
			marginLeft: '15px',
			marginRight: '15px'
		},
		collapseGrid: {
			marginBottom: '5px'
		},
		chartBox: {
			backgroundColor: theme.palette.common.white
		},
		portfolioValueBox: {
			margin: '10px 16px 0px 16px'
		},
		portfolioValue: {
			fontWeight: 800
		}
	}
)

function findLastIndex(array, predicate) {
	let l = array.length
	while (l--) {
		if (predicate(array[l], l, array)) return l
	}
	return -1
}

const ContractsComparison = ({ classes, contract, intl }) => {
	const [expanded, setExpanded] = React.useState(false)
	const [minPrice, setMinPrice] = React.useState(0)
	const [maxPrice, setMaxPrice] = React.useState(0)
	const [medianPrice, setMedianPrice] = React.useState(0)
	const [distributionPrice, setDistributionPrice] = React.useState([0, 0, 0, 0, 0, 0])
	const [positionPrice, setPositionPrice] = React.useState(0)

	const [minValue, setMinValue] = React.useState(0)
	const [maxValue, setMaxValue] = React.useState(0)
	const [medianValue, setMedianValue] = React.useState(0)
	const [distributionValue, setDistributionValue] = React.useState([0, 0, 0, 0, 0, 0])
	const [positionValue, setPositionValue] = React.useState(0)

	React.useEffect(() => {
		getClusterData(contract.id)
			.then(cluster => {
				const dataValue = JSON.parse(cluster.dataValue)
				const dataPrice = JSON.parse(cluster.dataPrice)

				const positionValue = findLastIndex(dataValue.intervals, bound => contract.contractValue > bound)
				const positionPrice = findLastIndex(dataPrice.intervals, bound => contract.contractValue > bound)

				setMinPrice(cluster.minPrice)
				setMaxPrice(cluster.maxPrice)
				setMedianPrice(cluster.medianPrice)
				setDistributionPrice(dataPrice.distribution)
				setPositionPrice(positionPrice)

				setMinValue(cluster.minValue)
				setMaxValue(cluster.maxValue)
				setMedianValue(cluster.medianValue)
				setDistributionValue(dataValue.distribution)
				setPositionValue(positionValue)
			})
	}, [contract])

	return (
		<Grid container className={classes.grid}>
			{contract.module === 'FR' && (
				<Grid item xs={12}>
					<Box display="flex" flexDirection="row" gridColumnGap={8} alignItems="center" className={classes.portfolioValueBox}>
						<FormattedMessage id="pages.contract.comparison.portfolioValue" />
						<Typography className={classes.portfolioValue}>
							{contract.portfolioValue ? `${contract.portfolioValue} ${contract.currency}` : '-'}
						</Typography>
					</Box>
				</Grid>
			)}
			<Grid item xs={11}>
				<Typography
					variant="h5"
					className={classes.collapseText}
					onClick={() => setExpanded(!expanded)}
				>
					{expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
					{expanded ? intl.formatMessage({ id: 'pages.contract.comparison.hide' }) : intl.formatMessage({ id: 'pages.contract.comparison.show' })} {intl.formatMessage({ id: 'pages.contract.comparison.text' })}
				</Typography>
			</Grid>
			<Grid item xs={1}>
				<Tooltip
					title={intl.formatMessage({ id: 'pages.contract.comparison.tooltip' })}
					className={classes.tooltip}
				>
					<HelpIcon />
				</Tooltip>
			</Grid>
			<Grid item xs={12}>
				<Collapse in={expanded} className={classes.collapse}>
					<Grid container spacing={2} className={classes.collapseGrid}>
						<Grid item xs={6}>
							<ContractChart
								title={intl.formatMessage({ id: 'pages.contract.comparison.chart.price' })}
								value={contract.currentSellingPrice}
								rangeMin={minPrice}
								rangeMax={maxPrice}
								median={medianPrice}
								data={distributionPrice}
								position={positionPrice}
								currency={contract.currency}
							/>
						</Grid>
						<Grid item xs={6}>
							<ContractChart
								title={intl.formatMessage({ id: 'pages.contract.comparison.chart.value' })}
								value={contract.contractValue}
								rangeMin={minValue}
								rangeMax={maxValue}
								median={medianValue}
								data={distributionValue}
								position={positionValue}
								currency={contract.currency}
							/>
						</Grid>
					</Grid>
				</Collapse>
			</Grid>
		</Grid>
	)
}

export default compose(
	withStyles(styles),
	injectIntl
)(ContractsComparison)
