import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, Tooltip, withStyles } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar/Avatar'
import Box from '@material-ui/core/Box/Box'
import Typography from '@material-ui/core/Typography/Typography'
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons'
import { SpringDataTable } from 'isotope-client'
import React, { useCallback, useContext } from 'react'
import { withRouter } from 'react-router'
import NoResultFragment from '../../components/NoResult'
import { ContractsContext } from '../../contexts/ContractsContext'
import { setFilters, setTableUrl } from '../contracts/services/contractsActions'
import { defaultFiltersPriority } from '../contracts/services/contractsReducer'
import TablePaginationActions from '../datatable/TablePaginationActions'
import { SALES_REP_OVERVIEW_TABLE_NAME } from './SRConsts'
import { FormattedMessage, injectIntl } from 'react-intl'
import { compose } from 'recompose'

const styles = theme => ({
	radius: {
		borderRadius: '5px'
	},
	tableHeader: {
		height: 0
	},
	tableHeaderCol: {
		padding: 0,
		bottom: theme.spacing(1),
		height: 0
	},
	tableBodyCol: {
		padding: 0,
		fontSize: '16px!important',
		fontWeight: '400!important'
	},
	link: {
		color: theme.palette.primary.light,
		cursor: 'pointer'
	},
	avatar: {
		cursor: 'pointer'
	},
	caption: {
		color: theme.palette.grey['500']
	}
})

const MobileSRTable = ({ classes, history, intl }) => {
	const { dispatch } = useContext(ContractsContext)

	const goToPortfolio = useCallback((status, id) => {
		let filters = { ...defaultFiltersPriority }
		dispatch(setTableUrl('priority'))
		dispatch(setFilters({ ...filters, status: [status] }))
		history.push(`/portfolio/${id}`)
	}, [dispatch, history])

	const colProps = {
		classes: {
			head: classes.tableHeaderCol,
			body: classes.tableBodyCol
		}
	}

	const headers = [
		{
			key: 'sales',
			tableHeaderColumnProps: colProps,
			render: user =>
				<ExpansionPanel className={classes.expansionPanelHeader}>
					<ExpansionPanelSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Tooltip title={`${user.firstName} ${user.lastName}`}>
							<Avatar className={classes.avatar} onClick={() => goToPortfolio(null, user.id)}>({(user.firstName[0] + user.lastName[0]).toUpperCase()})</Avatar>
						</Tooltip>
						<Box pl={1} />
						<Box className={classes.cardTable}>
							<Typography>
								<FormattedMessage
									id="pages.srOverview.row.title"
									values={{
										openContracts: user.openContractsCount,
										totalContracts: user.totalContractsCount,
										openContractsPercentage: user.openContractsPercentage
									}}
								/>
							</Typography>
							<Typography variant="caption" className={classes.caption}>
								<FormattedMessage
									id="pages.srOverview.row.subtitle"
									values={{ nbActions: user.contractsWithoutActions }}
								/>
							</Typography>
						</Box>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails className={classes.expansionPanelBody}>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<Typography><FormattedMessage id="pages.srOverview.table.headers.openActions.title" /></Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography>{user.openActionsCount}</Typography>
								<Typography variant="caption" className={classes.caption}><FormattedMessage id="pages.srOverview.table.headers.openActions.text" values={{ nbActions: user.overdueActionsCount }} /></Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography><FormattedMessage id="pages.srOverview.table.headers.overdueContracts" /></Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography>{user.overdueCount}</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography><FormattedMessage id="pages.srOverview.table.headers.toBeCancelled" /></Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography className={classes.link}
											onClick={() => goToPortfolio('TO_BE_CANCELLED', user.id)}>{user.toBeCancelled}%</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography><FormattedMessage id="pages.srOverview.table.headers.toBeAutoRenewed" /></Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography className={classes.link}
											onClick={() => goToPortfolio('TO_BE_AUTO_RENEWED', user.id)}>{user.toBeAutoRenewed}%</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography><FormattedMessage id="pages.srOverview.table.headers.renegotiated" /></Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography className={classes.link}
											onClick={() => goToPortfolio('RENEGOTIATED', user.id)}>{user.renegotiated}%</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography><FormattedMessage id="pages.srOverview.table.headers.upgraded" /></Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography className={classes.link} onClick={() => goToPortfolio('UPGRADED', user.id)}>{user.upgraded}%</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography><FormattedMessage id="pages.srOverview.table.headers.downgraded" /></Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography className={classes.link}
											onClick={() => goToPortfolio('DOWNGRADED', user.id)}>{user.downgraded}%</Typography>
							</Grid>

						</Grid>
					</ExpansionPanelDetails>
				</ExpansionPanel>
		}
	]

	return (
		<SpringDataTable
			headers={headers}
			apiUrl="/sales-representatives/overview"
			apiUrlPrefix="/api"
			noResultFragment={<NoResultFragment message={intl.formatMessage({ id: 'pages.srOverview.table.noResult' })} />}
			ActionsComponent={(props) => <TablePaginationActions {...props} />}
			nom={SALES_REP_OVERVIEW_TABLE_NAME}
		/>
	)
}

export default compose(
	withStyles(styles),
	withRouter,
	injectIntl
)(MobileSRTable)
