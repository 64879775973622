import React, { FunctionComponent, ReactElement } from 'react'
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, withStyles, WithStyles } from '@material-ui/core'
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons'
import Box from '@material-ui/core/Box/Box'
import Typography from '@material-ui/core/Typography/Typography'
import { mobileTableStyle } from './style'

interface Props extends WithStyles<typeof mobileTableStyle> {
	children: ReactElement
	title: string | ReactElement
}

const MobileTableContent: FunctionComponent<Props> = ({ classes, children, title }) => {
	return (
		<ExpansionPanel>
			<ExpansionPanelSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel1a-content"
				id="panel1a-header"
			>
				<Box className={classes.cardTable}>
					<Typography>{title}</Typography>
				</Box>
			</ExpansionPanelSummary>
			<ExpansionPanelDetails>
				{children}
			</ExpansionPanelDetails>
		</ExpansionPanel>
	)
}

export default withStyles(mobileTableStyle)(MobileTableContent)
