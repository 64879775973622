import React, { FunctionComponent } from 'react'
import { isWidthDown, Paper, WithStyles, withStyles, withWidth, WithWidth } from '@material-ui/core'
import { tabBarStyles } from '../../style'
import { compose } from 'recompose'
import { injectIntl, IntlShape } from 'react-intl'
import ReportingFiltersForm from '../../ReportingFiltersForm'
import { ReportingContextProps } from '../../types'
import { ReportingContext } from '../../../../contexts/ReportingContext'
import TablePerformance from './TablePerformance'
import { REPORTING_PAGES } from '../../../../constants'
import DetailedPerformanceChart from './DetailedPerformanceChart'
import { onChange, validate } from '../utils'
import MobileTablePerformance from './MobileTablePerformance'
import classNames from 'classnames'

interface Props extends WithStyles<typeof tabBarStyles>, WithWidth {
	intl: IntlShape
}

const DetailedPerformance: FunctionComponent<Props> = ({ classes, intl , width}) => {
	const { setFilters } = React.useContext<ReportingContextProps>(ReportingContext)

	return (
		<Paper className={classNames(classes.radiusWithTabBar, isWidthDown('sm', width) ? classes.tabContentMobile : classes.tabContentDesktop)}>
			<div className={classes.pad}>
				<ReportingFiltersForm
					validate={validate(intl)}
					onChange={onChange(setFilters)}
					tab="performance"
					showView
					alignRight
					showLabels={false}
				/>
			</div>
			<DetailedPerformanceChart/>
			<div className={classes.table}>
				{
					isWidthDown('sm', width) ? <MobileTablePerformance target={REPORTING_PAGES.DETAILED} /> : (
							<TablePerformance target={REPORTING_PAGES.DETAILED}/>
					)
				}
			</div>
		</Paper>
	)
}

const enhance = compose(
	withWidth(),
	withStyles(tabBarStyles),
	injectIntl
)

export default enhance(DetailedPerformance)