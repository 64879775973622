import React, { useContext } from 'react'
import { matchPath, Route, Switch } from 'react-router'
import { withRouter } from 'react-router-dom'
import { LoggedInContext } from '../contexts/LoggedInContext'
import BackOfficeRouter from './BackOfficeRouter'
import FrontOfficeRouter from './FrontOfficeRouter'
import { PROFILE } from '../enums'
import { hasAnyProfile, hasProfile } from '../utils/accessChecker'
import { ErrorHandlerContextProvider } from '../contexts/ErrorHandlerContext'

const App = ({ history: { location, replace } }) => {
	const { user } = useContext(LoggedInContext)

	React.useEffect(() => {
		const replaceSR = () => {
			if (hasProfile(user, PROFILE.SALES_REPRESENTATIVE)) {
				replace('/portfolio')
			} else {
				replace('/dashboard')
			}
		}

		if (
			!matchPath(location.pathname, '/select-country') &&
			!matchPath(location.pathname, '/emea/reporting/teams') &&
			!matchPath(location.pathname, '/bo') &&
			(hasProfile(user, PROFILE.ZONE_MANAGER) ||
			(hasProfile(user, PROFILE.NATIONAL) && user.countries.length > 1)) &&
			!user.countryId
		) {
			replace('/select-country')
		} else if (user && (!hasProfile(user, PROFILE.ZONE_MANAGER) && (!hasProfile(user, PROFILE.NATIONAL) || user.countries.length < 2))
			&& (matchPath(location.pathname, '/select-country') || matchPath(location.pathname, '/emea/reporting/teams'))) {
			replaceSR()
		} else if (
			user &&
			!hasAnyProfile(user, [PROFILE.ZONE_MANAGER, PROFILE.NATIONAL]) &&
			matchPath(location.pathname, '/bo')
		) {
			replaceSR()
		} else if (location.pathname === '/') {
			replaceSR()
		}
	}, [replace, user, location])

	if (!user) {
		return null
	}

	return (
		<ErrorHandlerContextProvider>
			<Switch>
				<Route path="/bo" component={BackOfficeRouter} />
				<Route path="*">
					<FrontOfficeRouter />
				</Route>
			</Switch>
		</ErrorHandlerContextProvider>
	)
}

export default withRouter(App)
