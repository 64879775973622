import { FunctionComponent } from 'react'
import { DrawerMenuItemProps } from './types'
import { Link } from 'react-router-dom'
import { createStyles, ListItem, ListItemIcon, ListItemText, Theme, withStyles } from '@material-ui/core'
import classNames from 'classnames'
import * as React from 'react'
import { drawerMenuItemStyles } from './styles'

const styles = (theme: Theme) => createStyles({
	...drawerMenuItemStyles(theme)
})

const SingleLevel: FunctionComponent<DrawerMenuItemProps> = ({ item, isCurrent, classes }) => {

	return <Link to={item.path}>
		<ListItem button className={classNames(classes.drawerButton, { [classes.drawerButtonActive]: isCurrent })}>
			<ListItemIcon className={classes.drawerIcon}>{item.icon}</ListItemIcon>
			<ListItemText primary={item.label} className={classes.itemText} disableTypography />
		</ListItem>
	</Link>
}

export default withStyles(styles)(SingleLevel)