import React from 'react'
import { Box, createStyles, Grid, Paper, Typography, withStyles } from '@material-ui/core'

const styles = () =>
	createStyles({
		root: {
			backgroundImage: 'url(\'../img/OtisLogin.jpg\')',
			position: 'absolute',
			top: 0,
			left: 0,
			height: '100%',
			width: '100%',
			backgroundSize: 'cover',
			margin: 0,
			display: 'flex'
		},
		container: {
			marginTop: '10%',
		},
		paper: {
			padding: 32,
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
		},
		title: {
			fontSize: '1.5rem',
			marginBottom: 10,
		},
	})

const Maintenance = ({ classes }) => {
	return (
		<Box className={classes.root}>
			<Grid container justify="center" className={classes.container}>
				<Grid item>
					<Paper className={classes.paper}>
						<Typography variant="h3" className={classes.title}>
							Contracts import in progress
						</Typography>
						<Typography variant="subtitle1">Please come back later</Typography>
					</Paper>
				</Grid>
			</Grid>
		</Box>
	)
}

export default withStyles(styles)(Maintenance)
