import { LoginAction, SET_BRANCH, SET_USER, UPDATE_USER, SET_REGION } from './loginActions'
import { User } from '../../../utils/accessChecker'

type LoginState = User | undefined
const initialState: LoginState = undefined

function loginReducer(state: LoginState, action: LoginAction): LoginState {
	switch (action.type) {
		case SET_USER:
			return action.user
		case UPDATE_USER:
			if (state) {
				return {
					...state,
					...action.user
				}
			}
			return state
		case SET_BRANCH:
			if (state) {
				return {
					...state,
					branchId: action.branch?.id,
					branchName: action.branch?.name
				}
			}
			return state
		case SET_REGION:
			if (state) {
				return {
					...state,
					regionId: action.region?.id,
					regionName: action.region?.name
				}
			}
			return state
		default:
			throw new Error()
	}
}

export { initialState, loginReducer }
