import { Button, Dialog, DialogActions, DialogContent, DialogTitle, InputLabel, TextField } from '@material-ui/core'
import React, { useContext } from 'react'
import { Field, Form } from 'react-final-form'
import { FormattedMessage } from 'react-intl'
import { LoggedInContext } from '../../contexts/LoggedInContext'
import { PROFILE } from '../../enums'
import { hasProfile } from '../../utils/accessChecker'
import { setContractNegotiation } from './services/contractsActions'
import { createComment } from './services/contractsApi'

enum FIELDS {
	COMMENT = 'comment',
}

const ContractCommentsDialog = ({ contract, open, setOpen, initialValues, dispatch }) => {
	const { user } = useContext(LoggedInContext)

	const onSubmit = (data: any) => {
		createComment(contract.contractNegotiation.id, {
			comment: data.comment ? data.comment : '',
		}).then((result: any) => {
			dispatch(setContractNegotiation(result))
			setOpen(false)
		})
	}

	return (
		<Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
			<DialogTitle>
				<FormattedMessage
					id={`pages.contract.information.tabs.detailsTab.comments.${
						user && hasProfile(user, PROFILE.SALES_REPRESENTATIVE) ? 'srTitle' : 'managersTitle'
					}`}
				/>
			</DialogTitle>
			<Form
				initialValues={initialValues}
				onSubmit={onSubmit}
				render={({ handleSubmit }) => (
					<form onSubmit={handleSubmit}>
						<DialogContent>
							<Field
								name={FIELDS.COMMENT}
								render={({ input }) => (
									<>
										<InputLabel shrink id="description-label">
											<FormattedMessage id="pages.contract.information.tabs.detailsTab.comments.dialog.comment" />
										</InputLabel>
										<TextField {...input} multiline fullWidth InputProps={{ disableUnderline: true }} />
									</>
								)}
							/>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => setOpen(false)}>
								<FormattedMessage id="pages.contract.information.tabs.detailsTab.comments.dialog.cancel" />
							</Button>
							<Button type="submit" variant="contained" size="small" color="primary" disableElevation>
								<FormattedMessage id="pages.contract.information.tabs.detailsTab.comments.dialog.update" />
							</Button>
						</DialogActions>
					</form>
				)}
			/>
		</Dialog>
	)
}

export default ContractCommentsDialog
