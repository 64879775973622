import React from 'react'

type Props = {
	style: object
}

export default ({ style } : Props) => {
	return (
		<span className="material-symbols-outlined" style={style}>grading</span>
	)
}