import React, { FunctionComponent } from 'react'
import { WithWidth } from '@material-ui/core/withWidth/withWidth'
import { isWidthDown, withWidth } from '@material-ui/core'
import TabBarMobile from './TabBarMobile'
import TabBarDesktop from './TabBarDesktop'
import { TabBarProps } from './types'

interface Props extends WithWidth, TabBarProps {
}

const TabBar: FunctionComponent<Props> = ({ width, data, tableUrl, setTableUrl }) => {
	return (isWidthDown('sm', width) ?
			<TabBarMobile data={data} tableUrl={tableUrl} setTableUrl={setTableUrl} /> :
			<TabBarDesktop data={data} tableUrl={tableUrl} setTableUrl={setTableUrl} />
	)
}

export default withWidth()(TabBar)