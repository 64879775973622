import { Box, Button, Grid, Paper, Typography, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useMsal } from '@azure/msal-react'

const styles = theme => ({
	root: {
		backgroundImage: 'url(\'img/OtisLogin.jpg\')',
		height: '100%',
		width: '100%',
		backgroundSize: 'cover',
		margin: 0,
		display: 'flex'
	},
	paper: {
		padding: 40,
		borderRadius: 2
	},
	image: {
		position: 'absolute',
		margin: 30
	},
	title: {
		fontSize: 36,
		fontFamily: theme.typography.fontFamily,
		fontWeight: 900,
		lineHeight: '30px',
		color: theme.palette.grey[800]
	},
	subTitle: {
		fontSize: 16,
		fontWeight: 900,
		fontFamily: theme.typography.fontFamily,
		color: theme.palette.grey[800]
	},
	warning: {
		fontSize: 15,
		fontWeight: 400,
		fontFamily: theme.typography.fontFamily,
		color: theme.palette.error.main,
		marginTop: 40
	},
	microsoft: {
		width: 15,
		height: 15,
		marginRight: 15
	},
	lowerBox: {
		height: 45
	},
	button: {
		justifyContent: 'left',
		paddingLeft: 10,
		fontWeight: 300,
		fontSize: '0.8em',
		backgroundColor: '#023f87',
		width: '100%',
		borderRadius: 2,
		color: 'white',
		textTransform: 'none',
		"&:hover": {
			backgroundColor: '#023f87'
		}
	},
	buttonMargin: {
		marginTop: 60
	}
})


const LoginPage = ({ classes, unauthorized }) => {
	const { instance } = useMsal()
	document.body.classList.toggle('login-content', true)
	document.body.parentNode.classList.toggle('login-content', true)

	return (
		<Box className={classes.root}>
			<Grid
				container
				direction="row"
				justify="center"
				alignItems="center"
			>
				<Grid item sm={false} md={3} lg={7} xl={7} />
				<Grid item sm={8} md={5} lg={3} xl={2}>
					<Paper className={classes.paper} elevation={3}>
						<Typography variant="h2" className={classes.title}><FormattedMessage id="title" /></Typography>
						{unauthorized && <Box className={classes.lowerBox}>
							<Typography className={classes.warning}><FormattedMessage id="login.error" /></Typography>
						</Box>}
						<Button className={classNames(classes.button, { [classes.buttonMargin]: !unauthorized })} size="large" color="primary"
						        disableElevation onClick={() => instance.loginRedirect()}>
							<img src="img/microsoft.png" alt="logo Microsoft" className={classes.microsoft} />
							<FormattedMessage id="login.button" />
						</Button>
					</Paper>
				</Grid>
			</Grid>
		</Box>
	)
}
LoginPage.propTypes = {
	unauthorized: PropTypes.bool
}
LoginPage.defaultProps = {
	unauthorized: false
}

export default withStyles(styles)(LoginPage)
