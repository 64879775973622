import React, { FunctionComponent } from 'react'
import { Tooltip, Typography, withStyles, WithStyles } from '@material-ui/core'
import { tableStyles } from '../../style'
import { compose } from 'recompose'
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl'
import { LEVEL, REPORTING_PAGES, REPORTING_URL, reportingAdoptionThreshold } from '../../../../constants'
import { ThumbDownAltOutlined } from '@material-ui/icons'
import classNames from 'classnames'
import ReportingTable from '../../ReportingTable'
import { AdoptionData, ReportingContextProps } from '../../types'
import {
	getEntityLibelle,
	redirectToCountryTeamsReporting,
	selectBranchReporting,
	selectRegionReporting
} from '../utils'
import { ReportingContext } from '../../../../contexts/ReportingContext'
import { RouteComponentProps, withRouter } from 'react-router'
import { LoggedInContext } from '../../../../contexts/LoggedInContext'

interface Props extends WithStyles<typeof tableStyles>, RouteComponentProps {
	intl: IntlShape,
	target: string
}

const TableAdoption: FunctionComponent<Props> = ({ classes, intl, history, target}) => {
	const { perimeter } = React.useContext<ReportingContextProps>(ReportingContext)
	const { setUser, setRegion, setBranch } = React.useContext<LoggedInContext>(LoggedInContext)
	const canClick = React.useMemo(() => target !== REPORTING_PAGES.DETAILED, [target])

	const onClick = React.useCallback((row: AdoptionData) => {
		if (row.entityId !== '-1') {
			if (perimeter === LEVEL.COUNTRY) {
				redirectToCountryTeamsReporting(history, row.entityId, setUser)
			} else if (perimeter === LEVEL.REGION) {
				selectRegionReporting(history, row.entityId, setRegion)
			} else if (perimeter === LEVEL.BRANCH) {
				selectBranchReporting(history, row.entityId, setBranch)
			}
		}
	}, [perimeter, history, setUser, setRegion, setBranch])

	const headers = React.useMemo(() => ([
		{
			key: target === REPORTING_PAGES.DETAILED ? 'month' : 'entity',
			name: intl.formatMessage({ id: `${target === REPORTING_PAGES.DETAILED ? 'pages.reporting.adoption.table.month' : `pages.reporting.level.${perimeter}`}` }),
			tableHeaderColumnProps: {
				classes: {
					root: classes.centerText
				}
			},
			render: (row: AdoptionData) => <div className={classNames(classes.centerText,
				target !== REPORTING_PAGES.DETAILED && (perimeter !== LEVEL.SALES_REP && perimeter !== LEVEL.USER) ? classes.pointer : '')}
			                                    onClick={canClick ? () => onClick(row) : undefined}>
					<Typography display="inline">
						{
							target === REPORTING_PAGES.DETAILED ?
								<FormattedMessage id={`pages.reporting.month.${row.month}`} /> :
								getEntityLibelle(perimeter, row.entity)
						}
					</Typography>
				</div>
		}, {
			key: 'nbConnexion',
				name: intl.formatMessage({ id: 'pages.reporting.adoption.table.nbConnexion' }),
			tableHeaderColumnProps: {
				classes: {
					root: classes.centerText
				}
			},
			render: (row: AdoptionData) => <div className={classes.centerText}>
				<Typography display="inline">
					{row.nbConnexion}
				</Typography>
			</div>
		}, {
			key: 'nbActionsOuvertes',
			name: intl.formatMessage({ id: 'pages.reporting.adoption.table.nbActionsOuvertes' }),
			tableHeaderColumnProps: {
				classes: {
					root: classes.centerText
				}
			},
			render: (row: AdoptionData) => <div className={classes.centerText}>
				<Typography display="inline">
					{row.nbActionsOuvertes}
				</Typography>
			</div>
		}, {
			key: 'nbChangementsActions',
			name: intl.formatMessage({ id: 'pages.reporting.adoption.table.nbChangementsActions' }),
			tableHeaderColumnProps: {
				classes: {
					root: classes.centerText
				}
			},
			render: (row: AdoptionData) => <div className={classes.centerText}>
				<Typography display="inline">
					{row.nbChangementsActions}
				</Typography>
			</div>
		}, {
			key: 'nbChangementsDate',
			name: intl.formatMessage({ id: 'pages.reporting.adoption.table.nbChangementsDate' }),
			tableHeaderColumnProps: {
				classes: {
					root: classes.centerText
				}
			},
			render: (row: AdoptionData) => <div className={classNames(classes.inline, row.nbChangementsDate >= reportingAdoptionThreshold ? classes.bad : '')}>
					<Typography display="inline">{row.nbChangementsDate}</Typography>
					{
						row.nbChangementsDate >= reportingAdoptionThreshold && (
							<Tooltip
								title={intl.formatMessage({ id: 'pages.reporting.adoption.tooltip' })}
								arrow
							>
								<div className={classes.icons}><ThumbDownAltOutlined /></div>
							</Tooltip>
						)
					}
				</div>
		}, {
			key: 'nbAjoutContrats',
			name: intl.formatMessage({ id: 'pages.reporting.adoption.table.nbAjoutContrats' }),
			tableHeaderColumnProps: {
				classes: {
					root: classes.centerText
				}
			},
			render: (row: AdoptionData) => <div className={classes.centerText}>
				<Typography display="inline">
					{row.nbAjoutContrats}
				</Typography>
			</div>
		}
	]), [classes.bad, classes.centerText, classes.inline, classes.icons, classes.pointer, intl, target, perimeter, onClick, canClick])

	return (
		<ReportingTable
			target={target}
			headers={headers}
			tab={REPORTING_URL.ADOPTION}
		/>
	)
}

export default compose(
	withStyles(tableStyles),
	injectIntl,
	withRouter
)(TableAdoption)