import { SvgIcon, Tooltip } from '@material-ui/core'
import React from 'react'
import HighRiskHighValueIcon from '../svg/HighRiskHighValueIcon'
import LowValueIcon from '../svg/LowValueIcon'
import ManuallyAddedIcon from '../svg/ManuallyAddedIcon'

export default ({ contractNegotiation, intl }) => {
	switch (contractNegotiation.classification) {
		case 'HIGH_RISK_HIGH_VALUE':
			return <Tooltip title={intl.formatMessage({ id: 'valueIcon.high' }, { month: contractNegotiation.month, year: contractNegotiation.year })}>
				<SvgIcon>
					<HighRiskHighValueIcon />
				</SvgIcon>
			</Tooltip>
		case 'LOW_VALUE':
			return <Tooltip title={intl.formatMessage({ id: 'valueIcon.low' }, { month: contractNegotiation.month, year: contractNegotiation.year })}>
				<SvgIcon>
					<LowValueIcon />
				</SvgIcon>
			</Tooltip>
		case 'MANUALLY_PRIORITIZED':
			return <Tooltip title={intl.formatMessage({ id: 'valueIcon.manually' })}>
				<SvgIcon>
					<ManuallyAddedIcon />
				</SvgIcon>
			</Tooltip>
		default:
			return null
	}
}
