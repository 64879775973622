import { Box, Grid } from '@material-ui/core'
import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import PageTitle from '../../components/PageTitle'
import Contracts from '../contracts/Contracts'
import ClosedContractsTableBox from './ClosedContractsTableBox'
import OpenActionsBox from './OpenActionsBox'
import PriorityContractsBox from './PriorityContractsBox'
import { AccessChecker, hasAnyProfile } from '../../utils/accessChecker'
import { PROFILE } from '../../enums'
import * as api from './services/salesPortfolioApi'
import { ContractsContext } from '../../contexts/ContractsContext'
import { TABLE_URL } from '../../components/TabBarDesktop'
import { setTableUrl } from '../contracts/services/contractsActions'
import { scrollToRef } from '../../utils/scrollToRef'
import { injectIntl, IntlShape } from 'react-intl'
import { compose } from 'recompose'
import { LoggedInContext } from '../../contexts/LoggedInContext'
import { getCountryModules } from './services/salesPortfolioApi'
import ContractsFollowUpBox from './ContractsFollowUpBox'

type PathParams = {
	id: string
}

type SalesData = {
	firstName: string,
	lastName: string
	regionId: string | null
	branchId: string | null
}

type Props = {
	intl: IntlShape
}

const SalesPortfolio: React.FunctionComponent<Props & RouteComponentProps<PathParams>> = ({ match, intl }) => {
	const [salesData, setSalesData] = React.useState<SalesData | undefined>(undefined)
	const salesRepId = match.params.id
	const { dispatch } = React.useContext(ContractsContext)
	const { user } = React.useContext(LoggedInContext);
	const [countryModules, setCountryModules] = React.useState({forecastModule: false, countryModule: null})

	React.useEffect(() => {
		dispatch(setTableUrl(TABLE_URL.PRIORITY))
		api.getSalesData(salesRepId).then((data) => {
			if (data) setSalesData(data)
		})
	}, [salesRepId, dispatch])

	React.useEffect(() => {
		if (user) {
			getCountryModules(user.countryId).then((result) => {
				setCountryModules(result)
			})
		}
	}, [user])

	const breadCrumbs = salesData ? [
		{
			label: intl.formatMessage({ id: 'pages.salePortfolioManager.breadcrumbs.dashboard' }),
			path: '/dashboard',
			data: { regionId: salesData.regionId, branchId: salesData.branchId }
		},
		{
			label: intl.formatMessage({ id: 'pages.salePortfolioManager.breadcrumbs.srOverview' }),
			path: '/sr-overview'
		},
		{
			label: intl.formatMessage({ id: 'pages.salePortfolioManager.breadcrumbs.srPortfolio' }, { firstName: salesData.firstName, lastName: salesData.lastName })
		}
	] : [{ label: intl.formatMessage({ id: 'pages.salePortfolioManager.breadcrumbs.portfolio' }) }]

	if (user && hasAnyProfile(user, [PROFILE.ZONE_MANAGER, PROFILE.NATIONAL]) && salesData && salesData.regionId) {
		breadCrumbs.unshift({
			label: intl.formatMessage({ id: 'pages.salePortfolioManager.breadcrumbs.dashboardRegional' }),
			path: '/dashboard',
			data: { regionId: salesData.regionId, branchId: null },
		})
	}

	const title = salesData
		? intl.formatMessage({ id: 'pages.salePortfolioManager.breadcrumbs.srPortfolio' }, { firstName: salesData.firstName, lastName: salesData.lastName })
		: intl.formatMessage({ id: 'pages.salePortfolioManager.title.portfolio' })

	const contractsRef = React.useRef(null)
	const scrollToContracts = () => scrollToRef(contractsRef)

	return (
		<AccessChecker profiles={[PROFILE.ZONE_MANAGER, PROFILE.NATIONAL, PROFILE.REGIONAL_MANAGER, PROFILE.BRANCH_MANAGER, PROFILE.BRANCH_MANAGER_ASSISTANT]}>
			<PageTitle title={title} breadcrumbs={breadCrumbs} topActionButtons={[]} />
			<Box>
				<Grid container spacing={2}>
					<Grid item xs={12} md={4}>
						<PriorityContractsBox salesRepId={salesRepId} scrollToContracts={() => scrollToContracts()} />
					</Grid>
					<Grid item xs={12} md={4}>
						{countryModules.forecastModule ? (
							<ContractsFollowUpBox salesRepId={match.params.id} />
						) : (
							<OpenActionsBox salesRepId={match.params.id} />
						)}
					</Grid>
					<Grid item xs={12} md={4}>
						<ClosedContractsTableBox salesRepId={salesRepId} />
					</Grid>
					<Grid item xs={12}>
						<div ref={contractsRef}>
							<Contracts salesRepId={salesRepId} modules={countryModules} />
						</div>
					</Grid>
				</Grid>
			</Box>
		</AccessChecker>
	)
}

export default compose(
	withRouter,
	injectIntl
)(SalesPortfolio)
