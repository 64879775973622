import {
	CLOSE_NEW_USER_DIALOG,
	CLOSE_USER_UPDATE_DIALOG,
	OPEN_NEW_USER_DIALOG,
	RESET_FILTERS,
	SET_FILTERS,
	SET_USER_FOR_UPDATE_DIALOG
} from './usersActions'

const defaultFilters = {
	searchQuery: '',
	profile: 'all',
	showDisabled: false
}

const initialState = {
	newUserDialog: false,
	userForUpdateDialog: null,
	filters: { ...defaultFilters }
}

function reducer(state, action) {
	switch (action.type) {
		case OPEN_NEW_USER_DIALOG:
			return {
				...state,
				newUserDialog: true
			}
		case CLOSE_NEW_USER_DIALOG:
			return {
				...state,
				newUserDialog: false
			}
		case CLOSE_USER_UPDATE_DIALOG:
			return {
				...state,
				userForUpdateDialog: null
			}
		case SET_USER_FOR_UPDATE_DIALOG:
			return {
				...state,
				userForUpdateDialog: action.user
			}
		case SET_FILTERS:
			return {
				...state,
				filters: { ...action.filters }
			}
		case RESET_FILTERS:
			return {
				...state,
				filters: { ...defaultFilters }
			}
		default:
			throw new Error()
	}
}

export { initialState, reducer }