import React, { FunctionComponent } from 'react'
import { Tooltip, Typography, WithStyles, withStyles } from '@material-ui/core'
import { compose } from 'recompose'
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl'
import {
	LEVEL,
	REPORTING_PAGES,
	REPORTING_URL,
	reportingPerformanceLowThreshold,
	reportingPerformanceUpThreshold
} from '../../../../constants'
import { ThumbDownAltOutlined, ThumbUpAltOutlined } from '@material-ui/icons'
import { shortenBigNumbers } from '../../../../utils/utils'
import { tableStyles } from '../../style'
import ReportingTable from '../../ReportingTable'
import { PerformanceData, ReportingContextProps } from '../../types'
import { RouteComponentProps, withRouter } from 'react-router'
import { ReportingContext } from '../../../../contexts/ReportingContext'
import {
	getEntityLibelle,
	redirectToCountryTeamsReporting,
	selectBranchReporting,
	selectRegionReporting
} from '../utils'
import { LoggedInContext } from '../../../../contexts/LoggedInContext'
import classNames from 'classnames'


interface Props extends WithStyles<typeof tableStyles>, RouteComponentProps {
	intl: IntlShape,
	target: string
}

const getToolTip = (titleId: string, intl : IntlShape) =>
	(...chunks : any) => <span title={intl.formatMessage({ id: titleId })}><>{chunks}</></span>

const TablePerformance: FunctionComponent<Props> = ({ classes, intl, history, target }) => {
	const { perimeter } = React.useContext<ReportingContextProps>(ReportingContext)
	const { user, setUser,setRegion, setBranch } = React.useContext<LoggedInContext>(LoggedInContext)
	const canClick = React.useMemo(() => target !== REPORTING_PAGES.DETAILED, [target])

	const onClick = React.useCallback((row: PerformanceData) => {
		if (row.entityId !== "-1") {
			if (perimeter === LEVEL.COUNTRY) {
				redirectToCountryTeamsReporting(history, row.entityId, setUser)
			} else if (perimeter === LEVEL.REGION) {
				selectRegionReporting(history, row.entityId, setRegion)
			} else if (perimeter === LEVEL.BRANCH) {
				selectBranchReporting(history, row.entityId, setBranch)
			}
		}
	}, [perimeter, history, setUser, setRegion, setBranch])

	const headers = React.useMemo(() => ([
		{
			key: target === REPORTING_PAGES.DETAILED ? 'month' : 'entity',
			name: intl.formatMessage({ id: `${target === REPORTING_PAGES.DETAILED ? 'pages.reporting.performance.table.month' : `pages.reporting.level.${perimeter}`}` }),
			tableHeaderColumnProps: {
				classes: {
					root: classes.centerText
				},
			},
			render: (row: PerformanceData) => <div className={classNames(classes.centerText,
				target !== REPORTING_PAGES.DETAILED && (perimeter !== LEVEL.SALES_REP && perimeter !== LEVEL.USER) ? classes.pointer : '')}
			                                       onClick={canClick ? () => onClick(row) : undefined}>
					<Typography display="inline">
						{
							target === REPORTING_PAGES.DETAILED ?
								<FormattedMessage id={`pages.reporting.month.${row.month}`} /> :
								getEntityLibelle(perimeter, row.entity)
						}
					</Typography>
				</div>
		},
		{
			key: 'savedContracts',
			name: intl.formatMessage({ id: 'pages.reporting.performance.table.savedContracts' },
				{ br: <br />, baliseToolTip: getToolTip('pages.reporting.performance.table.tooltip.savedContracts', intl) }),
			tableHeaderColumnProps: {
				classes: {
					root: classNames(classes.centerText, classes.pointer)
				}
			},
			render: (row: PerformanceData) => <div className={classes.centerText}>
				<Typography display="inline">
					{`${shortenBigNumbers(row.savedContractsUnits, user?.locale)} / ${shortenBigNumbers(row.savedContractsValue, user?.locale)}${row.currency}`}
				</Typography>
			</div>
		},
		{
			key: 'lostContracts',
			name: intl.formatMessage({ id: 'pages.reporting.performance.table.lostContracts' },
				{ br: <br />, baliseToolTip: getToolTip('pages.reporting.performance.table.tooltip.lostContracts', intl) }),
			tableHeaderColumnProps: {
				classes: {
					root: classNames(classes.centerText, classes.pointer)
				}
			},
			render: (row: PerformanceData) => <div className={classes.centerText}>
				<Typography display="inline">
					{`${shortenBigNumbers(row.lostContractsUnits, user?.locale)} / ${shortenBigNumbers(row.lostContractsValue, user?.locale)}${row.currency}`}
				</Typography>
			</div>
		},
		{
			key: 'savedContractsRate',
			name: intl.formatMessage({ id: 'pages.reporting.performance.table.savedContractsRate' },
				{ br: <br />, baliseToolTip: getToolTip('pages.reporting.performance.table.tooltip.savedContractsRate', intl) }),
			tableHeaderColumnProps: {
				classes: {
					root: classNames(classes.centerText, classes.pointer)
				}
			},
			render: (row: PerformanceData) => <div className={classes.inline}>
				<Typography display="inline">
					{`${row.savedContractsRateUnits}% / ${row.savedContractsRateValue}%`}
				</Typography>
				{
					((row.savedContractsRateUnits < reportingPerformanceLowThreshold) || (row.savedContractsRateUnits > reportingPerformanceUpThreshold)) && (
						<Tooltip
							title={intl.formatMessage({ id: `pages.reporting.performance.tooltip.${(row.savedContractsRateUnits < reportingPerformanceLowThreshold) ? 'bad' : 'good'}` })}
							arrow
						>
							<div className={classes.icons}>
								{(row.savedContractsRateUnits < reportingPerformanceLowThreshold) &&
                                    <div className={classes.bad}><ThumbDownAltOutlined /></div>}
								{(row.savedContractsRateUnits > reportingPerformanceUpThreshold) &&
                                    <div className={classes.good}><ThumbUpAltOutlined /></div>}
							</div>
						</Tooltip>
					)
				}
			</div>
		},
		{
			key: 'renegociatedContracts',
			name: intl.formatMessage({ id: 'pages.reporting.performance.table.renegociatedContracts' }, { br: <br /> }),
			tableHeaderColumnProps: {
				classes: {
					root: classes.centerText
				}
			},
			render: (row: PerformanceData) => <div className={classes.centerText}>
				<Typography display="inline">
					{`${shortenBigNumbers(row.renegociatedContractsUnits, user?.locale)} / ${shortenBigNumbers(row.renegociatedContractsValue, user?.locale)}${row.currency}`}
				</Typography>
			</div>
		},
		{
			key: 'renegociatedContractsRate',
			name: intl.formatMessage({ id: 'pages.reporting.performance.table.renegociatedContractsRate' },
				{ br: <br />, baliseToolTip: getToolTip('pages.reporting.performance.table.tooltip.renegociatedContractsRate', intl) }),
			tableHeaderColumnProps: {
				classes: {
					root: classNames(classes.centerText, classes.pointer)
				}
			},
			render: (row: PerformanceData) => <div className={classes.centerText}>
				<Typography display="inline">
					{`${row.renegociatedContractsRateUnits}% / ${row.renegociatedContractsRateValue}%`}
				</Typography>
			</div>
		}
	]), [classes.bad, classes.centerText, classes.good, classes.inline, classes.icons, classes.pointer, perimeter, intl, target, onClick, user, canClick])

	return (
		<ReportingTable
			target={target}
			headers={headers}
			tab={REPORTING_URL.PERFORMANCE}
		/>
	)
}

export default compose(
	withStyles(tableStyles),
	injectIntl,
	withRouter
)(TablePerformance)