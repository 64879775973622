import messagesIsotope from 'isotope-client/messages'

const getMessages = locale => {
	const filename = `messages_${locale}`
	return require(`./${filename}`).default
}

export const loadMessages = locale => ({
	...messagesIsotope,
	...getMessages(locale)
})
