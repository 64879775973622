const ROOT = 'ACTIONS'

export const SET_ACTIONS = `${ROOT}/SET_ACTIONS`
export const ADD_ACTION = `${ROOT}/ADD_ACTION`
export const UPDATE_ACTION = `${ROOT}/UPDATE_ACTION`
export const CLOSE_ACTION = `${ROOT}/CLOSE_ACTION`

export const addAction = action => ({
	type: ADD_ACTION,
	action
})

export const updateAction = action => ({
	type: UPDATE_ACTION,
	action
})

export const setActions = actions => ({
	type: SET_ACTIONS,
	actions
})

export const closeAction = (action, status) => ({
	type: CLOSE_ACTION,
	action,
	status
})
