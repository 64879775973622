import { Box, Breadcrumbs, Grid, Typography, withStyles } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import React, { useContext } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { RTVContext } from '../contexts/RTVContext'
import { LoggedInContext } from '../contexts/LoggedInContext'
import * as apiBranches from '../modules/branches/branchesApi'
import * as apiRegions from '../modules/regions/api'

const styles = (theme) => ({
	root: {
		display: 'flex',
		alignItems: 'flex-end',
		padding: '30px 0 0',
		marginBottom: '30px'
	},
	place: {
		fontWeight: 700,
		color: theme.palette.grey['500']
	},
	link: {
		fontWeight: 500,
		color: theme.palette.grey['500'],
		'&:hover': {
			textDecoration: 'underline'
		}
	},
	breadcrumb: {
		marginBottom: 5,
		color: theme.palette.grey['500']
	},
	titleMain: {
		position: 'relative',
		overflow: 'visible',
		'&::before': {
			content: '\'\'',
			position: 'absolute',
			height: 5,
			width: 100,
			backgroundColor: theme.palette.secondary.main,
			bottom: -20
		}
	}
})

const HeaderTitle = ({ classes, location }) => {
	const { pageTitle, topActionButtons, breadcrumbs } = useContext(RTVContext)
	const { setBranch, setRegion } = React.useContext(LoggedInContext)
	
	const handleClick = (item) => {
		if (item.data) {
			apiRegions.setRegion(item.data.regionId).then((response) => {
				setRegion(response)
				apiBranches.setBranch(item.data.branchId).then((response) => {
					setBranch(response)
				})
			})
		}
	}
	
	return (
		<Grid container className={classes.root}>
			<Box flexGrow={1} mb={3}>
				<Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumb}>
					{breadcrumbs.map((item, index) => {
							const last = index === breadcrumbs.length - 1
							const to = item.path

							return last ? (
								<Typography key={index} variant="h6" className={classes.place}>
									{item.label ? item.label : pageTitle}
								</Typography>
							) : (
								<Link to={to} key={index} onClick={() => handleClick(item)}>
									<Typography variant="h6" className={classes.link}>
										{item.label}
									</Typography>
								</Link>
							)
						}
					)}
				</Breadcrumbs>
				<Typography color="textSecondary" variant="h1" align="left" className={classes.titleMain}>
					{pageTitle}
				</Typography>
			</Box>
			{topActionButtons && topActionButtons.length ? (
				<Box display="flex">
					{topActionButtons.map((actionButton, i) =>
						<Box ml={{ xs: 0, md: '20px' }} mr={{ xs: '20px', md: 0 }} key={i}>
							{actionButton}
						</Box>
					)}
				</Box>
			) : null}
		</Grid>
	)
}

const enhance = compose(
	withStyles(styles),
	withRouter
)
export default enhance(HeaderTitle)
