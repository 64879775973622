import { Tooltip, withStyles } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar/Avatar'
import Box from '@material-ui/core/Box/Box'
import Typography from '@material-ui/core/Typography/Typography'
import { SpringDataTable } from 'isotope-client'
import React, { useCallback, useContext } from 'react'
import { withRouter } from 'react-router'
import NoResultFragment from '../../components/NoResult'
import { ContractsContext } from '../../contexts/ContractsContext'
import { MANUALLY_CLOSED_STATUS } from '../../enums'
import { setFilters, setTableUrl } from '../contracts/services/contractsActions'
import { defaultFiltersPriority } from '../contracts/services/contractsReducer'
import TablePaginationActions from '../datatable/TablePaginationActions'
import { SALES_REP_OVERVIEW_TABLE_NAME } from './SRConsts'
import { ErrorHandlerContext } from '../../contexts/ErrorHandlerContext'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'

const styles = theme => ({
	radius: {
		borderRadius: '5px'
	},
	tableHeader: {
		height: 80
	},
	tableHeaderCol: {
		borderRadius: '5px 5px 0 0',
		fontSize: '13px!important',
		fontWeight: '400!important',
		height: 80,
		padding: '32px 0px 0px 24px'
	},
	tableBodyCol: {
		fontSize: '16px!important',
		fontWeight: '400!important'
	},
	link: {
		color: theme.palette.primary.light,
		cursor: 'pointer'
	},
	avatar: {
		cursor: 'pointer'
	},
	caption: {
		color: theme.palette.grey['500']
	}
})

const DesktopSRTable = ({ classes, history, intl }) => {
	const { dispatch } = useContext(ContractsContext)
	const { setError } = React.useContext(ErrorHandlerContext)

	const colProps = {
		classes: {
			head: classes.tableHeaderCol,
			body: classes.tableBodyCol
		}
	}

	const goToPortfolio = useCallback((status, id) => {
		let filters = { ...defaultFiltersPriority }
		dispatch(setTableUrl('priority'))
		if (status) {
			dispatch(setFilters({ ...filters, status: [status] }))
		}
		history.push(`/portfolio/${id}`)
	}, [dispatch, history])

	const headers = [
		{
			key: 'lastName',
			name: intl.formatMessage({ id: 'pages.srOverview.table.headers.lastName' }),
			sortable: true,
			tableHeaderColumnProps: colProps,
			render: row => <Tooltip title={`${row.firstName} ${row.lastName}`}>
				<Avatar className={classes.avatar} onClick={() => goToPortfolio(null, row.id)}>{(row.firstName[0] + row.lastName[0]).toUpperCase()}</Avatar>
			</Tooltip>
		},
		{
			key: 'openContractsCount',
			name: intl.formatMessage({ id: 'pages.srOverview.table.headers.openContractsCount.title' }),
			sortable: true,
			tableHeaderColumnProps: colProps,
			render: row => <>
				<Typography>{`${row.openContractsCount}/${row.totalContractsCount} (${row.openContractsPercentage}%)`}</Typography>
				<Typography variant="caption" className={classes.caption}>{intl.formatMessage({ id: 'pages.srOverview.table.headers.openContractsCount.text' }, { nbContracts: row.contractsWithoutActions })}</Typography>
			</>
		},
		{
			key: 'openActionsCount',
			name: intl.formatMessage({ id: 'pages.srOverview.table.headers.openActionsCount.title' }),
			sortable: true,
			tableHeaderColumnProps: colProps,
			render: row => <>
				<Typography>{row.openActionsCount}</Typography>
				<Typography variant="caption" className={classes.caption}>{intl.formatMessage({ id: 'pages.srOverview.table.headers.openActionsCount.text' }, { nbActions: row.overdueActionsCount })}</Typography>
			</>
		},
		{
			key: 'overdueCount',
			name: intl.formatMessage({ id: 'pages.srOverview.table.headers.overdueCount' }),
			sortable: true,
			tableHeaderColumnProps: colProps,
			render: row => <Box alignItems="right"><Typography>{row.overdueCount}</Typography></Box>
		},
		{
			key: 'toBeCancelled',
			name: intl.formatMessage({ id: 'pages.srOverview.table.headers.toBeCancelled' }),
			sortable: true,
			tableHeaderColumnProps: colProps,
			render: row => <Typography className={classes.link}
			                           onClick={() => goToPortfolio(MANUALLY_CLOSED_STATUS.TO_BE_CANCELLED, row.id)}>{row.toBeCancelled}%</Typography>
		},
		{
			key: 'toBeAutoRenewed',
			name: intl.formatMessage({ id: 'pages.srOverview.table.headers.toBeAutoRenewed' }),
			sortable: true,
			tableHeaderColumnProps: colProps,
			render: row => <Typography className={classes.link}
			                           onClick={() => goToPortfolio(MANUALLY_CLOSED_STATUS.TO_BE_AUTO_RENEWED, row.id)}>{row.toBeAutoRenewed}%</Typography>
		},
		{
			key: 'renegotiated',
			name: intl.formatMessage({ id: 'pages.srOverview.table.headers.renegotiated' }),
			sortable: true,
			tableHeaderColumnProps: colProps,
			render: row => <Typography className={classes.link}
			                           onClick={() => goToPortfolio(MANUALLY_CLOSED_STATUS.RENEGOTIATED, row.id)}>{row.renegotiated}%</Typography>
		},
		{
			key: 'upgraded',
			name: intl.formatMessage({ id: 'pages.srOverview.table.headers.upgraded' }),
			sortable: true,
			tableHeaderColumnProps: colProps,
			render: row => <Typography className={classes.link}
			                           onClick={() => goToPortfolio(MANUALLY_CLOSED_STATUS.UPGRADED, row.id)}>{row.upgraded}%</Typography>
		},
		{
			key: 'downgraded',
			name: intl.formatMessage({ id: 'pages.srOverview.table.headers.downgraded' }),
			sortable: true,
			tableHeaderColumnProps: colProps,
			render: row => <Typography className={classes.link}
			                           onClick={() => goToPortfolio(MANUALLY_CLOSED_STATUS.DOWNGRADED, row.id)}>{row.downgraded}%</Typography>
		}
	]

	return (
		<SpringDataTable
			headers={headers}
			apiUrl="/sales-representatives/overview"
			apiUrlPrefix="/api"
			noResultFragment={<NoResultFragment message={intl.formatMessage({ id: 'pages.srOverview.table.noResult' })} />}
			tableHeaderProps={{ className: classes.tableHeader }}
			ActionsComponent={(props) => <TablePaginationActions {...props} />}
			nom={SALES_REP_OVERVIEW_TABLE_NAME}
			postFetch={(data, error) => error && setError(error)}
		/>
	)
}

export default compose(
	withStyles(styles),
	withRouter,
	injectIntl
)(DesktopSRTable)
