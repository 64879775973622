import { Collapse, createStyles, List, ListItem, ListItemText, makeStyles, Select, Theme } from '@material-ui/core'
import { ExpandLessRounded, ExpandMoreRounded } from '@material-ui/icons'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import { LoggedInContext } from '../../contexts/LoggedInContext'
import { PROFILE } from '../../enums'
import { hasAnyProfile } from '../../utils/accessChecker'
import ListItemSelector from './ListItemSelector'
import { Branch, Region } from './types'
import BranchSelector from '../branches/BranchSelector'
import * as apiBranches from '../branches/branchesApi'
import * as apiRegions from './api'

const useStyles = makeStyles(
	(theme: Theme) =>
		createStyles({
			selectRegion: {
				maxWidth: 300,
				width: '100%',
				textTransform: 'uppercase',
				fontSize: 13,
				color: theme.palette.grey['800'],
				fontWeight: 700,
				lineHeight: 0,
				[theme.breakpoints.down('sm')]: {
					backgroundColor: theme.palette.text.secondary,
				},
				'& [class*="MuiSelect-root"]': {
					paddingRight: 34,
				},
				'& [class*="MuiSelect-icon"]': {
					right: 10,
				},
			},
		}),
	{ name: 'RegionSelector' }
)

const MobileRegionSelector = ({ history }) => {
	const { user, setBranch, setRegion } = useContext(LoggedInContext)
	const [regionList, setRegionList] = useState<Region[]>([])
	const [loadBranches, setLoadBranches] = useState<boolean>(true)
	const classes = useStyles()

	const [open, setOpen] = useState<boolean>(false)
	const [openCollapse, setOpenCollapse] = useState<number | null>(null)

	useEffect(() => {
		if (user && hasAnyProfile(user, [PROFILE.ZONE_MANAGER, PROFILE.NATIONAL, PROFILE.REGIONAL_MANAGER])) {
			apiRegions.getRegions()
				.then((regions: Region[]) => {
					if (hasAnyProfile(user, [PROFILE.ZONE_MANAGER, PROFILE.NATIONAL])) {
						setRegionList([...regions, { id: null, name: 'National', idCountry: '', branches: [] }])
					} else {
						setRegionList(regions)
					}
				})
				.finally(() => setLoadBranches(false))
		} else {
			setLoadBranches(false)
		}
	}, [user])

	const handleSelect = useCallback(
		(value: any) => {
			const [region, branch] = value.split('-')
			setOpenCollapse(regionList.findIndex((elt: Region) => elt.id === region))
			setOpen(false)
			apiRegions.setRegion(region !== 'null' ? region : null)
				.then((response: any) => {
					setRegion(response)
					apiBranches.setBranch(branch !== 'null' ? branch : null).then((response: any) => {
						setBranch(response)
					})
				})
				.finally(() => history.push('/dashboard'))
		},
		[setRegion, setBranch, history, regionList]
	)

	const handleClose = () => {
		setOpenCollapse(regionList.findIndex((elt: Region) => elt.id === user?.regionId))
		setOpen(false)
	}

	if (loadBranches) {
		return null
	}
	if (regionList.length <= 1) {
		return <BranchSelector />
	}

	return (
		<Select
			IconComponent={ExpandMoreRounded}
			className={classes.selectRegion}
			disableUnderline
			value={`${user?.regionId}-${user?.branchId}` || null}
			displayEmpty
			MenuProps={{
				getContentAnchorEl: null,
				anchorOrigin: {
					vertical: 'bottom',
					horizontal: 'left',
				},
			}}
			onOpen={() => setOpen(true)}
			open={open}
			onClose={handleClose}
			renderValue={(_: unknown) => (user?.branchId ? user?.branchName : user?.regionId ? user?.regionName : 'National')}
		>
			{regionList.map((item: Region, index: number) =>
				item.branches.length > 0 ? (
					<>
						<ListItem
							button
							key={item.id}
							onClick={() => setOpenCollapse((prevValue) => (index === prevValue ? null : index))}
						>
							<ListItemText primary={item.name} />
							{openCollapse === index ? <ExpandLessRounded /> : <ExpandMoreRounded />}
						</ListItem>
						<Collapse key={item.id} in={openCollapse === index} unmountOnExit>
							<List component="div" disablePadding>
								<ListItemSelector
									key={item.id}
									value={`${item.id}-null`}
									style={{ marginLeft: 10 }}
									handleClick={() => handleSelect(`${item.id}-null`)}
									selected={user?.regionId === item.id && user?.branchId === null}
									text="Whole region"
								/>
								{item.branches.map((item2: Branch) => (
									<ListItemSelector
										key={item2.id}
										value={`${item.id}-${item2.id}`}
										style={{ marginLeft: 10 }}
										handleClick={() => handleSelect(`${item.id}-${item2.id}`)}
										selected={user?.regionId === item.id && user?.branchId === item2.id}
										text={item2.name}
									/>
								))}
							</List>
						</Collapse>
					</>
				) : (
					<ListItemSelector
						key={item.id}
						value={`${item.id}-null`}
						handleClick={() => handleSelect(`${item.id}-null`)}
						selected={user?.regionId === item.id && user?.branchId === null}
						text={item.name}
					/>
				)
			)}
		</Select>
	)
}

export default withRouter(MobileRegionSelector)
