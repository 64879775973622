import React, { FunctionComponent } from 'react'
import { Paper, WithStyles, withStyles } from '@material-ui/core'
import { filtersStyles } from '../style'
import { injectIntl, IntlShape } from 'react-intl'
import { compose } from 'recompose'
import ReportingFiltersForm from '../ReportingFiltersForm'
import { ReportingContextProps, ReportingFiltersProps } from '../types'
import { ReportingContext } from '../../../contexts/ReportingContext'
import { onChange } from './utils'

interface Props extends WithStyles<typeof filtersStyles> {
	intl: IntlShape
}

interface PerimeterFormError {
	level?: string,
	year?: string
}

const PerimeterFilters: FunctionComponent<Props> = ({ classes, intl }) => {
	const { levelOptions, yearOptions, perimeter, setFilters } = React.useContext<ReportingContextProps>(ReportingContext)

	const initialValues = React.useMemo(() => {
		const level = levelOptions.length > 0 ? levelOptions[0].value : ''
		if (yearOptions.length > 0) {
			return {
				level,
				year: yearOptions[0]
			}
		}
		return { level }
		}, [levelOptions, yearOptions])

	const validate = (values: ReportingFiltersProps) => {
		const errors: PerimeterFormError = {}
		if (!values.level) {
			errors.level = intl.formatMessage({ id: 'pages.contract.forecast.errors.required' })
		}
		if (values.year === 0) {
			errors.year = intl.formatMessage({ id: 'pages.contract.forecast.errors.required' })
		}
		return errors
	}

	return (
		<Paper className={classes.paper}>
			<ReportingFiltersForm
				validate={validate}
				onChange={onChange(setFilters)}
				initialValues={initialValues}
				levelOptions={levelOptions}
				yearOptions={yearOptions}
				showLevel
				showYear
				perimeter={perimeter}
			/>
		</Paper>
	)
}

const enhance = compose(
	withStyles(filtersStyles),
	injectIntl
)

export default enhance(PerimeterFilters)