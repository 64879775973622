import { Box, ClickAwayListener, Grid, List, ListItem, ListItemText, Paper, Popper } from '@material-ui/core'
import React, { useContext } from 'react'
import { ActionsFiltersContext } from '../contexts/ActionsFiltersContext'

const ActionFilter = ({ anchorEl, open, closeFilters, filterItems, fieldName }) => {
	const { filters, setFilters } = useContext(ActionsFiltersContext)

	return (
		<Popper
			anchorEl={anchorEl}
			placement="bottom-end"
			open={open}
			onClose={closeFilters}
			disablePortal={false}
			modifiers={{
				flip: {
					enabled: false,
				},
				preventOverflow: {
					enabled: false
				}
			}}
		>
			<ClickAwayListener onClickAway={closeFilters}>
				<Paper>
					<Box p={3}>
						<Grid container spacing={2}>
							<List>
								{Object.keys(filterItems).map(key =>
									<ListItem button key={key} onClick={() => {
										setFilters({ ...filters, [fieldName]: filterItems[key].key })
										closeFilters()
									}}>
										<ListItemText primary={filterItems[key].label} />
									</ListItem>
								)}
							</List>
						</Grid>
					</Box>
				</Paper>
			</ClickAwayListener>
		</Popper>
	)
}

export default (ActionFilter)