import { ACTION_TYPE } from '../../enums'

export const ACTIONS_STATUS = intl => ({
	all: {
		label: intl.formatMessage({ id: 'action.status.all' }),
		key: ''
	},
	OPEN: {
		label: intl.formatMessage({ id: 'action.status.open.label' }),
		chipLabel: intl.formatMessage({ id: 'action.status.open.chipLabel' }),
		key: 'OPEN'
	},
	CLOSED: {
		label: intl.formatMessage({ id: 'action.status.closed.label' }),
		chipLabel: intl.formatMessage({ id: 'action.status.closed.chipLabel' }),
		key: 'CLOSED'
	}
})

export const ACTION_TYPE_LABELS = intl => ({
	all: {
		label: intl.formatMessage({ id: 'action.type.all.label' }),
		defaultDescription: '',
		key: ''
	},
	[ACTION_TYPE.FIRST_CALL]: {
		label: intl.formatMessage({ id: `action.type.${ACTION_TYPE.FIRST_CALL}.label` }),
		defaultDescription: intl.formatMessage({ id: `action.type.${ACTION_TYPE.FIRST_CALL}.defaultDescription` }),
		key: ACTION_TYPE.FIRST_CALL
	},
	[ACTION_TYPE.CUSTOMER_MEETING]: {
		label: intl.formatMessage({ id: `action.type.${[ACTION_TYPE.CUSTOMER_MEETING]}.label` }),
		defaultDescription: intl.formatMessage({ id: `action.type.${[ACTION_TYPE.CUSTOMER_MEETING]}.defaultDescription` }),
		key: ACTION_TYPE.CUSTOMER_MEETING
	},
	[ACTION_TYPE.REPORT_TO_CUSTOMER]: {
		label: intl.formatMessage({ id: `action.type.${[ACTION_TYPE.REPORT_TO_CUSTOMER]}.label` }),
		defaultDescription: intl.formatMessage({ id: `action.type.${[ACTION_TYPE.REPORT_TO_CUSTOMER]}.defaultDescription` }),
		key: ACTION_TYPE.REPORT_TO_CUSTOMER
	},
	[ACTION_TYPE.PROACTIVE_PROPOSAL]: {
		label: intl.formatMessage({ id: `action.type.${[ACTION_TYPE.PROACTIVE_PROPOSAL]}.label` }),
		defaultDescription: intl.formatMessage({ id: `action.type.${[ACTION_TYPE.PROACTIVE_PROPOSAL]}.defaultDescription` }),
		key: ACTION_TYPE.PROACTIVE_PROPOSAL
	},
	[ACTION_TYPE.RENEGOTIATION]: {
		label: intl.formatMessage({ id: `action.type.${[ACTION_TYPE.RENEGOTIATION]}.label` }),
		defaultDescription: intl.formatMessage({ id: `action.type.${[ACTION_TYPE.RENEGOTIATION]}.defaultDescription` }),
		key: ACTION_TYPE.RENEGOTIATION
	},
	[ACTION_TYPE.DOWNSELL]: {
		label: intl.formatMessage({ id: `action.type.${[ACTION_TYPE.DOWNSELL]}.label` }),
		defaultDescription: intl.formatMessage({ id: `action.type.${[ACTION_TYPE.DOWNSELL]}.defaultDescription` }),
		key: ACTION_TYPE.DOWNSELL
	},
	[ACTION_TYPE.UPSELL]: {
		label: intl.formatMessage({ id: `action.type.${[ACTION_TYPE.UPSELL]}.label` }),
		defaultDescription: intl.formatMessage({ id: `action.type.${[ACTION_TYPE.UPSELL]}.defaultDescription` }),
		key: ACTION_TYPE.UPSELL
	},
	[ACTION_TYPE.SEND_MECHANIC_TO_JOBSITE]: {
		label: intl.formatMessage({ id: `action.type.${[ACTION_TYPE.SEND_MECHANIC_TO_JOBSITE]}.label` }),
		defaultDescription: intl.formatMessage({ id: `action.type.${[ACTION_TYPE.SEND_MECHANIC_TO_JOBSITE]}.defaultDescription` }),
		key: ACTION_TYPE.SEND_MECHANIC_TO_JOBSITE
	},
	[ACTION_TYPE.SEND_SUPERVISOR_TO_JOBSITE]: {
		label: intl.formatMessage({ id: `action.type.${[ACTION_TYPE.SEND_SUPERVISOR_TO_JOBSITE]}.label` }),
		defaultDescription: intl.formatMessage({ id: `action.type.${[ACTION_TYPE.SEND_SUPERVISOR_TO_JOBSITE]}.defaultDescription` }),
		key: ACTION_TYPE.SEND_SUPERVISOR_TO_JOBSITE
	},
	[ACTION_TYPE.CONTACT_SUPERVISOR_MECHANIC]: {
		label: intl.formatMessage({ id: `action.type.${[ACTION_TYPE.CONTACT_SUPERVISOR_MECHANIC]}.label` }),
		defaultDescription: intl.formatMessage({ id: `action.type.${[ACTION_TYPE.CONTACT_SUPERVISOR_MECHANIC]}.defaultDescription` }),
		key: ACTION_TYPE.CONTACT_SUPERVISOR_MECHANIC
	},
	[ACTION_TYPE.CUSTOMER_CLAIM]: {
		label: intl.formatMessage({ id: `action.type.${[ACTION_TYPE.CUSTOMER_CLAIM]}.label` }),
		defaultDescription: '',
		key: ACTION_TYPE.CUSTOMER_CLAIM
	},
	[ACTION_TYPE.CANCELLATION_REQUEST]: {
		label: intl.formatMessage({ id: `action.type.${[ACTION_TYPE.CANCELLATION_REQUEST]}.label` }),
		defaultDescription: '',
		key: ACTION_TYPE.CANCELLATION_REQUEST
	},
	[ACTION_TYPE.OTHER]: {
		label: intl.formatMessage({ id: `action.type.${[ACTION_TYPE.OTHER]}.label` }),
		defaultDescription: '',
		key: ACTION_TYPE.OTHER
	}
})