import * as React from 'react'
import { FunctionComponent } from 'react'
import { PROFILE } from '../enums'
import { LoggedInContext } from '../contexts/LoggedInContext'
import { Redirect } from 'react-router'

export type Authority = {
	authority: PROFILE
}

export type Branch = {
	id: string,
	name: string,
	idRegion: string
}

export type Region = {
	id: string
	name: string
	idCountry: string
}

export type User = {
	idOtis: string,
	username: string,
	lastname: string,
	firstname: string,
	email: string,
	authorities: Array<Authority>,
	enabled: boolean,
	locale: string,
	// TODO type
	preferences: Map<string, string>,
	branchId?: string,
	branchName?: string,
	regionId?: string,
	regionName?: string,
	countryId: string,
	countryName: string,
	countries: Array<string>,
	regions?: Array<string>
}

export const hasProfile = (user: User, profile: PROFILE): boolean => user?.authorities.some(authority => authority.authority === profile)

export const hasAnyProfile = (user: User, profiles: Array<PROFILE>): boolean => profiles.some(profile => hasProfile(user, profile))

interface Props {
	profiles: Array<PROFILE>
}

export const AccessChecker: FunctionComponent<Props> = ({ profiles, children }) => (
	<LoggedInContext.Consumer>
		{({ user }) => {
			if (typeof user !== 'undefined') {
				if (hasAnyProfile(user, profiles)) {
					return children
				}
				return <Redirect to="/" />
			}
			return null
		}}
	</LoggedInContext.Consumer>
)