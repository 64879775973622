const serializeFileList = function serializeFileList(form, key, fileList) {
	for (let i = 0; i < fileList.length; ++i) {
		form.append(key, fileList[i])
	}

	return form
}

export let serializeParts = function serializeParts(parts) {
	const form = new FormData()
	parts.forEach(function (part) {
		if (part.value) {
			if (part.value instanceof FileList) {
				serializeFileList(form, part.key, part.value)
			} else {
				var type = part.type || 'application/json'
				var value = type === 'application/json' ? JSON.stringify(part.value) : part.value
				form.append(
					part.key,
					new Blob([value], {
						type,
					}),
					part.key
				)
			}
		}
	})
	return form
}
