import React, { FunctionComponent } from 'react'
import { Grid, withStyles, WithStyles } from '@material-ui/core'
import { mobileTableStyle } from '../../style'
import { REPORTING_PAGES, REPORTING_URL, reportingAdoptionThreshold } from '../../../../constants'
import ReportingTable from '../../ReportingTable'
import MobileTableContent from '../../MobileTableContent'
import Typography from '@material-ui/core/Typography/Typography'
import { ThumbDownAltOutlined } from '@material-ui/icons'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import { AdoptionData, ReportingContextProps } from '../../types'
import { getEntityLibelle } from '../utils'
import { ReportingContext } from '../../../../contexts/ReportingContext'

interface Props extends WithStyles<typeof mobileTableStyle> {
	target: string
}

const MobileTableAdoption : FunctionComponent<Props> = ({ classes, target }) => {
	const { perimeter } = React.useContext<ReportingContextProps>(ReportingContext)

	const colProps = {
		classes: {
			head: classes.tableHeaderCol,
			body: classes.tableBodyCol
		}
	}

	const headers = React.useMemo(() => ([
		{
			key: 'adoptions',
			tableHeaderColumnProps: colProps,
			render: (row: AdoptionData) => <MobileTableContent
				title={<Typography display="inline">
					{
						target === REPORTING_PAGES.DETAILED ?
							<FormattedMessage id={`pages.reporting.month.${row.month}`} /> :
							getEntityLibelle(perimeter, row.entity)
					}
				</Typography>
				}
			>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<Typography><FormattedMessage id="pages.reporting.adoption.table.nbConnexion" /></Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography>{row.nbConnexion}</Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography><FormattedMessage id="pages.reporting.adoption.table.nbActionsOuvertes" /></Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography>{row.nbActionsOuvertes}</Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography><FormattedMessage id="pages.reporting.adoption.table.nbChangementsActions" /></Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography>{row.nbChangementsActions}</Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography><FormattedMessage id="pages.reporting.adoption.table.nbChangementsDate" /></Typography>
					</Grid>
					<Grid item xs={6}>
						<div className={classNames(classes.aligned, row.nbChangementsDate >= reportingAdoptionThreshold ? classes.bad : '')}>
							<Typography display="inline">{row.nbChangementsDate}</Typography>
							{
								row.nbChangementsDate >= reportingAdoptionThreshold && <div className={classes.icons}><ThumbDownAltOutlined /></div>
							}
						</div>
					</Grid>
					<Grid item xs={6}>
						<Typography><FormattedMessage id="pages.reporting.adoption.table.nbAjoutContrats" /></Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography>{row.nbAjoutContrats}</Typography>
					</Grid>
				</Grid>
			</MobileTableContent>
		}
	]), [classes.aligned, classes.icons, classes.bad, colProps, target, perimeter])

	return (
		<ReportingTable
			target={target}
			headers={headers}
			tab={REPORTING_URL.ADOPTION}
		/>
	)
}

export default withStyles(mobileTableStyle)(MobileTableAdoption)