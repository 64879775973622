import { Badge, withStyles } from '@material-ui/core'
import React from 'react'

const styles = () => ({
	badgeBasic: {
		'& [class*="MuiBadge-badge"]': {
			padding: '0 10px',
			fontSize: 12,
			maxHeight: 20,
			fontWeight: 700
		}
	}
})

const BadgeTab = ({ classes, badgeContent, badgeState }) => {
	return (
		<>
			<Badge badgeContent={badgeContent} className={classes.badgeBasic} color={badgeState} showZero />
		</>
	)
}

export default withStyles(styles)(BadgeTab)