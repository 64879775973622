import React, { Fragment } from 'react'
import { compose } from 'recompose'
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl'
import {
	Button,
	createStyles,
	DialogActions,
	DialogContent,
	DialogTitle,
	Theme,
	WithStyles,
	withStyles
} from '@material-ui/core'
import MatrixTable from '../../../components/MatrixTable'
import { Matrix, RenegotiationTipErrors, RenegotiationTipForm } from './type'
import { getRenegotiationMatrix, updateRenegotiationTip } from './api'
import { Field, Form } from 'react-final-form'
import FormInput from 'isotope-client/components/form/fields/FormInput'
import Typography from '@material-ui/core/Typography/Typography'

const styles = (theme: Theme) =>
	createStyles({
		error: {
			color: theme.palette.error.main,
		}
	})

const MAX_SCORE = 5
const scores = Array.from({ length : MAX_SCORE }, (x, i) => i + 1)

interface RenegatiationTableProps extends WithStyles<typeof styles> {
	intl: IntlShape
	countryId: string
}

const TIP_FIELD_NAME = 'tip'

const RenegotiationTable = ({ intl, classes, countryId }: RenegatiationTableProps) => {
	const [data, setData] = React.useState<Matrix[]>([])
	const [submitErrors, setSubmitErrors] = React.useState<RenegotiationTipErrors>({})

	React.useEffect(() => {
		getRenegotiationMatrix(countryId)
			.then(setData)
	}, [setData, countryId])

	const onSubmit = (values: RenegotiationTipForm, priceScore: number, riskScore: number, onClose: () => void) => {
		updateRenegotiationTip(countryId, priceScore, riskScore, values.tip)
			.then( (data: Matrix[]) => {
				setData(data)
				onClose()
			})
			.catch(e => setSubmitErrors({ tip: e.value.defaultMessage }))

	}

	const getEditionForm = (priceScore: number, riskScore: number, onClose: () => void) => {
		const dataElement = data.find(item => item.positionX === priceScore && item.positionY === riskScore)
		const value = dataElement === undefined? "" : dataElement.value
		return (
			<Fragment>
				<DialogTitle><FormattedMessage id="renegotiation.dialog.title" values={{risk: riskScore, price: priceScore}} /></DialogTitle>
				<Form
					initialValues={{tip: value}}
					onSubmit={(values: RenegotiationTipForm) => onSubmit(values, priceScore, riskScore, onClose)}
					render={({ handleSubmit }) => (
						<form onSubmit={handleSubmit}>
							<DialogContent>
								<Field<string>
									label={intl.formatMessage({ id: 'renegotiation.dialog.tip' })}
									name={TIP_FIELD_NAME}
									fullWidth
									component={FormInput}
									multiline
									rows={7}
									inputProps={{
										maxLength: 2048
									}}
								>
								</Field>
								{submitErrors[TIP_FIELD_NAME] && <Typography className={classes.error} variant="body1">{submitErrors[TIP_FIELD_NAME]}</Typography>}
							</DialogContent>
							<DialogActions>
								<Button onClick={onClose}><FormattedMessage id="renegotiation.dialog.cancel" /></Button>
								<Button type="submit" variant="contained" size="small" color="primary" disableElevation>
									<FormattedMessage id="renegotiation.dialog.update" />
								</Button>
							</DialogActions>
						</form>
					)}
				/>
			</Fragment>
		)
	}

	return (
		<MatrixTable
			yHeaderLabel={<FormattedMessage id="renegotiation.risk_score"/>}
			ySubHeaders={scores}
			xHeaderLabel={<FormattedMessage id="renegotiation.price_score"/>}
			xSubHeaders={scores}
			data={data}
			getDialogContent={getEditionForm}
			cellColors={[
				['#ededed', '#ededed', '#ededed', '#FFEDD5', '#FFEDD5'],
				['#ededed', '#ededed', '#ededed', '#FFEDD5', '#FFEDD5'],
				['#ededed', '#ededed', '#ededed', '#FFEDD5', '#FEE2E2'],
				['#F7FEE7', '#F7FEE7', '#F7FEE7', '#FEE2E2', '#fecaca'],
				['#FEF3C7', '#FEF3C7', '#FEF3C7', '#fecaca', '#fecaca']
			]}
		/>
	)
}

export default compose(injectIntl, withStyles(styles))(RenegotiationTable)