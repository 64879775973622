import React, { createContext, useState } from 'react'

type ErrorHandlerContext = {
	error?: Object,
	setError?: React.Dispatch<React.SetStateAction<{}>>
}

const ErrorHandlerContext = createContext<ErrorHandlerContext>({})

const ErrorHandlerContextProvider = ({ children }) => {
	const [error, setError] = useState({})

	return (
		<ErrorHandlerContext.Provider value={{ error, setError }}>
			{children}
		</ErrorHandlerContext.Provider>
	)
}

export { ErrorHandlerContext, ErrorHandlerContextProvider }