import React from 'react'
import SelectCountryNavbar from '../../layout/SelectCountryNavbar'
import Wrapper from '../../layout/Wrapper'
import TeamsReportingPage from '../teams/TeamsReportingPage'

const EmeaReporting = () => {
	return (
		<>
			<SelectCountryNavbar />
			<Wrapper>
				<TeamsReportingPage/>
			</Wrapper>
		</>
	)
}

export default EmeaReporting