import { Dialog, DialogContent, DialogTitle, withStyles, WithStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography/Typography'
import * as React from 'react'
import { ContractsContext } from '../../contexts/ContractsContext'
import { RouteComponentProps, withRouter } from 'react-router'
import { SpringDataTable } from 'isotope-client'
import NoResultFragment from '../../components/NoResult'
import TablePaginationActions from '../datatable/TablePaginationActions'
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl'
import { compose } from 'recompose'

const styles = () => ({
	tableCell: {
		fontWeight: 500,
		fontSize: 15
	},
	tableLink: {
		cursor: 'pointer'
	}
})

type CountData = {
	contractsCount?: number,
	salesRepCount?: number
}

type OtherSalesRepRow = {
	id?: string,
	firstName?: string,
	lastName?: string,
	contractsCount?: number
}

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
	open: boolean,
	onClose: any,
	intl: IntlShape
}

const OtherSalesRepDialog: React.FunctionComponent<Props> = ({ classes, open, onClose, history, intl }) => {
	const { contract } = React.useContext(ContractsContext)

	const headers = [
		{
			key: 'sales',
			name: intl.formatMessage({ id: 'pages.contractsOverview.otherSR.headers.sales' }),
			sortable: true,
			render: (row: OtherSalesRepRow) => <Typography className={classes.tableCell}>
				{row.firstName} {row.lastName}
			</Typography>
		},
		{
			key: 'contractsCount',
			name: intl.formatMessage({ id: 'pages.contractsOverview.otherSR.headers.contracts' }),
			sortable: true,
			render: (row: OtherSalesRepRow) => <Typography className={classes.tableCell}>
				{row.contractsCount}
			</Typography>
		}
	]

	return (
		<Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
			<DialogTitle><FormattedMessage id="pages.contractsOverview.otherSR.title" /></DialogTitle>
			<DialogContent>
				<SpringDataTable
					apiUrlPrefix="/api"
					apiUrl={`/contracts/${contract.id}/other-sales`}
					nom="otherSalesRep"
					headers={headers}
					noResultFragment={<NoResultFragment message={intl.formatMessage({ id: 'pages.contractsOverview.otherSR.noResult' })} />}
					ActionsComponent={(props) => <TablePaginationActions {...props} noPagination={false} />}
				/>
			</DialogContent>
		</Dialog>
	)
}

export default compose(
	withStyles(styles),
	withRouter,
	injectIntl
)(OtherSalesRepDialog)
