import { Box, Button, createStyles, Grid, isWidthDown, Paper, withStyles, withWidth } from '@material-ui/core'
import { ExpandMoreRounded } from '@material-ui/icons'
import React, { useContext, useRef, useState } from 'react'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import SearchBar from '../../components/SearchBar'
import { LoggedInContext } from '../../contexts/LoggedInContext'
import { FORECAST_PROBABILITY } from '../../enums'
import ForecastFilter from './ForecastFilter'
import ForecastsListDesktop from './ForecastsListDesktop'
import ForecastsListMobile from './ForecastsListMobile'

const styles = (theme: any) => {
	return createStyles({
		radiusWithTabBar: {
			borderRadius: '0 5px 5px 5px',
			'& > div:last-child': {
				borderRadius: '0 0 5px 5px',
			},
		},
		btnPrimary: {
			fontSize: 13,
			fontWeight: 700,
			color: theme.palette.grey['600'],
			minHeight: 50,
			padding: '6px 10px',
			letterSpacing: 0,
			textTransform: 'inherit',
			backgroundColor: theme.palette.text.secondary,
			border: '1px solid',
			borderColor: theme.palette.background.lightShadow,
			borderRadius: '4px',
			boxShadow: '0 2px 0 0 #E5EAF5',
			transition: 'all .2s ease',
			'&:hover': {
				backgroundColor: theme.palette.text.secondary,
				borderColor: theme.palette.grey['700'],
			},
		},
		cardTable: {
			display: 'flex',
			flexDirection: 'column',
			flex: '0 1 300px',
			width: '100%',
		},
		titleCardTable: {
			fontSize: 15,
			fontWeight: 700,
			textTransform: 'uppercase',
			color: theme.palette.grey['800'],
		},
		subCardTable: {
			fontSize: 12,
			fontWeight: 400,
			textTransform: 'uppercase',
			color: theme.palette.grey['500'],
		},
		avatar: {
			marginRight: 15,
		},
		iconTypology: {
			height: 26,
			width: 26,
			padding: 3,
			margin: '0 3px',
			borderRadius: 5,
			borderColor: theme.palette.background.lightShadow,
			border: '2px solid',
		},
		toolsTable: {
			display: 'flex',
			padding: 20,
		},
		flexEnd: {
			display: 'flex',
			justifyContent: 'flex-end',
		},
	})
}

const ForecastsTable = ({ classes, width, intl }) => {
	const { user } = useContext(LoggedInContext)
	const [filters, setFilters] = useState({ searchQuery: '', probability: '', cancellationMonth: '' })
	const [probabilityAnchorEl, setProbabilityAnchorEl] = useState<HTMLButtonElement | null>(null)
	const [cancellationMonthAnchorEl, setCancellationMonthAnchorEl] = useState<HTMLButtonElement | null>(null)
	const probabilityRef = useRef() as React.RefObject<HTMLButtonElement>
	const cancellationMonthRef = useRef() as React.RefObject<HTMLButtonElement>

	const probabilities = {
		all: {
			label: intl.formatMessage({ id: 'forecasts.probability.all.label' }),
			key: '',
		},
		[FORECAST_PROBABILITY.LOW]: {
			label: intl.formatMessage({ id: `forecasts.probability.${[FORECAST_PROBABILITY.LOW]}.label` }),
			key: FORECAST_PROBABILITY.LOW,
		},
		[FORECAST_PROBABILITY.HIGH]: {
			label: intl.formatMessage({ id: `forecasts.probability.${FORECAST_PROBABILITY.HIGH}.label` }),
			key: FORECAST_PROBABILITY.HIGH,
		},
	}

	const cancellationMonth = () => {
		const months: Record<string, Record<string, string>> = {
			all: {
				label: intl.formatMessage({ id: 'forecasts.cancellationMonth.all.label' }),
				key: '',
			},
			outdated: {
				label: intl.formatMessage({ id: 'forecasts.cancellationMonth.outdated.label' }),
				key: 'outdated',
			},
		}
		const currentDate = new Date()
		currentDate.setDate(1)
		for (let i = 1; i <= 12; i++) {
			currentDate.setMonth(currentDate.getMonth() + 1)
			months[currentDate.toLocaleDateString('en-CA')] = {
				label: currentDate.toLocaleString(user?.locale, { month: 'long', year: 'numeric' }).toUpperCase(),
				key: currentDate.toLocaleDateString('en-CA'),
			}
		}
		return months
	}

	return (
		<Paper className={classes.radiusWithTabBar} style={{ marginTop: 10 }}>
			<Grid container className={classes.toolsTable}>
				<Grid item xs={12} md={5} lg={7}>
					<Box display="flex" justifyContent="flex-start" flexGrow="1" mb={{ xs: '20px', md: 0 }}>
						<SearchBar
							placeholder={intl.formatMessage({ id: 'forecasts.page.list.searchBar' })}
							onChange={(e: any) => setFilters({ ...filters, searchQuery: e.target.value })}
							value={filters.searchQuery}
						/>
					</Box>
				</Grid>
				<Grid item xs={12} md={7} lg={5}>
					<Grid container spacing={2} className={classes.flexEnd}>
						<Grid item xs={isWidthDown('sm', width) ? 12 : undefined}>
							<Box display="flex" flexGrow={{ xs: 1, md: 0 }}>
								<Button
									ref={probabilityRef}
									fullWidth={isWidthDown('sm', width)}
									className={classes.btnPrimary}
									onClick={() => setProbabilityAnchorEl(probabilityRef.current)}
								>
									<Box pl={1} pr={1}>
										{filters.probability ? probabilities[filters.probability].label : probabilities['all'].label}
									</Box>
									<ExpandMoreRounded />
								</Button>
							</Box>
						</Grid>
						<Grid item xs={isWidthDown('sm', width) ? 12 : undefined}>
							<Box display="flex" flexGrow={{ xs: 1, md: 0 }}>
								<Button
									ref={cancellationMonthRef}
									fullWidth={isWidthDown('sm', width)}
									className={classes.btnPrimary}
									onClick={() => setCancellationMonthAnchorEl(cancellationMonthRef.current)}
								>
									<Box pl={1} pr={1}>
										{filters.cancellationMonth
											? cancellationMonth()[filters.cancellationMonth].label
											: cancellationMonth()['all'].label}
									</Box>
									<ExpandMoreRounded />
								</Button>
							</Box>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			{isWidthDown('sm', width) ? (
				<ForecastsListMobile filters={filters} />
			) : (
				<ForecastsListDesktop filters={filters} />
			)}
			<ForecastFilter
				open={!!probabilityAnchorEl}
				anchorEl={probabilityAnchorEl}
				closeFilters={() => setProbabilityAnchorEl(null)}
				filterItems={probabilities}
				fieldName="probability"
				setFilters={setFilters}
			/>
			<ForecastFilter
				open={!!cancellationMonthAnchorEl}
				anchorEl={cancellationMonthAnchorEl}
				closeFilters={() => setCancellationMonthAnchorEl(null)}
				filterItems={cancellationMonth()}
				fieldName="cancellationMonth"
				setFilters={setFilters}
			/>
		</Paper>
	)
}

export default compose(withStyles(styles), withWidth(), injectIntl)(ForecastsTable)
