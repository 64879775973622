import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Grid,
	SvgIcon,
	Tooltip,
	Typography,
	withStyles,
} from '@material-ui/core'
import { ChevronRight } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { compose } from 'recompose'
import GraphLegendChip from '../../svg/GraphLegendChip'
import SROpenActions from '../../svg/SROpenActions'
import * as api from './services/salesPortfolioApi'

const styles = (theme) => ({
	root: {
		height: '100%',
	},
	cardContent: {
		height: '100%',
	},
	link: {
		color: theme.palette.primary.light,
	},
	svgIcon: {
		fontSize: '8em',
	},
	grid: {
		textAlign: 'right',
		paddingBottom: theme.spacing(2),
		display: 'flex',
		justifyContent: 'space-around',
		alignItems: 'center',
	},
	legendPadding: {
		paddingLeft: theme.spacing(1),
	},
})

const OpenActionsBox = ({ salesRepId, classes, intl }) => {
	const [openActionsCounts, setOpenActionsCounts] = useState({ totalCount: 0, plannedCount: 0 })
	const [percentage, setPercentage] = useState(0)

	useEffect(() => {
		api.loadSROpenActionsCount(salesRepId).then((data) => {
			setOpenActionsCounts(data)
		})
		api.loadSRPlannedAgainstOverdueActions(salesRepId).then((data) => {
			setPercentage(data)
		})
	}, [salesRepId])

	return (
		<>
			<Card className={classes.root}>
				<CardHeader
					title={intl.formatMessage(
						{ id: 'pages.srPortfolio.openActions.title' },
						{ count: openActionsCounts.totalCount }
					)}
				/>
				<CardContent className={classes.cardContent}>
					<Box pt={5} pb={1}>
						<Grid container spacing={2} className={classes.grid}>
							<Grid item xs={6}>
								<SvgIcon className={classes.svgIcon}>
									<SROpenActions
										percent={percentage}
										plannedCount={openActionsCounts.plannedCount}
										overdueCount={openActionsCounts.totalCount - openActionsCounts.plannedCount}
									/>
								</SvgIcon>
							</Grid>
							<Grid item xs={6}>
								<Tooltip
									title={intl.formatMessage(
										{ id: 'pages.srPortfolio.openActions.plannedActions.tooltip' },
										{ count: openActionsCounts.plannedCount }
									)}
								>
									<Box display="flex" alignItems="center">
										<SvgIcon>
											<GraphLegendChip color="#34CB81" />
										</SvgIcon>
										<Typography variant="h5" className={classes.legendPadding}>
											<FormattedMessage id="pages.srPortfolio.openActions.plannedActions.title" />
										</Typography>
									</Box>
								</Tooltip>
								<Tooltip
									title={intl.formatMessage(
										{ id: 'pages.srPortfolio.openActions.overdueActions.tooltip' },
										{ count: openActionsCounts.totalCount - openActionsCounts.plannedCount }
									)}
								>
									<Box display="flex" alignItems="center">
										<SvgIcon>
											<GraphLegendChip color="#F65275" />
										</SvgIcon>
										<Typography variant="h5" className={classes.legendPadding}>
											<FormattedMessage id="pages.srPortfolio.openActions.overdueActions.title" />
										</Typography>
									</Box>
								</Tooltip>
							</Grid>
						</Grid>
					</Box>
					<Box pt={1} pb={1}>
						<Link to={`/actions${salesRepId ? `/${salesRepId}` : ''}`}>
							<Button variant="contained" size="large" color="primary">
								<FormattedMessage id="pages.srPortfolio.openActions.button" /> <ChevronRight />
							</Button>
						</Link>
					</Box>
				</CardContent>
			</Card>
		</>
	)
}

export default compose(withStyles(styles), injectIntl)(OpenActionsBox)
