import { Branch, User, Region } from '../../../utils/accessChecker'

export const SET_USER = 'LOGIN/SET_USER'
type SetUserAction = {
	type: typeof SET_USER,
	user: User
}
export const setUserAction: (user: User) => SetUserAction = (user) => ({
	type: SET_USER,
	user
})
export const UPDATE_USER = 'LOGIN/UPDATE_USER'
type UpdateUserAction = {
	type: typeof UPDATE_USER,
	user: Partial<User>
}
export const updateUserAction: (user: Partial<User>) => UpdateUserAction = (user) => ({
	type: UPDATE_USER,
	user
})

export const SET_BRANCH = 'USER/SET_BRANCH'
type SetBranchAction = {
	type: typeof SET_BRANCH,
	branch: Branch | null
}
export const setBranchAction: (branch: Branch | null) => SetBranchAction = (branch) => ({
	type: SET_BRANCH,
	branch
})

export const SET_REGION = 'USER/SET_REGION'
type SetRegionAction = {
	type: typeof SET_REGION,
	region: Region | null
}
export const setRegionAction: (region: Region | null) => SetRegionAction = (region) => ({
	type: SET_REGION,
	region
})

export type LoginAction = SetUserAction | SetBranchAction | UpdateUserAction | SetRegionAction