import React, { createContext, useContext, useEffect, useReducer } from 'react'
import { withRouter } from 'react-router'
import { setActions } from '../modules/actions/services/actionsActions'
import { initialState, reducer } from '../modules/actions/services/actionsReducer'
import * as api from '../modules/contracts/services/contractsApi'
import { ActionsFiltersContext } from './ActionsFiltersContext'

const ActionsContext = createContext({})

const ActionsContextProvider = withRouter(({ children, match, isActions }) => {
	const [state, dispatch] = useReducer(reducer, initialState)
	const { filters } = useContext(ActionsFiltersContext)

	useEffect(() => {
		if (!isActions) {
			api.loadContractActions(match.params.id, filters).then(data => dispatch(setActions(data)))
		}
	}, [match.params.id, filters, isActions])

	return (
		<ActionsContext.Provider value={{
			actions: state.actions,
			dispatch
		}}>
			{children}
		</ActionsContext.Provider>
	)
})

export { ActionsContext, ActionsContextProvider }