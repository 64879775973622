import React from 'react'
import { Select, FormControl, InputLabel, FormHelperText, createStyles, withStyles } from '@material-ui/core'

const styles = () => {
	return createStyles({
		root: {
			'& [class*="MuiInput-root"]': {
				width: 328,
				marginBottom: 33.6,
			},
			'& [class*="MuiSelect-root"]': {
				border: '1px solid',
				borderColor: 'rgba(0, 0, 0, 0.08)',
			},
		},
		formError: {
			color: '#EB1616',
			fontSize: 10,
			marginBottom: 21.6,
		},
		rootError: {
			'& [class*="MuiInput-root"]': {
				width: 328,
                marginBottom: -2.4
			},
			'& [class*="MuiSelect-root"]': {
				border: '1px solid',
				borderColor: '#EB1616',
			},
		},
	})
}

const MultiSelect = ({ classes, input: { value, name, onChange, ...restInput }, meta, label, ...rest }) => {
	const hasError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched

	return (
		<FormControl className={hasError ? classes.rootError : classes.root}>
			<InputLabel>{label}</InputLabel>
			<Select
				multiple
				displayEmpty
				disableUnderline
				{...rest}
				name={name}
				inputProps={restInput}
				error={meta.error && meta.touched}
				onChange={onChange}
				value={[...value]}
				className={classes.input}
			/>
			{hasError && (
				<FormHelperText className={classes.formError}>
					<span>{meta.error || meta.submitError}</span>
				</FormHelperText>
			)}
		</FormControl>
	)
}

export default withStyles(styles)(MultiSelect)
