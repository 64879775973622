import { Box, IconButton, withStyles } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle'
import React, { useCallback } from 'react'
import CloseRiskForm from './CloseRiskForm'
import * as api from './services/contractsApi'
import { Close as CloseIcon } from '@material-ui/icons'
import { setContractNegotiation } from './services/contractsActions'
import { FormattedMessage } from 'react-intl'

const styles = (theme) => {
	return ({
			tab: {
				display: 'flex',
				width: 'auto'
			}
		}
	)
}

const CloseRiskDialog = ({ contract, open, onClose, contractsDispatch }) => {

	const initialValues = {
		comment: '',
		outcome: 'TO_BE_AUTO_RENEWED'
	}

	const closeRisk = useCallback((data) => {
		api.closeRisk(contract.id, data).then(contractNegotiation => {
			contractsDispatch(setContractNegotiation(contractNegotiation))
			onClose()
		})
	}, [onClose, contract.id, contractsDispatch])

	return (
		<Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="sm">
			<DialogTitle>
				<Box display="flex" alignItems="center" justifyContent="space-between" flexGrow="1">
					<FormattedMessage id="pages.contract.risk.close.title" />
					<IconButton onClick={onClose}><CloseIcon /></IconButton>
				</Box>
			</DialogTitle>
			<CloseRiskForm initialValues={initialValues} onSubmit={closeRisk} onClose={onClose} />
		</Dialog>
	)
}

export default withStyles(styles)(CloseRiskDialog)
