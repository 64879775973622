import { AppBar, Avatar, Box, IconButton, Toolbar, Typography, withStyles } from '@material-ui/core'
import { SettingsOutlined as SettingsIcon } from '@material-ui/icons'
import React, { useContext, useState } from 'react'
import { LoggedInContext } from '../../contexts/LoggedInContext'
import LogoutMenu from './LogoutMenu'
import { FormattedMessage } from 'react-intl'
import { compose } from 'redux'
import { withRouter } from 'react-router'

const styles = (theme) => ({
	root: {
		boxShadow: '0px 0px 3px 6px rgba(60,68,91,.15)'
	},
	boxLeft: {
		cursor: 'pointer',
		display: 'flex',
		flexGrow: 1,
		alignItems: 'center',
		justifyContent: 'flex-start',
		'& > *': {
			marginRight: 15
		}
	},
	boxRight: {
		display: 'flex',
		flexGrow: 1,
		alignItems: 'center',
		justifyContent: 'flex-end',
		'& > *': {
			marginLeft: 15
		}
	},
	positionToggle: {
		position: 'relative',
		marginLeft: '-40px'
	},
	toggleNav: {
		color: theme.palette.secondary.main,
		width: 30,
		height: 30,
		left: 30
	},
	titleNavBar: {
		textTransform: 'uppercase',
		fontSize: 17,
		fontWeight: 700,
		color: theme.palette.text.primary
	},
	selectBranch: {
		maxWidth: 300,
		width: '100%',
		textTransform: 'uppercase',
		fontSize: 13,
		color: theme.palette.grey['800'],
		fontWeight: '700',
		lineHeight: 0,
		'& [class*="MuiSelect-icon"]': {
			right: 10
		}
	},
	searchBar: {
		padding: '0 15px',
		maxWidth: 360,
		width: '100%',
		opacity: 1,
		color: theme.palette.grey['800'],
		backgroundColor: theme.palette.grey['50'],
		'& [class*="MuiSelect-icon"]': {
			right: 10
		}

	},
	btnPrimary: {
		color: theme.palette.grey['600'],
		backgroundColor: theme.palette.text.secondary,
		border: '1px solid',
		borderColor: theme.palette.background.lightShadow,
		borderRadius: '4px',
		boxShadow: '0 2px 0 0 #E5EAF5',
		transition: 'all .2s ease',
		'&:hover': {
			backgroundColor: theme.palette.text.secondary,
			borderColor: theme.palette.grey['700']
		}
	},
	pinBadge: {
		'& [class*="MuiBadge-badge"]': {
			position: 'absolute',
			top: '-10px',
			right: '-10px',
			backgroundColor: theme.palette.secondary.light,
			'&:after': {
				content: '\'\'',
				position: 'absolute',
				top: 6,
				zIndex: 1000,
				display: 'block',
				height: 8,
				width: 8,
				borderRadius: 8,
				backgroundColor: theme.palette.secondary.main
			}
		}
	},
	avatarPointer: {
		cursor: 'pointer'
	}
})

const SelectCountryNavbar = ({ classes, history }) => {
	const { user } = useContext(LoggedInContext)
	const [logoutMenuOpen, setLogoutMenuOpen] = useState(null)

	return <AppBar className={classes.root}>
		<Toolbar>
			<Box className={classes.boxLeft} ml={4} onClick={() => history.push('/select-country')}>
				<Typography className={classes.titleNavBar}><FormattedMessage id="title" /></Typography>
			</Box>
			<Box className={classes.boxRight}>
				<IconButton className={classes.btnPrimary}
							onClick={() => history.push('/bo/users')}><SettingsIcon /></IconButton>
				<Avatar onClick={(e) => setLogoutMenuOpen(e.currentTarget)}
						className={classes.avatarPointer}>{(user.firstname[0] + user.lastname[0]).toUpperCase()}</Avatar>
			</Box>
			<LogoutMenu
				anchorEl={logoutMenuOpen}
				onClose={() => setLogoutMenuOpen(null)}
			/>
		</Toolbar>
	</AppBar>
}

export default compose(
	withStyles(styles),
	withRouter
)(SelectCountryNavbar)

