import { Box, ClickAwayListener, Grid, List, ListItem, ListItemText, Paper, Popper } from '@material-ui/core'
import React from 'react'

const ForecastFilter = ({ anchorEl, open, closeFilters, filterItems, fieldName, setFilters }) => {
	return (
		<Popper
			anchorEl={anchorEl}
			placement="bottom-end"
			open={open}
			disablePortal={false}
			modifiers={{
				flip: {
					enabled: false,
				},
				preventOverflow: {
					enabled: false,
				},
			}}
		>
			<ClickAwayListener onClickAway={closeFilters}>
				<Paper>
					<Box p={3}>
						<Grid container spacing={2}>
							<List>
								{Object.keys(filterItems).map((key) => (
									<ListItem
										button
										key={key}
										onClick={() => {
											setFilters((oldValue: any) => {
												return { ...oldValue, [fieldName]: filterItems[key].key }
											})
											closeFilters()
										}}
									>
										<ListItemText primary={filterItems[key].label} />
									</ListItem>
								))}
							</List>
						</Grid>
					</Box>
				</Paper>
			</ClickAwayListener>
		</Popper>
	)
}

export default ForecastFilter
