import React, { FunctionComponent } from 'react'
import { isWidthDown, Paper, WithStyles, withStyles, withWidth, WithWidth } from '@material-ui/core'
import { tabBarStyles } from '../../style'
import classNames from 'classnames'
import { injectIntl, IntlShape } from 'react-intl'
import { compose } from 'recompose'
import ReportingFiltersForm from '../../ReportingFiltersForm'
import { onChange, validate } from '../../details/utils'
import BarChart from '../BarChart'
import { getComparativeAdoptionChartData } from '../../api'
import { REPORTING_PAGES } from '../../../../constants'
import { ReportingContextProps } from '../../types'
import { ReportingContext } from '../../../../contexts/ReportingContext'
import MobileTableAdoption from '../../details/adoption/MobileAdoptionTable'
import TableAdoption from '../../details/adoption/TableAdoption'

interface Props extends WithStyles<typeof tabBarStyles>, WithWidth {
	intl: IntlShape
}

const ComparativeAdoption: FunctionComponent<Props> = ({classes, width, intl}) => {
	const { yearOptions, setFilters } = React.useContext<ReportingContextProps>(ReportingContext)

	const initialValues = React.useMemo(() => {
		if (yearOptions.length > 0) {
			return { year: yearOptions[0] }
		}
		return {}
	}, [yearOptions])

	return (
		<Paper
			className={classNames(classes.radiusWithTabBar, isWidthDown('sm', width) ? classes.tabContentMobile : classes.tabContentDesktop)}>
			<div className={classes.pad}>
				<ReportingFiltersForm
					validate={validate(intl)}
					onChange={onChange(setFilters)}
					initialValues={initialValues}
					yearOptions={yearOptions}
					showYear
					alignRight
					showLabels={false}
				/>
			</div>
			<BarChart
				title={intl.formatMessage({ id: 'pages.reporting.teams.graph.adoption.title' })}
				legend={intl.formatMessage({ id: 'pages.reporting.teams.graph.adoption.legend' })}
				getData={getComparativeAdoptionChartData}
			/>
			<div className={classes.table}>
				{
					isWidthDown('sm', width) ? <MobileTableAdoption target={REPORTING_PAGES.TEAMS} /> : (
						<TableAdoption target={REPORTING_PAGES.TEAMS}/>
					)
				}
			</div>
		</Paper>
		)
}

const enhance = compose(
	withWidth(),
	withStyles(tabBarStyles),
	injectIntl
)

export default enhance(ComparativeAdoption)