import { Box, withStyles } from '@material-ui/core'
import React from 'react'
import { withRouter } from 'react-router'
import PageTitle from '../../components/PageTitle'
import { ActionsContextProvider } from '../../contexts/ActionsContext'
import { ActionsFiltersContextProvider } from '../../contexts/ActionsFiltersContext'
import { LoggedInContext } from '../../contexts/LoggedInContext'
import { PROFILE } from '../../enums'
import { hasAnyProfile } from '../../utils/accessChecker'
import * as api from '../salesPortfolio/services/salesPortfolioApi'
import ActionsTable from './ActionsTable'
import { compose } from 'recompose'
import { injectIntl } from 'react-intl'

const styles = (theme) => {
	return ({
			tab: {
				display: 'flex',
				width: 'auto'
			}
		}
	)
}

const Actions = ({ classes, match, intl }) => {
	const { user } = React.useContext(LoggedInContext)
	const [salesData, setSalesData] = React.useState(null)
	const salesRepId = match.params.id

	React.useEffect(() => {
		if (salesRepId) {
			api.getSalesData(salesRepId).then((data) => {
				if (data) setSalesData(data)
			})
		} else {
			setSalesData(null)
		}
	}, [salesRepId])

	const breadcrumbs = salesData && hasAnyProfile(user, [PROFILE.ZONE_MANAGER, PROFILE.NATIONAL, PROFILE.REGIONAL_MANAGER, PROFILE.BRANCH_MANAGER, PROFILE.BRANCH_MANAGER_ASSISTANT]) ?
		[
			{
				path: '/dashboard',
				label: intl.formatMessage({ id: 'action.page.breadcrumbs.dashboard' })
			},
			{
				path: '/sr-overview',
				label: intl.formatMessage({ id: 'action.page.breadcrumbs.srOverview' })
			},
			{
				path: `/portfolio/${salesRepId}`,
				label: intl.formatMessage({ id: 'action.page.breadcrumbs.repPortfolio' }, { firstName: salesData.firstName, lastName: salesData.lastName })
			},
			{
				label: intl.formatMessage({ id: 'action.page.breadcrumbs.actionsOverview' })
			}
		]
		:
		[
			{
				path: '/portfolio',
				label: intl.formatMessage({ id: 'action.page.breadcrumbs.myPortfolio' })
			},
			{
				label: intl.formatMessage({ id: 'action.page.breadcrumbs.actionsOverview' })
			}
		]

	const title = salesData ? intl.formatMessage({ id: 'action.page.title' }, { firstName: salesData.firstName, lastName: salesData.lastName })
		: intl.formatMessage({ id: 'action.page.defaultTitle' })

	return (
		<>
			<PageTitle title={title} breadcrumbs={breadcrumbs} />
			<Box className={classes.root}>
				<ActionsFiltersContextProvider isActions>
					<ActionsContextProvider isActions>
						<ActionsTable />
					</ActionsContextProvider>
				</ActionsFiltersContextProvider>
			</Box>
		</>
	)
}

export default compose(
	withRouter,
	withStyles(styles),
	injectIntl
)(Actions)