import React, { FunctionComponent } from 'react'
import BarChart from '../../../reporting/teams/BarChart'
import { injectIntl, IntlShape } from 'react-intl'
import { compose } from 'recompose'
import { dataTableValueSelectors } from 'isotope-client'
import { connect } from 'react-redux'
import { ActivityData } from '../../types'

interface Props {
	intl: IntlShape,
	data?: ActivityData[]
}

const COMPARATIVE_ACTIVITY_TABLE_NAME = 'reportingDataTableactivity'

const formatDataForChart = (data: ActivityData[]) => {
	return data.filter(d => d.entityId !== "-1")
		.map(d => ({
		entity: d.entity,
		value: d.contractsWithOverdueActionsRate
	}))
}

const ComparativeActivityChart: FunctionComponent<Props> = ({ intl, data }) => {
	if (!data) {
		return <></>
	}

	return <BarChart
		title={intl.formatMessage({ id: 'pages.reporting.teams.graph.activity.title' })}
		legend={intl.formatMessage({ id: 'pages.reporting.teams.graph.activity.legend' })}
		getData={() => Promise.resolve(formatDataForChart(data))}
	/>
}

const mapStateToProps = (state) => ({
	data: dataTableValueSelectors.getDataTableData(state)(COMPARATIVE_ACTIVITY_TABLE_NAME)
})

const enhance = compose(
	connect(mapStateToProps),
	injectIntl
)

export default enhance(ComparativeActivityChart)
