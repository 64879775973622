import { useContext, useEffect } from 'react'
import { RTVContext } from '../contexts/RTVContext'

export default ({ title, topActionButtons, breadcrumbs }) => {
	const { setGlobalPageTitle, setTopActionButtons, setBreadcrumbs } = useContext(RTVContext)
	useEffect(() => {
		setGlobalPageTitle(title)
		setTopActionButtons(topActionButtons)
		setBreadcrumbs(breadcrumbs)
	}, [title, topActionButtons, breadcrumbs, setGlobalPageTitle, setTopActionButtons, setBreadcrumbs])

	return null
}

