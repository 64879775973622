import React, { FunctionComponent } from 'react'
import { Box } from '@material-ui/core'

interface TabPanelProps {
	children?: React.ReactNode;
	name: any;
	value: any;
}

const TabPanel: FunctionComponent<TabPanelProps> = ({ children, name, value }) => {
	return (
		<div
			role="tabpanel"
			hidden={value !== name}
			id={`simple-tabpanel-${name}`}
			aria-labelledby={`simple-tab-${name}`}
		>
			{value === name && (<Box>{children}</Box>)}
		</div>
	)
}

export default TabPanel