import * as H from 'history'
import { getRenegotiationTip as getRenegotiationTipApi } from '../modules/contracts/services/contractsApi'
import indicatorRound from './indicatorRound'
import { IntlShape } from 'react-intl'
import { Matrix } from '../modules/backOffice/renegotiation/type'
import { OUTLIER } from '../constants'

export const redirectToRightPage = (history : H.History<H.LocationState>) => {
	if (window.location.pathname.includes('teams')) {
		history.push('/reporting/teams')
	} else if (window.location.pathname.includes('detailed')) {
		history.push('/reporting/detailed')
	} else {
		history.push('/dashboard')
	}
}

export const shortenBigNumbers = (number: number, locale : string | undefined) : string => {
	if (number > 1000) {
		return `${Math.round(number / 1000).toLocaleString(locale)} K`;
	}
	return number.toString()
}

export const getRenegotiationTip = async (countryId: string, contractPriceScore: string, contractRiskScore: number,
                                          intl: IntlShape) => {
	if (contractPriceScore === null) return intl.formatMessage({ id: 'pages.contract.information.headers.renegotiationTip.noTip' })
	else if (contractPriceScore === OUTLIER) return intl.formatMessage({ id: 'pages.contract.information.headers.renegotiationTip.outlier' })
	else {
		const res = await getRenegotiationTipApi(countryId, contractPriceScore, indicatorRound(contractRiskScore))
		return res.value
	}
}

export const getRenegotiationTipFromMatrix = (contractPriceScore: string, contractRiskScore: number, renegotiationMatrix: Matrix[], intl: IntlShape) => {
	if (contractPriceScore === null) return intl.formatMessage({ id: 'pages.contract.information.headers.renegotiationTip.noTip' })
	else if (contractPriceScore === OUTLIER) return intl.formatMessage({ id: 'pages.contract.information.headers.renegotiationTip.outlier' })
	else {
		const tip =  renegotiationMatrix.find(tip => {
			return tip.positionX === parseInt(contractPriceScore) && tip.positionY === indicatorRound(contractRiskScore)
		})
		return tip ? tip.value : ''
	}
}