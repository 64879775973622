import Dialog from '@material-ui/core/Dialog/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle'
import React, { useContext } from 'react'
import { UsersContext } from '../../../../contexts/UsersContext'
import { refreshUserList } from '../services/usersActions'
import * as api from '../services/usersApi'
import UserForm from './UserForm'
import { PROFILE } from '../../../../enums'
import { FormattedMessage, injectIntl } from 'react-intl'

const NewUserDialog = ({ open, onClose, intl }) => {
	const { tableDispatch } = useContext(UsersContext)
	const [error, setError] = React.useState(null)

	const initialValues = {
		lastName: '',
		firstName: '',
		email: '',
		profile: PROFILE.NATIONAL
	}

	const createUser = (data, formApi, callback) => api.createUser(data).then(() => {
		setError(null)
		refreshUserList(tableDispatch)
		onClose()
	}).catch((e) => {
		setError(e.bodyError.id)
		callback({
			email: e.email ? e.email.id : null,
			id: e.id ? e.id.id : null
		})
	})

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
			<DialogTitle><FormattedMessage id="user.new.title" /></DialogTitle>
			<UserForm initialValues={initialValues} onSubmit={createUser} onClose={onClose} validateButtonLabel={intl.formatMessage({ id: 'user.new.buttonLabel' })} error={error} />
		</Dialog>
	)
}

export default injectIntl(NewUserDialog)
