import { Box, Button, Typography, withStyles } from '@material-ui/core'
import React, { useContext, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { LoggedInContext } from '../../contexts/LoggedInContext'
import { PROFILE } from '../../enums'
import { UsersComment } from '../../interfaces'
import { hasProfile } from '../../utils/accessChecker'
import ContractCommentsDialog from './ContractCommentsDialog'

interface ContractCommentsProps {
	classes: Record<string, any>
	managers: UsersComment
	salesRep: UsersComment
	dispatch?: any
	contract?: any
	update?: boolean
}

const styles = () => ({
	comments: {
		display: 'flex',
		justifyContent: 'space-between',
		'& [class*="MuiTypography-h5"]': {
			fontSize: 15,
			marginBottom: 20,
		},
		'& [class*="MuiTypography-subtitle2"]': {
			color: '#687BAE',
			fontSize: 13,
			marginBottom: 10,
			fontWeight: 400,
		},
		'& [class*="MuiTypography-body1"]': {
			fontSize: 15,
			marginBottom: 10,
		},
		'& [class*="MuiBox-root"]': {
			width: '49%',
		},
	},
	button: {
		marginTop: 20,
	},
})

const ContractComments: React.FunctionComponent<ContractCommentsProps> = ({
	classes,
	managers,
	salesRep,
	dispatch,
	contract,
	update,
}) => {
	const [updateDialog, setUpdateDialog] = useState(false)
	const { user } = useContext(LoggedInContext)

	const initialValues = {
		comment: user && !hasProfile(user, PROFILE.SALES_REPRESENTATIVE) ? managers.text : salesRep.text,
	}

	return (
		<>
			<Box className={classes.comments}>
				<Box>
					<Typography variant="h5">
						<FormattedMessage id="pages.contract.information.tabs.detailsTab.comments.managersTitle" />
					</Typography>
					<Typography variant="subtitle2">
						{managers.date ? (
							<FormattedMessage
								id="pages.contract.information.tabs.detailsTab.comments.lastModified"
								values={{
									date: new Date(managers.date).toLocaleDateString(),
									firstName: managers.firstname,
									lastName: managers.lastname,
								}}
							/>
						) : (
							<FormattedMessage id="pages.contract.information.tabs.detailsTab.comments.empty" />
						)}
					</Typography>
					<Typography variant="body1">{managers.text}</Typography>
					{user && !hasProfile(user, PROFILE.SALES_REPRESENTATIVE) && (update !== undefined ? update : true) && (
						<Button
							variant="contained"
							size="small"
							color="primary"
							disableElevation
							onClick={() => setUpdateDialog(true)}
							className={classes.button}
						>
							<FormattedMessage id="pages.contract.information.tabs.detailsTab.comments.updateComment" />
						</Button>
					)}
				</Box>
				<Box>
					<Typography variant="h5">
						<FormattedMessage id="pages.contract.information.tabs.detailsTab.comments.srTitle" />
					</Typography>
					<Typography variant="subtitle2">
						{salesRep.date ? (
							<FormattedMessage
								id="pages.contract.information.tabs.detailsTab.comments.lastModified"
								values={{
									date: new Date(salesRep.date).toLocaleDateString(),
									firstName: salesRep.firstname,
									lastName: salesRep.lastname,
								}}
							/>
						) : (
							<FormattedMessage id="pages.contract.information.tabs.detailsTab.comments.empty" />
						)}
					</Typography>
					<Typography variant="body1">{salesRep.text}</Typography>
					{user && hasProfile(user, PROFILE.SALES_REPRESENTATIVE) && (update !== undefined ? update : true) && (
						<Button
							variant="contained"
							size="small"
							color="primary"
							disableElevation
							onClick={() => setUpdateDialog(true)}
							className={classes.button}
						>
							<FormattedMessage id="pages.contract.information.tabs.detailsTab.comments.updateComment" />
						</Button>
					)}
				</Box>
			</Box>
			{(update !== undefined ? update : true) && (
				<ContractCommentsDialog
					open={updateDialog}
					setOpen={setUpdateDialog}
					initialValues={initialValues}
					contract={contract}
					dispatch={dispatch}
				/>
			)}
		</>
	)
}

export default withStyles(styles)(ContractComments)
