import React, { FunctionComponent, useState } from 'react'
import { DrawerMenuItemProps } from './types'
import classNames from 'classnames'
import {
	ListItem,
	ListItemIcon,
	ListItemText,
	Collapse,
	List,
	Theme,
	createStyles,
	withStyles
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import { drawerMenuItemStyles } from './styles'

const styles = (theme : Theme) => createStyles({
	subItemText: {
		...drawerMenuItemStyles(theme).itemText,
		paddingLeft: '60px'
	},
	drawerSubButton: {
		...drawerMenuItemStyles(theme).drawerButton,
		minHeight: '40px'
	},
	...drawerMenuItemStyles(theme)
})

const MultiLevelMobile: FunctionComponent<DrawerMenuItemProps> = ({ item, isCurrent, classes}) => {
	const children = item.children
	const [open, setOpen] = useState<boolean>(false)

	const onClick = () => setOpen(prev => !prev)

	return <>
		<ListItem button onClick={onClick} className={classNames(classes.drawerButton, {
			[classes.drawerButtonActive]: isCurrent
		})}>
			<ListItemIcon className={classes.drawerIcon}>{item.icon}</ListItemIcon>
			<ListItemText primary={item.label} className={classes.itemText} disableTypography />
		</ListItem>
		<Collapse in={open} timeout="auto" unmountOnExit>
			<List component="div" disablePadding>
				{
					children.map((child, index) => (
						<Link to={child.path} key={index}>
							<ListItem button className={classNames(classes.drawerSubButton, {
								[classes.drawerButtonActive]: isCurrent
							})}>
								<ListItemText primary={child.label} className={classes.subItemText} disableTypography/>
							</ListItem>
						</Link>
					))
				}
			</List>
		</Collapse>
	</>
}

export default withStyles(styles)(MultiLevelMobile)