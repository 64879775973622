import React, { FunctionComponent } from 'react'
import { Tooltip, Typography, withStyles, WithStyles } from '@material-ui/core'
import { injectIntl, IntlShape } from 'react-intl'
import { compose } from 'recompose'
import { tableStyles } from '../../style'
import {
	LEVEL,
	REPORTING_PAGES,
	REPORTING_URL,
	reportingActivityLowThreshold,
	reportingActivityUpThreshold
} from '../../../../constants'
import { shortenBigNumbers } from '../../../../utils/utils'
import { ThumbDownAltOutlined, ThumbUpAltOutlined } from '@material-ui/icons'
import { RouteComponentProps, withRouter } from 'react-router'
import classNames from 'classnames'
import ReportingTable from '../../ReportingTable'
import { ActivityData, ReportingContextProps } from '../../types'
import { ReportingContext } from '../../../../contexts/ReportingContext'
import {
	getEntityLibelle,
	redirectToCountryTeamsReporting,
	selectBranchReporting,
	selectRegionReporting
} from '../utils'
import { LoggedInContext } from '../../../../contexts/LoggedInContext'

interface Props extends WithStyles<typeof tableStyles>, RouteComponentProps {
	intl: IntlShape,
	target: string
}

const TableActivity: FunctionComponent<Props> = ({ classes, intl, target, history }) => {
	const { perimeter } = React.useContext<ReportingContextProps>(ReportingContext)
	const { user, setUser, setRegion, setBranch } = React.useContext<LoggedInContext>(LoggedInContext)
	const entity = `pages.reporting.level.${perimeter}`
	const canClick = React.useMemo(() => target !== REPORTING_PAGES.DETAILED, [target])

	const redirectToDashboard = React.useCallback(() => {
		history.push('/dashboard')
	}, [history])

	const onClick = React.useCallback((row : ActivityData) => {
		if (row.entityId !== "-1") {
			if (perimeter === LEVEL.COUNTRY) {
				redirectToCountryTeamsReporting(history, row.entityId, setUser)
			} else if (perimeter === LEVEL.REGION) {
				selectRegionReporting(history, row.entityId, setRegion)
			} else if (perimeter === LEVEL.BRANCH) {
				selectBranchReporting(history, row.entityId, setBranch)
			}
		}
	}, [perimeter, setUser, history, setBranch, setRegion])

	const headers = React.useMemo(() => ([
		{
			key: target === REPORTING_PAGES.DETAILED ? 'day' : 'entity',
			name: intl.formatMessage({ id: `${target === REPORTING_PAGES.DETAILED ? 'pages.reporting.activity.table.day' : entity}` }),
			tableHeaderColumnProps: {
				classes: {
					root: classes.centerText
				}
			},
			render: (row : ActivityData) => <div className={classNames(classes.centerText,
				target !== REPORTING_PAGES.DETAILED && (perimeter !== LEVEL.SALES_REP && perimeter !== LEVEL.USER) ? classes.pointer : '')}
			                                     onClick={canClick ? () => onClick(row) : undefined}>
				<Typography display="inline">
					{
						target === REPORTING_PAGES.DETAILED ?
							`${new Date(row.day).toLocaleDateString().substring(0, 5)}/${new Date(row.day).getFullYear().toString().substr(-2)}` :
							getEntityLibelle(perimeter, row.entity)
					}
				</Typography>
			</div>
		}, {
			key: 'noActionsContracts',
			name: intl.formatMessage({ id: 'pages.reporting.activity.table.noActionsContracts' }, { br: <br /> }),
			tableHeaderColumnProps: {
				classes: {
					root: classes.centerText
				}
			},
			render: (row: ActivityData) =>  <div
					className={classNames(classes.centerText, new Date(row.day).getMonth() === new Date().getMonth() ? classes.pointer : '')}
					onClick={redirectToDashboard}>
					<Typography display="inline">
						{`${shortenBigNumbers(row.noActionsContractsUnits, user?.locale)} / ${shortenBigNumbers(row.noActionsContractsValue, user?.locale)}${row.currency}`}
					</Typography>
				</div>
		}, {
			key: 'contractsWithActions',
			name: intl.formatMessage({ id: 'pages.reporting.activity.table.contractsWithActions' }, { br: <br /> }),
			tableHeaderColumnProps: {
				classes: {
					root: classes.centerText
				}
			},
			render: (row: ActivityData) => <div className={classes.centerText}>
				<Typography display="inline">
						{`${shortenBigNumbers(row.contractsWithActionsUnits, user?.locale)} / ${shortenBigNumbers(row.contractsWithActionsValue, user?.locale)}${row.currency}`}
				</Typography>
			</div>
		}, {
			key: 'contractsWithActionsRate',
			name: intl.formatMessage({ id: 'pages.reporting.activity.table.contractsWithActionsRate' }),
			tableHeaderColumnProps: {
				classes: {
					root: classes.centerText
				}
			},
			render: (row: ActivityData) => <div>
						<Tooltip
							title={intl.formatMessage({ id: 'pages.reporting.activity.tooltip' })}
							arrow
						>
							<div className={classes.inline}>
								<Typography display="inline">
									{`${row.contractsWithActionsRate}%`}
								</Typography>
								{
									((row.contractsWithActionsRate < reportingActivityLowThreshold) || (row.contractsWithActionsRate > reportingActivityUpThreshold)) && (

										<div className={classes.icons}>
											{(row.contractsWithActionsRate < reportingActivityLowThreshold) &&
                                                <div className={classes.bad}><ThumbDownAltOutlined /></div>}
											{(row.contractsWithActionsRate > reportingActivityLowThreshold) &&
                                                <div className={classes.good}><ThumbUpAltOutlined /></div>}
										</div>
									)
								}
							</div>
						</Tooltip>
			</div>
		}, {
			key: 'contractsWithOverdueActionsRate',
			name: intl.formatMessage({ id: 'pages.reporting.activity.table.contractsWithOverdueActionsRate' }),
			tableHeaderColumnProps: {
				classes: {
					root: classes.centerText
				}
			},
			render: (row: ActivityData) => <div className={classes.centerText}>
				<Typography display="inline">
					{`${row.contractsWithOverdueActionsRate}%`}
				</Typography>
			</div>
		}, {
			key: 'contractsWithHelpRequested',
			name: intl.formatMessage({ id: 'pages.reporting.activity.table.contractsWithHelpRequested' }),
			tableHeaderColumnProps: {
				classes: {
					root: classes.centerText
				}
			},
			render: (row: ActivityData) => <div className={classes.centerText}>
				<Typography display="inline">
					{row.contractsWithHelpRequested}
				</Typography>
			</div>
		},
	]), [classes.bad, classes.centerText, classes.good, classes.inline, classes.icons, classes.pointer, redirectToDashboard, intl, target, perimeter, onClick, user, entity, canClick])

	return (
		<ReportingTable
			target={target}
			headers={headers}
			tab={REPORTING_URL.ACTIVITY}
		/>
	)
}

export default compose(
	withStyles(tableStyles),
	injectIntl,
	withRouter
)(TableActivity)