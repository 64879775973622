import { Box, ClickAwayListener, List, ListItem, makeStyles, Paper, Popper, Typography } from '@material-ui/core'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { LoggedInContext } from '../../../contexts/LoggedInContext'
import { hasAnyProfile, hasProfile } from '../../../utils/accessChecker'
import { PROFILE } from '../../../enums'
import { FormattedMessage, injectIntl } from 'react-intl'

const useStyles = makeStyles((theme) => ({
	root: {
		flex: 1,
		marginTop: 10,
		zIndex: 2000
	},
	paper: {
		padding: '15px 0',
		border: '1px solid',
		borderColor: theme.palette.grey['200'],
		borderRadius: 4,
		boxShadow: '0 0 15px 0 rgba(0,25,80,.2)',
		minWidth: 425
	},
	title: {
		display: 'flex',
		alignItems: 'center',
		fontSize: 12,
		fontWeight: 600,
		color: theme.palette.text.light,
		padding: '0 20px',
		marginBottom: 15,
		height: 30
	},
	groupList: {
		padding: '0 20px'
	},
	itemList: {
		fontSize: 15,
		fontWeight: 500,
		color: theme.palette.text.primary,
		padding: '0',
		marginBottom: 15
	}
}))

const NotificationsTray = ({ anchorEl, isMenuOpen, handleMenuClose, overdueContracts, overdueActions, needHelpContracts }) => {
	const classes = useStyles()
	const { user } = useContext(LoggedInContext)

	return (
		<Popper
			anchorEl={anchorEl}
			placement="bottom-end"
			open={isMenuOpen}
			onClose={handleMenuClose}
			className={classes.root}
		>
			<ClickAwayListener onClickAway={handleMenuClose}>
				<Paper className={classes.paper}>
					{hasAnyProfile(user, [PROFILE.BRANCH_MANAGER, PROFILE.BRANCH_MANAGER_ASSISTANT]) && <Box>
						<Typography className={classes.title}><FormattedMessage id="layout.notifications.help.title" /></Typography>
						<List className={classes.groupList}>
							{needHelpContracts.length === 0 ?
								<ListItem key="no-help-needed-contract" className={classes.itemList}><FormattedMessage id="layout.notifications.help.noContract" /></ListItem> :
								needHelpContracts.map(contract => (
									<Link key={`help-${contract.id}`} to={`/contracts/${contract.id}`}>
										<ListItem key={contract.id} className={classes.itemList} onClick={handleMenuClose}>
											<FormattedMessage
												id="layout.notifications.help.needHelp"
												values={{
													firstName: contract.firstNameSR,
													lastName: contract.lastNameSR,
													customerName: contract.customerName,
													id: contract.id
												}}
											/>
										</ListItem>
									</Link>
								))
							}
						</List>
					</Box>}
					{hasProfile(user, PROFILE.SALES_REPRESENTATIVE) && <Box>
						<Typography className={classes.title}><FormattedMessage id="layout.notifications.overdueContracts.title" /></Typography>
						<List className={classes.groupList}>
							{overdueContracts.length === 0 ?
								<ListItem key="no-overdue-contract" className={classes.itemList}><FormattedMessage id="layout.notifications.overdueContracts.noContract" /></ListItem> :
								overdueContracts.map(contract => (
									<Link key={`link-${contract.id}`} to={`/contracts/${contract.id}`}>
										<ListItem key={contract.id} className={classes.itemList} onClick={handleMenuClose}>
											<FormattedMessage
												id="layout.notifications.overdueContracts.contract"
												values={{
													customerName: contract.customerName,
													id: contract.id,
													nbMonths: new Date().getMonth() + 1 - contract.month
												}}
											/>
										</ListItem>
									</Link>
								))
							}
						</List>
						<Typography className={classes.title}><FormattedMessage id="layout.notifications.overdueActions.title" /></Typography>
						<List className={classes.groupList}>
							{overdueActions.length === 0 ?
								<ListItem key="no-overdue-action" className={classes.itemList}><FormattedMessage id="layout.notifications.overdueActions.noAction" /></ListItem> :
								overdueActions.map(action => (
									<Link key={`link-${action.id}`} to={`/contracts/${action.idContract}`}>
										<ListItem key={action.id} className={classes.itemList} onClick={handleMenuClose}>
											<FormattedMessage
												id="layout.notifications.overdueActions.action"
												values={{
													customerName: action.customerName,
													idContract: action.idContract,
													deadline: new Date(action.deadline).toLocaleDateString()
												}}
											/>
										</ListItem>
									</Link>
								))
							}
						</List>
					</Box>}
				</Paper>
			</ClickAwayListener>
		</Popper>
	)
}

export default injectIntl(NotificationsTray)