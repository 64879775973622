import { Box, Card, CardContent, CardHeader, Divider, Grid, withStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button/Button'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'
import Typography from '@material-ui/core/Typography/Typography'
import { Add as AddIcon, ExpandMoreRounded, FiberManualRecord as CircleIcon, FilterListRounded as FiltersIcon } from '@material-ui/icons'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { withRouter } from 'react-router'
import { compose } from 'recompose'
import ConfirmationDialog from '../../components/ConfirmationDialog'
import NoResultFragment from '../../components/NoResult'
import SearchBar from '../../components/SearchBar'
import StatusChip from '../../components/StatusChip'
import { ActionsContext } from '../../contexts/ActionsContext'
import { ActionsFiltersContext } from '../../contexts/ActionsFiltersContext'
import { getColorByStatus } from '../../utils/getColorByStatus'
import NewActionDialog from '../actions/NewActionDialog'
import UpdateActionDialog from '../actions/UpdateActionDialog'
import { ACTION_TYPE_LABELS, ACTIONS_STATUS } from './actionConstants'
import ActionFilters, { actionFiltersList } from './ActionFilters'
import { closeAction, setActions } from './services/actionsActions'
import * as actionsApi from './services/actionsApi'
import { ErrorHandlerContext } from '../../contexts/ErrorHandlerContext'
import { FormattedMessage, injectIntl } from 'react-intl'

const styles = (theme) => ({
	radiusWithTabBar: {
		borderRadius: '0 5px 5px 5px',
		'& > div:last-child': {
			borderRadius: '0 0 5px 5px'
		}
	},
		tab: {
			display: 'flex',
			width: 'auto'
		},
		headTools: {
			display: 'flex',
			flexDirection: 'column',
			'& > *': {
				width: '100%'
			}
		},
		contentActionList: {
			padding: '0!important'
		},
		itemActionList: {
			padding: 30,
			backgroundColor: theme.palette.text.secondary
		},
		title: {
			fontSize: 18,
			fontWeight: 700,
			marginBottom: 20
		},
		deadline: {
			fontSize: 16,
			fontWeight: 500,
			marginBottom: 20,
			color: theme.palette.grey['800']
		},
		modifiedDate: {
			fontSize: 13,
			fontWeight: 400,
			marginBottom: 10,
			color: theme.palette.text.light
		},
		description: {
			fontSize: 16,
			fontWeight: 400,
			marginBottom: 30,
			color: theme.palette.grey['800']
		},
		btnPrimary: {
			fontSize: 13,
			fontWeight: 700,
			color: theme.palette.grey['600'],
			minHeight: 50,
			flex: 1,
			padding: '6px 10px',
			letterSpacing: 0,
			textTransform: 'inherit',
			backgroundColor: theme.palette.text.secondary,
			border: '1px solid',
			borderColor: theme.palette.background.lightShadow,
			borderRadius: '4px',
			boxShadow: '0 2px 0 0 #E5EAF5',
			transition: 'all .2s ease',
			'&:hover': {
				backgroundColor: theme.palette.text.secondary,
				borderColor: theme.palette.grey['700']
			}
		}
	}
)

const Contract = ({ classes, contract, refreshAllActions, contractActions, intl }) => {
	const { filters, setFilters } = useContext(ActionsFiltersContext)
	const { actions, dispatch } = useContext(ActionsContext)
	const [actionForUpdateDialog, setActionForUpdateDialog] = useState(null)
	const [newActionDialog, setNewActionDialog] = useState(false)
	const [actionForClosureConfirmationDialog, setActionForClosureConfirmationDialog] = useState(false)
	const [filtersAnchorEl, setFiltersAnchorEl] = useState(null)
	const filterRef = useRef()
	const { setError } = React.useContext(ErrorHandlerContext)
	const [lastCreatedActionId, setLastCreatedActionId] = useState(null)
	const lastCreatedActionRef = useRef()
	const scrollToAction = () => window.scrollTo(0, lastCreatedActionRef.current.offsetTop - 100)
	const filtersList = actionFiltersList(intl)
	
	useEffect(() => {
		refreshAllActions(filters.status)
	}, [refreshAllActions, filters.status])
	
	const actionClosure = useCallback((action) => {
		actionsApi.closeAction(action).then((data) => {
			if (data) {
				setActionForClosureConfirmationDialog(null)
				dispatch(closeAction(data, filters.status))
			}
			refreshAllActions(filters.status)
		})
			.catch(error => setError(error))
	}, [filters, dispatch, refreshAllActions, setError])

	React.useEffect(() => {
		dispatch(setActions(contractActions))
	}, [contractActions, dispatch])

	return (
		<>
			<Card className={classes.radiusWithTabBar}>
				<CardHeader
					className={classes.headTools}
					title={
						<Box>
							<Box display="flex" flexGrow={1} mb="15px">Actions</Box>
							<Box display="flex" flexGrow={1} justifyContent="space-between" flexWrap="wrap">
								<Grid item xs={12} md={6}>
									<Box display="flex" justifyContent="flex-start" flexGrow="1" mb={{ xs: '20px', md: 0 }}>
										<SearchBar
											onChange={(e) => setFilters({ ...filters, searchQuery: e.target.value })}
											placeholder={intl.formatMessage({ id: 'action.contract.searchBar' })}
										/>
									</Box>
								</Grid>
								<Grid item xs={12} md={6}>
									<Box display="flex" justifyContent="flex-end">
										<Box ml={{ xs: 0, md: 2 }} display="flex" flexGrow={{ xs: 1, md: 0 }}>
											<Button
												ref={filterRef}
												className={classes.btnPrimary}
												onClick={() => setFiltersAnchorEl(filterRef.current)}
											>
												<FiltersIcon />
												<Box pl={1} pr={1}>{filtersList[filters.status === '' ? 'all' : filters.status].label}</Box>
												<ExpandMoreRounded />
											</Button>
										</Box>
										<Box ml={2} display="flex" flexGrow={{ xs: 1, md: 0 }}>
											{(contract.contractNegotiation.manualStatus === 'IN_PROGRESS' || contract.contractNegotiation.manualStatus === 'TODO') &&
											<Button
												variant="contained"
												size="large"
												color="primary"
												disableElevation
												startIcon={<AddIcon />}
												onClick={() => setNewActionDialog(true)}>
												<FormattedMessage id="action.contract.addButton" />
											</Button>}
										</Box>
									</Box>
								</Grid>
							</Box>
						</Box>
					}
				/>
				<CardContent className={classes.contentActionList}>
					<Grid container>
						{!actions && <CircularProgress />}
						{actions && actions.length === 0 && <Grid item xs={12}><NoResultFragment message={intl.formatMessage({ id: 'action.contract.noResult' })} /></Grid>}
						{actions && actions.map((action, index) => (
							<Grid item xs={12} key={index} ref={action.id === lastCreatedActionId ? lastCreatedActionRef : null}>
								<Divider />
								<Box className={classes.itemActionList}>
									<Box display="flex" flexGrow={1} justifyContent="space-between">
										<Box display="flex">
											<Typography display="inline" className={classes.title} color="textPrimary">{ACTION_TYPE_LABELS(intl)[action.type].label}</Typography>
											<Box ml={2}>
												<StatusChip Icon={CircleIcon}
															{...getColorByStatus(action.status)}
															label={ACTIONS_STATUS(intl)[action.status].chipLabel.toUpperCase()} />
											</Box>
										</Box>
										<Typography className={classes.deadline}>{intl.formatMessage({ id: 'action.contract.deadline' })} : {new Date(action.deadline).toLocaleDateString()}</Typography>
									</Box>
									{action.modificationUser && !action.modificationDate && (<Typography className={classes.modifiedDate}>
											<FormattedMessage
												id="action.contract.openBy"
												values={{ firstName: action.modificationUser.firstName, lastName: action.modificationUser.lastName }}
											/>
										</Typography>
									)}
									{action.modificationDate && action.status === 'CLOSED' && (<Typography className={classes.modifiedDate}>
											<FormattedMessage
												id="action.contract.closedOn"
												values={{
													date: new Date(action.modificationDate).toLocaleDateString(),
													firstName: action.modificationUser.firstName,
													lastName: action.modificationUser.lastName
												}}
											/>
										</Typography>
									)}
									{action.modificationDate && action.status !== 'CLOSED' && (<Typography className={classes.modifiedDate}>
										<FormattedMessage
											id="action.contract.lastModified"
											values={{
												date: new Date(action.modificationDate).toLocaleDateString(),
												firstName: action.modificationUser.firstName,
												lastName: action.modificationUser.lastName
											}}
										/>
									</Typography>)}
									<Typography className={classes.description}>{action.description}</Typography>
									{action.status === 'OPEN' && (
										<Box display='flex'>
											<Box ml={{ xs: '20px', md: 0 }} mr={{ xs: 0, md: '20px' }}>
												<Button variant="contained" size="small" color="primary" disableElevation
														onClick={() => setActionForUpdateDialog(action)}>
													<FormattedMessage id="action.contract.button.update" />
												</Button>
											</Box>
											<Box ml={{ xs: '20px', md: 0 }} mr={{ xs: 0, md: '20px' }}>
												<Button variant="contained" size="small" color="secondary" disableElevation
														onClick={() => setActionForClosureConfirmationDialog(action)}>
													<FormattedMessage id="action.contract.button.close" />
												</Button>
											</Box>
										</Box>
									)}
								</Box>
							</Grid>
						))}
					</Grid>
				</CardContent>
			</Card>
			<NewActionDialog
				contract={contract}
				open={newActionDialog}
				onClose={() => setNewActionDialog(false)}
				setLastCreatedActionId={(id) => setLastCreatedActionId(id)}
				scrollToAction={() => scrollToAction()}
			/>
			<UpdateActionDialog
				contract={contract} action={actionForUpdateDialog} open={!!actionForUpdateDialog}
				onClose={() => setActionForUpdateDialog(null)}
			/>
			<ConfirmationDialog
				cancelAction={() => setActionForClosureConfirmationDialog(null)}
				confirmAction={() => actionClosure(actionForClosureConfirmationDialog.id)}
				title={intl.formatMessage({ id: 'action.contract.confirmation.title' })}
				content={intl.formatMessage({ id: 'action.contract.confirmation.content' })}
				confirmActionLabel={intl.formatMessage({ id: 'action.contract.confirmation.buttonLabel' })}
				open={!!actionForClosureConfirmationDialog}
				onClose={() => setActionForClosureConfirmationDialog(null)}
			/>
			<ActionFilters open={Boolean(filtersAnchorEl)} anchorEl={filtersAnchorEl} closeFilters={() => setFiltersAnchorEl(null)} />
		</>
	)
}

const enhance = compose(
	withStyles(styles),
	withRouter,
	injectIntl
)
export default enhance(Contract)
