import React, { useContext, useEffect, useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { AccessChecker } from '../../../utils/accessChecker'
import { PROFILE } from '../../../enums'
import { Box, CircularProgress, createStyles, MenuItem, withStyles } from '@material-ui/core'
import { Field, Form } from 'react-final-form'
import { FormSelect } from 'isotope-client'
import { compose } from 'recompose'
import { LoggedInContext } from '../../../contexts/LoggedInContext'
import { getCountries } from '../../selectcountry/services/api'
import { Country } from '../countries/types'
import Typography from '@material-ui/core/Typography/Typography'
import { OnChange } from 'react-final-form-listeners'
import { RenegotiationCountryFormError, RenegotiationCountryFormValues } from './type'
import RenegotiationTable from './RenegotiationTable'

const styles = () =>
	createStyles({
		select: {
			width: 328,
			'&::before': {
				content: 'unset',
			},
			'&::after': {
				content: 'unset',
			}
		},
		centerLoader: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			padding: '20px 0'
		}
	})

const RenegotiationHeatmapPage = ({intl, classes}) => {
	const { user } = useContext(LoggedInContext)
	const [countries, setCountries] = useState<Country[]>([])
	const [selectedCountry, setSelectedCountry] = useState<string|undefined>(user?.countryId)
	const [loading, setLoading] = useState<boolean>(true)

	useEffect(() => {
		getCountries().then(countries => {
			setCountries(countries)
			setLoading(false)
		})
	}, [selectedCountry])


	const onSubmit = (values : RenegotiationCountryFormValues) => {
		setSelectedCountry(values.country)
	}

	const validate = (values: RenegotiationCountryFormValues) => {
		const errors: RenegotiationCountryFormError = {}
		if (!values.country) {
			errors.country = intl.formatMessage({ id: 'import.form.country.errors.required' })
		}
		return errors
	}

	if (loading) {
		return <div className={classes.centerLoader}><CircularProgress/></div>
	}

	return (
		<AccessChecker profiles={[PROFILE.ZONE_MANAGER, PROFILE.NATIONAL]}>
			{
				!loading && (
					<Box pt={3} pb={3}>
						<Typography variant="body1">
							<FormattedMessage id="renegotiation.message" />
						</Typography>
						<Box pt={1}>
							<Form
								initialValues={{ country: user?.countryId }}
								onSubmit={onSubmit}
								validate={validate}
								render={({ handleSubmit }) => (
									<form onSubmit={handleSubmit}>
										<OnChange name="country">
											{(value: string) => onSubmit({ country: value })}
										</OnChange>
										<Field<string>
											name="country"
											fullWidth
											component={FormSelect}
											inputStyle={classes.select}
											label={intl.formatMessage({ id: 'import.form.country.label' })}
											disabled={countries.length < 2}
										>
											{countries.map((country, key) => (
												<MenuItem key={key} value={country.id}>
													{country.name}
												</MenuItem>
											))}
										</Field>
									</form>
								)}
							/>
						</Box>
						{
							selectedCountry && (<Box pt={5}>
								<RenegotiationTable
									countryId={selectedCountry}
								/>
							</Box>)
						}
					</Box>
				)
			}
		</AccessChecker>
	)
}

export default compose(injectIntl, withStyles(styles))(RenegotiationHeatmapPage)