import RadioGroup from '@material-ui/core/RadioGroup'
import Grid from '@material-ui/core/Grid/Grid'
import { FormControl, FormControlLabel, withStyles } from '@material-ui/core'
import { Field } from 'react-final-form'
import InputLabel from '@material-ui/core/InputLabel/InputLabel'
import React from 'react'
import Radio from '@material-ui/core/Radio'
import CircleCheckedFilled from '@material-ui/icons/CheckCircle'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'

const styles = (theme) => {
	return ({
			detail: {
				marginTop: '14px'
			},
			radioRoot: {
				color: theme.palette.grey['300']
			},
			radioChecked: {
				color: theme.palette.primary.light
			}
		}
	)
}

const RadioFormField = ({
	input: { checked, value, name, onChange, classes, ...otherInput },
	meta,
	...other
}) => (
	<Radio
		classes={classes}
		{...other}
		name={name}
		value={value}
		checked={checked}
		onChange={onChange}
		inputProps={otherInput}
		icon={<RadioButtonUncheckedIcon />}
		checkedIcon={<CircleCheckedFilled />}
		color='default'
	/>
)


const RadioField = ({ radioItems, defaultItem, fieldName, classes }) => {

	return <FormControl component="fieldset">
		<RadioGroup defaultValue={radioItems[defaultItem].key}>
			{Object.keys(radioItems).map(key =>
				<Grid container key={`radio-detail-${key}`}>
					<Grid item key={`radio-${key}`} display="flex">
						<FormControlLabel
							value={radioItems[key].key}
							control={<Field
								type="radio"
								name={fieldName}
								value={radioItems[key].key}
								component={RadioFormField}
								classes={{
									root: classes.radioRoot,
									checked: classes.radioChecked
								}}
							/>}
							label={radioItems[key].label}
						/>
					</Grid>
					<Grid
						item
						key={`detail-${key}`}
						display="flex"
					>
						<InputLabel
							id="detail-label"
							className={classes.detail}
						>
							{radioItems[key].detail}
						</InputLabel>
					</Grid>
				</Grid>
			)}
		</RadioGroup>
	</FormControl>
}

export default withStyles(styles)(RadioField)