import React, { FunctionComponent } from 'react'
import { Box, CircularProgress, createStyles, withStyles, WithStyles } from '@material-ui/core'
import { centerLoader } from '../style'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highstock'
import { ErrorHandlerContext } from '../../../contexts/ErrorHandlerContext'
import { ComparedPerformanceChartData, ExtendedReportingFiltersProps, ReportingContextProps } from '../types'
import { ReportingContext } from '../../../contexts/ReportingContext'
import { compose } from 'recompose'
import { getEntityLibelle } from '../details/utils'

const styles = () => createStyles({
	box : {
		padding: '0 30px'
	},
	hidden: {
		display: 'none'
	},
	centerLoader
})

interface Props extends WithStyles<typeof styles> {
	getData: (filters: ExtendedReportingFiltersProps) => Promise<ComparedPerformanceChartData[]>,
	title: string,
	legend: string
}

const BarChart: FunctionComponent<Props> = ({ classes, getData, title, legend}) => {
	const { filters, perimeter } = React.useContext<ReportingContextProps>(ReportingContext)
	const [loading, setLoading] = React.useState<boolean>(true)
	const { setError } = React.useContext<ErrorHandlerContext>(ErrorHandlerContext)
	const [entities, setEntities] = React.useState<string[]>([])
	const [values, setValues] = React.useState<number[]>([])

	React.useEffect(() => {
		getData({...filters, perimeter})
			.then((data: ComparedPerformanceChartData[]) => {
				setEntities(data.map(item => getEntityLibelle(perimeter, item.entity)))
				setValues(data.map(item => item.value))
			})
			.catch(e => setError && setError(e))
			.finally(() => setLoading(false))
	}, [filters, perimeter, setError, getData])

	const options = React.useMemo(() => ({
		chart: {
			type: 'bar',
			marginRight: 30
		},
		title: {
			text: title
		},
		xAxis: {
			categories: entities,
			title: {
				text: null
			}
		},
		yAxis: {
			min: 0,
			max: 100,
			title: {
				text: '%',
				style: {
					fontSize: '12px!important',
					fontWeight: '600!important'
				},
				align: 'high',
				x: 25,
				y: -23
			},
			labels: {
				style: {
					fontSize: '12px!important',
					fontWeight: '600!important'
				}
			}
		},
		tooltip: {
			valueSuffix: ' %'
		},
		legend: {
			align: 'center',
			verticalAlign: 'bottom',
			x: 0,
			y: 23,
			floating: true,
			backgroundColor: '#FFFFFF',
		},
		credits: {
			enabled: false
		},
		series: [{
			name: legend,
			data: values,
			color: '#2F66BB'
		}]
	}), [entities, values, title, legend])

	return (
		<Box className={classes.box}>
			<div className={!loading ? classes.hidden : classes.centerLoader}>
				<CircularProgress size={20} />
			</div>
			<div className={loading ? classes.hidden : ''}>
				<HighchartsReact
					highcharts={Highcharts}
					options={options}
				/>
			</div>
		</Box>
	)
}

export default compose(
	withStyles(styles)
)(BarChart)