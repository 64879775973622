import React from 'react'

export default () => (
	<>
		<g id="Groupe_1709" data-name="Groupe 1709" transform="translate(-960 -20.687)">
			<path id="Tracé_163" data-name="Tracé 163" d="M0,0H8V8H0Z" transform="translate(964 28)" fill="#687bae" />
			<g id="Groupe_1395" data-name="Groupe 1395" transform="translate(960 20.687)">
				<g id="trending_up-24px_1_" data-name="trending_up-24px (1)" transform="translate(0)">
					<path id="Tracé_139" data-name="Tracé 139" d="M0,0H19.313V19.313H0Z" fill="none" />
					<rect id="Rectangle_1906" data-name="Rectangle 1906" width="2" height="19" transform="translate(0 0.313)" fill="#172f50" />
					<rect id="Rectangle_1907" data-name="Rectangle 1907" width="19" height="2" transform="translate(0 17.313)" fill="#172f50" />
				</g>
			</g>
		</g>
	</>
)