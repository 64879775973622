import { fetchFactory } from 'isotope-client'

export const loadContractsCount = (salesRepId) => fetchFactory(`/contracts/counts/${salesRepId || 'me'}`, {}, '/api')

export const loadOverviewContractsCount = () => fetchFactory('/contracts/overview-counts', {}, '/api')

export const loadContractById = (id) => fetchFactory(`/contracts/${id}`, {}, '/api')
export const loadContractActions = (id, filters) => fetchFactory(`/contracts/${id}/actions?status=${filters.status === 'all' ? '' : filters.status}&searchQuery=${filters.searchQuery}`, {}, '/api')

export const setHelpNeededApi = (id, helpNeeded) => fetchFactory(`/contracts-negotiations/set-help-needed?id=${id}&helpNeeded=${helpNeeded}`, {}, '/api')

export const closeRisk = (id, form) => fetchFactory(`/contracts-negotiations/close/${id}`, { body: JSON.stringify(form), method: 'post' }, '/api')

export const reopenRisk = (id) => fetchFactory(`/contracts-negotiations/reopen/${id}`, { method: 'post' }, '/api')

export const getOverdueContracts = () => fetchFactory('/contracts/overdue', {}, "/api")

export const getNeedHelp = () => fetchFactory('/contracts/need-help', {}, "/api")

export const getContractsDistribution = () => fetchFactory(`/contracts/distribution`, {}, "/api")

export const loadDashboardContractsCount = () => fetchFactory(`/contracts/dashboard-contracts-counts`, {}, '/api')

export const loadOtherContractsCounts = (idContract) => fetchFactory(`/contracts/${idContract}/other-contracts`, {}, '/api')

export const getClusterData = (idContract) => fetchFactory(`/clusters/by-contract/${idContract}`, {}, '/api')

export const manuallyPrioritize = (id) => fetchFactory(`/contracts-negotiations/manually-prioritize/${id}`, { method: 'post' }, '/api')

export const loadContractFranceById = (id) => fetchFactory(`/contracts-france/${id}`, {}, '/api')

export const createComment = (id, form) => fetchFactory(`/contracts-negotiations/comment/${id}`, { method: 'put', body: JSON.stringify(form) }, '/api')

export const updateForecast = (id, form) => fetchFactory(`/contracts-negotiations/forecast/${id}`, { method: 'put', body: JSON.stringify(form) }, '/api')

export const getContractsYears = () => fetchFactory('/contracts-negotiations/years', {}, '/api')

export const getRenegotiationTip = (countryId, priceScore, riskScore) =>
    fetchFactory(`/matrix/renegotiation/country/${countryId}/priceScore/${priceScore}/riskScore/${riskScore}`, {}, '/api')

export const getRenegotiationMatrix = (countryId) => fetchFactory(`/matrix/renegotiation/country/${countryId}`, {}, '/api')