import * as React from 'react'
import { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { SpringDataTable } from 'isotope-client'
import NoResultFragment from '../../components/NoResult'
import {
	Avatar,
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	createStyles,
	Theme,
	Tooltip,
	Typography,
	WithStyles,
	withStyles,
	withWidth
} from '@material-ui/core'
import indicatorRound from '../../utils/indicatorRound'
import { ChevronRight } from '@material-ui/icons'
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl'
import { compose } from 'recompose'

const styles = (theme: Theme) => createStyles({
	card: {
		'& [class*="MuiSvgIcon-root"]': {
			width: 22,
			height: 22,
			margin: 0
		},
		'& [aria-label="next page"]': {
			marginRight: -5
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			overflow: 'scroll'
		}
	},
	cardContent: {
		backgroundColor: theme.palette.common.white,
		padding: 0,
		position: 'relative',
		'&:last-child': {
			paddingBottom: 0
		}
	},
	cardTable: {
		display: 'flex',
		flexDirection: 'column',
		flex: '0 1 300px',
		width: '100%'
	},
	titleCardTable: {
		fontSize: 15,
		fontWeight: 700,
		textTransform: 'uppercase',
		color: theme.palette.grey['800']
	},
	subCardTable: {
		fontSize: 12,
		fontWeight: 400,
		textTransform: 'uppercase',
		color: theme.palette.grey['500']
	},
	avatar: {
		marginRight: 15
	},
	error: {
		color: theme.palette.error.main,
		fontSize: 15,
		fontWeight: 600
	},
	showButton: {
		marginBottom: 20,
		marginTop: 20
	}
})

type Props = {
	intl: IntlShape
}

const HighRiskContracts: FunctionComponent<Props & WithStyles<typeof styles>> = ({ classes, intl }) => {
	const headers = [
		{
			key: 'id',
			name: intl.formatMessage({ id: 'pages.dashboard.highRisk.table.headers.contract' }),
			render: contract => (
				<Box display="flex" alignItems="center">
					<Tooltip title={`${contract.firstNameSalesRep} ${contract.lastNameSalesRep}`}>
						<Link to={`/portfolio/${contract.idSalesRep}`}>
							<Avatar className={classes.avatar}>{(contract.firstNameSalesRep[0] + contract.lastNameSalesRep[0]).toUpperCase()}</Avatar>
						</Link>
					</Tooltip>
					<Box className={classes.cardTable}>
						<Link to={`/contracts/${contract.id}`}>
							<Typography
								display="inline"
								className={classes.titleCardTable}
							>
								{contract.customer.name} ({contract.id})
							</Typography>
							<Typography className={classes.subCardTable}>{contract.address}</Typography>
						</Link>
					</Box>
				</Box>
			)
		},
		{
			key: 'actionsOpened',
			name: intl.formatMessage({ id: 'pages.dashboard.highRisk.table.headers.actionsOpened' }),
			render: contract => contract.contractNegotiation && contract.contractNegotiation.actions.filter(a => a.status === 'OPEN').length
		},
		{
			key: 'valueIndicator',
			name: intl.formatMessage({ id: 'pages.dashboard.highRisk.table.headers.valueIndicator' }),
			render: contract => <Tooltip
				title={intl.formatMessage({ id: 'pages.dashboard.highRisk.table.tooltip.value' }, { indicator: indicatorRound(contract.valueIndicator) })}><Typography>{indicatorRound(contract.valueIndicator)}</Typography></Tooltip>
		},
		{
			key: 'riskIndicator',
			name: intl.formatMessage({ id: 'pages.dashboard.highRisk.table.headers.riskIndicator' }),
			render: contract => <Tooltip
				title={intl.formatMessage({ id: 'pages.dashboard.highRisk.table.tooltip.risk' }, { indicator: indicatorRound(contract.riskIndicator) })}><Typography>{indicatorRound(contract.riskIndicator)}</Typography></Tooltip>
		}
	]

	return (
		<Card className={classes.card}>
			<CardHeader title={intl.formatMessage({ id: 'pages.dashboard.highRisk.table.title' })} />
			<CardContent className={classes.cardContent}>
				<SpringDataTable
					nom={`contractDataTableHighRisk`}
					headers={headers}
					filters={{ searchQuery: '', sorting: 'value', status: ['TODO', 'IN_PROGRESS'] }}
					apiUrlPrefix="/api"
					apiUrl={'/contracts/high-risk'}
					noResultFragment={<NoResultFragment message={intl.formatMessage({ id: 'pages.dashboard.highRisk.table.noResult' })} />}
					ActionsComponent={() =>
						<Link to={'/contracts-overview'}>
							<Button variant="contained" size="large" color="primary" className={classes.showButton}>
								<FormattedMessage id="pages.dashboard.highRisk.button" />
								<ChevronRight />
							</Button>
						</Link>
					}
					defaultPageSize={3}
				/>
			</CardContent>
		</Card>
	)
}

export default compose(
	withStyles(styles),
	withWidth({ withTheme: true }),
	injectIntl
)(HighRiskContracts)
