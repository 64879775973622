export const OVERVIEW_TABLE_URL = {
	PRIORITY: 'overview-priority',
	CANCELLED: 'overview-cancelled',
	ALL: 'overview-all'
}

export const REPORTING_URL = {
	ADOPTION: 'adoption',
	ACTIVITY: 'activity',
	PERFORMANCE: 'performance'
}

export const LEVEL= {
	COUNTRY: 'COUNTRY',
	REGION: 'REGION',
	BRANCH: 'BRANCH',
	SALES_REP: 'SALES_REP',
	USER: 'USER'
}

export const REPORTING_PAGES = {
	TEAMS: 'teams',
	DETAILED: 'detailed'
}

export const reportingActivityLowThreshold = 50
export const reportingActivityUpThreshold = 80
export const reportingAdoptionThreshold = 5
export const reportingPerformanceLowThreshold = 80
export const reportingPerformanceUpThreshold = 95

export const OUTLIER = 'outlier'