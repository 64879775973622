import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import React from 'react'
import { AlertOptions } from '../interfaces'

interface PopupResultatProps {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	options: AlertOptions
}

const PopupResultat: React.FC<PopupResultatProps> = ({ open, setOpen, options }) => {
	return (
		<Snackbar
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
			open={open}
			autoHideDuration={2000}
			onClose={() => setOpen(false)}
		>
			<Alert variant="filled" onClose={() => setOpen(false)} severity={options.severity}>
				{options.message}
			</Alert>
		</Snackbar>
	)
}

export default PopupResultat
