import { Tab, Tabs, Typography } from '@material-ui/core'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { withStyles } from '@material-ui/styles'
import { CONTRACT_TABS } from '../enums'
import BadgeBasic from './BadgeTab'

const styles = (theme: any) => {
	return {
		tabsContracts: {
			'& [class*="MuiButtonBase-root"]': {
				display: 'flex',
				alignItems: 'center',
				height: 50,
				padding: '0 20px',
				width: 'auto',
				borderRadius: '4px 4px 0 0',
				marginRight: 1,
				opacity: 1,
			},
			'& [class*="MuiTypography-root"]': {
				display: 'flex',
				alignItems: 'center',
				fontSize: 16,
				fontWeight: '700',
				textTransform: 'initial',
			},
			'& [class*="Mui-selected"]': {
				backgroundColor: `${theme.palette.text.secondary}!important`,
				color: `${theme.palette.text.primary}!important`,
				opacity: 1,
				'& [class*="MuiBadge-colorPrimary"]': {
					backgroundColor: theme.palette.primary.light,
					color: theme.palette.text.secondary,
				},
				'&:hover': {
					backgroundColor: '#fff!important',
					color: '#021227!important',
				},
			},
			'& [class*="PrivateTabIndicator-root"]': {
				display: 'none',
			},
		},
		zeroAction: {
			backgroundColor: '#F65275',
			color: 'white',
			'&:hover': { backgroundColor: '#AC3951!important' },
		},
		normal: {
			backgroundColor: theme.palette.background.default,
			color: theme.palette.common.white,
			'&:hover': { backgroundColor: theme.palette.grey['800'] },
		},
	}
}

const TabBarContract = ({ classes, actions, currentTab, setCurrentTab, refreshed }) => {
	return (
		<Tabs
			value={currentTab}
			onChange={(_, value: CONTRACT_TABS) => setCurrentTab(value)}
			className={classes.tabsContracts}
		>
			<Tab
				value={CONTRACT_TABS.DETAILS}
				className={classes.normal}
				label={
					<Typography noWrap>
						<FormattedMessage id="pages.contract.information.tabs.tab.details" />
					</Typography>
				}
			/>
			{refreshed && (
				<Tab
					value={CONTRACT_TABS.ACTIONS}
					className={actions.length > 0 ? classes.normal : classes.zeroAction}
					label={
						<Typography noWrap>
							{actions.length > 0 ? (
								<>
									<FormattedMessage id="pages.contract.information.tabs.tab.actions" />{' '}
									<BadgeBasic badgeContent={actions.length} badgeState="primary" />
								</>
							) : (
								<FormattedMessage id="pages.contract.information.tabs.tab.zeroAction" />
							)}
						</Typography>
					}
				/>
			)}
		</Tabs>
	)
}

export default withStyles(styles)(TabBarContract)
