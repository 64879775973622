import * as React from 'react'
import { FunctionComponent } from 'react'
import {
	Box,
	Card,
	CardContent,
	CardHeader,
	createStyles,
	Grid,
	SvgIcon,
	Theme,
	Tooltip,
	Typography,
	WithStyles,
	withStyles
} from '@material-ui/core'
import GraphLegendChip from '../../svg/GraphLegendChip'
import ContractsDistrib from '../../svg/ContractsDistrib'
import { getContractsDistribution } from '../contracts/services/contractsApi'
import { Link } from 'react-router-dom'
import { LoggedInContext } from '../../contexts/LoggedInContext'
import { FormattedMessage, injectIntl, IntlShape }from 'react-intl'
import { compose } from 'recompose'

const styles = ({ palette, spacing }: Theme) => createStyles({
	root: {
		height: '100%'
	},
	cardContent: {
		height: '100%'
	},
	link: {
		color: palette.primary.light
	},
	svgIcon: {
		fontSize: '8em'
	},
	grid: {
		textAlign: 'right',
		paddingBottom: spacing(2),
		display: 'flex',
		justifyContent: 'space-around',
		alignItems: 'center'
	},
	legendPadding: {
		paddingLeft: spacing(1)
	}
})

type Props = {
	intl: IntlShape
}

const TopPriorityContractsDistrib: FunctionComponent<Props & WithStyles<typeof styles>> = ({ classes, intl }) => {
	const [todoCount, setTodoCount] = React.useState(0)
	const [inProgressCount, setInProgressCount] = React.useState(0)
	const [manuallyClosedCount, setManuallyClosedCount] = React.useState(0)
	const { user } = React.useContext(LoggedInContext)

	React.useEffect(() => {
		getContractsDistribution()
			.then(counts => {
				setTodoCount(counts.todoCount)
				setInProgressCount(counts.inProgressCount)
				setManuallyClosedCount(counts.manuallyClosedCount)
			})
	}, [user])

	const total = todoCount + inProgressCount + manuallyClosedCount

	return (
		<>
			<Card className={classes.root}>
				<CardHeader title={intl.formatMessage({ id: 'pages.dashboard.topPriorityContracts.title' })} />
				<CardContent className={classes.cardContent}>
					<Box pt={1} pb={1}>
						<Grid container spacing={2} className={classes.grid}>
							<Grid item xs={4}>
								<SvgIcon className={classes.svgIcon}>
									<ContractsDistrib
										firstPercent={total === 0 ? 0 : todoCount * 100 / total}
										secondPercent={total === 0 ? 0 : inProgressCount * 100 / total}
										todoCount={todoCount}
										inProgressCount={inProgressCount}
										manuallyClosedCount={manuallyClosedCount}
									/>
								</SvgIcon>
							</Grid>
							<Grid item xs={8}>
								<Link to="/contracts-overview/todo">
									<Tooltip title={intl.formatMessage({ id: 'pages.dashboard.topPriorityContracts.tooltip' }, { nb : todoCount })}>
										<Box display="flex" alignItems="center">
											<SvgIcon>
												<GraphLegendChip color="#818EA0" />
											</SvgIcon>
											<Typography variant="h5" className={classes.legendPadding}>
												<FormattedMessage id="pages.dashboard.topPriorityContracts.type.noAction" />
											</Typography>
										</Box>
									</Tooltip>
								</Link>
								<Link to="/contracts-overview/in-progress">
									<Tooltip title={intl.formatMessage({ id: 'pages.dashboard.topPriorityContracts.tooltip' }, { nb : inProgressCount })}>
										<Box display="flex" alignItems="center">
											<SvgIcon>
												<GraphLegendChip color="#34CB81" />
											</SvgIcon>
											<Typography variant="h5" className={classes.legendPadding}>
												<FormattedMessage id="pages.dashboard.topPriorityContracts.type.inProgress" />
											</Typography>
										</Box>
									</Tooltip>
								</Link>
								<Link to="/contracts-overview/manually-closed">
									<Tooltip title={intl.formatMessage({ id: 'pages.dashboard.topPriorityContracts.tooltip' }, { nb : manuallyClosedCount })}>
										<Box display="flex" alignItems="center">
											<SvgIcon>
												<GraphLegendChip color="#172F50" />
											</SvgIcon>
											<Typography variant="h5" className={classes.legendPadding}>
												<FormattedMessage id="pages.dashboard.topPriorityContracts.type.manuallyClosed" />
											</Typography>
										</Box>
									</Tooltip>
								</Link>
							</Grid>
						</Grid>
					</Box>
				</CardContent>
			</Card>
		</>
	)
}

export default compose(
	withStyles(styles),
	injectIntl
)(TopPriorityContractsDistrib)