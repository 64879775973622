import { Box, IconButton, withStyles } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import React from 'react'
import Button from '@material-ui/core/Button/Button'
import { Close as CloseIcon } from '@material-ui/icons'
import { FormattedMessage } from 'react-intl'

const styles = (theme) => {
	return ({
			tab: {
				display: 'flex',
				width: 'auto'
			}
		}
	)
}

const ErrorPopup = ({ open, onClose, title, content }) => {

	return (
		<Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="sm">
			<DialogTitle>
				<Box display="flex" alignItems="center" justifyContent="space-between" flexGrow="1">
					{title}
					<IconButton onClick={onClose}><CloseIcon /></IconButton>
				</Box>
			</DialogTitle>
			<DialogContent>{content}</DialogContent>
			<DialogActions>
				<Button onClick={onClose} variant="contained" size="small" color="primary" disableElevation={true}><FormattedMessage id="pages.contract.information.dialog.error.close" /></Button>
			</DialogActions>
		</Dialog>
	)
}

export default withStyles(styles)(ErrorPopup)
