import { Box, Button, Card, CardContent, CardHeader, Grid, Tooltip, withStyles } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'
import Typography from '@material-ui/core/Typography/Typography'
import {
	AttachMoney as DollarIcon,
	BuildOutlined as WrenchIcon,
	FiberManualRecord as CircleIcon,
	LockOutlined as LockIcon,
	NotificationsOffOutlined as NoHelpNeededIcon,
	NotificationsOutlined as HelpNeededIcon
} from '@material-ui/icons'
import React, { useCallback, useContext, useEffect } from 'react'
import { withRouter } from 'react-router'
import { compose } from 'recompose'
import ConfirmationDialog from '../../components/ConfirmationDialog'
import ErrorPopup from '../../components/ErrorPopup'
import PageTitle from '../../components/PageTitle'
import StatusChip from '../../components/StatusChip'
import ValueRiskIcon from '../../components/ValueRiskIcon'
import { ContractsContext } from '../../contexts/ContractsContext'
import { LoggedInContext } from '../../contexts/LoggedInContext'
import { PROFILE } from '../../enums'
import { hasAnyProfile, hasProfile } from '../../utils/accessChecker'
import { getColorByStatus } from '../../utils/getColorByStatus'
import indicatorRound from '../../utils/indicatorRound'
import { sanitizeLabel, truncate } from '../../utils/stringUtils'
import CloseRiskDialog from './CloseRiskDialog'
import { removeContract, setContract, setContractId, setContractNegotiation } from './services/contractsActions'
import { loadContractActions, manuallyPrioritize, reopenRisk, setHelpNeededApi } from './services/contractsApi'
import OtherContracts from './OtherContracts'
import { ErrorHandlerContext } from '../../contexts/ErrorHandlerContext'
import RiskTypologyPopup from '../../components/RiskTypologyPopup'
import { FormattedMessage, injectIntl } from 'react-intl'
import ContractContent from './ContractContent'
import ForecastModule from './modules/ForecastModule'
import GradingIcon from '../../svg/GradingIcon'
import RenegotiationTipDialog from './RenegotiationTipDialog'
import { getRenegotiationTip } from '../../utils/utils'

const styles = (theme) => {
	return ({
			tab: {
				display: 'flex',
				width: 'auto'
			},
			badgeButton: {
				borderRadius: 4,
				border: '2px solid',
				borderColor: theme.palette.grey['100'],
				height: 26,
				width: 26,
				marginTop: 2,
				alignItems: 'center',
				padding: '2px',
				cursor: 'pointer'
			},
			badgeTypology: {
				display: 'flex',
				flex: '0 1 auto',
				alignItems: 'center',
				borderRadius: 4,
				padding: '8px',
				border: '2px solid',
				borderColor: theme.palette.grey['100'],
				height: 24,
				marginTop: 2,
				marginRight: '5px',
				cursor: 'pointer'
			},
			badgeLabel: {
				marginLeft: '3px'
			},
			helpNeededButton: {
				backgroundColor: theme.palette.grey['50'],
				borderColor: theme.palette.grey['50'],
				color: theme.palette.secondary.main
			},
			comment: {
				fontSize: 16,
				fontWeight: 400,
				marginBottom: 30,
				color: theme.palette.grey['800']
			},
			commentTitle: {
				fontSize: 13,
				fontWeight: 400,
				marginBottom: 10,
				color: theme.palette.text.light
			},
			headTools: {
				display: 'flex',
				flexDirection: 'column',
				'& > *': {
					width: '100%'
				}
			},
			cardHeader: {
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between'
			},
			root : {
				'& [class*="MuiCardHeader-action"]': {
					width: '65%'
				}
			}
		}
	)
}

const Contract = ({ match, classes, intl }) => {
	const { contract, dispatch } = useContext(ContractsContext)
	const { user } = useContext(LoggedInContext)
	const { setError } = React.useContext(ErrorHandlerContext)

	const [openCloseRisk, setOpenCloseRisk] = React.useState(false)
	const [openCloseRiskError, setOpenCloseRiskError] = React.useState(false)
	const [openReopenDialog, setOpenReopenDialog] = React.useState(false)
	const [actions, setActions] = React.useState([])
	const [openRiskFlag, setOpenRiskFlag] = React.useState(false)
	const [openRenegotiationTipDialog, setOpenRenegotiationTipDialog] = React.useState(false)
	const [flagDetails, setFlagDetails] = React.useState('')
	const [flagTitle, setFlagTitle] = React.useState('')
	const [openAddPriority, setOpenAddPriority] = React.useState(false)
	const [refreshed, setRefreshed] = React.useState(false)
	const [renegotiationTip, setRenegotiationTip] = React.useState("")

	const setHelpNeeded = useCallback((helpNeeded) => {
		if (contract) {
			setHelpNeededApi(contract.contractNegotiation.id, helpNeeded)
				.then(data => dispatch(setContract({ ...contract, contractNegotiation: data })))
		}
	}, [contract, dispatch])

	const addMonthsToClosureDate = useCallback((months) => {
		if (contract && contract.contractNegotiation) {
			return new Date(new Date(contract.contractNegotiation.closureDate).setMonth(new Date(contract.contractNegotiation.closureDate).getMonth() + months))
		}
	}, [contract])

	useEffect(() => {
		dispatch(setContractId(match.params.id))
		return () => {
			dispatch(removeContract())
		}
	}, [match.params.id, dispatch])

	useEffect(() => {
		if (user !== null && contract !== null) {
			getRenegotiationTip(user.countryId, contract.priceScore, contract.riskIndicator, intl)
				.then(setRenegotiationTip)
		}
	}, [contract, user, intl])

	const refreshActions = useCallback((status) => {
		contract && loadContractActions(contract.id, { status: status, searchQuery: '' })
			.then(actions => {setActions(actions); setRefreshed(true)})
			.catch(error => setError(error))
	}, [contract, setError])

	const customerNameTruncated = React.useMemo(() => {
		if (contract && contract.customer) {
			return truncate(contract.customer.name, 30)
		}
		return null
	}, [contract])

	if (!contract) {
		return <CircularProgress />
	}

	const breadcrumbs = hasAnyProfile(user, [PROFILE.ZONE_MANAGER, PROFILE.NATIONAL, PROFILE.REGIONAL_MANAGER, PROFILE.BRANCH_MANAGER, PROFILE.BRANCH_MANAGER_ASSISTANT]) ?
		[
			{
				path: '/dashboard',
				label: intl.formatMessage({ id: 'pages.contract.breadcrumbs.dashboard' }),
				data: { regionId: contract.regionId, branchId: contract.branchId }
			},
			{
				path: '/sr-overview',
				label: intl.formatMessage({ id: 'pages.contract.breadcrumbs.srOverview' })
			},
			{
				path: `/portfolio/${contract.idSalesRep}`,
				label: intl.formatMessage({ id: 'pages.contract.breadcrumbs.srPortfolio' }, { firstName: contract.firstNameSalesRep, lastName: contract.lastNameSalesRep })
			},
			{
				label: intl.formatMessage({ id: 'pages.contract.breadcrumbs.contract' }, { name: customerNameTruncated, contractId: contract.id })
			}
		]
		:
		[
			{
				path: '/portfolio',
				label: intl.formatMessage({ id: 'pages.contract.breadcrumbs.myPortfolio' })
			},
			{
				label: intl.formatMessage({ id: 'pages.contract.breadcrumbs.contract' }, { name: customerNameTruncated, contractId: contract.id })
			}
		]

	if (user && hasAnyProfile(user, [PROFILE.ZONE_MANAGER, PROFILE.NATIONAL]) && contract && contract.regionId) {
		breadcrumbs.unshift({
			label: intl.formatMessage({ id: 'pages.contract.breadcrumbs.dashboardRegional' }),
			path: '/dashboard',
			data: { regionId: contract.regionId, branchId: null },
		})
	}

	const handleCloseRisk = () => {
		if (actions.every(action => action.status === 'CLOSED')) {
			setOpenCloseRisk(true)
		} else {
			setOpenCloseRiskError(true)
		}
	}

	const confirmReopen = () => {
		reopenRisk(contract.id)
			.then((contractNegotiation) => {
				dispatch(setContractNegotiation(contractNegotiation))
				setOpenReopenDialog(false)
			})
			.catch(error => setError(error))
	}

	const confirmManuallyPrioritize = () => {
		manuallyPrioritize(contract.id)
			.then((contractNegotiation) => {
				dispatch(setContractNegotiation(contractNegotiation))
				setOpenAddPriority(false)
				refreshActions('OPEN')
			})
			.catch(error => setError(error))
	}

	let topActionsButtons = []
	let closeRiskButton = undefined
	if (contract.contractNegotiation && !contract.contractNegotiation.closureDate) {
		closeRiskButton = <Button
			variant="contained"
			size="large" color="secondary"
			startIcon={<LockIcon />}
			disableElevation={true}
			onClick={() => handleCloseRisk()}
		>
			<FormattedMessage id="pages.contract.actions.close" />
		</Button>
	}

	let addToPriorityButton = undefined
	if (!contract.contractNegotiation || (contract.contractNegotiation.closureDate && addMonthsToClosureDate(6) < new Date())) {
		addToPriorityButton = <Button
			variant="contained"
			size="large" color="secondary"
			disableElevation={true}
			onClick={() => setOpenAddPriority(true)}
		>
			<FormattedMessage id="pages.contract.actions.add" />
		</Button>
	}

	let managerHelpButton = undefined
	if (contract.contractNegotiation && (contract.contractNegotiation.manualStatus === 'TODO' || contract.contractNegotiation.manualStatus === 'IN_PROGRESS')) {
		managerHelpButton = contract.contractNegotiation.helpNeeded ? (
			<Tooltip title={intl.formatMessage({ id: 'pages.contract.actions.help.tooltip' })} placement="top">
				<Button variant="outlined" size="large" className={classes.helpNeededButton} disableElevation={true} startIcon={<HelpNeededIcon />}
						onClick={() => setHelpNeeded(false)}>
					<FormattedMessage id="pages.contract.actions.help.title" />
				</Button>
			</Tooltip>
		) : (
			<Tooltip title={intl.formatMessage({ id: 'pages.contract.actions.help.tooltip' })} placement="top">
				<Button variant="outlined" size="large" color="primary" disableElevation={true} startIcon={<NoHelpNeededIcon />}
						onClick={() => setHelpNeeded(true)}>
					<FormattedMessage id="pages.contract.actions.help.title" />
				</Button>
			</Tooltip>
		)
	}

	topActionsButtons = addToPriorityButton ? [addToPriorityButton] : [managerHelpButton, closeRiskButton]

	const valueRiskIcon = contract.contractNegotiation ?
		<ValueRiskIcon contractNegotiation={contract.contractNegotiation} intl={intl} /> : undefined

	return (
		<>
			<PageTitle title={`${customerNameTruncated} (${contract.id})`} breadcrumbs={breadcrumbs} topActionButtons={topActionsButtons} />
			<Box>
				<Grid container spacing={2}>
					<Grid item xs={12} md={9}>
						<Card>
							<CardHeader
								title={intl.formatMessage({ id: 'pages.contract.information.title' })}
								action={<Box className={classes.cardHeader}>
									{valueRiskIcon}
									{contract.contractNegotiation && <StatusChip
										Icon={CircleIcon}
										{...getColorByStatus(contract.contractNegotiation.manualStatus)}
										label={sanitizeLabel(intl.formatMessage({ id: `pages.contract.information.status.${contract.contractNegotiation.manualStatus}` }))}
									/>}
								</Box>}
								className={classes.root}
							/>
							<CardContent>
								<Grid container spacing={3}>
									<Grid item xs={12}>
										<Grid container spacing={2}>
											<Grid item xs={3}>
												<Typography variant="subtitle2" ><FormattedMessage id="pages.contract.information.headers.customerIdCrm"/></Typography>
												<Typography variant="subtitle1" >{contract.customer.idCrm}</Typography>
											</Grid>
											<Grid item xs={3}>
												<Tooltip title={intl.formatMessage({ id: 'pages.contract.information.headers.contractValue.tooltip' })}>
													<Typography variant="subtitle2"><FormattedMessage id="pages.contract.information.headers.contractValue.title" /></Typography>
												</Tooltip>
												<Typography variant="subtitle1">{contract.contractValue.toLocaleString()} {contract.currency}</Typography>
											</Grid>
											<Grid item xs={3}>
												<Typography variant="subtitle2"><FormattedMessage id="pages.contract.information.headers.value" /></Typography>
												<Typography variant="subtitle1">{indicatorRound(contract.valueIndicator)}/5</Typography>
											</Grid>
											<Grid item xs={3}>
												<Typography variant="subtitle2"><FormattedMessage id="pages.contract.information.headers.riskTypology.title" /></Typography>
												<Box display='flex'>
													{contract.salesFlag &&
													<Box
														className={classes.badgeTypology}
														onClick={() => {
															setOpenRiskFlag(true)
															setFlagDetails(contract.salesFlagDetails)
															setFlagTitle(intl.formatMessage({ id: 'pages.contract.information.headers.riskTypology.flags.sales.title' }))
														}}
													>
														<DollarIcon style={{ fontSize: '16px' }} />
														<Typography><FormattedMessage id="pages.contract.information.headers.riskTypology.flags.sales.name" /></Typography>
													</Box>}
													{contract.fieldFlag &&
													<Box
														className={classes.badgeTypology}
														onClick={() => {
															setOpenRiskFlag(true)
															setFlagDetails(contract.fieldFlagDetails)
															setFlagTitle(intl.formatMessage({ id: 'pages.contract.information.headers.riskTypology.flags.field.title' }))
														}}
													>
														<WrenchIcon style={{ fontSize: '16px' }} />
														<Typography className={classes.badgeLabel}> <FormattedMessage id="pages.contract.information.headers.riskTypology.flags.field.name" /></Typography>
													</Box>}
												</Box>
											</Grid>
											<Grid item xs={3}>
												<Typography variant="subtitle2"><FormattedMessage id="pages.contract.information.headers.units" /></Typography>
												<Typography variant="subtitle1">{contract.unitsNumber}</Typography>
											</Grid>
											<Grid item xs={3}>
												<Tooltip title={intl.formatMessage({ id: 'pages.contract.information.headers.price.title' })}>
													<Typography variant="subtitle2"><FormattedMessage id="pages.contract.information.headers.price.title" /></Typography>
												</Tooltip>
												<Typography variant="subtitle1">{contract.currentSellingPrice.toLocaleString()} {contract.currency}</Typography>
											</Grid>
											<Grid item xs={3}>
												<Typography variant="subtitle2"><FormattedMessage id="pages.contract.information.headers.risk" /></Typography>
												<Typography
													variant="subtitle1">{indicatorRound(contract.riskIndicator)}/5</Typography>
											</Grid>
											{
												renegotiationTip.trim().length !== 0 && (
													<Grid item xs={3}>
														<Typography variant="subtitle2"><FormattedMessage id="pages.contract.information.headers.renegotiationTip.header" /></Typography>
														<Box className={classes.badgeButton}
														     onClick={() => setOpenRenegotiationTipDialog(true)}
														>
															<GradingIcon style={{ fontSize: '19px' }}/>
														</Box>
													</Grid>
												)
											}
											<Grid item xs={3}>
												<Typography variant="subtitle2"><FormattedMessage id="pages.contract.information.headers.callbacks" /></Typography>
												<Typography variant="subtitle1">{contract.callbacks}</Typography>
											</Grid>
											<Grid item xs={3}>
												<Typography variant="subtitle2"><FormattedMessage id="pages.contract.information.headers.endDate" /></Typography>
												<Typography
													variant="subtitle1">{new Date(contract.endDate).toLocaleDateString()}</Typography>
											</Grid>
											<Grid item xs={3}>
												<Typography variant="subtitle2"><FormattedMessage id="pages.contract.information.headers.invoicesValue" /></Typography>
												<Typography
													variant="subtitle1">{contract.openInvoiceValue.toLocaleString()} {contract.currency}</Typography>
											</Grid>
											<Grid item xs={renegotiationTip.trim().length !== 0 ? 3 : 6}>
												<Typography variant="subtitle2"><FormattedMessage id="pages.contract.information.headers.address" /></Typography>
												<Typography variant="subtitle1">{contract.address}</Typography>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12} md={3}>
						<OtherContracts />
					</Grid>
					{contract.contractNegotiation && contract.contractNegotiation.closureDate && contract.contractNegotiation.closureUser && (
						<Grid item xs={12}>
							<Card>
								<CardHeader
									className={classes.headTools}
									title={
										<Box display="flex" flexGrow={1} justifyContent="space-between" flexWrap="wrap" alignItems="center">
											<Grid item xs={12} md={6}>
												<Box display="flex" justifyContent="flex-start" flexGrow="1" mb={{ xs: '20px', md: 0 }}>
													<FormattedMessage
														id="pages.contract.information.closed.text"
														values={{
															date: new Date(contract.contractNegotiation.closureDate).toLocaleDateString(),
															firstName: contract.contractNegotiation.closureUser.firstName,
															lastName: contract.contractNegotiation.closureUser.lastName
														}}
													/>
												</Box>
											</Grid>
											{!hasProfile(user, PROFILE.SALES_REPRESENTATIVE) && <Grid item xs={12} md={6}>
												<Box display="flex" justifyContent="flex-end">
													<Box ml={{ xs: 0, md: 2 }} display="flex"
														 flexGrow={{ xs: 1, md: 0 }}>
														<Button
															variant="contained"
															size="large"
															color="primary"
															disableElevation={true}
															onClick={() => setOpenReopenDialog(true)}
														>
															<FormattedMessage id="pages.contract.information.closed.button" />
														</Button>
													</Box>
												</Box>
											</Grid>}
										</Box>
									}
								/>
								<CardContent>
									<Typography className={classes.commentTitle}><FormattedMessage id="pages.contract.information.comment" /></Typography>
									<Typography
										className={classes.comment}>{contract.contractNegotiation.closureInfo}</Typography>
								</CardContent>
							</Card>
						</Grid>
					)}
					{(contract.contractNegotiation || contract.module) &&
					<Grid item xs={12}>
						<ContractContent contract={contract} refreshActions={refreshActions} actions={actions} dispatch={dispatch} refreshed={refreshed} />
					</Grid>
					}
					{contract.contractNegotiation && contract.forecastModule &&
					<Grid item xs={12}>
						<ForecastModule contract={contract} dispatch={dispatch} />
					</Grid>
					}
					<Grid item xs={12}>
						<Box display="flex" justifyContent="flex-end">
							{closeRiskButton}
						</Box>
					</Grid>
				</Grid>
			</Box>
			<CloseRiskDialog contract={contract} open={openCloseRisk} onClose={() => setOpenCloseRisk(false)} contractsDispatch={dispatch} />
			<ErrorPopup
				open={openCloseRiskError}
				onClose={() => setOpenCloseRiskError(false)}
				title={intl.formatMessage({ id: 'pages.contract.information.dialog.error.title' })}
				content={intl.formatMessage({ id: 'pages.contract.information.dialog.error.text' })}
			/>
			{hasProfile(user, PROFILE.SALES_REPRESENTATIVE) ?
				<ConfirmationDialog
					cancelAction={() => setOpenReopenDialog(false)}
					title={intl.formatMessage({ id: 'pages.contract.information.dialog.notAvailable.title' })}
					content={intl.formatMessage({ id: 'pages.contract.information.dialog.notAvailable.text' })}
					noConfirmAction
					cancelActionLabel={intl.formatMessage({ id: 'pages.contract.information.dialog.notAvailable.cancel' })}
					open={openReopenDialog}
					onClose={() => setOpenReopenDialog(false)}
				/> :
				<ConfirmationDialog
					cancelAction={() => setOpenReopenDialog(false)}
					confirmAction={() => confirmReopen()}
					title={intl.formatMessage({ id: 'pages.contract.information.dialog.reOpen.title' })}
					content={intl.formatMessage({ id: 'pages.contract.information.dialog.reOpen.text' })}
					confirmActionLabel={intl.formatMessage({ id: 'pages.contract.information.dialog.reOpen.confirm' })}
					open={openReopenDialog}
					onClose={() => setOpenReopenDialog(false)}
				/>
			}
			<RiskTypologyPopup
				open={openRiskFlag}
				onClose={() => setOpenRiskFlag(false)}
				title={flagTitle}
				flagDetails={flagDetails}
			/>
			<ConfirmationDialog
				cancelAction={() => setOpenAddPriority(false)}
				confirmAction={() => confirmManuallyPrioritize()}
				title={intl.formatMessage({ id: 'pages.contract.information.dialog.addPriority.title' })}
				content={intl.formatMessage({ id: 'pages.contract.information.dialog.addPriority.text' })}
				confirmActionLabel={intl.formatMessage({ id: 'pages.contract.information.dialog.addPriority.confirm' })}
				cancelActionLabel={intl.formatMessage({ id: 'pages.contract.information.dialog.addPriority.cancel' })}
				open={openAddPriority}
				onClose={() => setOpenAddPriority(false)}
			/>
			<RenegotiationTipDialog
				open={openRenegotiationTipDialog}
				onClose={() => setOpenRenegotiationTipDialog(false)}
				tip={renegotiationTip}
			/>
		</>
	)
}

const enhance = compose(
	withStyles(styles),
	withRouter,
	injectIntl
)
export default enhance(Contract)
