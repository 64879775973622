import React, { createContext, useState } from 'react'

const defaultFiltersNotDoneContract = {
	searchQuery: '',
	status: ''
}

const defaultFiltersDoneContract = {
	searchQuery: '',
	status: ''
}

const defaultFiltersActionList = {
	searchQuery: '',
	status: 'OPEN'
}

const ActionsFiltersContext = createContext({})

const ActionsFiltersContextProvider = ({ children, contractStatus, isActions }) => {
	const [filters, setFilters] = useState(
		isActions ? defaultFiltersActionList :
		!contractStatus || contractStatus === 'TODO' || contractStatus === 'IN_PROGRESS' ? defaultFiltersNotDoneContract : defaultFiltersDoneContract
	)

	const setSearchQuery = (searchQuery) => setFilters({ ...filters, searchQuery })

	return (
		<ActionsFiltersContext.Provider value={{ filters, setFilters, setSearchQuery }}>
			{children}
		</ActionsFiltersContext.Provider>
	)
}

export { ActionsFiltersContext, ActionsFiltersContextProvider }