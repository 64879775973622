import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	MenuItem,
	withStyles,
} from '@material-ui/core'
import { FormInput, FormSelect, fetchFactory } from 'isotope-client'
import React from 'react'
import { Field, Form } from 'react-final-form'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Country } from '../types'
import { compose } from 'recompose'

interface UpdateCountryDialogProps {
	classes: any
	country: Country
	onClose: () => void
	intl: any
}

type FormError = {
	name?: string
	lowValueThreshold?: string
	highRiskValueThreshold?: string
	lowValueAmount?: string
	highRiskValueAmount?: string
	refresh?: string
	forecastModule?: string,
	usdConversionRate?: string
}

const styles = (_theme: any) => {
	return {
		select: {
			'&::before': {
				content: 'unset',
			},
			'&::after': {
				content: 'unset',
			},
		},
	}
}

const UpdateCountryDialog: React.FC<UpdateCountryDialogProps> = ({ classes, intl, country, onClose }) => {
	const initialValues = {
		name: country.name,
		lowValueThreshold: country.lowValueThreshold,
		highRiskValueThreshold: country.highRiskValueThreshold,
		lowValueAmount: country.lowValueAmount,
		highRiskValueAmount: country.highRiskValueAmount,
		refresh: country.refresh,
		forecastModule: country.forecastModule,
		usdConversionRate: country.usdConversionRate
	}

	const options = [
		{
			value: false,
			name: intl.formatMessage({ id: 'countries.dialog.values.false' }),
		},
		{
			value: true,
			name: intl.formatMessage({ id: 'countries.dialog.values.true' }),
		},
	]

	function onSubmit(values: any) {
		fetchFactory(`/countries/${country.id}`, { method: 'put', body: JSON.stringify(values) })
			.then(() => onClose())
			.catch((error: any) => console.error(error))
	}

	function validate(values: Country) {
		const errors: FormError = {}
		if (!values.name) {
			errors.name = intl.formatMessage({ id: 'countries.dialog.errors.required' })
		}

		if (!values.lowValueThreshold) {
			errors.lowValueThreshold = intl.formatMessage({ id: 'countries.dialog.errors.required' })
		} else if (values.lowValueThreshold > 999) {
			errors.lowValueThreshold = intl.formatMessage({ id: 'countries.dialog.errors.maximum' })
		}

		if (!values.highRiskValueThreshold) {
			errors.highRiskValueThreshold = intl.formatMessage({ id: 'countries.dialog.errors.required' })
		} else if (values.highRiskValueThreshold > 999) {
			errors.highRiskValueThreshold = intl.formatMessage({ id: 'countries.dialog.errors.maximum' })
		}

		if (!values.lowValueAmount) {
			errors.lowValueAmount = intl.formatMessage({ id: 'countries.dialog.errors.required' })
		} else if (values.lowValueAmount <= 0) {
			errors.lowValueAmount = intl.formatMessage({ id: 'countries.dialog.errors.negative' })
		} else if (values.lowValueAmount > 999) {
			errors.lowValueAmount = intl.formatMessage({ id: 'countries.dialog.errors.maximum' })
		}

		if (!values.highRiskValueAmount) {
			errors.highRiskValueAmount = intl.formatMessage({ id: 'countries.dialog.errors.required' })
		} else if (values.highRiskValueAmount <= 0) {
			errors.highRiskValueAmount = intl.formatMessage({ id: 'countries.dialog.errors.negative' })
		} else if (values.highRiskValueAmount > 999) {
			errors.highRiskValueAmount = intl.formatMessage({ id: 'countries.dialog.errors.maximum' })
		}

		if (values.refresh === null || values.refresh === undefined) {
			errors.forecastModule = intl.formatMessage({ id: 'countries.dialog.errors.required' })
		}

		if (values.refresh === null || values.refresh === undefined) {
			errors.forecastModule = intl.formatMessage({ id: 'countries.dialog.errors.required' })
		}

		if (!values.usdConversionRate) {
			errors.usdConversionRate = intl.formatMessage({ id: 'countries.dialog.errors.required' })
		} else if (values.usdConversionRate <= 0) {
			errors.usdConversionRate = intl.formatMessage({ id: 'countries.dialog.errors.negative' })
		} else if (values.usdConversionRate > 999) {
			errors.usdConversionRate = intl.formatMessage({ id: 'countries.dialog.errors.maximum' })
		}

		return errors
	}

	return (
		<Dialog open={!!country} onClose={onClose} fullWidth maxWidth="sm">
			<DialogTitle>
				<FormattedMessage id="countries.dialog.title" />
			</DialogTitle>
			<Form
				initialValues={initialValues}
				onSubmit={onSubmit}
				validate={validate}
				render={({ handleSubmit }) => (
					<form onSubmit={handleSubmit}>
						<DialogContent>
							<Grid container spacing={4}>
								<Grid item xs={12}>
									<Field
										name="name"
										fullWidth
										component={FormInput}
										label={intl.formatMessage({ id: 'countries.dialog.labels.name' })}
										maxLength={255}
									/>
								</Grid>
								<Grid item xs={12}>
									<Field
										name="lowValueThreshold"
										fullWidth
										component={FormInput}
										type="number"
										label={intl.formatMessage({ id: 'countries.dialog.labels.lowValueThreshold' })}
										maxLength={255}
									/>
								</Grid>
								<Grid item xs={12}>
									<Field
										name="highRiskValueThreshold"
										fullWidth
										component={FormInput}
										type="number"
										label={intl.formatMessage({ id: 'countries.dialog.labels.highRiskValueThreshold' })}
										maxLength={255}
									/>
								</Grid>
								<Grid item xs={12}>
									<Field
										name="lowValueAmount"
										fullWidth
										component={FormInput}
										type="number"
										label={intl.formatMessage({ id: 'countries.dialog.labels.lowValueAmount' })}
										maxLength={255}
									/>
								</Grid>
								<Grid item xs={12}>
									<Field
										name="highRiskValueAmount"
										fullWidth
										component={FormInput}
										type="number"
										label={intl.formatMessage({ id: 'countries.dialog.labels.highRiskValueAmount' })}
										maxLength={255}
									/>
								</Grid>
								<Grid item xs={12}>
									<Field
										name="refresh"
										fullWidth
										component={FormSelect}
										inputStyle={classes.select}
										label={intl.formatMessage({ id: 'countries.dialog.labels.refresh' })}
									>
										{options.map((option, key) => (
											<MenuItem key={key} value={option.value as any}>
												{option.name}
											</MenuItem>
										))}
									</Field>
								</Grid>
								<Grid item xs={12}>
									<Field
										name="forecastModule"
										fullWidth
										component={FormSelect}
										inputStyle={classes.select}
										label={intl.formatMessage({ id: 'countries.dialog.labels.forecastModule' })}
									>
										{options.map((option, key) => (
											<MenuItem key={key} value={option.value as any}>
												{option.name}
											</MenuItem>
										))}
									</Field>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<Field
									name="usdConversionRate"
									fullWidth
									component={FormInput}
									type="number"
									label={intl.formatMessage({ id: 'countries.dialog.labels.usdConversionRate' })}
									maxLength={255}
								/>
							</Grid>
						</DialogContent>
						<DialogActions>
							<Button onClick={onClose}>
								<FormattedMessage id="countries.dialog.actions.cancel" />
							</Button>
							<Button type="submit" variant="contained" size="small" color="primary" disableElevation>
								<FormattedMessage id="countries.dialog.actions.update" />
							</Button>
						</DialogActions>
					</form>
				)}
			/>
		</Dialog>
	)
}

export default compose(withStyles(styles), injectIntl)(UpdateCountryDialog)
