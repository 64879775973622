import { dataTable as tablereducer } from 'isotope-client/components/datatable/connected/services/dataTableReducer'
import React, { createContext, useReducer } from 'react'
import { withRouter } from 'react-router'
import { initialState, reducer } from '../modules/backOffice/users/services/usersReducer'

const UsersContext = createContext({})

const UsersContextProvider = withRouter(({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState)
	const [tableState, tableDispatch] = useReducer(tablereducer, {})

	return (
		<UsersContext.Provider
			value={{
				filters: state.filters,
				userForUpdateDialog: state.userForUpdateDialog,
				newUserDialog: state.newUserDialog,
				dispatch,
				tableState,
				tableDispatch
			}}>
			{children}
		</UsersContext.Provider>
	)
})

export { UsersContext, UsersContextProvider }
