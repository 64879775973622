import {
	AppBar,
	Badge,
	Box,
	Button,
	IconButton,
	InputAdornment,
	Toolbar,
	Typography,
	withStyles
} from '@material-ui/core'
import {
	Close as CloseIcon,
	Menu as MenuIcon,
	NotificationsOutlined as NotificationsIcon,
	Search as SearchIcon
} from '@material-ui/icons'

import React, { useCallback, useContext, useState } from 'react'
import SearchBar from '../../../components/SearchBar'
import SearchTray from '../../searchTray/SearchTray'
import NotificationsTray from './NotificationsTray'
import { hasAnyProfile, hasProfile } from '../../../utils/accessChecker'
import { PROFILE } from '../../../enums'
import { LoggedInContext } from '../../../contexts/LoggedInContext'
import { Link } from 'react-router-dom'
import HomeIcon from '@material-ui/icons/HomeOutlined'
import { FormattedMessage, injectIntl } from 'react-intl'
import { compose } from 'recompose'

const styles = (theme) => ({
	root: {
		height: 85,
		boxShadow: '0px 0px 3px 6px rgba(60,68,91,.15)'
	},
	boxLeft: {
		display: 'flex',
		flexGrow: 1,
		alignItems: 'center',
		justifyContent: 'flex-start',
		'& > *': {
			marginRight: 15
		}
	},
	boxRight: {
		display: 'flex',
		flexGrow: 1,
		alignItems: 'center',
		justifyContent: 'flex-end',
		'& > *': {
			marginLeft: 15
		}
	},
	positionToggle: {
		position: 'relative',
		marginLeft: 0
	},
	toggleNav: {
		color: theme.palette.secondary.main,
		width: 30,
		height: 30,
		left: 30
	},
	titleNavBar: {
		textTransform: 'uppercase',
		fontSize: 17,
		fontWeight: 700,
		color: theme.palette.text.primary
	},
	searchBar: {
		padding: '0 15px',
		maxWidth: 360,
		width: '100%',
		opacity: 1,
		color: theme.palette.grey['800'],
		backgroundColor: theme.palette.grey['50'],
		'& [class*="MuiSelect-icon"]': {
			right: 10
		}

	},
	btnPrimary: {
		color: theme.palette.grey['600'],
		backgroundColor: theme.palette.text.secondary,
		border: '1px solid',
		borderColor: theme.palette.background.lightShadow,
		borderRadius: '4px',
		boxShadow: '0 2px 0 0 #E5EAF5',
		transition: 'all .2s ease',
		'&:hover': {
			backgroundColor: theme.palette.text.secondary,
			borderColor: theme.palette.grey['700']
		}
	},
	homeIcon: {
		width: 20,
		height: 20,
		marginLeft: 0,
		margitRight: 17
	},
	pinBadge: {
		'& [class*="MuiBadge-badge"]': {
			position: 'absolute',
			top: '-10px',
			right: '-10px',
			backgroundColor: theme.palette.secondary.light,
			'&:after': {
				content: '\'\'',
				position: 'absolute',
				top: 6,
				zIndex: 1000,
				display: 'block',
				height: 8,
				width: 8,
				borderRadius: 8,
				backgroundColor: theme.palette.secondary.main
			}
		}
	}
})

const MobileNavbar = ({ toggleDrawer, classes, overdueContracts, overdueActions, needHelpContracts, hasNotification, intl }) => {
	const { user } = useContext(LoggedInContext)
	const [notificationAnchorEl, setNotificationAnchorEl] = useState(null)
	const [searchAnchorEl, setSearchAnchorEl] = useState(null)
	const [searchBar, setSearchBar] = useState(false)

	const [searchQuery, setSearchQuery] = useState('')

	const searchBarClick = useCallback((e) => {
			if (e.target.path && e.target.path.filter(a => a.name === 'closeIcon').length === 0) {
				if (!Boolean(searchAnchorEl) && searchQuery) {
					setSearchAnchorEl(e.target)
				}
			}
		}, [searchAnchorEl, searchQuery]
	)
	const searchBarChange = useCallback((e) => {
			if (e.target.value.length > 1 && !searchAnchorEl) {
				setSearchAnchorEl(e.currentTarget)
			}
			if (!e.target.value) {
				setSearchAnchorEl(null)
			}
			setSearchQuery(e.target.value)
		}, [searchAnchorEl]
	)

	const title = React.useMemo(() => {
		if (user.countryName) {
			return <Typography className={classes.titleNavBar}><FormattedMessage id="title" /><br />{user.countryName.toLocaleUpperCase()}</Typography>
		}
		return <Typography className={classes.titleNavBar}><FormattedMessage id="title" /></Typography>
	}, [user, classes])

	const multiCountries = React.useMemo(() => hasProfile(user, PROFILE.ZONE_MANAGER) || (hasProfile(user, PROFILE.NATIONAL) && user.countries.length > 1), [user])

	return (
		<>
			<AppBar>
				<Toolbar>
					{searchBar ?
						<>
							<SearchBar
								onClick={searchBarClick}
								onChange={searchBarChange}
								placeholder={intl.formatMessage({ id : 'searchBar' })}
								endAdornment={
									<InputAdornment position="start">
										<CloseIcon name="closeIcon" onClick={() => {
											setSearchBar(false)
											setSearchQuery('')
											setSearchAnchorEl(null)
										}} />
									</InputAdornment>
								}
							/>
							<SearchTray
								anchorEl={searchAnchorEl}
								isMenuOpen={Boolean(searchAnchorEl)}
								handleMenuClose={() => setSearchAnchorEl(null)}
								query={searchQuery}
							/>
						</>
						:
						<>
							<Box className={classes.boxLeft}>
								<IconButton onClick={toggleDrawer} className={classes.positionToggle}>
									<MenuIcon className={classes.toggleNav} />
								</IconButton>
								{multiCountries
									? <Button
										className={classes.btnPrimary}
										classes={{
											startIcon: classes.homeIcon
										}}
										component={Link}
										startIcon={<HomeIcon />}
										to="/select-country"
									>{title}</Button>
									: title}
							</Box>
							<Box className={classes.boxRight}>
								<IconButton className={classes.btnPrimary}>
									<SearchIcon onClick={() => setSearchBar(true)} />
								</IconButton>
								{!hasAnyProfile(user, [PROFILE.ZONE_MANAGER, PROFILE.NATIONAL]) &&
								<IconButton onClick={(e) => setNotificationAnchorEl(e.currentTarget)} className={classes.btnPrimary}>
									<Badge badgeContent="" color="secondary" className={classes.pinBadge} invisible={!hasNotification}>
										<NotificationsIcon />
									</Badge>
								</IconButton>}
							</Box>
							<NotificationsTray
								anchorEl={notificationAnchorEl}
								isMenuOpen={Boolean(notificationAnchorEl)}
								handleMenuClose={() => setNotificationAnchorEl(null)}
								overdueActions={overdueActions}
								overdueContracts={overdueContracts}
								needHelpContracts={needHelpContracts}
							/>
						</>
					}
				</Toolbar>
			</AppBar>
		</>
	)
}

export default compose(
	withStyles(styles),
	injectIntl
)(MobileNavbar)