import { Avatar, Box, Tooltip, Typography, withStyles } from '@material-ui/core'
import { Alarm as ClockIcon, AttachMoney as DollarIcon, BuildOutlined as WrenchIcon, FiberManualRecordRounded as CircleIcon, UnsubscribeOutlined } from '@material-ui/icons'
import { SpringDataTable } from 'isotope-client'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import NoResultFragment from '../../components/NoResult'
import StatusChip from '../../components/StatusChip'
import ValueRiskIcon from '../../components/ValueRiskIcon'
import { ContractsContext } from '../../contexts/ContractsContext'
import { LoggedInContext } from '../../contexts/LoggedInContext'
import { getColorByStatus } from '../../utils/getColorByStatus'
import indicatorRound from '../../utils/indicatorRound'
import { sanitizeLabel } from '../../utils/stringUtils'
import TablePaginationActions from '../datatable/TablePaginationActions'
import { CRM_STATUS } from '../../enums'
import { ErrorHandlerContext } from '../../contexts/ErrorHandlerContext'
import RiskTypologyPopup from '../../components/RiskTypologyPopup'
import { compose } from 'recompose'
import { FormattedMessage, injectIntl } from 'react-intl'
import { TABLE_URL } from '../../components/TabBarDesktop'
import { OVERVIEW_TABLE_URL } from '../../constants'
import GradingIcon from '../../svg/GradingIcon'
import RenegotiationTipDialog from './RenegotiationTipDialog'
import { getRenegotiationTipFromMatrix } from '../../utils/utils'

const styles = (theme) => ({
	cardTable: {
		display: 'flex',
		flexDirection: 'column',
		flex: '0 1 300px',
		width: '100%'
	},
	titleCardTable: {
		fontSize: 15,
		fontWeight: '700',
		textTransform: 'uppercase',
		color: theme.palette.grey['800']
	},
	subCardTable: {
		fontSize: 12,
		fontWeight: '400',
		textTransform: 'uppercase',
		color: theme.palette.grey['500']
	},
	avatar: {
		marginRight: 15
	},
	iconTypology: {
		height: 26,
		width: 26,
		padding: 3,
		margin: '-4px 3px',
		borderRadius: 5,
		borderColor: theme.palette.background.lightShadow,
		border: '2px solid',
		cursor: 'pointer'
	},
	error: {
		color: theme.palette.error.main,
		fontSize: 15,
		fontWeight: 600
	},
	clockIcon: {
		color: theme.palette.warning.icon
	},
	hidden: {
		display: 'none'
	},
	titleTooltip: {
		color: '#94A5BC',
		fontWeight: 700,
	},
	valueTooltip: {
		color: '#B9B9B9',
		fontWeight: 700,
	},
	badgeButton: {
		borderRadius: 4,
		border: '2px solid',
		borderColor: theme.palette.grey['100'],
		height: 26,
		width: 26,
		marginTop: 2,
		alignItems: 'center',
		padding: '2px',
		cursor: 'pointer',
		margin: '0px 6px'
	},
	flex: {
		display: 'flex',
		alignItems: 'center'
	}
})

function convertIfNull(value) {
	return value !== null && value !== undefined ? value : '-'
}

const TableContentDesktop = ({ classes, isOverview, intl, modules, renegotiationMatrix }) => {
	const { filters, tableUrl, userId } = useContext(ContractsContext)
	const { user } = useContext(LoggedInContext)
	const [shouldRefresh, setShouldRefresh] = useState(true)
	const { setError } = React.useContext(ErrorHandlerContext)
	const [openRiskFlag, setOpenRiskFlag] = React.useState(false)
	const [openRenegotiationTipDialog, setOpenRenegotiationTipDialog] = React.useState(false)
	const [flagDetails, setFlagDetails] = React.useState('')
	const [flagTitle, setFlagTitle] = React.useState('')
	const [loading, setLoading] = React.useState(true)
	const [ready, setReady] = React.useState(false)
	const [renegotiationTip, setRenegotiationTip] = React.useState("")

	useEffect(() => {setShouldRefresh(true)}, [user])
	
	useEffect(() => {setLoading(true)}, [tableUrl])

	useEffect(() => {
		if (modules.id) setReady(true)
	}, [modules])

	const getRenegotiationTip = useCallback((contract) =>
			getRenegotiationTipFromMatrix(contract.priceScore, contract.riskIndicator, renegotiationMatrix, intl)
		, [renegotiationMatrix, intl])

	let headers
	if ((tableUrl === TABLE_URL.ALL || tableUrl === OVERVIEW_TABLE_URL.ALL) && modules.countryModule === "FR") {
		headers = [
			{
				key: 'id',
				name: intl.formatMessage({ id: 'pages.contractsOverview.table.headers.contract.title' }),
				render: (contractFrance) => (
					<Box display="flex" alignItems="center">
						{isOverview && (
							<Tooltip
								title={`${contractFrance.contract.firstNameSalesRep} ${contractFrance.contract.lastNameSalesRep}`}
							>
								<Link to={`/portfolio/${contractFrance.contract.idSalesRep}`}>
									<Avatar className={classes.avatar}>
										{(
											contractFrance.contract.firstNameSalesRep[0] + contractFrance.contract.lastNameSalesRep[0]
										).toUpperCase()}
									</Avatar>
								</Link>
							</Tooltip>
						)}
						<Box className={classes.cardTable}>
							<Link to={`/contracts/${contractFrance.contract.id}`}>
								<Typography display="inline" className={classes.titleCardTable}>
									{contractFrance.contract.customer.name} ({contractFrance.contract.id})
								</Typography>
								{contractFrance.contract.crmStatus === CRM_STATUS.CANCELLED && (
									<Tooltip
										title={intl.formatMessage({
											id: 'pages.contractsOverview.table.headers.contract.cancelled.tooltip',
										})}
										arrow
									>
										<Typography display="inline" className={classes.error}>
											{' '}
											<FormattedMessage id="pages.contractsOverview.table.headers.contract.cancelled.title" />
										</Typography>
									</Tooltip>
								)}
								{contractFrance.contract.contractNegotiation &&
									contractFrance.contract.contractNegotiation.helpNeeded && (
										<Typography display="inline" className={classes.error}>
											{' '}
											<FormattedMessage id="pages.contractsOverview.table.headers.contract.needHelp.title" />
										</Typography>
									)}
								<Typography className={classes.subCardTable}>{contractFrance.contract.address}</Typography>
							</Link>
						</Box>
					</Box>
				),
			},
			{
				key: 'status',
				name: intl.formatMessage({ id: 'pages.contractsOverview.table.headers.status.title' }),
				render: (contractFrance) =>
					contractFrance.contract.contractNegotiation ? (
						<StatusChip
							Icon={CircleIcon}
							color={getColorByStatus(contractFrance.contract.contractNegotiation.manualStatus).color}
							gradient={getColorByStatus(contractFrance.contract.contractNegotiation.manualStatus).gradient}
							label={sanitizeLabel(
								intl.formatMessage({
									id: `pages.contract.information.status.${contractFrance.contract.contractNegotiation.manualStatus}`,
								})
							)}
						/>
					) : (
						<Tooltip
							title={intl.formatMessage({ id: 'pages.contractsOverview.table.headers.status.notPriority.tooltip' })}
						>
							<Typography>
								<FormattedMessage id="pages.contractsOverview.table.headers.status.notPriority.title" />
							</Typography>
						</Tooltip>
					),
			},
			{
				key: 'units',
				name: intl.formatMessage({ id: 'pages.contractsOverview.table.headers.unitsNumber.title' }),
				render: (contractFrance) => (
					<Tooltip
						title={
							<>
								<Typography className={classes.titleTooltip}>
									<FormattedMessage id="pages.contractsOverview.table.headers.unitsNumber.tooltip" />
								</Typography>
								<Typography className={classes.valueTooltip}>
									{contractFrance.contract.currentSellingPrice
										? `${contractFrance.contract.currentSellingPrice} ${contractFrance.contract.currency}`
										: '-'}
								</Typography>
							</>
						}
					>
						<Typography>{convertIfNull(contractFrance.contract.unitsNumber)}</Typography>
					</Tooltip>
				),
			},
			{
				key: 'valueAndRiskIndicator',
				name: `${intl.formatMessage({
					id: 'pages.contractsOverview.table.headers.valueIndicator.title',
				})}\u00A0|\u00A0${intl.formatMessage({ id: 'pages.contractsOverview.table.headers.riskIndicator.title' })}`,
				render: (contractFrance) => (
					<Box className={classes.flex}>
						<Tooltip
							title={intl.formatMessage(
								{ id: 'pages.contractsOverview.table.headers.valueIndicator.tooltip' },
								{ indicator: indicatorRound(contractFrance.contract.valueIndicator) }
							)}
						>
							<Typography>{indicatorRound(contractFrance.contract.valueIndicator)}</Typography>
						</Tooltip>
						&nbsp;|&nbsp;
						<Tooltip
							title={intl.formatMessage(
								{ id: 'pages.contractsOverview.table.headers.riskIndicator.tooltip' },
								{ indicator: indicatorRound(contractFrance.contract.riskIndicator) }
							)}
						>
							<Typography>{indicatorRound(contractFrance.contract.riskIndicator)}</Typography>
						</Tooltip>
						{
							getRenegotiationTip(contractFrance.contract) !== undefined && getRenegotiationTip(contractFrance.contract).length !== 0 && (
								<Box className={classes.badgeButton}
								     onClick={() => {
									     const tip = getRenegotiationTip(contractFrance.contract)
									     if (tip !== undefined && tip.trim().length !== 0) {
										     setRenegotiationTip(tip)
										     setOpenRenegotiationTipDialog(true)
									     }
								     }}
								>
									<GradingIcon style={{ fontSize: '19px' }} />
								</Box>
							)
						}
					</Box>

				),
			},
			{
				key: 'riskTypology',
				name: intl.formatMessage({ id: 'pages.contractsOverview.table.headers.riskTypology.title' }),
				render: (contractFrance) => (
					<Box display="flex">
						{contractFrance.contract.contractNegotiation &&
							contractFrance.contract.contractNegotiation.classification && (
								<ValueRiskIcon contractNegotiation={contractFrance.contract.contractNegotiation} intl={intl} />
							)}
						{contractFrance.contract.salesFlag && (
							<DollarIcon
								className={classes.iconTypology}
								onClick={() => {
									setOpenRiskFlag(true)
									setFlagDetails(contractFrance.contract.salesFlagDetails)
									setFlagTitle(
										intl.formatMessage({ id: 'pages.contractsOverview.table.headers.riskTypology.flag.sales' })
									)
								}}
							/>
						)}
						{contractFrance.contract.fieldFlag && (
							<WrenchIcon
								className={classes.iconTypology}
								onClick={() => {
									setOpenRiskFlag(true)
									setFlagDetails(contractFrance.contract.fieldFlagDetails)
									setFlagTitle(
										intl.formatMessage({ id: 'pages.contractsOverview.table.headers.riskTypology.flag.field' })
									)
								}}
							/>
						)}
					</Box>
				),
			},
			{
				key: 'advancedNoticePeriodDate',
				name: intl.formatMessage({ id: 'pages.contractsOverview.table.headers.advancedNoticePeriodDate' }),
				render: (contractFrance) => (
					<Typography>
						{contractFrance.advancedNoticePeriodDate
							? new Date(contractFrance.advancedNoticePeriodDate).toLocaleDateString()
							: '-'}
					</Typography>
				),
			},
			{
				key: 'endDate',
				name: intl.formatMessage({ id: 'pages.contractsOverview.table.headers.endDate.title' }),
				render: (contractFrance) => (
					<Tooltip
						title={
							<>
								<Typography className={classes.titleTooltip}>
									<FormattedMessage id="pages.contractsOverview.table.headers.endDate.tooltip.contractEndTypology" />
								</Typography>
								<Typography className={classes.valueTooltip}>
									{convertIfNull(contractFrance.contractEndTypology)}
								</Typography>
								{contractFrance.requestedCancellationDate && (
									<>
										<Typography className={classes.titleTooltip}>
											<FormattedMessage id="pages.contractsOverview.table.headers.endDate.tooltip.requestedCancellationDate" />
										</Typography>
										<Typography className={classes.valueTooltip}>
											{new Date(contractFrance.requestedCancellationDate).toLocaleDateString()}
										</Typography>
									</>
								)}
							</>
						}
					>
						{contractFrance.requestedCancellationDate ? (
							<Typography color="secondary" style={{ alignItems: 'center' }}>
								{new Date(contractFrance.contract.endDate).toLocaleDateString()}&nbsp;
								<UnsubscribeOutlined />
							</Typography>
						) : (
							<Typography>{new Date(contractFrance.contract.endDate).toLocaleDateString()}</Typography>
						)}
					</Tooltip>
				),
			},
		]
	} else {
		headers = [
			{
				key: 'id',
				name: intl.formatMessage({ id: 'pages.contractsOverview.table.headers.contract.title' }),
				render: (value) => {
					const contract = modules.countryModule === 'FR' ? value.contract : value
					return (
						<Box display="flex" alignItems="center">
							{isOverview && (
								<Tooltip title={`${contract.firstNameSalesRep} ${contract.lastNameSalesRep}`}>
									<Link to={`/portfolio/${contract.idSalesRep}`}>
										<Avatar className={classes.avatar}>
											{(contract.firstNameSalesRep[0] + contract.lastNameSalesRep[0]).toUpperCase()}
										</Avatar>
									</Link>
								</Tooltip>
							)}
							<Box className={classes.cardTable}>
								<Link to={`/contracts/${contract.id}`}>
									<Typography display="inline" className={classes.titleCardTable}>
										{contract.customer.name} ({contract.id})
									</Typography>
									{contract.crmStatus === CRM_STATUS.CANCELLED && (
										<Tooltip
											title={intl.formatMessage({
												id: 'pages.contractsOverview.table.headers.contract.cancelled.tooltip',
											})}
											arrow
										>
											<Typography display="inline" className={classes.error}>
												{' '}
												<FormattedMessage id="pages.contractsOverview.table.headers.contract.cancelled.title" />
											</Typography>
										</Tooltip>
									)}
									{contract.contractNegotiation && contract.contractNegotiation.helpNeeded && (
										<Typography display="inline" className={classes.error}>
											{' '}
											<FormattedMessage id="pages.contractsOverview.table.headers.contract.needHelp.title" />
										</Typography>
									)}
									<Typography className={classes.subCardTable}>{contract.address}</Typography>
								</Link>
							</Box>
						</Box>
					)
				},
			},
			{
				key: 'status',
				name: intl.formatMessage({ id: 'pages.contractsOverview.table.headers.status.title' }),
				render: (value) => {
					const contract = modules.countryModule === 'FR' ? value.contract : value
					return contract.contractNegotiation ? (
						<StatusChip
							Icon={CircleIcon}
							{...getColorByStatus(contract.contractNegotiation.manualStatus)}
							label={sanitizeLabel(
								intl.formatMessage({
									id: `pages.contract.information.status.${contract.contractNegotiation.manualStatus}`,
								})
							)}
						/>
					) : (
						<Tooltip
							title={intl.formatMessage({ id: 'pages.contractsOverview.table.headers.status.notPriority.tooltip' })}
						>
							<Typography>
								<FormattedMessage id="pages.contractsOverview.table.headers.status.notPriority.title" />
							</Typography>
						</Tooltip>
					)
				},
			},
			{
				key: 'actionsOpened',
				name: intl.formatMessage({ id: 'pages.contractsOverview.table.headers.actionsOpened' }),
				render: (value) => {
					const contract = modules.countryModule === 'FR' ? value.contract : value
					return (
						contract.contractNegotiation &&
						contract.contractNegotiation.actions.filter((a) => a.status === 'OPEN').length
					)
				},
			},
			{
				key: 'valueIndicator',
				name: intl.formatMessage({ id: 'pages.contractsOverview.table.headers.valueIndicator.title' }),
				render: (value) => {
					const contract = modules.countryModule === 'FR' ? value.contract : value
					return (
						<Box className={classes.flex}>
							<Tooltip
								title={intl.formatMessage(
									{ id: 'pages.contractsOverview.table.headers.valueIndicator.tooltip' },
									{ indicator: indicatorRound(contract.valueIndicator) }
								)}
							>
								<Typography>{indicatorRound(contract.valueIndicator)}</Typography>
							</Tooltip>
							{
								getRenegotiationTip(contract) !== undefined && getRenegotiationTip(contract).length !== 0 && (
									<Box className={classes.badgeButton}
									     onClick={() => {
											 const tip = getRenegotiationTip(contract)
										     if (tip !== undefined && tip.trim().length !== 0) {
											     setRenegotiationTip(tip)
											     setOpenRenegotiationTipDialog(true)
										     }
									     }}
									>
										<GradingIcon style={{ fontSize: '19px' }} />
									</Box>
								)
							}
						</Box>
					)
				},
			},
			{
				key: 'riskIndicator',
				name: intl.formatMessage({ id: 'pages.contractsOverview.table.headers.riskIndicator.title' }),
				render: (value) => {
					const contract = modules.countryModule === 'FR' ? value.contract : value
					return (
						<Tooltip
							title={intl.formatMessage(
								{ id: 'pages.contractsOverview.table.headers.riskIndicator.tooltip' },
								{ indicator: indicatorRound(contract.riskIndicator) }
							)}
						>
							<Typography>{indicatorRound(contract.riskIndicator)}</Typography>
						</Tooltip>
					)
				},
			},
			{
				key: 'riskTypology',
				name: intl.formatMessage({ id: 'pages.contractsOverview.table.headers.riskTypology.title' }),
				render: (value) => {
					const contract = modules.countryModule === 'FR' ? value.contract : value
					return (
						<Box display="flex">
							{contract.contractNegotiation && contract.contractNegotiation.classification && (
								<ValueRiskIcon contractNegotiation={contract.contractNegotiation} intl={intl} />
							)}
							{contract.salesFlag && (
								<DollarIcon
									className={classes.iconTypology}
									onClick={() => {
										setOpenRiskFlag(true)
										setFlagDetails(contract.salesFlagDetails)
										setFlagTitle(
											intl.formatMessage({ id: 'pages.contractsOverview.table.headers.riskTypology.flag.sales' })
										)
									}}
								/>
							)}
							{contract.fieldFlag && (
								<WrenchIcon
									className={classes.iconTypology}
									onClick={() => {
										setOpenRiskFlag(true)
										setFlagDetails(contract.fieldFlagDetails)
										setFlagTitle(
											intl.formatMessage({ id: 'pages.contractsOverview.table.headers.riskTypology.flag.field' })
										)
									}}
								/>
							)}
						</Box>
					)
				},
			},
			{
				key: 'endDate',
				name: intl.formatMessage({ id: 'pages.contractsOverview.table.headers.endDate.title' }),
				render: (value) => {
					const contract = modules.countryModule === 'FR' ? value.contract : value
					return (
						<Box display="flex" alignItems="center">
							{modules.countryModule === 'FR' && value.requestedCancellationDate ? (
								<Tooltip
									title={
										<>
											<Typography className={classes.titleTooltip}>
												<FormattedMessage id="pages.contractsOverview.table.headers.endDate.tooltip.contractEndTypology" />
											</Typography>
											<Typography className={classes.valueTooltip}>
												{convertIfNull(value.contractEndTypology)}
											</Typography>
											{value.requestedCancellationDate && (
												<>
													<Typography className={classes.titleTooltip}>
														<FormattedMessage id="pages.contractsOverview.table.headers.endDate.tooltip.requestedCancellationDate" />
													</Typography>
													<Typography className={classes.valueTooltip}>
														{new Date(value.requestedCancellationDate).toLocaleDateString()}
													</Typography>
												</>
											)}
										</>
									}
								>
									<Typography color="secondary" style={{ alignItems: 'center' }}>
										{new Date(contract.endDate).toLocaleDateString()}&nbsp;
										<UnsubscribeOutlined />
									</Typography>
								</Tooltip>
							) : (
								<>
									{contract.contractNegotiation && new Date().getMonth() + 1 >= contract.contractNegotiation.month + 3 && (
										<Tooltip
											title={intl.formatMessage({
												id: 'pages.contractsOverview.table.headers.endDate.tooltip.desktop',
											})}
										>
											<ClockIcon className={classes.clockIcon} />
										</Tooltip>
									)}
									<Typography display="inline">&nbsp;{new Date(contract.endDate).toLocaleDateString()}</Typography>
								</>
							)}
						</Box>
					)
				},
			},
		]
	}
	 
	return (
		<>
			{!ready ? (
				<div>
					<NoResultFragment message={intl.formatMessage({ id: 'pages.contractsOverview.table.loading' })} />
				</div>
			) : (
				<>
					<div className={!loading ? classes.hidden: ''}>
						<NoResultFragment message={intl.formatMessage({ id: 'pages.contractsOverview.table.loading' })} />
					</div>
					<div className={loading ? classes.hidden : ''}>
						<SpringDataTable
							nom={`contractDataTable${tableUrl}${user?.otisActiveBranch?.id || ''}`}
							headers={headers}
							filters={filters}
							apiUrlPrefix="/api"
							shouldRefresh={shouldRefresh}
							apiUrl={`/contracts/${userId || 'me'}/${tableUrl}${
								modules.countryModule ? `/${modules.countryModule}` : ''
							}`}
							noResultFragment={
								<NoResultFragment message={intl.formatMessage({ id: 'pages.contractsOverview.table.noResult' })} />
							}
							ActionsComponent={(props) => <TablePaginationActions {...props} noPagination={false} />}
							postFetch={(data, error) => {
								error && setError(error)
								setLoading(false)
							}}
						/>
					</div>
				</>
			)}
			<RiskTypologyPopup
				open={openRiskFlag}
				onClose={() => setOpenRiskFlag(false)}
				title={flagTitle}
				flagDetails={flagDetails}
			/>
			{
				renegotiationMatrix.length > 0 && (<RenegotiationTipDialog
					open={openRenegotiationTipDialog}
					onClose={() => setOpenRenegotiationTipDialog(false)}
					tip={renegotiationTip}
				/>)
			}
		</>
	)
}

export default compose(
	withStyles(styles),
	injectIntl
)(TableContentDesktop)
