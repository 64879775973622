import { Box } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import TabBarContract from '../../components/TabBarContract'
import { ActionsContextProvider } from '../../contexts/ActionsContext'
import { ActionsFiltersContextProvider } from '../../contexts/ActionsFiltersContext'
import { CONTRACT_TABS } from '../../enums'
import ContractActions from '../actions/ContractActions'
import ContractDetails from './ContractDetails'

const ContractContent = ({ actions, contract, refreshActions, dispatch, refreshed }) => {
	const [currentTab, setCurrentTab] = useState<CONTRACT_TABS>(CONTRACT_TABS.DETAILS)

	useEffect(() => {
		if (!refreshed) {
			refreshActions('')
		}
	}, [refreshed, refreshActions])

	return (
		<Box>
			<TabBarContract
				actions={actions}
				currentTab={currentTab}
				setCurrentTab={setCurrentTab}
				refreshed={contract.contractNegotiation ? refreshed : false}
			/>
			{currentTab === CONTRACT_TABS.DETAILS ? (
				<ContractDetails contract={contract} dispatch={dispatch} />
			) : (
				<ActionsFiltersContextProvider contractStatus={contract.contractNegotiation.manualStatus} isActions={false}>
					<ActionsContextProvider>
						<ContractActions contract={contract} refreshAllActions={refreshActions} contractActions={actions} />
					</ActionsContextProvider>
				</ActionsFiltersContextProvider>
			)}
		</Box>
	)
}

export default ContractContent
