import * as React from 'react'
import { FunctionComponent } from 'react'
import { Button as MuiButton, Card, CardContent, CardHeader, createStyles, Theme, Typography, withStyles, WithStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { ChevronRight } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

type ButtonProps = {
	label: string,
	link?: string,
	onClick?: VoidFunction
}

const Button: FunctionComponent<ButtonProps> = ({ label, link, onClick }) => {
	if (link) {
		return <Link to={link}>
			<MuiButton
				variant="contained"
				size="large"
				color="primary"
				onClick={onClick}
			>
				{label}
				<ChevronRight />
			</MuiButton>
		</Link>
	}
	return <MuiButton
		variant="contained"
		size="large"
		color="primary"
		onClick={onClick}
	>
		{label}
		<ChevronRight />
	</MuiButton>
}
type Indicator = {
	label: string,
	count: number,
	link?: string,
	onClick?: VoidFunction
}



type IndicatorProps = Indicator & {
	className: string
}
const Indicator: FunctionComponent<IndicatorProps> = ({ count, link, onClick, className }) => {
	const indicatorCount = <Typography variant="h5" className={className}>
		{count} <FormattedMessage id="pages.dashboard.priorityOpenContracts.contracts" />
		<ChevronRight />
	</Typography>
	if (link) {
		return <Link to={link}>
			{indicatorCount}
		</Link>
	}
	if (onClick) {
		return <div onClick={onClick}>
			{indicatorCount}
		</div>
	}
	return indicatorCount

}

const styles = (theme: Theme) => createStyles({
	card: {
		'& [class*="MuiSvgIcon-root"]': {
			width: 22,
			height: 22,
			margin: 0
		},
		'& [aria-label="next page"]': {
			marginRight: -5
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			overflow: 'auto'
		}
	},
	table: {
		padding: theme.spacing(1)
	},
	cardHeader: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	cardContent: {
		height: '100%',
		padding: theme.spacing(2)
	},
	link: {
		color: theme.palette.primary.light,
		cursor: 'pointer',
		alignItems: 'center'
	}
})

type Props = WithStyles<typeof styles> & {
	title: string,
	indicators: Array<Indicator>,
	button: ButtonProps
}

const ContractsIndicatorsCard: FunctionComponent<Props> = ({ title, indicators, button, classes }) => {

	return (
		<Card className={classes.card}>
			<CardHeader
				className={classes.cardHeader}
				title={title}
			/>
			<CardContent className={classes.cardContent}>
				<Grid container direction="column" spacing={2}>
					{indicators.map((indicator, key) => {
						return <Grid item key={`contract-indicator-${key}`}>
							<Typography variant="h5">{indicator.label}</Typography>
							<Indicator {...indicator} className={classes.link} />
						</Grid>
					})}
					<Grid item>
						<Button {...button} />
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	)
}

export default withStyles(styles)(ContractsIndicatorsCard)