import { Typography, withStyles } from '@material-ui/core'
import React, { useContext } from 'react'
import { LoggedInContext } from '../../contexts/LoggedInContext'

const styles = (theme) => ({
	selectBranch: {
		textTransform: 'uppercase',
		color: theme.palette.grey['800'],
		fontWeight: '700'
	}
})

const BranchDisplay = ({ classes }) => {
	const { user } = useContext(LoggedInContext)

	return (
		<Typography className={classes.selectBranch}>
			{user.branchName}
		</Typography>
	)
}

export default withStyles(styles)(BranchDisplay)

