import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Grid,
	SvgIcon,
	Tooltip,
	Typography,
	withStyles,
} from '@material-ui/core'
import { ChevronRight } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { compose } from 'recompose'
import GraphLegendChip from '../../svg/GraphLegendChip'
import SROpenActions from '../../svg/SROpenActions'
import TwoValuesDonutChart from '../../svg/TwoValuesDonutChart'
import * as api from './services/salesPortfolioApi'

const styles = (_theme: any) => ({
	root: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column' as any,
	},
	cardContent: {
		flexGrow: 1,
	},
	svgIcon: {
		fontSize: '8em',
		width: '100%',
		height: '100%',
		marginTop: 8,
	},
	button: {
		color: '#1976d2',
		border: '1px solid rgba(25, 118, 210, 0.5)',
	},
	firstTitle: {
		marginTop: 14,
	},
	secondTitle: {
		marginTop: 40,
	},
})

const ContractsFollowUpBox = ({ salesRepId, classes, intl }) => {
	const [openActionsCounts, setOpenActionsCounts] = useState({ totalCount: 0, plannedCount: 0 })
	const [percentage, setPercentage] = useState(0)
	const [forecastsCounts, setForecastsCounts] = useState({highValue: 0, lowValue: 0})

	useEffect(() => {
		api.loadSROpenActionsCount(salesRepId).then((data) => {
			setOpenActionsCounts(data)
		})
		api.loadSRPlannedAgainstOverdueActions(salesRepId).then((data) => {
			setPercentage(data)
		})
		api.getSRForecastsCounts(salesRepId).then((data) => {
			setForecastsCounts(data)
		})
	}, [salesRepId])

	return (
		<Card className={classes.root}>
			<CardHeader title={intl.formatMessage({ id: 'pages.srPortfolio.followUp.title' })} />
			<CardContent className={classes.cardContent}>
				<Grid container alignItems="center">
					<Grid item xs={12} className={classes.firstTitle}>
						<Typography variant="h5">
							<FormattedMessage
								id="pages.srPortfolio.followUp.openActions.title"
								values={{ count: openActionsCounts.totalCount }}
							/>
						</Typography>
					</Grid>
					<Grid item xs={12} md={6} container direction="row" alignItems="center">
						<Grid item xs={4}>
							<SvgIcon className={classes.svgIcon}>
								<SROpenActions
									percent={percentage}
									plannedCount={openActionsCounts.plannedCount}
									overdueCount={openActionsCounts.totalCount - openActionsCounts.plannedCount}
								/>
							</SvgIcon>
						</Grid>
						<Grid item xs={8}>
							<Tooltip
								title={intl.formatMessage(
									{ id: 'pages.srPortfolio.followUp.openActions.planned.tooltip' },
									{ count: openActionsCounts.plannedCount }
								)}
							>
								<Box display="flex" alignItems="center">
									<SvgIcon>
										<GraphLegendChip color="#34CB81" />
									</SvgIcon>
									<Typography variant="h5">
										<FormattedMessage id="pages.srPortfolio.followUp.openActions.planned.title" />
									</Typography>
								</Box>
							</Tooltip>
							<Tooltip
								title={intl.formatMessage(
									{ id: 'pages.srPortfolio.followUp.openActions.overdue.tooltip' },
									{ count: openActionsCounts.totalCount - openActionsCounts.plannedCount }
								)}
							>
								<Box display="flex" alignItems="center">
									<SvgIcon>
										<GraphLegendChip color="#F65275" />
									</SvgIcon>
									<Typography variant="h5">
										<FormattedMessage id="pages.srPortfolio.followUp.openActions.overdue.title" />
									</Typography>
								</Box>
							</Tooltip>
						</Grid>
					</Grid>
					<Grid item xs={12} md={6}>
						<Box display="flex" justifyContent="end">
							<Link to={`/actions${salesRepId ? `/${salesRepId}` : ''}`}>
								<Button className={classes.button} variant="outlined" size="small">
									<FormattedMessage id="pages.srPortfolio.followUp.button" /> <ChevronRight />
								</Button>
							</Link>
						</Box>
					</Grid>
					<Grid item xs={12} className={classes.secondTitle}>
						<Typography variant="h5">
							<FormattedMessage id="pages.srPortfolio.followUp.forecasts.title" />
						</Typography>
					</Grid>
					<Grid item xs={12} md={6} container direction="row" alignItems="center">
						<Grid item xs={4}>
							<SvgIcon className={classes.svgIcon}>
								<TwoValuesDonutChart
									firstValue={{
										title: intl.formatMessage(
											{ id: 'pages.srPortfolio.followUp.forecasts.high.tooltip' },
											{ count: forecastsCounts.highValue }
										),
										value: forecastsCounts.highValue,
									}}
									secondValue={{
										title: intl.formatMessage(
											{ id: 'pages.srPortfolio.followUp.forecasts.low.tooltip' },
											{ count: forecastsCounts.lowValue }
										),
										value: forecastsCounts.lowValue,
									}}
								/>
							</SvgIcon>
						</Grid>
						<Grid item xs={8}>
							<Tooltip
								title={intl.formatMessage(
									{ id: 'pages.srPortfolio.followUp.forecasts.high.tooltip' },
									{ count: forecastsCounts.highValue }
								)}
							>
								<Box display="flex" alignItems="center">
									<SvgIcon>
										<GraphLegendChip color="#34CB81" />
									</SvgIcon>
									<Typography variant="h5">
										<FormattedMessage id="pages.srPortfolio.followUp.forecasts.high.title" />
									</Typography>
								</Box>
							</Tooltip>
							<Tooltip
								title={intl.formatMessage(
									{ id: 'pages.srPortfolio.followUp.forecasts.low.tooltip' },
									{ count: forecastsCounts.lowValue }
								)}
							>
								<Box display="flex" alignItems="center">
									<SvgIcon>
										<GraphLegendChip color="#F65275" />
									</SvgIcon>
									<Typography variant="h5">
										<FormattedMessage id="pages.srPortfolio.followUp.forecasts.low.title" />
									</Typography>
								</Box>
							</Tooltip>
						</Grid>
					</Grid>
					<Grid item xs={12} md={6}>
						<Box display="flex" justifyContent="end">
							<Link to={`/forecasts${salesRepId ? `/${salesRepId}` : ''}`}>
								<Button className={classes.button} variant="outlined" size="small">
									<FormattedMessage id="pages.srPortfolio.followUp.button" /> <ChevronRight />
								</Button>
							</Link>
						</Box>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	)
}

export default compose(withStyles(styles), injectIntl)(ContractsFollowUpBox)
