import * as React from 'react'
import { FunctionComponent } from 'react'
import {
    Card,
    CardContent,
    CardHeader,
    createStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Theme,
    Tooltip,
    withStyles,
    WithStyles,
    Typography
} from '@material-ui/core'
import { CLASSIFICATION, MANUALLY_CLOSED_STATUS } from '../../enums'
import InformationIcon from '@material-ui/icons/InfoOutlined'
import { YtdStatistics, YtdStatRow } from './types'
import { getManuallyClosedContracts } from './service/kpiApi'
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl'
import { compose } from 'recompose'

const styles = (theme: Theme) => createStyles({
    table: {
        padding: theme.spacing(1)
    },
    cardHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    cardContent: {
        height: '100%',
        padding: theme.spacing(1)
    },
    tableRowRoot: {
        height: '46px'
    }
})

/**
 * Table Cell with overrided style to make it look like a link
 */
const LinkTableCell = withStyles(theme => ({
    head: {
        padding: theme.spacing(1)
    },
    body: {
        color: theme.palette.primary.light,
        cursor: 'pointer',
        fontSize: '15px',
        fontWeight: 500,
        padding: theme.spacing(1),
        wordWrap: 'normal'
    }
}))(TableCell)

/**
 * Table cell with header style
 */
const HeaderTableCell = withStyles(theme => ({
    head: {
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0)
        },
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(1)
        }
    },
    body: {
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0)
        },
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(1)
        },
        fontWeight: 500,
        padding: theme.spacing(1),
        wordWrap: 'normal'
    }
}))(TableCell)


/**
 * Row header labels
 */
const rowHeaders = intl => ({
    [MANUALLY_CLOSED_STATUS.TO_BE_CANCELLED]: {
        title: intl.formatMessage({ id: `pages.dashboard.manuallyClosedContracts.rows.${MANUALLY_CLOSED_STATUS.TO_BE_CANCELLED}.title` }),
        tooltip: intl.formatMessage({ id: `pages.dashboard.manuallyClosedContracts.rows.${MANUALLY_CLOSED_STATUS.TO_BE_CANCELLED}.tooltip` })
    },
    [MANUALLY_CLOSED_STATUS.TO_BE_AUTO_RENEWED]: {
    	title: intl.formatMessage({ id: `pages.dashboard.manuallyClosedContracts.rows.${MANUALLY_CLOSED_STATUS.TO_BE_AUTO_RENEWED}.title` }),
		tooltip: intl.formatMessage({ id: `pages.dashboard.manuallyClosedContracts.rows.${MANUALLY_CLOSED_STATUS.TO_BE_AUTO_RENEWED}.tooltip` })
	},
    [MANUALLY_CLOSED_STATUS.RENEGOTIATED]: {
        title: intl.formatMessage({ id: `pages.dashboard.manuallyClosedContracts.rows.${MANUALLY_CLOSED_STATUS.RENEGOTIATED}.title` }),
        tooltip: intl.formatMessage({ id: `pages.dashboard.manuallyClosedContracts.rows.${MANUALLY_CLOSED_STATUS.RENEGOTIATED}.tooltip` })
    },
    [MANUALLY_CLOSED_STATUS.UPGRADED]: {
        title: intl.formatMessage({ id: `pages.dashboard.manuallyClosedContracts.rows.${MANUALLY_CLOSED_STATUS.UPGRADED}.title` }),
        tooltip: intl.formatMessage({ id: `pages.dashboard.manuallyClosedContracts.rows.${MANUALLY_CLOSED_STATUS.UPGRADED}.tooltip` })
    },
    [MANUALLY_CLOSED_STATUS.DOWNGRADED]: {
        title: intl.formatMessage({ id: `pages.dashboard.manuallyClosedContracts.rows.${MANUALLY_CLOSED_STATUS.DOWNGRADED}.title` }),
        tooltip: intl.formatMessage({ id: `pages.dashboard.manuallyClosedContracts.rows.${MANUALLY_CLOSED_STATUS.DOWNGRADED}.tooltip` })
    }
})

interface Props extends WithStyles<typeof styles> {
    onCellClick(status?: MANUALLY_CLOSED_STATUS, classification?: CLASSIFICATION): void,
    stats: YtdStatistics,
    intl: IntlShape
}

/**
 * Utility method to display a value as a percentage
 */
const getPercentage = (value: number, total: number): string => `${(total !== 0 ? (value * 100) / total : 0).toFixed(0)}%`

/**
 * Initial value for totals
 */
const initTotals: YtdStatRow = { allContracts: 0, highValueRisk: 0, lowValue: 0 }

const ManuallyClosedContractsTable: FunctionComponent<Props> = ({ stats, onCellClick, classes, intl }) => {

    const [totals, setTotals] = React.useState<YtdStatRow>(initTotals)
    const rowHeadersList = rowHeaders(intl)

    React.useEffect(() => {
        const newTotals = Object.keys(stats).map(key => stats[key]).reduce<YtdStatRow>((acc, current) => {
            if (typeof current !== 'undefined') {
                return {
                    allContracts: acc.allContracts + current.allContracts,
                    highValueRisk: acc.highValueRisk + current.highValueRisk,
                    lowValue: acc.lowValue + current.lowValue
                }
            }
            return acc
        }, initTotals)
        setTotals(newTotals)
    }, [stats])

    return (
        <Card>
            <CardHeader
                className={classes.cardHeader}
                title={intl.formatMessage({ id: `pages.dashboard.manuallyClosedContracts.title` })}
                action={<Tooltip title={intl.formatMessage({ id: `pages.dashboard.manuallyClosedContracts.tooltip` })}>
                    <InformationIcon />
                </Tooltip>}
            />
            <CardContent className={classes.cardContent}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <HeaderTableCell />
                            <HeaderTableCell align="right"><FormattedMessage id="pages.dashboard.manuallyClosedContracts.headers.all" /></HeaderTableCell>
                            <HeaderTableCell align="right"><FormattedMessage id="pages.dashboard.manuallyClosedContracts.headers.high" /></HeaderTableCell>
                            <HeaderTableCell align="right"><FormattedMessage id="pages.dashboard.manuallyClosedContracts.headers.low" /></HeaderTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(MANUALLY_CLOSED_STATUS)
                            .map(key => MANUALLY_CLOSED_STATUS[key as keyof typeof MANUALLY_CLOSED_STATUS])
                            .map((status, index) =>
                            <TableRow key={index} classes={{root: classes.tableRowRoot}}>
                                <HeaderTableCell><Tooltip title={rowHeadersList[status].tooltip} arrow><Typography>{rowHeadersList[status].title}</Typography></Tooltip></HeaderTableCell>
                                <LinkTableCell
                                    align="right"
                                    onClick={() => onCellClick(status, CLASSIFICATION.ALL)}
                                >
                                    {getPercentage(stats[status].allContracts, totals.allContracts)}
                                </LinkTableCell>
                                <LinkTableCell
                                    align="right"
                                    onClick={() => onCellClick(status, CLASSIFICATION.HIGH_RISK_VALUE)}
                                >
                                    {getPercentage(stats[status].highValueRisk, totals.highValueRisk)}
                                </LinkTableCell>
                                <LinkTableCell
                                    align="right"
                                    onClick={() => onCellClick(status, CLASSIFICATION.LOW_VALUE)}
                                >
                                    {getPercentage(stats[status].lowValue, totals.lowValue)}
                                </LinkTableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    )
}

export { getManuallyClosedContracts }
export default compose(
    withStyles(styles),
    injectIntl
)(ManuallyClosedContractsTable)