import { withStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button/Button'
import DialogActions from '@material-ui/core/DialogActions/DialogActions'
import DialogContent from '@material-ui/core/DialogContent/DialogContent'
import Grid from '@material-ui/core/Grid/Grid'
import InputLabel from '@material-ui/core/InputLabel/InputLabel'
import React from 'react'
import { Field, Form } from 'react-final-form'
import RadioField from '../../components/RadioField'
import FormInput from 'isotope-client/components/form/fields/FormInput'
import { FormattedMessage, injectIntl } from 'react-intl'
import { compose } from 'recompose'


const styles = (theme) => {
	return ({
			tab: {
				display: 'flex',
				width: 'auto'
			},
			outcomeDetail: {
				marginTop: '14px'
			},
			radioRoot: {
				color: theme.palette.grey['300']
			},
			radioChecked: {
				color: theme.palette.primary.light
			}
		}
	)
}

const outcomesList = intl => ({
	to_be_autorenewed: {
		label: intl.formatMessage({ id: 'pages.contract.risk.close.outcome.to_be_autorenewed.label' }),
		key: 'TO_BE_AUTO_RENEWED',
		detail: intl.formatMessage({ id: 'pages.contract.risk.close.outcome.to_be_autorenewed.detail' })
	},
	to_be_cancelled: {
		label: intl.formatMessage({ id: 'pages.contract.risk.close.outcome.to_be_cancelled.label' }),
		key: 'TO_BE_CANCELLED',
		detail: intl.formatMessage({ id: 'pages.contract.risk.close.outcome.to_be_cancelled.detail' })
	},
	renegotiated: {
		label: intl.formatMessage({ id: 'pages.contract.risk.close.outcome.renegotiated.label' }),
		key: 'RENEGOTIATED',
		detail: intl.formatMessage({ id: 'pages.contract.risk.close.outcome.renegotiated.detail' })
	},
	upgraded: {
		label: intl.formatMessage({ id: 'pages.contract.risk.close.outcome.upgraded.label' }),
		key: 'UPGRADED',
		detail: intl.formatMessage({ id: 'pages.contract.risk.close.outcome.upgraded.detail' })
	},
	downgraded: {
		label: intl.formatMessage({ id: 'pages.contract.risk.close.outcome.downgraded.label' }),
		key: 'DOWNGRADED',
		detail: intl.formatMessage({ id: 'pages.contract.risk.close.outcome.downgraded.detail' })
	}
})

const validate = (values, intl) => {
	const errors = {}
	if (!values.outcome) {
		errors.outcome = intl.formatMessage({ id: 'pages.contract.risk.close.errors.required' })
	}
	if (!values.comment) {
		errors.comment = intl.formatMessage({ id: 'pages.contract.risk.close.errors.mandatory' })
	}
	return errors
}


const CloseRiskForm = ({ initialValues, onClose, onSubmit, classes, intl }) => {

	return (
		<Form
			initialValues={initialValues}
			onSubmit={onSubmit}
			validate={values => validate(values, intl)}
			render={({ handleSubmit }) => (
				<form onSubmit={handleSubmit}>
					<DialogContent>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<InputLabel id="outcome-label">
									<FormattedMessage id="pages.contract.risk.close.outcome.title" />
								</InputLabel>
								<RadioField
									radioItems={outcomesList(intl)}
									defaultItem="to_be_autorenewed"
									fieldName="outcome"
								/>
							</Grid>
							<Grid item xs={12}>
								<Field
									label={intl.formatMessage({ id: 'pages.contract.risk.close.comment' })}
									name="comment"
									component={FormInput}
									multiline
									fullWidth
									rows={7}
									inputProps={{
										maxLength: 2048
									}}
								/>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button onClick={onClose}><FormattedMessage id="pages.contract.risk.close.cancel" /></Button>
						<Button type="submit" variant="contained" size="small" color="primary" disableElevation={true}>
							<FormattedMessage id="pages.contract.risk.close.button" />
						</Button>
					</DialogActions>
				</form>
			)}
		/>

	)
}

export default compose(
	withStyles(styles),
	injectIntl
)(CloseRiskForm)