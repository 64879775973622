import { MenuItem, Select } from '@material-ui/core'
import { ExpandMoreRounded } from '@material-ui/icons'
import React, { useContext } from 'react'
import { LoggedInContext } from '../../contexts/LoggedInContext'
import { FormattedMessage } from 'react-intl'
import { changeLanguage } from '../../config/api'
import { changeLocale } from '../../config/actions'
import { connect } from 'react-redux'
import { Language } from '../../config/const'

const LanguageSelector = ({ changeLocale }) => {
	const { user } = useContext(LoggedInContext)

	return (
		<Select
			IconComponent={ExpandMoreRounded}
			disableUnderline
			value={user?.locale}
			displayEmpty
			onChange={
				(e) => changeLanguage(e.target.value).then(user => changeLocale(user.locale))
			}
			MenuProps={{
				getContentAnchorEl: null,
				anchorOrigin: {
					vertical: 'bottom',
					horizontal: 'left'
				},
				style: {
					zIndex: 3000
				}
			}}
		>
			{
				Object.values(Language).map(langue =>
					<MenuItem key={langue} value={langue}><FormattedMessage id={`language.${langue}`} /></MenuItem>
				)
			}
		</Select>
	)
}

const mappedAction = {
	changeLocale
}

export default connect(null, mappedAction)(LanguageSelector)

