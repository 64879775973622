import { Grid, IconButton, Typography, withStyles } from '@material-ui/core'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import React from 'react'

const styles = () => ({
	pagination: {
		height: 30
	},
	buttons: {
		height: 50
	}
})

const TablePaginationActions = (props) => {
	const { count, page, rowsPerPage, onChangePage, ButtonsComponent, noPagination, classes } = props

	const handleBackButtonClick = (event) => {
		onChangePage(event, page - 1)
	}

	const handleNextButtonClick = (event) => {
		onChangePage(event, page + 1)
	}

	return (
		<Grid container alignItems="center" wrap="wrap">
			{ButtonsComponent && <Grid item xs={12} className={classes.buttons}>
				{ButtonsComponent}
			</Grid>
			}
			{!noPagination && <Grid item xs={12} container alignItems="center" className={classes.pagination}>
				<IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
					<KeyboardArrowLeft />
				</IconButton>
				<Typography display="inline" noWrap>
					{page * rowsPerPage + 1} - {Math.min((page + 1) * rowsPerPage, count)} of {count}
				</Typography>
				<IconButton
					onClick={handleNextButtonClick}
					disabled={page >= Math.ceil(count / rowsPerPage) - 1}
					aria-label="next page"
				>
					<KeyboardArrowRight />
				</IconButton>
			</Grid>
			}
		</Grid>
	)
}

export default withStyles(styles)(TablePaginationActions)
