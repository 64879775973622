import { List, ListItem, makeStyles, Typography } from '@material-ui/core'
import * as React from 'react'
import * as api from './services/searchApi'
import { ContractsSearchResult } from './types'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles((theme) => ({
	title: {
		display: 'flex',
		alignItems: 'center',
		fontSize: 12,
		fontWeight: 600,
		color: theme.palette.text.primary,
		padding: '0 20px',
		marginBottom: 15,
		height: 30
	},
	noResults: {
		fontSize: 15,
		fontWeight: 400,
		padding: '0',
		marginBottom: 15
	},
	groupList: {
		padding: '0 20px'
	},
	itemList: {
		fontSize: 15,
		fontWeight: 500,
		color: theme.palette.text.primary,
		padding: '0',
		marginBottom: 15
	}
}))

const ContractsSearchBlock = ({ query, handleMenuClose }) => {
	const [contracts, setContracts] = React.useState([])
	const classes = useStyles()

	React.useEffect(() => {
		if (query.length > 1) {
			api.getContracts(query).then((data) => {
				if (data) {
					setContracts(data)
				}
			})
		}
	}, [query])

	return (
		<>
			<Typography className={classes.title}><FormattedMessage id="search.contracts.title" /></Typography>
			<List className={classes.groupList}>
				{contracts.length > 0 ? contracts.map((contract: ContractsSearchResult) => (
						<Link key={contract.id} to={`/contracts/${contract.id}`}>
							<ListItem className={classes.itemList} onClick={handleMenuClose}>
								{contract.customer.name} ({contract.id})
							</ListItem>
						</Link>
					)) :
					<ListItem className={classes.noResults}><FormattedMessage id="search.contracts.noResult" /></ListItem>
				}
			</List>
		</>
	)
}

export default ContractsSearchBlock