import React from 'react'
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle'
import { Box, IconButton, Typography } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button/Button'
import Dialog from '@material-ui/core/Dialog/Dialog'
import { FormattedMessage } from 'react-intl'

interface RenegotiationTipDialogProps {
	open: boolean,
	onClose: () => void,
	tip: string
}

const RenegotiationTipDialog = ({ open, onClose, tip }: RenegotiationTipDialogProps) => {
	return (
		<Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="sm">
			<DialogTitle>
				<Box display="flex" alignItems="center" justifyContent="space-between" flexGrow="1">
					<FormattedMessage id="pages.contract.information.headers.renegotiationTip.dialogTitle" />
					<IconButton onClick={onClose}><CloseIcon /></IconButton>
				</Box>
			</DialogTitle>
			<DialogContent>
				<Typography>- {tip}</Typography>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} variant="contained" size="small" color="primary" disableElevation={true}><FormattedMessage id="pages.contract.flags.close" /></Button>
			</DialogActions>
		</Dialog>
	)
}

export default RenegotiationTipDialog