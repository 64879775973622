import * as React from 'react'
import { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { SpringDataTable } from 'isotope-client'
import NoResultFragment from '../../components/NoResult'
import {
	Button,
	Card,
	CardContent,
	CardHeader,
	createStyles,
	Grid,
	isWidthUp,
	Theme,
	Typography,
	WithStyles,
	withStyles,
	withWidth,
	WithWidth
} from '@material-ui/core'
import { compose } from 'recompose'
import ChevronRight from '@material-ui/icons/ChevronRight'
import { RouteComponentProps, withRouter } from 'react-router'
import ProgressBar from '../../components/ProgressBar'
import TablePaginationActions from '../datatable/TablePaginationActions'
import { SalesRepListRow } from './types'
import TablePaginationActionsMobile from '../datatable/TablePaginationActionsMobile'
import { LoggedInContext } from '../../contexts/LoggedInContext'
import { PaperLoaderDataTable } from 'isotope-client/components/layout/PaperLoader'
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl'

const getTotalProgress = (row: SalesRepListRow) => {
	const nbOpenContrats = row.todoCount + row.inProgressCount
	return nbOpenContrats === 0 ? row.closedCount : nbOpenContrats
}

const styles = (theme: Theme) => createStyles({
	card: {
		'& [class*="MuiSvgIcon-root"]': {
			width: 22,
			height: 22,
			margin: 0
		},
		'& [aria-label="next page"]': {
			marginRight: -5
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			overflow: 'scroll'
		}
	},
	cardContent: {
		padding: 0,
		position: 'relative',
		backgroundColor: 'unset',
		'&:last-child': {
			paddingBottom: 0
		}
	},
	tableHeader: {
		width: '25%'
	},
	button: {
		fontSize: 13,
		fontWeight: 'normal',
		'& [class*="MuiButton-endIcon"]': {
			marginLeft: 2,
			marginRight: -9
		}
	},
	tableCell: {
		fontWeight: 500,
		fontSize: 15
	},
	buttonMobile: {
		color: theme.palette.primary.main,
		paddingLeft: 0,
		paddingRight: 0
	}
})

interface DispatchProps {
	intl: IntlShape
}

interface Props extends WithStyles<typeof styles>, WithWidth, RouteComponentProps, DispatchProps {
}

const SalesRepList: FunctionComponent<Props> = ({ width, classes, history, intl }) => {
	const { user } = React.useContext(LoggedInContext)

	const headers = [{
		name: intl.formatMessage({ id: 'pages.dashboard.salesRep.table.headers.lastName' }),
		key: 'lastName',
		sortable: false,
		tableHeaderColumnProps: {
			classes: {
				root: classes.tableHeader
			}
		},
		render: (row: SalesRepListRow) => <Link to={`/portfolio/${row.id}`}>
			<Typography component="span" className={classes.tableCell}>
				{row.firstName} {row.lastName}
			</Typography>
		</Link>
	}, {
		name: intl.formatMessage({ id: 'pages.dashboard.salesRep.table.headers.todo' }),
		key: 'todo',
		sortable: true,
		tableHeaderColumnProps: {
			classes: {
				root: classes.tableHeader
			}
		},
		render: (row: SalesRepListRow) => <Grid container alignItems="center" spacing={1}>
			<Grid item container xs={2} justify="flex-end">
				<Typography component="span" display="inline" className={classes.tableCell}>{row.todoCount}</Typography>
			</Grid>
			<Grid item xs={10}>
				<ProgressBar color="error" value={row.todoCount} total={getTotalProgress(row)} />
			</Grid>
		</Grid>
	}, {
		name: intl.formatMessage({ id: 'pages.dashboard.salesRep.table.headers.inProgress' }),
		key: 'inProgress',
		sortable: true,
		tableHeaderColumnProps: {
			classes: {
				root: classes.tableHeader
			}
		},
		render: (row: SalesRepListRow) => <Grid container alignItems="center" spacing={1}>
			<Grid item container xs={2} justify="flex-end">
				<Typography component="span" display="inline"
				            className={classes.tableCell}>{row.inProgressCount}</Typography>
			</Grid>
			<Grid item xs={10}>
				<ProgressBar color="primary" value={row.inProgressCount} total={getTotalProgress(row)} />
			</Grid>
		</Grid>
	}, {
		name: intl.formatMessage({ id: 'pages.dashboard.salesRep.table.headers.closed' }),
		key: 'closed',
		sortable: true,
		tableHeaderColumnProps: {
			classes: {
				root: classes.tableHeader
			}
		},
		render: (row: SalesRepListRow) => <Grid container justify="center">
			<Typography component="span" display="inline" className={classes.tableCell}>{row.closedCount}</Typography>
		</Grid>
	}]

	return (
		<Card className={classes.card}>
			<CardHeader title={`${user?.branchName || user?.regionName || intl.formatMessage({ id: 'pages.dashboard.salesRep.title.national' })} ${intl.formatMessage({ id: 'pages.dashboard.salesRep.title.openContracts' })}`} />
			<CardContent className={classes.cardContent}>
				<PaperLoaderDataTable datatableName="sales-representatives">
					<SpringDataTable
						nom="sales-representatives"
						headers={headers}
						filters={{ timestamp: user?.branchId }}
						apiUrlPrefix="/api"
						apiUrl="/dashboard/sales-representatives"
						ActionsComponent={({ classes: notused, ...props }: Props) => isWidthUp('md', width) ?
							<TablePaginationActions
								{...props}
								ButtonsComponent={<Button
									variant="contained"
									size="large"
									color="primary"
									endIcon={<ChevronRight />}
									disableElevation
									onClick={() => history.push('/sr-overview')}
									className={classes.button}
								>
									<FormattedMessage id="pages.dashboard.salesRep.button" />
								</Button>}
								noPagination={false}
							/> : <TablePaginationActionsMobile
								{...props}
								ButtonsComponent={<Button
									variant="contained"
									size="small"
									color="primary"
									endIcon={<ChevronRight />}
									disableElevation
									onClick={() => history.push('/sr-overview')}
									className={classes.button}
								>
									<FormattedMessage id="pages.dashboard.salesRep.button" />
								</Button>}
								noPagination={false}
							/>}
						noResultFragment={<NoResultFragment message={intl.formatMessage({ id: 'pages.dashboard.salesRep.table.noResult' })} />}
					/>
				</PaperLoaderDataTable>
			</CardContent>
		</Card>
	)
}

const enhance = compose(
	withStyles(styles),
	withWidth({ withTheme: true }),
	withRouter,
	injectIntl
)

export default enhance(SalesRepList)
