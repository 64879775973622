import 'react-app-polyfill/ie11'
import { ConnectedRouter, connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { configureFetch, i18n, logOut, saveStore, storeLocalUser } from 'isotope-client'
import React from 'react'
import ReactDOM from 'react-dom'
import { intlReducer, Provider } from 'react-intl-redux'
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { reducer as formReducer } from 'redux-form'
import thunk from 'redux-thunk'
import reducers from './config/reducers'
import Root from './containers/Root'
import './index.css'
import { loadMessages } from './config'
import { getConfiguration } from './modules/configuration/configurationApi'
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { getAzureRequest, getConfig } from './config/azureUtils'
import { Language } from './config/const'
import Maintenance from './containers/Maintenance'

// Cette méthode permet d'enfermer tout le code à exécuter après les vérifications sur le login
const reactInit = (configuration) => {

	// Initialisation de la partie i18n (react-intl)
	const formats = {
		date: i18n.dateFormats,
		time: i18n.timeFormats,
		number: i18n.numberFormats,
	}
	
	// Create a history of your choosing (we're using a browser history in this case)
	const history = createBrowserHistory()
	
	// Initialisation de tous les enhancers utilisés avec Redux
	const enhancers = [
		applyMiddleware(
			thunk,
			routerMiddleware(history)
		)
	]
	
	// En mode dév, on utilise le devtools
	if (process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION__) {
		enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__())
	}
	
	// Mise en place de l'authentification Azure
	const pca = new PublicClientApplication(getConfig(configuration))
	
	async function getAccessToken() {
		const accounts = pca.getAllAccounts()
		if (accounts.length > 0) {
			return await pca.acquireTokenSilent(getAzureRequest(accounts[0]))
				.then((response) => {
					return response.idToken
				}).catch(error => {
					console.error('Error while fetching Azure ID Token', error)
					return null
				})
		}
		return null
	}
	
	
	configureFetch({
		fetchFactory: defaultFetchFactory => (...args) => {
			return getAccessToken()
				.then(token => {
					if (token) {
						storeLocalUser({ token: `Bearer ${token}` })
						return defaultFetchFactory(...args)
					} else {
						return store.dispatch(logOut())
					}
				})
		},
		postMultipart: defaultPostMultipart => (...args) => {
			return getAccessToken()
				.then(token => {
					if (token) {
						storeLocalUser({ token: `Bearer ${token}` })
						return defaultPostMultipart(...args)
					} else {
						return store.dispatch(logOut())
					}
				})
		},
		compose: true
	})

	const defaultLocale = Language.EN
	const messages = loadMessages(defaultLocale)

	// Création du store de redux
	let store = createStore(
		combineReducers({
			...reducers,
			router: connectRouter(history),
			intl: intlReducer,
			form: formReducer
		}), {
			intl: {
				locale: defaultLocale,
				messages,
				formats: {
					...formats
				},
				defaultLocale,
				defaultFormats: {
					...formats
				}
			}
		},
		compose(...enhancers)
	)

	saveStore(store)

	ReactDOM.render(
		configuration.maintenance === "true" ? (
			<Maintenance />
		) : (
			<MsalProvider instance={pca}>
				<Provider store={store}>
					<ConnectedRouter history={history}>
						<Root />
					</ConnectedRouter>
				</Provider>
			</MsalProvider>
		),
		document.getElementById('root')
	)
}

getConfiguration()
	.then(configuration => {
		reactInit(configuration)
		// Webpack Hot Module Replacement API
		if (module.hot) {
			module.hot.accept('./containers/Root', () => {
				reactInit(configuration)
			})
		}
	})

