import { Tab, Tabs, Typography, WithStyles, withStyles } from '@material-ui/core'
import React, { FunctionComponent } from 'react'
import { withRouter } from 'react-router'
import BadgeBasic from './BadgeTab'
import { FormattedMessage } from 'react-intl'
import { compose } from 'recompose'
import { TabBarProps } from './types'

const styles = (theme) => {
	return ({
			tabsContracts: {
				'& [class*="MuiButtonBase-root"]': {
					backgroundColor: theme.palette.background.default,
					color: theme.palette.common.white,
					opacity: 1,
					'&:hover': { backgroundColor: theme.palette.grey['800'] }
				},
				'& [class*="MuiBadge-colorPrimary"]': {
					backgroundColor: theme.palette.grey['900'],
					color: theme.palette.grey['400']
				},
				'& [class*="Mui-selected"]': {
					backgroundColor: theme.palette.text.secondary,
					color: theme.palette.text.primary,
					opacity: 1,
					'& [class*="MuiBadge-colorPrimary"]': {
						backgroundColor: theme.palette.primary.light,
						color: theme.palette.text.secondary
					},
					'&:hover': {
						backgroundColor: '#fff!important',
						color: '#021227!important'
					}
				},
				'& [class*="MuiTypography-root"]': {
					display: 'flex',
					alignItems: 'center'
				},
				'& [class*="PrivateTabIndicator-root"]': {
					display: 'none'
				}
			},
			tab: {
				display: 'flex',
				alignItems: 'center',
				height: 50,
				padding: '0 20px',
				width: 'auto',
				borderRadius: '4px 4px 0 0',
				marginRight: 1,
				'& [class*="MuiTypography-root"]': {
					fontSize: 16,
					fontWeight: '700',
					textTransform: 'initial'
				}
			}
		}
	)
}

export const TABLE_URL = {
	PRIORITY: 'priority',
	MONTHLY: 'monthly',
	ALL: 'all',
}

interface TabBarDesktopProps extends WithStyles<typeof styles>, TabBarProps {
}

const TabBarDesktop: FunctionComponent<TabBarDesktopProps> = ({ tableUrl, dispatch, data, setUrl, setTableUrl, classes }) => {
	const handleChange = (e, newValue) => {
		const searchParams = new URLSearchParams()
		searchParams.append("tableUrl", newValue)
		window.history.replaceState(null, "", `${window.location.pathname}?${searchParams.toString()}`)
		dispatch && setUrl ? dispatch(setUrl(newValue)) : (setTableUrl && setTableUrl(newValue))
	}

	return (
		<Tabs value={tableUrl} onChange={handleChange} aria-label="simple" className={classes.tabsContracts}>
			{
				data.map((item, index) => (
					<Tab
						key={index}
						value={item.path}
						className={classes.tab}
						label={
						<Typography noWrap>
							<FormattedMessage id={item.labelId} />
							{ (item.badge || item.badge === 0) && <BadgeBasic badgeContent={item.badge} badgeState="primary" />}
						</Typography>
					}
					/>
				))
			}
		</Tabs>
	)
}

const enhance = compose(withRouter, withStyles(styles))

export default enhance(TabBarDesktop)