import { createStyles, isWidthDown, withStyles, withWidth } from '@material-ui/core'
import {
	Dashboard as DashboardIcon,
	DynamicFeed as DynamicFeedIcon,
	InsertInvitation as InsertInvitationIcon,
	PeopleAltOutlined as PeopleIcon,
	Speed as SpeedIcon
} from '@material-ui/icons'
import React, { useState } from 'react'
import { LoggedInContext } from '../../contexts/LoggedInContext'
import { PROFILE } from '../../enums'
import { hasAnyProfile, hasProfile } from '../../utils/accessChecker'
import { getOverdueActions } from '../actions/services/actionsApi'
import { getNeedHelp, getOverdueContracts } from '../contracts/services/contractsApi'
import Drawer from './drawer/Drawer'
import MobileDrawer from './drawer/MobileDrawer'
import MobileNavbar from './navbar/MobileNavbar'
import Navbar from './navbar/Navbar'
import Wrapper from './Wrapper'
import { ErrorHandlerContext } from '../../contexts/ErrorHandlerContext'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import MonitoringIcon from '../../svg/MonitoringIcon'

let interval = null

const styles = (theme) => createStyles({
	svgIcon: {
		color: theme.palette.secondary.main
	}
})

const Layout = ({ width, children, intl, classes }) => {
	const [isDrawerOpen, setIsDrawerOpen] = useState(false)
	const [overdueContracts, setOverdueContracts] = React.useState([])
	const [overdueActions, setOverdueActions] = React.useState([])
	const [needHelpContracts, setNeedHelpContracts] = React.useState([])
	const { setError } = React.useContext(ErrorHandlerContext)

	const { user } = React.useContext(LoggedInContext)

	React.useEffect(() => {
		const loadOverdueAlerts = () => {
			getOverdueContracts()
				.then(contracts => setOverdueContracts(contracts))
				.catch(error => setError(error))
			getOverdueActions()
				.then(actions => setOverdueActions(actions))
				.catch(error => setError(error))
			if (hasAnyProfile(user, [PROFILE.BRANCH_MANAGER, PROFILE.BRANCH_MANAGER_ASSISTANT])) {
				getNeedHelp()
					.then(contracts => setNeedHelpContracts(contracts))
			}
		}

		if (!hasAnyProfile(user, [PROFILE.ZONE_MANAGER, PROFILE.NATIONAL])) {
			loadOverdueAlerts()
			interval = setInterval(loadOverdueAlerts, 60000)
			return () => {
				if (!!interval) {
					clearInterval(interval)
					interval = null
				}
			}
		}
	}, [user, setError])

	const menuItems = React.useMemo(() => {
		const items = []
		if (hasAnyProfile(user, [PROFILE.ZONE_MANAGER, PROFILE.NATIONAL, PROFILE.REGIONAL_MANAGER, PROFILE.BRANCH_MANAGER, PROFILE.BRANCH_MANAGER_ASSISTANT])) {
			items.push({
				path: '/dashboard',
				icon: <DashboardIcon />,
				label: intl.formatMessage({ id: 'layout.dashboard' })
			}, {
				path: '/sr-overview',
				icon: <PeopleIcon />,
				label: intl.formatMessage({ id: 'layout.srOverview' })
			}, {
				path: '/contracts-overview',
				icon: <DynamicFeedIcon />,
				label: intl.formatMessage({ id: 'layout.contractsOverview' })
			}, {
				path: '/reporting',
				icon: <div className={classes.svgIcon}><MonitoringIcon/></div>,
				label: intl.formatMessage({ id: 'layout.reporting.label' }),
				children: [{ path: '/reporting/teams', label: intl.formatMessage({ id: 'layout.reporting.teams' })},
					{ path: '/reporting/detailed', label: intl.formatMessage({ id: 'layout.reporting.detailed' })}
				]
			})
		} else if (hasProfile(user, PROFILE.SALES_REPRESENTATIVE)) {
			items.push({
				path: '/portfolio',
				icon: <DashboardIcon />,
				label: intl.formatMessage({ id: 'layout.portfolio' })
			}, {
				path: '/actions',
				icon: <InsertInvitationIcon />,
				label: intl.formatMessage({ id: 'layout.actions' })
			}, {
				path: '/forecasts',
				icon: <SpeedIcon />,
				label: intl.formatMessage({ id: 'layout.forecasts' })
			}, {
				path: '/reporting/detailed',
				icon: <div className={classes.svgIcon}><MonitoringIcon/></div>,
				label: intl.formatMessage({ id: 'layout.reporting.detailed' })
			})
		}
		return items
	}, [user, intl, classes.svgIcon])

	const hasNotification = React.useMemo(() => {
		return overdueContracts.length > 0 || overdueActions.length > 0 || needHelpContracts.length > 0
	}, [overdueActions, overdueContracts, needHelpContracts])

	return (
		<>
			{isWidthDown('sm', width)
				? <MobileNavbar
					toggleDrawer={() => setIsDrawerOpen(!isDrawerOpen)}
					overdueContracts={overdueContracts}
					overdueActions={overdueActions}
					needHelpContracts={needHelpContracts}
					hasNotification={hasNotification}
				/>
				: <Navbar
					toggleDrawer={() => setIsDrawerOpen(!isDrawerOpen)}
					overdueContracts={overdueContracts}
					overdueActions={overdueActions}
					needHelpContracts={needHelpContracts}
					hasNotification={hasNotification}
				/>}
			{isWidthDown('sm', width)
				? <MobileDrawer
					items={menuItems}
					isDrawerOpen={isDrawerOpen}
					closeDrawer={() => setIsDrawerOpen(false)}
				/>
				: <Drawer
					items={menuItems}
					isDrawerOpen={isDrawerOpen}
					closeDrawer={() => setIsDrawerOpen(false)}
				/>}
			<Wrapper>
				{children}
			</Wrapper>
		</>
	)
}

export default compose(
	withWidth(),
	withStyles(styles),
	injectIntl
)(Layout)
