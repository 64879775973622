import { MenuItem, Select, makeStyles } from '@material-ui/core'
import { ExpandMoreRounded } from '@material-ui/icons'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { LoggedInContext } from '../../contexts/LoggedInContext'
import { withRouter } from 'react-router'
import * as api from './branchesApi'
import BranchDisplay from './BranchDisplay'
import { hasAnyProfile } from '../../utils/accessChecker'
import { PROFILE } from '../../enums'
import { redirectToRightPage } from '../../utils/utils'

const useStyles = makeStyles(theme => ({
	selectBranch: {
		maxWidth: 300,
		width: '100%',
		textTransform: 'uppercase',
		fontSize: 13,
		color: theme.palette.grey['800'],
		fontWeight: '700',
		lineHeight: 0,
		[theme.breakpoints.down('sm')]: {
			backgroundColor: theme.palette.text.secondary
		},
		'& [class*="MuiSelect-root"]': {
			paddingRight: 34
		},
		'& [class*="MuiSelect-icon"]': {
			right: 10
		}
	},
}), { name: 'BranchSelector' })

const BranchSelector = ({ history }) => {
	const { user, setBranch } = useContext(LoggedInContext)
	const [branchList, setBranchList] = useState([])
	const [loadBranches, setLoadBranches] = useState(true)
	const classes = useStyles()

	useEffect(() => {
		if (hasAnyProfile(user, [PROFILE.ZONE_MANAGER, PROFILE.NATIONAL, PROFILE.REGIONAL_MANAGER, PROFILE.BRANCH_MANAGER, PROFILE.BRANCH_MANAGER_ASSISTANT])) {
			api.getBranches().then(branches => {
				if (hasAnyProfile(user, [PROFILE.ZONE_MANAGER, PROFILE.NATIONAL, PROFILE.REGIONAL_MANAGER])) {
					setBranchList([...branches, { id: null, name: user.regionName ? user.regionName : 'National' }])
				} else {
					setBranchList(branches)
				}
			}).finally(() => setLoadBranches(false))
		} else {
			setLoadBranches(false)
		}
	}, [user])

	const handleSelectBranch = useCallback((branch) => {
		api.setBranch(branch).then(response => {
			setBranch(response)
		}).finally(() => {
			redirectToRightPage(history)
		})
	}, [setBranch, history])

	if (loadBranches) {
		return null
	}
	if (branchList.length <= 1) {
		return <BranchDisplay />
	}

	return (
		<Select
			IconComponent={ExpandMoreRounded}
			className={classes.selectBranch}
			disableUnderline
			value={user?.branchId || null}
			displayEmpty
			onChange={e => handleSelectBranch(e.target.value)}
			MenuProps={{
				getContentAnchorEl: null,
				anchorOrigin: {
					vertical: 'bottom',
					horizontal: 'left'
				}
			}}
		>
			{branchList.map(item =>
				<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
			)}
		</Select>
	)
}

export default withRouter(BranchSelector)

