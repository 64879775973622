import { Box, isWidthDown, withWidth } from '@material-ui/core'
import React, { useState } from 'react'
import ContractsTable from './ContractsTable'
import * as api from './services/contractsApi'
import { ContractsContext } from '../../contexts/ContractsContext'
import { setTableUrl, setUserId } from './services/contractsActions'
import TabBarDesktop from '../../components/TabBarDesktop'
import TabBarMobile from '../../components/TabBarMobile'


const Contracts = ({ width, salesRepId, modules }) => {
	const [counts, setCounts] = useState({
		priorityContractsCount: 0,
		monthlyPriorityContractsCount: 0,
		allContractsCount: 0
	})

	const { tableUrl, dispatch } = React.useContext(ContractsContext)
	React.useEffect(() => {
		api.loadContractsCount(salesRepId).then(data => {
			setCounts(data)
		})
		dispatch(setUserId(salesRepId))
	}, [salesRepId, dispatch])

	const data = React.useMemo(() => ([{
		path: 'priority',
		labelId: 'pages.portfolio.tabBar.priority',
		badge: counts.priorityContractsCount
	}, {
		path: 'monthly',
		labelId: 'pages.portfolio.tabBar.monthly',
		badge: counts.monthlyPriorityContractsCount
	}, {
		path: 'all',
		labelId: 'pages.portfolio.tabBar.all',
		badge: counts.allContractsCount
	}]), [counts])

	return (
		<>
			<Box>
				{isWidthDown('sm', width) ? <TabBarMobile data={data} dispatch={dispatch} tableUrl={tableUrl} setUrl={setTableUrl}/> :
					<TabBarDesktop data={data} dispatch={dispatch} tableUrl={tableUrl} setUrl={setTableUrl}/>}
				<ContractsTable modules={modules} />
			</Box>
		</>
	)
}

export default withWidth()(Contracts)
