import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import React from 'react'
import { injectIntl } from 'react-intl'

const ConfirmationDialog = ({ confirmAction, cancelAction, title, content, confirmActionLabel, cancelActionLabel, noConfirmAction, intl, ...otherProps }) => {
	return (
		<Dialog
			disableBackdropClick
			disableEscapeKeyDown
			fullWidth
			maxWidth="xs"
			{...otherProps}
		>
			<DialogTitle>
				<Box display="flex" alignItems="center" justifyContent="space-between" flexGrow="1">
					<Typography variant="h6">{title}</Typography>
					<IconButton onClick={cancelAction}><CloseIcon /></IconButton>
				</Box></DialogTitle>
			<DialogContent>
				{content}
			</DialogContent>
			<DialogActions>
				<Button onClick={cancelAction}>
					{cancelActionLabel ? cancelActionLabel : intl.formatMessage({ id: 'actionButton.cancel' })}
				</Button>
				{!noConfirmAction &&
				<Button variant="contained" size="small" color="primary" disableElevation onClick={confirmAction}>
					{confirmActionLabel}
				</Button>
				}
			</DialogActions>
		</Dialog>
	)
}

export default injectIntl(ConfirmationDialog)
