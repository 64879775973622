import { Box, Button, Grid, isWidthDown, Paper, withStyles, withWidth } from '@material-ui/core'
import { ExpandMoreRounded, FilterListRounded as FiltersIcon, SortRounded as SortIcon } from '@material-ui/icons'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { compose } from 'recompose'
import SearchBar from '../../components/SearchBar'
import { ContractsContext } from '../../contexts/ContractsContext'
import ContractFiltersDesktop from './filters/ContractFiltersDesktop'
import ContractFiltersMobile from './filters/ContractFiltersMobile'
import ContractSortsDropdown, { sorts } from './filters/ContractSortsDropdown'
import { setFilters, setTableUrl } from './services/contractsActions'
import TableContentDesktop from './TableContentDesktop'
import TableContentMobile from './TableContentMobile'
import { injectIntl } from 'react-intl'
import { TABLE_URL } from '../../components/TabBarDesktop'
import { OVERVIEW_TABLE_URL } from '../../constants'
import { getRenegotiationMatrix } from './services/contractsApi'
import { LoggedInContext } from '../../contexts/LoggedInContext'

const styles = (theme) => ({
	radiusWithTabBar: {
		marginTop: '4px',
		borderRadius: '0 5px 5px 5px',
		'& > div:last-child': {
			borderRadius: '0 0 5px 5px'
		}
	},
	btnPrimary: {
		fontSize: 13,
		fontWeight: 700,
		color: theme.palette.grey['600'],
		minHeight: 50,
		flex: 1,
		padding: '6px 10px',
		letterSpacing: 0,
		textTransform: 'inherit',
		backgroundColor: theme.palette.text.secondary,
		border: '1px solid',
		borderColor: theme.palette.background.lightShadow,
		borderRadius: '4px',
		boxShadow: '0 2px 0 0 #E5EAF5',
		transition: 'all .2s ease',
		'&:hover': {
			backgroundColor: theme.palette.text.secondary,
			borderColor: theme.palette.grey['700']
		}
	},
	btnPrimaryFiltered: {
		fontSize: 13,
		fontWeight: 700,
		color: 'white',
		minHeight: 50,
		flex: 1,
		padding: '6px 10px',
		letterSpacing: 0,
		textTransform: 'inherit',
		backgroundColor: '#2D6BFFAA',
		border: '1px solid',
		borderColor: '#2D6BFFCC',
		borderRadius: '4px',
		boxShadow: '0 2px 0 0 #2D6BFFCC',
		transition: 'all .2s ease',
		'&:hover': {
			backgroundColor: '#2D6BFFAA',
			borderColor: theme.palette.grey['700']
		}
	},
	cardTable: {
		display: 'flex',
		flexDirection: 'column',
		flex: '0 1 300px',
		width: '100%'
	},
	titleCardTable: {
		fontSize: 15,
		fontWeight: '700',
		textTransform: 'uppercase',
		color: theme.palette.grey['800']
	},
	subCardTable: {
		fontSize: 12,
		fontWeight: '400',
		textTransform: 'uppercase',
		color: theme.palette.grey['500']
	},
	avatar: {
		marginRight: 15
	},
	iconTypology: {
		height: 26,
		width: 26,
		padding: 3,
		margin: '0 3px',
		borderRadius: 5,
		borderColor: theme.palette.background.lightShadow,
		border: '2px solid'
	},
	toolsTable: {
		padding: 20
	}
})
const ContractsTable = ({ classes, width, isOverview, intl, modules }) => {
	const {user} = useContext(LoggedInContext)
	const [filtersAnchorEl, setFiltersAnchorEl] = useState(null)
	const [sortsAnchorEl, setSortsAnchorEl] = useState(null)
	const { filters, tableUrl, dispatch } = useContext(ContractsContext)
	const [searchParams, setSearchParams] = useState()
	const [filtered, setFiltered] = useState(false)
	const [renegotiationMatrix, setRenegotiationMatrix] = React.useState([])
	const filterRef = useRef()
	const sortRef = useRef()
	const sortsList = sorts(intl)

	useEffect(() => {
		if (user !== null) {
			getRenegotiationMatrix(user.countryId)
				.then(setRenegotiationMatrix)
		}
	}, [user])

	const handleSearchBarChange = (e) => {
		const searchParams = new URLSearchParams(window.location.search)
		searchParams.delete("searchQuery")
		searchParams.append("searchQuery", e.target.value)
		window.history.replaceState(null, "", `${window.location.pathname}?${searchParams.toString()}`)
		dispatch(setFilters({ ...filters, searchQuery: e.target.value }))
	}

	React.useEffect(() => {
		if (searchParams !== window.location.search) {
			setSearchParams(window.location.search)
			const searchParams = new URLSearchParams(window.location.search)
			if (searchParams.get("tableUrl") !== null && searchParams.get("tableUrl") !== tableUrl) {
				dispatch(setTableUrl(searchParams.get("tableUrl")))
			}
			searchParams.delete("tableUrl")
			if (searchParams.toString() !== "") {
				const newFilters = {}
				for (const key of Object.keys(filters)) {
					if (typeof filters[key] === "string") newFilters[key] = searchParams.get(key) !== null ? searchParams.get(key) : ""	
					else newFilters[key] = searchParams.getAll(key).length > 0 ? searchParams.getAll(key).map((value) => !isNaN(parseInt(value)) ? parseInt(value) : value) : []
					if (key !== "sorting" && key !== "searchQuery" && searchParams.getAll(key).length !== 0) setFiltered(true)
					searchParams.delete(key)
				}
				dispatch(setFilters(newFilters))
			}
		}
	}, [dispatch, tableUrl, filters, searchParams])

	const filterButton = <Button
		ref={filterRef}
		fullWidth
		className={filtered ? classes.btnPrimaryFiltered : classes.btnPrimary}
		onClick={() => setFiltersAnchorEl(filterRef.current)}
	>
		<FiltersIcon />
		<Box pl={1} pr={1}>{intl.formatMessage({ id: 'pages.contractsOverview.filters.title' })}</Box>
		<ExpandMoreRounded />
	</Button>

	const sortButton = <Button
		fullWidth
		ref={sortRef}
		className={classes.btnPrimary}
		onClick={() => setSortsAnchorEl(sortRef.current)}
	>
		<SortIcon />
		{sortsList[filters.sorting] && <Box pl={1} pr={1}>{intl.formatMessage({ id: 'pages.contractsOverview.sorts.title' })} {sortsList[filters.sorting].labelShort}</Box>}
		<ExpandMoreRounded />
	</Button>

	return (
		<>
			<Paper className={classes.radiusWithTabBar}>
				<Grid container display="flex" className={classes.toolsTable} spacing={1}>
					<Grid item xs={12} md={8}>
						<Box display="flex" justifyContent="flex-start" flexGrow="1" mb={{ xs: '20px', md: 0 }}>
							<SearchBar
								placeholder={intl.formatMessage({
									id: `pages.contractsOverview.table.searchBar.${
										(tableUrl === TABLE_URL.ALL || tableUrl === OVERVIEW_TABLE_URL.ALL) && modules.countryModule
											? modules.countryModule
											: 'classic'
									}`,
								})}
								onChange={handleSearchBarChange}
								value={filters.searchQuery}
							/>
						</Box>
					</Grid>
					{isWidthDown('sm', width) ? <>
							<Grid item xs={12} md={5}>{filterButton}</Grid>
							<Grid item xs={12} md={7}>{sortButton}</Grid>
						</>
						: <Grid container item display="flex" spacing={1} xs={12} md={4}>
							<Grid item xs={5}>
								<Box display="flex">
									{filterButton}
								</Box>
							</Grid>
							<Grid item xs={7}>
								<Box display="flex">
									{sortButton}
								</Box>
							</Grid>
						</Grid>}
				</Grid>
				{isWidthDown('sm', width) ? (
					<TableContentMobile isOverview={isOverview} modules={modules} renegotiationMatrix={renegotiationMatrix}/>
				) : (
					<TableContentDesktop isOverview={isOverview} modules={modules} renegotiationMatrix={renegotiationMatrix}/>
				)}
			</Paper>
			{isWidthDown('sm', width) ?
				<ContractFiltersMobile open={Boolean(filtersAnchorEl)} anchorEl={filtersAnchorEl} closeFilters={() => setFiltersAnchorEl(null)} modules={modules} setFiltered={setFiltered} /> :
				<ContractFiltersDesktop open={Boolean(filtersAnchorEl)} anchorEl={filtersAnchorEl} closeFilters={() => setFiltersAnchorEl(null)} modules={modules} setFiltered={setFiltered} />
			}
			<ContractSortsDropdown modules={modules} open={Boolean(sortsAnchorEl)} anchorEl={sortsAnchorEl} closeSorts={() => setSortsAnchorEl(null)} />
		</>
	)
}

const enhance = compose(
	withStyles(styles),
	withWidth(),
	injectIntl
)

export default enhance(ContractsTable)
