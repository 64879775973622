import * as React from 'react'
import { FunctionComponent, useCallback } from 'react'
import { YtdStatistics } from '../kpi/types'
import ManuallyClosedContractsTable, { getManuallyClosedContracts } from '../kpi/ManuallyClosedContractsTable'
import { LoggedInContext } from '../../contexts/LoggedInContext'
import { RouteComponentProps, withRouter } from 'react-router'

const ManuallyClosedContracts: FunctionComponent<RouteComponentProps> = ({ history }) => {
    const {user} = React.useContext(LoggedInContext)
    const [stats, setStats] = React.useState<YtdStatistics | undefined>(undefined)
    React.useEffect(() => {
        getManuallyClosedContracts().then(stats => setStats(stats))
    }, [user])

    const goToContractsOverview = useCallback((status, classification) => {
        history.push(`/contracts-overview/${status.toLowerCase().replace(/_/g, '-')}/${classification}`)
    }, [history])

    // TODO add loader
    if (typeof stats === 'undefined') {
        return null
    }

    return <ManuallyClosedContractsTable
        stats={stats}
        onCellClick={goToContractsOverview}
    />
}

export default withRouter(ManuallyClosedContracts)