import { createStyles, makeStyles, MenuItem, Select, Theme } from '@material-ui/core'
import { ArrowForwardIosRounded, ExpandMoreRounded } from '@material-ui/icons'
import NestedMenuItem from 'material-ui-nested-menu-item'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import { LoggedInContext } from '../../contexts/LoggedInContext'
import { PROFILE } from '../../enums'
import { hasAnyProfile } from '../../utils/accessChecker'
import { Branch, Region } from './types'
import BranchSelector from '../branches/BranchSelector'
import * as apiBranches from '../branches/branchesApi'
import * as apiRegions from './api'
import { redirectToRightPage } from '../../utils/utils'

const useStyles = makeStyles(
	(theme: Theme) =>
		createStyles({
			selectRegion: {
				maxWidth: 300,
				width: '100%',
				textTransform: 'uppercase',
				fontSize: 13,
				color: theme.palette.grey['800'],
				fontWeight: 700,
				lineHeight: 0,
				[theme.breakpoints.down('sm')]: {
					backgroundColor: theme.palette.text.secondary,
				},
				'& [class*="MuiSelect-root"]': {
					paddingRight: 34,
				},
				'& [class*="MuiSelect-icon"]': {
					right: 10,
				},
			},
			nestedMenu: {
				justifyContent: 'space-between',
				'& [class*="MuiSvgIcon-root"]': {
					fontSize: '0.8rem',
				},
			},
		}),
	{ name: 'RegionSelector' }
)

const RegionSelector = ({ history }) => {
	const { user, setBranch, setRegion } = useContext(LoggedInContext)
	const [regionList, setRegionList] = useState<Region[]>([])
	const [loadBranches, setLoadBranches] = useState<boolean>(true)
	const classes = useStyles()

	const [menuPosition, setMenuPosition] = useState<any>(null)

	useEffect(() => {
		if (user && hasAnyProfile(user, [PROFILE.ZONE_MANAGER, PROFILE.NATIONAL, PROFILE.REGIONAL_MANAGER])) {
			apiRegions.getRegions()
				.then((regions: Region[]) => {
					if (hasAnyProfile(user, [PROFILE.ZONE_MANAGER, PROFILE.NATIONAL])) {
						setRegionList([...regions, { id: null, name: 'National', idCountry: '', branches: [] }])
					} else {
						setRegionList(regions)
					}
				})
				.finally(() => setLoadBranches(false))
		} else {
			setLoadBranches(false)
		}
	}, [user])

	const handleOnOpen = (event: any) => {
		if (menuPosition) {
			return
		}
		event.preventDefault()
		setMenuPosition({
			top: event.pageY,
			left: event.pageX,
		})
	}

	const handleSelect = useCallback(
		(value: any) => {
			setMenuPosition(null)
			const [region, branch] = value.split('-')
			apiRegions.setRegion(region !== 'null' ? region : null)
				.then((response: any) => {
					setRegion(response)
					apiBranches.setBranch(branch !== 'null' ? branch : null).then((response: any) => {
						setBranch(response)
					})
				})
				.finally(() => {
					redirectToRightPage(history)
				})
		},
		[setRegion, setBranch, history]
	)

	if (loadBranches) {
		return null
	}

	if (regionList.length <= 1) {
		return <BranchSelector />
	}

	return (
		<Select
			IconComponent={ExpandMoreRounded}
			className={classes.selectRegion}
			disableUnderline
			value={`${user?.regionId}-${user?.branchId}` || null}
			displayEmpty
			MenuProps={{
				getContentAnchorEl: null,
				anchorOrigin: {
					vertical: 'bottom',
					horizontal: 'left',
				},
			}}
			onOpen={handleOnOpen}
			open={menuPosition !== null}
			onChange={(e) => handleSelect(e.target.value)}
			onClose={() => setMenuPosition(null)}
			renderValue={(_: unknown) => (user?.branchId ? user?.branchName : user?.regionId ? user?.regionName : 'National')}
		>
			{regionList.map((item: Region) =>
				item.branches.length > 0 ? (
					<NestedMenuItem
						className={classes.nestedMenu}
						key={item.id}
						label={item.name}
						rightIcon={<ArrowForwardIosRounded />}
						parentMenuOpen={!!menuPosition}
						value={`${item.id}-null`}
					>
						{item.branches.map((item2: Branch) => (
							<MenuItem
								selected={user?.branchId === item2.id}
								key={item2.id}
								value={`${item.id}-${item2.id}`}
								onClick={() => handleSelect(`${item.id}-${item2.id}`)}
							>
								{item2.name}
							</MenuItem>
						))}
					</NestedMenuItem>
				) : (
					<MenuItem key={item.id} value={`${item.id}-null`}>
						{item.name}
					</MenuItem>
				)
			)}
		</Select>
	)
}

export default withRouter(RegionSelector)
