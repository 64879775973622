import { Box, ClickAwayListener, Grid, List, ListItem, ListItemText, Paper, Popper } from '@material-ui/core'
import React, { useContext } from 'react'
import { ActionsFiltersContext } from '../../contexts/ActionsFiltersContext'
import { injectIntl } from 'react-intl'

export const actionFiltersList = intl => ({
	all: {
		label: intl.formatMessage({ id: 'action.filters.all' }),
		key: ''
	},
	OPEN: {
		label: intl.formatMessage({ id: 'action.filters.open' }),
		key: 'OPEN'
	},
	CLOSED: {
		label: intl.formatMessage({ id: 'action.filters.closed' }),
		key: 'CLOSED'
	}
})

const ActionFilters = ({ anchorEl, open, closeFilters, intl }) => {
	const { filters, setFilters } = useContext(ActionsFiltersContext)
	const filtersList = actionFiltersList(intl)
	return (
		<Popper
			anchorEl={anchorEl}
			placement="bottom-end"
			open={open}
			onClose={closeFilters}
			disablePortal={false}
			modifiers={{
				flip: {
					enabled: false,
				},
				preventOverflow: {
					enabled: false
				}
			}}
		>
			<ClickAwayListener onClickAway={closeFilters}>
				<Paper>
					<Box p={3}>
						<Grid container spacing={2}>
							<List>
								{Object.keys(filtersList).map(filter =>
									<ListItem button key={filter}>
										<ListItemText onClick={() => {
											setFilters({ ...filters, status: filtersList[filter].key })
											closeFilters()
										}} primary={filtersList[filter].label} />
									</ListItem>
								)}
							</List>
						</Grid>
					</Box>
				</Paper>
			</ClickAwayListener>
		</Popper>
	)
}

export default injectIntl(ActionFilters)