import { Box, Fab, Paper, withStyles, withWidth } from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'
import { SpringDataTable } from 'isotope-client'
import React, { useContext } from 'react'
import { compose } from 'recompose'
import { UsersContext } from '../../../contexts/UsersContext'
import { PROFILE } from '../../../enums'
import { AccessChecker, hasProfile } from '../../../utils/accessChecker'
import NewUserDialog from './forms/NewUserDialog'
import UpdateUserDialog from './forms/UpdateUserDialog'
import {
	closeNewUserDialog,
	closeUserUpdateDialog,
	openNewUserDialog,
	refreshUserList,
	setUserForUpdateDialog,
	USER_LIST_NAME
} from './services/usersActions'
import * as api from './services/usersApi'
import UsersFilters from './UsersFilters'
import { getCountries } from '../../selectcountry/services/api'
import { LoggedInContext } from '../../../contexts/LoggedInContext'
import { FormattedMessage, injectIntl } from 'react-intl'

const styles = (theme) => ({
	radiusWithTabBar: {
		backgroundColor: theme.palette.grey['200']
	},
	fab: {
		right: theme.spacing(5),
		bottom: theme.spacing(5),
		position: 'fixed',
		backgroundColor: theme.palette.primary.dark
	},
	fabIcon: {
		color: theme.palette.grey['50']
	}
})

const Users = ({ classes, intl }) => {
	const { filters, userForUpdateDialog, newUserDialog, dispatch, tableDispatch } = useContext(UsersContext)
	const { user } = React.useContext(LoggedInContext)

	const [countries, setCountries] = React.useState([])

	React.useEffect(() => {
		getCountries().then(setCountries)
	}, [])

	const deactivateUser = React.useCallback(user => {
		api.deactivateUser(user.id).then(() => {
			refreshUserList(tableDispatch)
		})
	}, [tableDispatch])

	const reactivateUser = React.useCallback(user => {
		api.reactivateUser(user.id).then(() => {
			refreshUserList(tableDispatch)
		})
	}, [tableDispatch])

	const headers = React.useMemo(() => {
		const headers = [
			{
				key: 'lastName',
				name: intl.formatMessage({ id: 'user.table.headers.lastName' }),
				sortable: true
			},
			{
				key: 'firstName',
				name: intl.formatMessage({ id: 'user.table.headers.firstName' })
			},
			{
				key: 'email',
				name: intl.formatMessage({ id: 'user.table.headers.email' })
			},
			{
				key: 'branches',
				name: intl.formatMessage({ id: 'user.table.headers.branch' }),
				render: row => row.branches.map(branch => branch.name).join(', ')
			},
			{
				key: 'profile',
				name: intl.formatMessage({ id: 'user.table.headers.profile' }),
				render: row => <FormattedMessage id={`profiles.${row.profile}`} />
			}
		]
		if (hasProfile(user, PROFILE.ZONE_MANAGER)) {
			headers.push({
				key: 'country',
				name: intl.formatMessage({ id: 'user.table.headers.country' }),
				render: row => countries.filter(country => row.countries.includes(country.id)).map(country => country.name).join(', ')
			})
		}
		headers.push({
			key: 'region',
			name: intl.formatMessage({ id: 'user.table.headers.region' }),
			render: row => row.regions?.map(region => region.name).join(', ')
		})
		return headers
	}, [countries, user, intl])


	const makeActions = React.useCallback(row => {
		if (row.status === 'INACTIVE') {
			return ([
				{ label: intl.formatMessage({ id: 'user.table.actions.reactivate' }), action: () => reactivateUser(row) }
			])
		} else {
			return ([
				{ label: intl.formatMessage({ id: 'user.table.actions.modify' }), action: () => dispatch(setUserForUpdateDialog(row)) },
				{ label: intl.formatMessage({ id: 'user.table.actions.deactivate' }), action: () => deactivateUser(row) }
			])
		}
	}, [reactivateUser, deactivateUser, dispatch, intl])

	return (
		<AccessChecker profiles={[PROFILE.ZONE_MANAGER, PROFILE.NATIONAL]}>
			<Box pt={5} pb={15}>
				<Paper className={classes.radiusWithTabBar} elevation={0}>
					<UsersFilters />
				</Paper>
				<SpringDataTable
					nom={USER_LIST_NAME}
					headers={headers}
					makeActions={makeActions}
					showActions
					sort={[{ property: 'lastName', direction: 'ASC' }]}
					filters={{ ...filters, profile: filters.profile === 'all' ? '' : filters.profile }}
					apiUrl="/rtv-users"
				/>

				<UpdateUserDialog open={!!userForUpdateDialog} onClose={() => dispatch(closeUserUpdateDialog())} />
				<NewUserDialog open={newUserDialog} onClose={() => dispatch(closeNewUserDialog())} />
				<Fab className={classes.fab} onClick={() => dispatch(openNewUserDialog())}><AddIcon className={classes.fabIcon} /></Fab>
			</Box>

		</AccessChecker>
	)
}

const enhance = compose(
	withStyles(styles),
	withWidth(),
	injectIntl
)

export default enhance(Users)
