import { dataTableActions } from 'isotope-client'

const ROOT = 'USERS'

export const USER_LIST_NAME = 'backOfficeUserList'

export const OPEN_NEW_USER_DIALOG = `${ROOT}/OPEN_NEW_USER_DIALOG`
export const CLOSE_NEW_USER_DIALOG = `${ROOT}/CLOSE_NEW_USER_DIALOG`
export const CLOSE_USER_UPDATE_DIALOG = `${ROOT}/CLOSE_USER_UPDATE_DIALOG`
export const SET_USER_FOR_UPDATE_DIALOG = `${ROOT}/SET_USER_FOR_UPDATE_DIALOG`
export const SET_FILTERS = `${ROOT}/SET_FILTERS`
export const RESET_FILTERS = `${ROOT}/RESET_FILTERS`

export const openNewUserDialog = () => ({
	type: OPEN_NEW_USER_DIALOG
})

export const closeNewUserDialog = () => ({
	type: CLOSE_NEW_USER_DIALOG
})

export const closeUserUpdateDialog = () => ({
	type: CLOSE_USER_UPDATE_DIALOG
})

export const setUserForUpdateDialog = user => ({
	type: SET_USER_FOR_UPDATE_DIALOG,
	user
})

export const setFilters = filters => ({
	type: SET_FILTERS,
	filters
})

export const resetFilters = () => ({
	type: RESET_FILTERS
})

export const refreshUserList = dataTableActions.refresh(USER_LIST_NAME)
