import React, { createContext, useEffect, useReducer } from 'react'
import { withRouter } from 'react-router'
import { setContract } from '../modules/contracts/services/contractsActions'
import * as api from '../modules/contracts/services/contractsApi'
import { initialState, reducer } from '../modules/contracts/services/contractsReducer'
import { ErrorHandlerContext } from './ErrorHandlerContext'

const allFilters = {
	status: [
		{ key: 'toDo', value: 'TODO' },
		{ key: 'inProgress', value: 'IN_PROGRESS' },
		{ key: 'toBeCancelled', value: 'TO_BE_CANCELLED' },
		{ key: 'toBeExtended', value: 'TO_BE_AUTO_RENEWED' },
		{ key: 'renegociated', value: 'RENEGOTIATED' },
		{ key: 'upgraded', value: 'UPGRADED' },
		{ key: 'downgraded', value: 'DOWNGRADED' }
	],
	classification: [
		{ key: 'high', value: 'HIGH_RISK_HIGH_VALUE' },
		{ key: 'low', value: 'LOW_VALUE' },
		{ key: 'manually', value: 'MANUALLY_PRIORITIZED' }
	],
	risk: [
		{ key: '5', value: 5 },
		{ key: '4', value: 4 },
		{ key: '3', value: 3 },
		{ key: '2', value: 2 },
		{ key: '1', value: 1 }
	],
	value: [
		{ key: '5', value: 5 },
		{ key: '4', value: 4 },
		{ key: '3', value: 3 },
		{ key: '2', value: 2 },
		{ key: '1', value: 1 },
		{ key: '0', value: 0 }
	],
	flags: [
		{ key: 'help', value: 'needHelp' },
		{ key: 'overdue', value: 'overdue' },
		{ key: 'actionsOverdue', value: 'actionsOverdue' }
	],
	endDate: [
		{ key: 'outdated', value: 'outdated' },
		{ key: 'short', value: 'short' },
		{ key: 'medium', value: 'medium' },
		{ key: 'long', value: 'long' }
	],
	riskTypology: [
		{ key: 'field', value: 'FIELD' },
		{ key: 'sales', value: 'SALES' },
	],
	customerFeedback: [
		{ key: 'cancellationLetter', value: 'cancellationLetter' },
		{ key: 'claimLetter', value: 'claimLetter' },
	]
}

const ContractsContext = createContext({
	filters: {},
	tableUrl: '',
	contract: {},
	contractId: '',
	userId: '',
	dispatch: e => {}
})

const ContractsContextProvider = withRouter(({ children, match }) => {
	const [state, dispatch] = useReducer(reducer, initialState)
	const { setError } = React.useContext(ErrorHandlerContext)

	useEffect(() => {
		if (state.contractId) {
			api
				.loadContractById(state.contractId)
				.then((data) => {
					if (data.module === 'FR') {
						api
							.loadContractFranceById(state.contractId)
							.then((module) => (data = { ...data, ...module }))
							.finally(() => dispatch(setContract(data)))
					} else dispatch(setContract(data))
				})
				.catch(error => setError(error))
		}
	}, [state.contractId, setError])

	return (
		<ContractsContext.Provider
			value={{
				filters: state.filters,
				tableUrl: state.tableUrl,
				contract: state.contract,
				contractId: state.contractId,
				userId: state.userId,
				dispatch
			}}>
			{children}
		</ContractsContext.Provider>
	)
})

export { ContractsContext, ContractsContextProvider, allFilters }
