import { Box, Paper, withStyles } from '@material-ui/core'
import React from 'react'
import ContractComments from './ContractComments'
import ContractFranceDetails from './modules/ContractFranceDetails'
import { UsersComment } from '../../interfaces'

const styles = () => ({
	paper: {
		borderRadius: '0 5px 5px 5px',
		'& > div:last-child': {
			borderRadius: '0 0 5px 5px',
		},
		padding: '20px 0px',
	},
	comments: {
		padding: '10px 30px 0px 30px',
	},
})

const ContractDetails = ({ classes, contract, dispatch }) => {
	let managers: UsersComment | null = null
	let salesRep: UsersComment | null = null
	if (contract.contractNegotiation) {
		managers = {
			date: contract.contractNegotiation.managersCommentDate,
			firstname: contract.contractNegotiation.managersCommentFirstName,
			lastname: contract.contractNegotiation.managersCommentLastName,
			text: contract.contractNegotiation.managersCommentText,
		}

		salesRep = {
			date: contract.contractNegotiation.srCommentDate,
			firstname: contract.firstNameSalesRep,
			lastname: contract.lastNameSalesRep,
			text: contract.contractNegotiation.srCommentText,
		}
	}

	return (
		<Paper className={classes.paper}>
			{contract.module === 'FR' && <ContractFranceDetails contract={contract} />}
			{contract.contractNegotiation && managers && salesRep && (
				<Box className={classes.comments}>
					<ContractComments managers={managers} salesRep={salesRep} contract={contract} dispatch={dispatch} />
				</Box>
			)}
		</Paper>
	)
}

export default withStyles(styles)(ContractDetails)
