import { Box, Grid, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles((theme) => ({
		chartBox: {
			backgroundColor: theme.palette.common.white
		},
		grid: {
			padding: '10px'
		},
		value: {
			fontWeight: 'bold',
			marginTop: '15px'
		},
		range: {
			color: theme.palette.text.light,
			textAlign: 'center'
		},
		median: {
			fontWeight: 'bold'
		}
	}
))

const defaultOptions = {
	title: {
		text: ''
	},
	chart: {
		type: 'column',
		height: '120px'
	},
	yAxis: {
		visible: false,
		endOnTick: false
	},
	xAxis: {
		visible: false
	},
	legend: {
		enabled: false
	},
	colors: ['#CCD2D9', '#CCD2D9', '#CCD2D9', '#CCD2D9', '#CCD2D9', '#CCD2D9'],
	series: [{
		data: [0, 0, 0, 0, 0, 0]
	}],
	plotOptions: {
		column: {
			borderRadius: 5,
			pointWidth: 26,
			colorByPoint: true,
			minPointLength: 6
		}
	},
	tooltip: {
		useHTML: true,
		headerFormat: `<span>{point.y} contracts</span>`,
		pointFormat: ''
	},
	credits: {
		enabled: false
	}
}

const ContractChart = ({ title, value, rangeMin, rangeMax, median, data, position, currency }) => {
	const [options, setOptions] = React.useState({ ...defaultOptions })
	const classes = useStyles()

	React.useEffect(() => {
		setOptions({
			...defaultOptions,
			series: [{ data: data }],
			colors: [...defaultOptions.colors.slice(0, position), '#2D6BFF', ...defaultOptions.colors.slice(position + 1)],
			xAxis: {
				visible: false
			}
		})
	}, [data, position, setOptions])

	return (
		<Box className={classes.chartBox}>
			<Box className={classes.grid}>
				<Box display="flex" justifyContent="flex-start">
					<Typography variant="h5">{title}</Typography>
				</Box>
				<Box display="flex" justifyContent="center">
					<Typography variant="h3" className={classes.value}>{value} {currency}</Typography>
				</Box>
				<Box display="flex" justifyContent="center">
					<Typography variant="subtitle2" className={classes.range}>
						<FormattedMessage id="pages.contract.charts.range" values={{ rangeMin, rangeMax, currency }} />
					</Typography>
				</Box>
				<HighchartsReact
					highcharts={Highcharts}
					options={options}
					allowChartUpdate={true}
				/>
				<Grid container direction="column" alignItems="center">
					<Grid item className={classes.median}>
						<Typography variant="subtitle2">
							<FormattedMessage id="pages.contract.charts.median" values={{ median, currency }} />
						</Typography>
					</Grid>
				</Grid>
			</Box>
		</Box>
	)
}

export default ContractChart
