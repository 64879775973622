import React from 'react'
import { isWidthDown, Paper, withStyles, WithStyles, withWidth } from '@material-ui/core'
import { tabBarStyles } from '../../style'
import { WithWidth } from '@material-ui/core/withWidth/withWidth'
import { REPORTING_PAGES } from '../../../../constants'
import TableActivity from './TableActivity'
import { compose } from 'recompose'
import MobileTableActivity from './MobileTableActivity'
import classNames from 'classnames'

interface Props extends WithStyles<typeof tabBarStyles>, WithWidth {
}

const DetailedActivity: React.FunctionComponent<Props> = ({ classes, width }) => {
	return (
		<Paper className={classNames(classes.radiusWithTabBar, isWidthDown('sm', width) ? classes.tabContentMobile : classes.tabContentDesktop)}>
			{
				isWidthDown('sm', width) ? <MobileTableActivity target={REPORTING_PAGES.DETAILED} /> : (
					<div className={classes.tableAlone}>
						<TableActivity target={REPORTING_PAGES.DETAILED} />
					</div>
				)
			}
		</Paper>
	)
}

const enhance = compose(
	withWidth(),
	withStyles(tabBarStyles)
)

export default enhance(DetailedActivity)