import { FilterError, ReportingFiltersProps } from '../types'
import { IntlShape } from 'react-intl'
import React from 'react'
import * as H from 'history'
import { setCountry } from '../../selectcountry/services/api'
import { fetchUser } from 'isotope-client'
import { Branch, Region, User } from '../../../utils/accessChecker'
import { setRegion as setRegionApi } from '../../regions/api'
import { setBranch as setBranchApi } from '../../branches/branchesApi'
import { LEVEL } from '../../../constants'

export const validate = (intl: IntlShape) => (values: ReportingFiltersProps) => {
	const errors: FilterError = {}
	if (values.year === 0) {
		errors.year = intl.formatMessage({ id: 'pages.contract.forecast.errors.required' })
	}
	return errors
}

export const onChange = (setFilters: React.Dispatch<React.SetStateAction<ReportingFiltersProps>>) => (event: React.ChangeEvent<{ name?: string; value: any }>) => {
	if (event.target.name) {
		setFilters(prevState => ({ ...(prevState || {}), [event.target.name as string]: event.target.value }))
	}
}

export const redirectToCountryTeamsReporting = (history: H.History<H.LocationState>,
                                                countryId: string, setUser : (user: User) => void) =>
	setCountry(countryId)
		.then(() => fetchUser(localStorage.getItem('token'))
			.then(user => {
				setUser(user)
				history.replace('/reporting/teams/')
			})
		)


export const selectRegionReporting = (history: H.History<H.LocationState>, regionId: string,
                                      setRegion : (region: Region) => void) =>
	setRegionApi(regionId)
		.then((region: Region) => {
			setRegion(region)
			history.replace(`/reporting/teams/`)
		})


export const selectBranchReporting = (history: H.History<H.LocationState>, branchId: string,
                                      setBranch : (branch: Branch) => void) =>
	setBranchApi(branchId)
		.then((branch: Branch) => {
			setBranch(branch)
			history.replace(`/reporting/teams/`)
		})


export const getEntityLibelle = (perimeter : string, libelle: string) =>
	perimeter === LEVEL.COUNTRY ? libelle.toUpperCase() : libelle