import { AppBar, Avatar, Badge, Box, Button, IconButton, Toolbar, Typography, withStyles } from '@material-ui/core'
import { Menu as MenuIcon, NotificationsOutlined as NotificationsIcon, SettingsOutlined as SettingsIcon } from '@material-ui/icons'
import React, { useCallback, useContext, useState } from 'react'
import SearchBar from '../../../components/SearchBar'
import { LoggedInContext } from '../../../contexts/LoggedInContext'
import { PROFILE } from '../../../enums'
import { hasAnyProfile, hasProfile } from '../../../utils/accessChecker'
import BranchSelector from '../../branches/BranchSelector'
import SearchTray from '../../searchTray/SearchTray'
import LogoutMenu from '../LogoutMenu'
import NotificationsTray from './NotificationsTray'
import { Link } from 'react-router-dom'
import HomeIcon from '@material-ui/icons/HomeOutlined'
import classNames from 'classnames'
import { FormattedMessage, injectIntl } from 'react-intl'
import { compose } from 'recompose'
import { withRouter } from 'react-router'
import RegionSelector from '../../regions/RegionSelector'

const styles = (theme) => ({
	root: {
		boxShadow: '0px 0px 3px 6px rgba(60,68,91,.15)'
	},
	boxLeft: {
		display: 'flex',
		flexGrow: 1,
		alignItems: 'center',
		justifyContent: 'flex-start',
		'& > *': {
			marginRight: 15
		}
	},
	boxRight: {
		display: 'flex',
		flexGrow: 1,
		alignItems: 'center',
		justifyContent: 'flex-end',
		'& > *': {
			marginLeft: 15
		}
	},
	positionToggle: {
		position: 'relative',
		marginLeft: '-40px'
	},
	toggleNav: {
		color: theme.palette.secondary.main,
		width: 30,
		height: 30,
		left: 30
	},
	titleNavBar: {
		whiteSpace: 'nowrap',
		textTransform: 'uppercase',
		fontSize: 17,
		fontWeight: 700,
		color: theme.palette.text.primary
	},
	searchBar: {
		padding: '0 15px',
		maxWidth: 360,
		width: '100%',
		opacity: 1,
		color: theme.palette.grey['800'],
		backgroundColor: theme.palette.grey['50'],
		'& [class*="MuiSelect-icon"]': {
			right: 10
		}
	},
	btnPrimary: {
		color: theme.palette.grey['600'],
		backgroundColor: theme.palette.text.secondary,
		border: '1px solid',
		borderColor: theme.palette.background.lightShadow,
		borderRadius: '4px',
		boxShadow: '0 2px 0 0 #E5EAF5',
		transition: 'all .2s ease',
		'&:hover': {
			backgroundColor: theme.palette.text.secondary,
			borderColor: theme.palette.grey['700']
		}
	},
	homeButton: {
		padding: '11px 13px',
		flexShrink: 0
	},
	homeIcon: {
		width: 20,
		height: 20,
		marginLeft: 0,
		margitRight: 17
	},
	pinBadge: {
		'& [class*="MuiBadge-badge"]': {
			position: 'absolute',
			top: '-10px',
			right: '-10px',
			backgroundColor: theme.palette.secondary.light,
			'&:after': {
				content: '\'\'',
				position: 'absolute',
				top: 6,
				zIndex: 1000,
				display: 'block',
				height: 8,
				width: 8,
				borderRadius: 8,
				backgroundColor: theme.palette.secondary.main
			}
		}
	},
	avatarPointer: {
		cursor: 'pointer'
	}
})

const Navbar = ({ toggleDrawer, classes, overdueContracts, overdueActions, needHelpContracts, hasNotification, intl, history }) => {
	const { user } = useContext(LoggedInContext)
	const [logoutMenuOpen, setLogoutMenuOpen] = useState(null)
	const [notificationAnchorEl, setNotificationAnchorEl] = useState(null)
	const [searchAnchorEl, setSearchAnchorEl] = useState(null)
	const [searchQuery, setSearchQuery] = useState('')

	const searchBarClick = useCallback((e) => {
			if (!Boolean(searchAnchorEl) && !!searchQuery) {
				setSearchAnchorEl(e.target)
			}
		}, [searchAnchorEl, searchQuery]
	)
	const searchBarChange = useCallback((e) => {
			if (!Boolean(searchAnchorEl) && e.target.value.length > 1) {
				setSearchAnchorEl(e.target)
			}
			if (!e.target.value) {
				setSearchAnchorEl(null)
			}
			setSearchQuery(e.target.value)
		}, [searchAnchorEl]
	)

	const title = React.useMemo(() => {
		if (user.countryName) {
			return <Typography className={classes.titleNavBar}><FormattedMessage id="title" /> - {user.countryName.toLocaleUpperCase()}</Typography>
		}
		return <Typography className={classes.titleNavBar}><FormattedMessage id="title" /></Typography>
	}, [user, classes])

	const multiCountries = React.useMemo(() => hasProfile(user, PROFILE.ZONE_MANAGER) || (hasProfile(user, PROFILE.NATIONAL) && user.countries.length > 1), [user])

	return (
		<>
			<AppBar className={classes.root}>
				<Toolbar>
					<Box className={classes.boxLeft} ml={4}>
						<IconButton onClick={toggleDrawer} name="burgerButton" className={classes.positionToggle}>
							<MenuIcon className={classes.toggleNav} name="burgerIcon" />
						</IconButton>
						{multiCountries
							? <Button
								className={classNames(classes.btnPrimary, classes.homeButton)}
								classes={{
									startIcon: classes.homeIcon
								}}
								component={Link}
								startIcon={<HomeIcon />}
								to="/select-country"
							>{title}</Button>
							: title}
						{hasAnyProfile(user, [PROFILE.ZONE_MANAGER, PROFILE.NATIONAL])
							|| (hasProfile(user, PROFILE.REGIONAL_MANAGER) && user.regions.length > 1) ? <RegionSelector /> : <BranchSelector />}
					</Box>
					<Box className={classes.boxRight}>
						<SearchBar onClick={searchBarClick} onChange={searchBarChange} placeholder={intl.formatMessage({ id : 'searchBar' })} />
						{!hasAnyProfile(user, [PROFILE.ZONE_MANAGER, PROFILE.NATIONAL]) &&
						<IconButton onClick={(e) => setNotificationAnchorEl(e.currentTarget)} className={classes.btnPrimary}>
							<Badge badgeContent="" color="secondary" className={classes.pinBadge} invisible={!hasNotification}>
								<NotificationsIcon />
							</Badge>
						</IconButton>}
						{hasAnyProfile(user, [PROFILE.ZONE_MANAGER, PROFILE.NATIONAL]) &&
						<IconButton className={classes.btnPrimary} onClick={() => history.push('/bo/users')}><SettingsIcon /></IconButton>}
						<Avatar onClick={(e) => setLogoutMenuOpen(e.currentTarget)} className={classes.avatarPointer}>{(user.firstname[0] + user.lastname[0]).toUpperCase()}</Avatar>
					</Box>
					<SearchTray
						anchorEl={searchAnchorEl}
						isMenuOpen={Boolean(searchAnchorEl)}
						handleMenuClose={() => setSearchAnchorEl(null)}
						query={searchQuery}
					/>
					<NotificationsTray
						anchorEl={notificationAnchorEl}
						isMenuOpen={Boolean(notificationAnchorEl)}
						handleMenuClose={() => setNotificationAnchorEl(null)}
						overdueContracts={overdueContracts}
						overdueActions={overdueActions}
						needHelpContracts={needHelpContracts}
					/>
					<LogoutMenu
						anchorEl={logoutMenuOpen}
						onClose={() => setLogoutMenuOpen(null)}
					/>
				</Toolbar>
			</AppBar>
		</>
	)
}

export default compose(
	withStyles(styles),
	injectIntl,
	withRouter,
)(Navbar)

