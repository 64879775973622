import { ClickAwayListener, Drawer as MuiDrawer, List, withStyles } from '@material-ui/core'
import React from 'react'
import { withRouter } from 'react-router'
import { compose } from 'recompose'
import DrawerMenuItem from './DrawerMenuItem'

const styles = (theme) => ({
	drawerContainer: {},
	drawerOpen: {
		top: 75,
		paddingTop: 40,
		backgroundColor: theme.palette.background.dark,
		color: theme.palette.text.secondary,
		transition: 'all .2s ease',
		width: 395,
		boxShadow: '0px 10px 10px 10px rgba(60,68,91,.15)'
	},
	drawerClosed: {
		top: 75,
		paddingTop: 40,
		width: 85,
		backgroundColor: theme.palette.background.dark,
		color: theme.palette.text.secondary,
		transition: 'all .2s ease'
	}
})

const Drawer = ({ items, isDrawerOpen, classes, location, closeDrawer }) => {
	const isCurrent = React.useCallback((path) => location.pathname.includes(path), [location])

	return (
		<ClickAwayListener onClickAway={(e) => {if (isDrawerOpen && e.path && e.path.filter(a => a.name === 'burgerButton').length === 0) closeDrawer()}}>
			<MuiDrawer
				variant="permanent"
				PaperProps={{ className: isDrawerOpen ? classes.drawerOpen : classes.drawerClosed }}
			>
				<List className={classes.drawerContainer}>
					{items.map((item, index) => <DrawerMenuItem key={`drawer-${index}`} item={item} isCurrent={isCurrent(item.path)} />)}
				</List>
			</MuiDrawer>
		</ClickAwayListener>
	)
}

const enhance = compose(
	withRouter,
	withStyles(styles)
)

export default enhance(Drawer)