import { fetchFactory } from 'isotope-client'

export const setBranch = (branch) => fetchFactory(`/rtv-users/set-branch`, {
	method: 'post',
	body: JSON.stringify({ branch })
})
export const getBranches = () => fetchFactory(`/branch`, {}, '/api')

export const getRegionBranches = () => fetchFactory(`/branch/region`, {}, '/api')

export const getCountryBranches = (countryId) => fetchFactory(`/branch/${countryId}`, {}, '/api')
