import {
	Button,
	createStyles,
	Grid,
	InputLabel,
	isWidthUp,
	MenuItem,
	Select,
	Theme,
	Typography,
	withStyles,
	withWidth,
} from '@material-ui/core'
import { ExpandMoreRounded } from '@material-ui/icons'
import React, { useContext } from 'react'
import { Field, Form } from 'react-final-form'
import { FormattedMessage } from 'react-intl'
import { compose } from 'recompose'
import { LoggedInContext } from '../../../contexts/LoggedInContext'

enum FIELDS {
	PROBABILITY = 'probability',
	CANCELLATION_MONTH = 'cancellationMonth',
}

const probability = [
	{
		name: 'pages.contract.forecast.probability.low',
		value: 'LOW',
	},
	{
		name: 'pages.contract.forecast.probability.high',
		value: 'HIGH',
	},
]

const styles = (theme: Theme) => {
	return createStyles({
		select: {
			textTransform: 'uppercase',
			fontSize: 13,
			color: theme.palette.grey['800'],
			backgroundColor: theme.palette.background.paper,
			fontWeight: 700,
			lineHeight: 0,
			[theme.breakpoints.down('sm')]: {
				backgroundColor: theme.palette.text.secondary,
			},
			'& [class*="MuiSelect-root"]': {
				paddingRight: 34,
			},
			'& [class*="MuiSelect-icon"]': {
				right: 10,
			},
		},
		inputLabel: {
			color: '#687BAE',
			width: '100%',
			marginBottom: 5,
		},
		itemContainer: {
			width: '100%',
			'& [class*="MuiGrid-item"]': {
				padding: 5,
			},
		},
		button: {
			height: 50,
		},
		error: {
			color: 'red',
			marginTop: 5,
		},
	})
}

const ForecastForm = ({ classes, contract, validate, initialValues, onSubmit, cancellationMonth, width }) => {
	const { user } = useContext(LoggedInContext)

	return (
		<Form
			initialValues={initialValues}
			onSubmit={onSubmit}
			validate={validate}
			render={({ handleSubmit, pristine, errors, touched }) => (
				<form onSubmit={handleSubmit} className={classes.itemContainer}>
					<Grid container>
						<Grid item xs={12} md={3}>
							<Field
								name={FIELDS.PROBABILITY}
								render={({ input, meta }) => (
									<>
										<InputLabel className={classes.inputLabel} id="label-select-probability">
											<FormattedMessage id="pages.contract.forecast.probability.label" />
										</InputLabel>
										<Select
											{...input}
											labelId="label-select-probability"
											IconComponent={ExpandMoreRounded}
											className={classes.select}
											disableUnderline
											displayEmpty
											disabled={
												contract.contractNegotiation.manualStatus !== 'IN_PROGRESS' &&
												contract.contractNegotiation.manualStatus !== 'TODO'
											}
										>
											{probability.map((item) => (
												<MenuItem key={item.value} value={item.value}>
													<FormattedMessage id={item.name} />
												</MenuItem>
											))}
										</Select>
										{meta.touched && meta.error && <Typography className={classes.error}>{meta.error}</Typography>}
									</>
								)}
							/>
						</Grid>
						<Grid item xs={12} md={3}>
							<Field
								name={FIELDS.CANCELLATION_MONTH}
								render={({ input, meta }) => (
									<>
										<InputLabel className={classes.inputLabel} id="label-select-cancellationMonth">
											<FormattedMessage id="pages.contract.forecast.cancellationMonth.label" />
										</InputLabel>
										<Select
											{...input}
											labelId="label-select-cancellationMonth"
											IconComponent={ExpandMoreRounded}
											className={classes.select}
											disableUnderline
											displayEmpty
											disabled={
												contract.contractNegotiation.manualStatus !== 'IN_PROGRESS' &&
												contract.contractNegotiation.manualStatus !== 'TODO'
											}
										>
											{cancellationMonth(user?.locale).map((item) => (
												<MenuItem key={item.value} value={item.value} disabled={item.value === ''}>
													{item.name}
												</MenuItem>
											))}
										</Select>
										{meta.touched && meta.error && <Typography className={classes.error}>{meta.error}</Typography>}
									</>
								)}
							/>
						</Grid>
						{(contract.contractNegotiation.manualStatus === 'IN_PROGRESS' ||
							contract.contractNegotiation.manualStatus === 'TODO') && (
							<Grid item xs={12} md={3} style={{ alignSelf: 'center' }}>
								{isWidthUp('md', width) && (
									<InputLabel className={classes.inputLabel} id="label-select-cancellationMonth">
										&nbsp;
									</InputLabel>
								)}
								<Button
									type="submit"
									variant="contained"
									size="small"
									color="primary"
									disableElevation
									disabled={pristine}
									className={classes.button}
								>
									<FormattedMessage id="pages.contract.forecast.save" />
								</Button>
								{isWidthUp('md', width) &&
									touched &&
									((touched[FIELDS.PROBABILITY] && errors[FIELDS.PROBABILITY]) ||
										(touched[FIELDS.CANCELLATION_MONTH] && errors[FIELDS.CANCELLATION_MONTH])) && (
										<Typography>&nbsp;</Typography>
									)}
							</Grid>
						)}
					</Grid>
				</form>
			)}
		/>
	)
}

export default compose(withWidth(), withStyles(styles))(ForecastForm)
