import React, { createContext, useCallback, useState } from 'react'

const RTVContext = createContext({})

const RTVContextProvider = ({ children }) => {
	const [pageTitle, setPageTitle] = useState('')
	const [breadcrumbs, setBreadcrumbs] = useState([])
	const [topActionButtons, setTopActionButtons] = useState([])

	const setGlobalPageTitle = useCallback((title) => {
		setPageTitle(title)
		document.title = `RTV Tool - ${title}`
	}, [])
	return (
		<RTVContext.Provider value={{ pageTitle, setGlobalPageTitle, topActionButtons, setTopActionButtons, breadcrumbs, setBreadcrumbs }}>
			{children}
		</RTVContext.Provider>
	)
}

export { RTVContext, RTVContextProvider }
